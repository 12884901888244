import { languageFromFullCode } from '../../../constants/browser';

let SCRIPT_CREATED = false;
let VISIBLE = false;
let LAST_GUEST_NAME = '';

export const showRocketChatWidget = (
  language,
  firstName,
  lastName,
  email,
  caseId
) => {
  const lang = languageFromFullCode(language);
  const name = `${firstName} ${lastName}`;

  if (!SCRIPT_CREATED) {
    SCRIPT_CREATED = true;
    VISIBLE = true;
    createScript();
    window.RocketChat(function () {
      this.setLanguage(lang);
      if (firstName) {
        LAST_GUEST_NAME = name;
        this.registerGuest({
          name,
          email: email || '',
          customFields: [{ key: 'caseId', value: caseId, overwrite: true }]
        });
      }
    });
  } else if (window.RocketChat) {
    window.RocketChat(function () {
      this.setLanguage(lang);
      if (firstName && LAST_GUEST_NAME !== name) {
        LAST_GUEST_NAME = name;
        this.registerGuest({
          name,
          email: email || '',
          customFields: [{ key: 'caseId', value: caseId, overwrite: true }]
        });
      }
      if (!VISIBLE) {
        VISIBLE = true;
        this.showWidget();
      }
    });
  }
};

export const hideRocketChatWidget = () => {
  VISIBLE = false;
  if (window.RocketChat) {
    window.RocketChat(function () {
      this.hideWidget();
    });
  }
};

const createScript = () => {
  window.RocketChat = function (c) {
    window.RocketChat._.push(c);
  };
  window.RocketChat._ = [];
  window.RocketChat.url = 'https://chat.playground-troy.de/livechat';

  const firstScript = document.getElementsByTagName('script')[0];

  let script = document.createElement('script');
  script.async = true;
  script.id = 'rocket-chat-script';
  script.src =
    'https://chat.playground-troy.de/livechat/rocketchat-livechat.min.js?_=201903270000';

  firstScript.parentNode.insertBefore(script, firstScript);
};
