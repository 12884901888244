import React, { useState } from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import {
  IDEAL_INSTALLMENT_VALUE,
  INSTALLMENT_VALUE,
  OFFLINE_SEPA_VALUE,
  PAY_LATER_VALUE,
  SEPA_DIRECT_DEBIT_VALUE
} from '../../../constants/payment';
import { useAppWidthClass } from '../../../utils/hooks';

import { ThemeButton, Translation } from '../../common';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    paddingBottom: theme.spacing(8)
  },
  rootTablet: {
    position: 'relative',
    paddingBottom: theme.spacing(7)
  },
  selected: {
    marginBottom: theme.spacing(0.5)
  },
  method: {
    maxWidth: 190,
    margin: 'auto'
  },
  methodCompact: {
    ...theme.typography.body1Bold
  },
  buttonAbsolute: {
    position: 'absolute !important',
    bottom: theme.spacing(1),
    left: '50%',
    transform: 'translateX(-50%)',

    '& > span': {
      whiteSpace: 'nowrap !important'
    }
  },
  buttonSmall: {
    width: 288
  },
  buttonContainer: {
    position: 'fixed',
    zIndex: 101,
    left: 0,
    width: '100vw',
    bottom: theme.spacing(8),
    padding: theme.spacing(2),
    background: alpha(theme.palette.background.default, 0.46),
    backdropFilter: 'blur(6px)',
    boxSizing: 'border-box'
  }
}));

const PaymentNow = ({
  methodName,
  methodValue,
  onPayNow,
  disabled,
  payNowLabel,
  isPaymentLoading
}) => {
  const classes = useStyles();
  const { isDesktopWidth, isTabletWidth, isMobileWidth } = useAppWidthClass();
  const [isLoading, setIsLoading] = useState(false);

  if (!methodValue) {
    return null;
  }

  const text =
    methodValue === OFFLINE_SEPA_VALUE
      ? 'SEPA_PAYMENT_BUTTON_TEXT'
      : methodValue === SEPA_DIRECT_DEBIT_VALUE
      ? 'SEPA_DEBIT_PAYMENT_BUTTON_TEXT'
      : payNowLabel;

  const hideButton = [
    INSTALLMENT_VALUE,
    IDEAL_INSTALLMENT_VALUE,
    PAY_LATER_VALUE
  ].includes(methodValue);

  return (
    <Box
      className={clsx(
        isDesktopWidth && !hideButton && classes.root,
        isTabletWidth && classes.rootTablet
      )}
    >
      {!isMobileWidth && (
        <>
          <Typography
            variant="body2"
            color="textPrimary"
            className={classes.selected}
          >
            <Translation inline>CHECKOUT_BOTTOM_PAYMENT_METHOD</Translation>
          </Typography>
          <Typography
            variant="h3"
            color="textPrimary"
            className={clsx(
              classes.method,
              isTabletWidth && classes.methodCompact
            )}
          >
            <Translation keyIfNoValue inline>
              {methodName}
            </Translation>
          </Typography>
        </>
      )}
      {!hideButton && (
        <div className={isMobileWidth && classes.buttonContainer}>
          <ThemeButton
            id="page.payments.component.payment-now.pay-now-button"
            className={clsx(
              !isMobileWidth && classes.buttonAbsolute,
              isMobileWidth && classes.buttonSmall
            )}
            variant="contained"
            size="large"
            isLoading={isPaymentLoading || isLoading}
            disabled={disabled || !methodValue || isLoading}
            onClick={() => {
                setIsLoading(true);
                onPayNow();
            }}
            textKey={text}
          />
        </div>
      )}
    </Box>
  );
};

export default PaymentNow;
