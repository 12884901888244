import React, { useEffect, useState } from 'react';
import IBAN from 'iban';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { phoneValidator } from '@troy/shared/src/utils/validation';
import { parsePhoneFromString } from '@troy/shared/src/utils/phone';
import { FormCheckbox } from '@troy/shared/src/components/common';

import { email as emailValidator } from '../../../utils/validation';

import {
  EMAIL_PLACEHOLDER,
  getBalanceWithCurrency,
  PHONE_PLACEHOLDER
} from '../../../constants/payment';

import { AccountAccessInterest, ThemeButton, Translation } from '..';
import {
  PaymentFormInput,
  PaymentFormRow,
  PaymentFormRowContent,
  PaymentFormRowLabel,
  PaymentFormSelect
} from '../../payments/index.troy';

const selectFieldInit = (number, pageName) => {
  const arr = [];
  for (let i = 0; i < number; i++) {
    arr.push({
      label: `${i + 1}`,
      value: i + 1,
      id: `page.${pageName}.component.recommend-help.day-select-${i + 1}-item`
    });
  }
  return arr;
};

const installmentsInit = (currency, locale, validRates, pageName) => {
  const result = [];

  for (let i = 0; i < validRates.length; i++) {
    result.push({
      label: `${
        currency
          ? getBalanceWithCurrency(validRates[i], currency, locale)
          : validRates[i]
      }`,
      value: validRates[i],
      id: `page.${pageName}.component.recommend-help.rate-select-${validRates[i]}-item`
    });
  }

  return result;
};

const calculateFirstPaymentDate = day => {
  const minimalDate = moment().add(5, 'd');
  let expectedDate = moment().date(day);

  let isNextMonth = false;
  while (expectedDate.isBefore(minimalDate, 'day')) {
    expectedDate = expectedDate.add(1, 'M');
    isNextMonth = true;
  }

  return {
    value: expectedDate,
    isNextMonth
  };
};

const ibanFormatter = iban => {
  if (!iban) return '';
  return IBAN.printFormat(iban, ['-']);
};

const ibanCheck = iban => iban && IBAN.isValid(iban);
const nameCheck = name => !!(name && name.trim());

const ibanValidator = iban => (ibanCheck(iban) ? undefined : 'Invalid IBAN');
const nameValidator = (name = '') => (nameCheck(name) ? undefined : 'Required');
const agreeValidator = agree => (agree ? undefined : 'Must be agreed');

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column'
  },
  legal: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: theme.shape.borderRadius * 4,
    padding: 16
  },
  submit: {
    margin: '40px auto 0 auto',
    width: 288,
    maxWidth: '100%'
  },
  remark: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.hint,
    marginTop: 40
  },
  variant2: {
    marginTop: 24
  },
  variant4: {
    marginTop: 40
  }
}));

const RecommendHelpCalculator = ({
  currency,
  locale,
  onSubmit,
  validRates,
  className,
  initialValues,
  pageName,
  formRef
}) => {
  const classes = useStyles();

  const { value, isNextMonth } = calculateFirstPaymentDate(initialValues.day);
  const [firstInstallmentDate, setFirstInstallmentDate] = useState(value);
  const [nextMonth, setNextMonth] = useState(isNextMonth);
  const [isLoading, setIsLoading] = useState(false);


  const onDayChange = value => {
    const { value: calculatedValue, isNextMonth } =
      calculateFirstPaymentDate(value);
    setFirstInstallmentDate(calculatedValue);
    setNextMonth(isNextMonth);
  };

  const selectFieldOptions = selectFieldInit(31, pageName);
  const installmentsOptions = installmentsInit(
    currency,
    locale,
    validRates,
    pageName
  );

  return (
    <Box className={className}>
      <Formik
        innerRef={formRef}
        validateOnMount
        initialValues={initialValues}
        onSubmit={({ mobilePhone, ...values }) => {
          setIsLoading(true)
          onSubmit({
            ...values,
            mobilePhone:
              (mobilePhone && parsePhoneFromString(mobilePhone).value) || null,
            firstInstallmentDate: [
              firstInstallmentDate.year(),
              firstInstallmentDate.month() + 1,
              firstInstallmentDate.date()
            ]
          });
        }}
      >
        {({ errors, isValid, values, touched, getFieldProps }) => {
          useEffect(() => {
            onDayChange(values.day);
          }, [values.day]);

          return (
            <Form id="recommend-help-calculator" className={classes.form}>
              <AccountAccessInterest variant={3}>
                <AccountAccessInterest variant={1}>
                  <PaymentFormRow center>
                    <PaymentFormRowLabel id="calculator-form-rate-label">
                      SEPA_REQUEST_PANEL_RATE
                    </PaymentFormRowLabel>
                    <PaymentFormRowContent>
                      <PaymentFormSelect
                        id="calculator-form-rate"
                        labelId="calculator-form-rate-label"
                        name="rate"
                        options={installmentsOptions}
                        detailText="SEPA_REQUEST_PANEL_MONTH"
                      />
                    </PaymentFormRowContent>
                  </PaymentFormRow>
                </AccountAccessInterest>
                <PaymentFormRow>
                  <PaymentFormRowLabel htmlFor="calculator-form-iban">
                    SEPA_DEBIT_PAYMENT_PANEL_IBAN_TEXT
                  </PaymentFormRowLabel>
                  <PaymentFormRowContent>
                    <PaymentFormInput
                      id="calculator-form-iban"
                      name="iban"
                      placeholder="DE00 0000 0000 0000 0000 00"
                      onFormat={ibanFormatter}
                      validator={ibanValidator}
                      error={errors.iban}
                      errorMessage="INCORRECT_IBAN"
                      touched={touched.iban}
                      currentValue={getFieldProps('iban').value}
                      minimumInputForErrorFeedback={9}
                    />
                  </PaymentFormRowContent>
                </PaymentFormRow>
                <PaymentFormRow>
                  <PaymentFormRowLabel htmlFor="calculator-form-account-holder">
                    SEPA_DEBIT_PAYMENT_PANEL_OWNER
                  </PaymentFormRowLabel>
                  <PaymentFormRowContent>
                    <PaymentFormInput
                      id="calculator-form-account-holder"
                      name="accountHolder"
                      validator={nameValidator}
                      error={errors.accountHolder}
                    />
                  </PaymentFormRowContent>
                </PaymentFormRow>
                <AccountAccessInterest className={classes.variant2} variant={2}>
                  <PaymentFormRow center>
                    <PaymentFormRowLabel id="calculator-form-day-label">
                      SEPA_DEBIT_PAYMENT_PANEL_MOVE_IN_DATE
                    </PaymentFormRowLabel>
                    <PaymentFormRowContent>
                      <PaymentFormSelect
                        id="calculator-form-day"
                        labelId="calculator-form-day-label"
                        name="day"
                        options={selectFieldOptions}
                        onChange={onDayChange}
                        detailText="SEPA_REQUEST__PANEL_IN_MONTH"
                      />
                    </PaymentFormRowContent>
                  </PaymentFormRow>
                  <PaymentFormRow>
                    <PaymentFormRowLabel htmlFor="calculator-form-first-installment-date">
                      SEPA_REQUEST_PANEL_DEBIT_ON_KEY
                    </PaymentFormRowLabel>
                    <PaymentFormRowContent>
                      <PaymentFormInput
                        id="calculator-form-first-installment-date"
                        readonly
                        value={
                          firstInstallmentDate &&
                          firstInstallmentDate.format('DD.MM.YYYY')
                        }
                        hint={
                          nextMonth
                            ? 'SEPA_REQUEST_PANEL_DEBIT_ON_TEXT'
                            : undefined
                        }
                      />
                    </PaymentFormRowContent>
                  </PaymentFormRow>
                </AccountAccessInterest>
              </AccountAccessInterest>
              <PaymentFormRow className={classes.legal}>
                <Translation inline>SEPA_MANDATE_LEGAL</Translation>
              </PaymentFormRow>
              <PaymentFormRow xsMargin>
                <FormCheckbox
                  id={`page.${pageName}.component.recommend-help.checkbox`}
                  name="agree"
                  validator={agreeValidator}
                  label="SEPA_MANDATE_CHECKBOX"
                  color="textPrimary"
                  alignItems="start"
                />
              </PaymentFormRow>
              <PaymentFormRow xsMargin>
                <FormCheckbox
                  id={`page.${pageName}.component.recommend-help.vvingk-checkbox`}
                  name="vvingkAgree"
                  validator={agreeValidator}
                  label="VVINGK_ACKNOWLEDGEMENT_CHECKBOX"
                  color="textPrimary"
                  alignItems="start"
                />
              </PaymentFormRow>
              <PaymentFormRow>
                <PaymentFormRowLabel htmlFor="calculator-form-email">
                  SEPA_REQUEST_PANEL_DEBIT_EMAIL_KEY
                </PaymentFormRowLabel>
                <PaymentFormRowContent>
                  <PaymentFormInput
                    id="calculator-form-email"
                    name="email"
                    placeholder={EMAIL_PLACEHOLDER}
                    validator={emailValidator}
                    error={errors.email}
                    hint="SEPA_REQUEST_PANEL_DEBIT_EMAIL_TEXT"
                  />
                </PaymentFormRowContent>
              </PaymentFormRow>
              <PaymentFormRow>
                <PaymentFormRowLabel htmlFor="calculator-form-mobile-phone">
                  SEPA_REQUEST_PANEL_PHONE_KEY
                </PaymentFormRowLabel>
                <PaymentFormRowContent>
                  <PaymentFormInput
                    id="calculator-form-mobile-phone"
                    name="mobilePhone"
                    placeholder={PHONE_PLACEHOLDER}
                    validator={phoneValidator}
                    error={errors.mobilePhone}
                    hint="SEPA_REQUEST_PANEL_PHONE_TEXT"
                  />
                </PaymentFormRowContent>
              </PaymentFormRow>
              <AccountAccessInterest className={classes.variant4} variant={4} />
              <ThemeButton
                id={`page.${pageName}.component.calculator.button`}
                className={classes.submit}
                variant="contained"
                type="submit"
                size="large"
                disabled={!isValid || isLoading}
                textKey="SEPA_REQUEST_PANEL_BUTTON_TEXT"
                startIcon="confirm"
                startIconColor="primaryContrast"
                isLoading={isLoading}
              />
            </Form>
          );
        }}
      </Formik>
      <Box className={classes.remark}>
        <Translation>CALCULATOR_FINEPRINT</Translation>
      </Box>
    </Box>
  );
};

export default RecommendHelpCalculator;
