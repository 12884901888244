import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';

import { FormCheckbox, Text } from '@troy/shared/src/components/common';

import {
  EMAIL_FIELD,
  SMS_FIELD,
  WHATS_APP_FIELD
} from '../../../constants/response-channel';
import { hasWhatsAppResponseChannelSelector } from '../../../store/selectors';
import { useAppWidthClass } from '../../../utils/hooks';

import { RadioGroup } from '../../common';
import ResponseChannelFormField from '../ResponseChannelFormField';
import { isMobileDevice } from '../../../utils/browser';

const useStyles = makeStyles(theme => ({
  container: {
    width: 415,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  group: {
    marginTop: theme.spacing(3)
  },
  row: {
    alignItems: 'flex-start',
    marginLeft: theme.spacing(-1),
    marginRight: 0,

    '&:nth-of-type(1n+2)': {
      marginTop: theme.spacing(3)
    }
  },
  radioIcon: {
    padding: theme.spacing(0.5),

    '& svg:first-of-type': {
      transform: 'scale(0.8)'
    }
  },
  radioLabel: {
    marginLeft: theme.spacing(3),
    width: '100%'
  },
  inputRow: {
    marginTop: theme.spacing(1)
  },
  checkbox: {
    marginTop: theme.spacing(2)
  },
  checkboxLabel: {
    ...theme.typography.body1,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2)
  }
}));

const UploadFileModalResponseChannel = ({
  values,
  touched,
  setFieldValue,
  invalidEmail,
  invalidSms,
  invalidWhatsapp,
  setHasBottomMobileSpacing
}) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();
  const withWhatsApp = useSelector(hasWhatsAppResponseChannelSelector);

  const onTextFieldFocus = (value, onChange) => fieldName => () => {
    if (value !== fieldName) {
      onChange(fieldName);
    }
  };

  const onMethodClick = onTextFieldFocus(values.method, value => {
    setFieldValue('method', value);
  });

  const handlePhoneFocus = () => {
    if (isMobileDevice()) {
      setHasBottomMobileSpacing(true);
    }
  };

  const handlePhoneBlur = () => {
    setHasBottomMobileSpacing(false);
  };

  return (
    <div className={!isMobileWidth ? classes.container : undefined}>
      <Text
        translated
        component="p"
        variant="body1"
        color="textPrimary"
        align="center"
      >
        RESPONSE_CHANNEL_DESCRIPTION
      </Text>
      <div className={classes.group}>
        <RadioGroup
          labelInteractive
          name="method"
          radioClassName={clsx(classes.row, isMobileWidth && classes.rowMobile)}
          radioIconClassName={classes.radioIcon}
          radioLabelClassName={classes.radioLabel}
          options={[
            {
              label: (
                <ResponseChannelFormField
                  inputRowClassName={classes.inputRow}
                  name={EMAIL_FIELD}
                  id="page.postbox.component.response-channel-radiogroup.email.field"
                  onClick={onMethodClick}
                  invalid={invalidEmail}
                  invalidMessage={
                    touched[EMAIL_FIELD]
                      ? 'RESPONSE_CHANNEL_NEW_EMAIL'
                      : undefined
                  }
                  header="E-Mail"
                  headerIcon="mailIcon"
                />
              ),
              value: EMAIL_FIELD,
              id: 'page.postbox.component.response-channel-radiogroup.email',
              testLabelRole: 'response-channel-item'
            },
            {
              label: (
                <ResponseChannelFormField
                  withPhoneInput
                  inputRowClassName={classes.inputRow}
                  name={SMS_FIELD}
                  id="page.postbox.component.response-channel-radiogroup.sms.field"
                  onClick={onMethodClick}
                  invalid={invalidSms}
                  invalidMessage={
                    touched[SMS_FIELD]
                      ? 'RESPONSE_CHANNEL_NEW_PHONE'
                      : undefined
                  }
                  header="SMS"
                  headerIcon="contactChat"
                  onFocus={handlePhoneFocus}
                  onBlur={handlePhoneBlur}
                />
              ),
              value: SMS_FIELD,
              id: 'page.postbox.component.response-channel-radiogroup.sms',
              testLabelRole: 'response-channel-item'
            },
            withWhatsApp && {
              label: (
                <ResponseChannelFormField
                  withPhoneInput
                  inputRowClassName={classes.inputRow}
                  name={WHATS_APP_FIELD}
                  id="page.postbox.component.response-channel-radiogroup.whatsapp.field"
                  onClick={onMethodClick}
                  currentValue={values[WHATS_APP_FIELD]}
                  invalid={invalidWhatsapp}
                  invalidMessage={
                    touched[WHATS_APP_FIELD]
                      ? 'RESPONSE_CHANNEL_NEW_PHONE'
                      : undefined
                  }
                  header="Whatsapp"
                  headerIcon="whatsappIcon"
                />
              ),
              value: WHATS_APP_FIELD,
              id: 'page.postbox.component.response-channel-radiogroup.whatsapp',
              testLabelRole: 'response-channel-item'
            }
          ].filter(v => !!v)}
        />
        <FormCheckbox
          className={classes.checkbox}
          labelClassName={classes.checkboxLabel}
          id="page.postbox.component.response-channel.remember-checkbox"
          name="remember"
          label="RESPONSE_CHANNEL_REMEMBER"
          variant="normal"
          color="textPrimary"
          value={!!values.remember}
          alignItems="start"
        />
      </div>
    </div>
  );
};

export default UploadFileModalResponseChannel;
