import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import { CustomIcon } from '@troy/shared/src/components/common';

import { Select, Translation } from '../../common';

const useStyles = makeStyles(theme => ({
  select: {
    ...theme.typography.body1,
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: theme.shape.borderRadius * 4,
    overflow: 'hidden',

    '&:before, &:after': {
      content: 'unset'
    }
  },
  selectRoot: {
    padding: '3px 23px 3px 15px'
  },
  selectIcon: {
    top: '50%',
    right: 8,
    transform: 'translateY(-50%)'
  },
  selectItem: {
    '&:hover:not([aria-selected="true"])': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main
    }
  },
  selectDetail: {
    ...theme.typography.body1,
    marginLeft: 16,
    display: 'inline-block',
    color: theme.palette.text.primary
  }
}));

const PaymentFormSelect = ({
  id,
  labelId,
  name,
  options,
  onChange,
  detailText
}) => {
  const classes = useStyles();

  return (
    <>
      <Select
        id={id}
        labelId={labelId}
        className={classes.select}
        classNameRoot={classes.selectRoot}
        classNameMenuItem={classes.selectItem}
        name={name}
        options={options}
        onChange={onChange}
        iconComponent={props => (
          <CustomIcon
            className={clsx(props.className, classes.selectIcon)}
            icon="expandMore"
            size="xs"
            variant="textPrimary"
          />
        )}
      />
      {detailText && (
        <Translation className={classes.selectDetail}>{detailText}</Translation>
      )}
    </>
  );
};

export default PaymentFormSelect;
