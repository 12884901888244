import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import {
  portalFooterLogoHeightSelector,
  portalFooterLogoSelector,
  portalFooterLogoSideImageSelector
} from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
  messagePadding: {
    marginLeft: 8,
    paddingBottom: 8
  }
}));

interface FooterLogoProps {
  className?: string;
  noLogo?: boolean;
}

const FooterLogo: React.FC<FooterLogoProps> = ({
  className,
  noLogo
}): React.ReactElement => {
  const classes = useStyles();
  const footerLogo = useSelector(portalFooterLogoSelector);
  const footerLogoHeight = useSelector(portalFooterLogoHeightSelector);
  const logoSideImage = useSelector(portalFooterLogoSideImageSelector);

  return (
    <div className={className}>
      {!noLogo && <img src={footerLogo} alt="Logo" height={footerLogoHeight} />}
      {!!logoSideImage && (
        <img
          className={!noLogo ? classes.messagePadding : undefined}
          src={logoSideImage}
          alt="message bubble"
        />
      )}
    </div>
  );
};

export default FooterLogo;
