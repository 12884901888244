import { createReducer } from 'redux-act';

import * as actions from '../actions/decisions';

const DEFAULT_STATE = {};

export default createReducer(
  {
    [actions.decisionsReceived]: (state, decisions) => {
      return decisions;
    },
    [actions.decisionsReset]: () => {
      return { ...DEFAULT_STATE };
    }
  },
  DEFAULT_STATE
);
