export const PROOF_OF_PAYMENT = 'VOUCHER';
export const CONFIRMATION_OF_TERMINATION = 'CONFIRMATION_OF_TERMINATION';
export const CERTIFICATE_OF_REGISTRATION = 'CERTIFICATE_OF_REGISTRATION';
export const COPY_OF_INVOICE = 'COPY_OF_INVOICE';
export const DEATH_CERTIFICATE = 'DEATH_CERTIFICATE';
export const OTHER = 'OTHER';

export const SELECT_VALUES = [
  PROOF_OF_PAYMENT,
  CONFIRMATION_OF_TERMINATION,
  CERTIFICATE_OF_REGISTRATION,
  COPY_OF_INVOICE,
  DEATH_CERTIFICATE,
  OTHER
].map(value => ({
  value,
  label: `UPLOAD_FILE_TYPE_${value}`,
  id: `page.post-box.component.upload-select.${value}-item`
}));

export const TRACKING_ID = {
  [PROOF_OF_PAYMENT]: 'proofofpayment',
  [CONFIRMATION_OF_TERMINATION]: 'confirmationoftermination',
  [CERTIFICATE_OF_REGISTRATION]: 'certificateofregistration',
  [COPY_OF_INVOICE]: 'copyofinvoice',
  [DEATH_CERTIFICATE]: 'deathcertificate',
  [OTHER]: 'other'
};
