import React from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { onClickWrapper } from '../../utils/ui';
import { useAppWidthClass } from '../../utils/hooks';
import {
  UIFilterOption,
  UINotificationChannelFilter,
  UINotificationDirectionFilter
} from '../../types/notifications';
import { UIDirectionFilters } from '../../utils/post-box/utils';

import { FormCheckbox, Text, Translation } from '../common';

const useStyles = makeStyles((theme: Theme) => ({
  rootDesktop: {
    margin: '16px 115px 40px 122px'
  },
  rootTablet: {
    margin: '8px 40px 24px 40px'
  },
  rootMobile: {
    margin: '8px 16px 24px 16px'
  },
  filterBy: {
    width: 88,
    display: 'inline-block'
  },
  contentDesktop: {
    marginTop: 16,
    display: 'flex'
  },
  contentCompact: {
    display: 'flex',
    flexDirection: 'column'
  },
  groupFiltersRowCompact: {
    display: 'flex'
  },
  groupFilters: {
    width: 200,
    padding: '8px 24px 8px 0',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    borderRight: `2px solid ${theme.palette.primary.main}`
  },
  groupFiltersCompact: {
    paddingLeft: 8,
    display: 'flex',
    flexDirection: 'column'
  },
  groupButton: {
    border: 'none',
    padding: 0,
    display: 'inline-flex',
    height: 16,
    background: 'transparent',
    cursor: 'pointer',

    '& span': {
      display: 'inline-block',
      height: 16
    }
  },
  groupButtonMargin: {
    '&:nth-of-type(1n+2)': {
      marginTop: 8
    }
  },
  categoryFilters: {
    marginLeft: 40
  },
  categoryFiltersCompact: {
    marginTop: 24
  },
  checkbox: {
    marginRight: 48
  },
  checkboxCompact: {
    marginRight: 16
  }
}));

const FilterPanelHeader = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <Text
      translated
      component="h5"
      variant="body2"
      color="textPrimary"
      className={classes.filterBy}
    >
      FILTER_PANEL_HEADER
    </Text>
  );
};

interface PostBoxFilterContentProps {
  directionFilterCounts: Record<UINotificationDirectionFilter, number>;
  onDirectionFilterChange: (type: UINotificationDirectionFilter) => void;
  directionFilter: UINotificationDirectionFilter;
  filterOptions: UIFilterOption[];
  filterValues: Record<UINotificationChannelFilter, boolean>;
  onOptionChange: (
    name: UINotificationChannelFilter
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PostBoxFilterContent: React.FC<PostBoxFilterContentProps> = ({
  directionFilterCounts,
  onDirectionFilterChange,
  directionFilter,
  filterOptions,
  filterValues,
  onOptionChange
}): React.ReactElement => {
  const classes = useStyles();
  const { isMobileWidth, isDesktopWidth, isTabletWidth } = useAppWidthClass();
  const isCompact = isMobileWidth || isTabletWidth;

  return (
    <div
      className={clsx(
        isDesktopWidth && classes.rootDesktop,
        isTabletWidth && classes.rootTablet,
        isMobileWidth && classes.rootMobile
      )}
    >
      {isDesktopWidth && <FilterPanelHeader />}
      <div
        className={
          isDesktopWidth ? classes.contentDesktop : classes.contentCompact
        }
      >
        <div className={clsx(isCompact && classes.groupFiltersRowCompact)}>
          {isCompact && <FilterPanelHeader />}
          <div
            className={
              isDesktopWidth
                ? classes.groupFilters
                : classes.groupFiltersCompact
            }
          >
            {UIDirectionFilters.map(({ label, type, id }) => {
              const chosen = directionFilter === type;
              const count = directionFilterCounts[type];

              return (
                <button
                  key={id}
                  id={id}
                  className={clsx(
                    classes.groupButton,
                    isDesktopWidth && classes.groupButtonMargin
                  )}
                  onClick={onClickWrapper(id, () =>
                    onDirectionFilterChange(type)
                  )}
                >
                  <Text
                    variant={chosen ? 'body2Bold' : 'body2'}
                    color="textPrimary"
                    component="span"
                  >
                    <Translation keyIfNoValue inline>
                      {label}
                    </Translation>
                    <span> ({count})</span>
                  </Text>
                </button>
              );
            })}
          </div>
        </div>
        <div
          className={
            isDesktopWidth
              ? classes.categoryFilters
              : classes.categoryFiltersCompact
          }
        >
          {filterOptions &&
            filterOptions.length > 0 &&
            filterOptions.map(({ label, type }, key) => (
              <FormCheckbox
                key={key}
                className={
                  isDesktopWidth ? classes.checkbox : classes.checkboxCompact
                }
                id={`postbox.filter.${type.toLowerCase()}-checkbox`}
                label={label}
                value={filterValues[type] || false}
                onClick={onOptionChange(type)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default PostBoxFilterContent;
