import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import {
  errorRedirect as errorRedirectAction,
  logout,
  setError,
  setErrorStatus
} from '../actions';
import { ERROR } from '../../constants/routes';

import {
  ABORT_ERROR,
  EXCEPTION,
  FORBIDDEN,
  NOT_FOUND,
  SERVER_ERROR
} from '../../constants/errorStatuses';
import { enqueueSnackbar } from '@troy/shared/src/store/actions';

export function* errorRedirect({ payload }) {
  yield put(setErrorStatus(payload));
  yield put(push(ERROR));
}

export function* handleError({ payload: err }) {
  yield put(setError(err));

  if (err.status === ABORT_ERROR) {
    yield put(errorRedirectAction(ABORT_ERROR));
  } else if (
    err.status >= 500 ||
    (err.networkError &&
      err.networkError.statusCode &&
      err.networkError.statusCode >= 500) ||
    (err.networkError &&
      !err.networkError.statusCode &&
      (err.message === 'Network error: Failed to fetch' ||
        err.message ===
          'Network error: NetworkError when attempting to fetch resource.'))
  ) {
    yield put(errorRedirectAction(SERVER_ERROR));
  } else if (
    err.status === NOT_FOUND ||
    (err.networkError &&
      err.networkError.statusCode &&
      err.networkError.statusCode === NOT_FOUND)
  ) {
    yield put(errorRedirectAction(NOT_FOUND));
  } else if (err.status === FORBIDDEN) {
    yield put(setErrorStatus(err.status));
    yield put(logout({ useToken: false, showErrorPage: true }));
  } else if (!err.message || err.status === EXCEPTION) {
    yield put(errorRedirectAction(EXCEPTION));
  } else {
    yield put(
      enqueueSnackbar({
        message: err.message,
        options: {
          variant: 'error'
        }
      })
    );
  }
}
