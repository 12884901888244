import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

import { Translation as TranslationCommon } from '@troy/shared/src/components/common';

import {
  hasCustomerTextsSelector,
  hasStaticTextsSelector,
  portalDirectorSignatureSelector,
  showTranslationTooltipsSelector,
  translationsSelector
} from '../../../store/selectors';
import { SkeletonBar } from '../SkeletonModule';
import { TooltipProps } from '@mui/material';

const useStyles = makeStyles(theme => ({
  skeleton: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const imgTagRegex = new RegExp(/<img .*\/>/);

interface TranslationProps {
  children: string;
  className?: string;
  placement?: TooltipProps['placement'];
  withSignature?: boolean;
  inline?: boolean;
  keyIfNoValue?: boolean;
  noWrap?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  id?: string;
  htmlFor?: string;
  component?: React.ElementType;
  skipLoadingCheck?: boolean;
  isFragment?: boolean;
}

const Translation: React.FC<TranslationProps> = ({
  className,
  children,
  placement,
  withSignature,
  keyIfNoValue,
  inline,
  noWrap,
  onClick,
  id,
  htmlFor,
  component: Component = 'div',
  skipLoadingCheck,
  isFragment
}) => {
  const classes = useStyles();
  const directorSignature = useSelector(portalDirectorSignatureSelector);
  const showTranslationTooltips = useSelector(showTranslationTooltipsSelector);
  const translations = useSelector(translationsSelector);
  const hasStaticTexts = useSelector(hasStaticTextsSelector);
  const hasCustomerTexts = useSelector(hasCustomerTextsSelector);

  if (children) {
    if (
      !skipLoadingCheck &&
      (!hasStaticTexts || (!translations[children] && !hasCustomerTexts))
    ) {
      return (
        <div className={classes.skeleton}>
          <SkeletonBar width="43px" animation="pulse" />
        </div>
      );
    }

    let childrenNew = children;
    let addSignature = false;
    let componentChildren;

    if (withSignature && translations) {
      const translation = translations[children] || children;
      if (imgTagRegex.test(translation)) {
        childrenNew = translation.replace(imgTagRegex, '');
        addSignature = true;
        componentChildren = (
          <>
            {childrenNew}
            <img
              alt="signature"
              className="agent-signature"
              src={directorSignature}
            />
          </>
        );
      }
    }

    return (
      <TranslationCommon
        className={className}
        componentChildren={componentChildren}
        propPlace={addSignature ? 'top' : undefined}
        inline={inline}
        keyIfNoValue={keyIfNoValue}
        component={inline ? 'span' : Component}
        showTranslationTooltips={showTranslationTooltips}
        placement={placement}
        isFragment={isFragment}
        noWrap={noWrap}
        id={id}
        htmlFor={htmlFor}
        onClick={onClick}
      >
        {addSignature ? ' ' : children}
      </TranslationCommon>
    );
  } else {
    return null;
  }
};

export default Translation;
