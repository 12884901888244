import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

import {
  liabilityReturnGoodsPickupAddressDataSelector,
  liabilityReturnGoodsPickupContactSelector,
  liabilityReturnGoodsPickupDateSelector,
  liabilityReturnGoodsPickupTimeRangeSelector
} from '../../../store/selectors';
import { Button, CustomIcon, Text } from '@troy/shared/src/components/common';

import { Translation } from '../../common';
import {
  ReturnGoodsContactDetails,
  ReturnGoodsDateDetails
} from '../../return-goods';

import boxImg from '../../../assets/img/rented-goods/rented_goods_box.svg';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  boxImg: {
    width: 40,
    height: 40
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  description: {
    ...theme.typography.body1,
    marginTop: theme.spacing(3),
    color: theme.palette.text.primary
  },
  collapse: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    ...theme.typography.body1Bold,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    border: 0,
    background: 'none',
    color: theme.palette.text.hint,
    textAlign: 'left',
    cursor: 'pointer',
    textTransform: 'capitalize',

    '&:focus,&:active': {
      outline: 'none'
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  iconOpen: {
    transform: 'rotate(180deg)'
  },
  line: {
    flex: 1,
    marginLeft: theme.spacing(3),
    height: 1,
    background: theme.palette.text.disabled
  },
  details: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row'
  },
  detailsMobile: {
    flexDirection: 'column'
  },
  detailsSection: {
    width: '50%',

    '&:first-of-type': {
      marginRight: theme.spacing(1.5)
    },
    '&:last-of-type': {
      marginLeft: theme.spacing(1.5)
    }
  },
  detailsSectionMobile: {
    width: '100%',

    '&:last-of-type': {
      marginTop: theme.spacing(5)
    }
  }
}));

const ReturnGoodsInfoSummary = ({ isTabletWidth, isMobileWidth }) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);

  const { customerName, street, postCode, city, addressCountry } = useSelector(
    liabilityReturnGoodsPickupAddressDataSelector
  );

  const phone = useSelector(liabilityReturnGoodsPickupContactSelector);
  const pickupDate = useSelector(liabilityReturnGoodsPickupDateSelector);
  const time = useSelector(liabilityReturnGoodsPickupTimeRangeSelector);

  const handleShowMoreLess = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen, setOpen]);

  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.titleRow}>
          <img src={boxImg} alt="Box" className={classes.boxImg} />
          <Typography
            className={classes.title}
            component="h3"
            variant={isTabletWidth || isMobileWidth ? 'h4' : 'h3'}
            color="secondary"
          >
            <Translation inline keyIfNoValue>
              BSH_RETURNGOODS_WORKFLOW_HEADLINE
            </Translation>
          </Typography>
        </Box>
        <Translation className={classes.description} keyIfNoValue>
          BSH_RETURNGOODS_SUCCESS_COPY
        </Translation>
        <Box className={classes.collapse}>
          <Button
            className={classes.button}
            variant="native"
            id="page.home.component.return-goods.show-more"
            onClick={handleShowMoreLess}
          >
            <CustomIcon
              className={clsx(classes.icon, isOpen && classes.iconOpen)}
              icon="expandMore"
              variant="hint"
              size="small"
            />
            <Text
              translated
              variant="body1Bold"
              component="span"
              color="textHint"
            >
              {isOpen ? 'BSH_RETURNGOODS_SHOWLESS' : 'BSH_RETURNGOODS_SHOWMORE'}
            </Text>
          </Button>
          <Box className={classes.line} />
        </Box>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { height: 'auto' },
                collapsed: { height: 0 }
              }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              <Box
                className={clsx(
                  classes.details,
                  isMobileWidth && classes.detailsMobile
                )}
              >
                <Box
                  className={
                    isMobileWidth
                      ? classes.detailsSectionMobile
                      : classes.detailsSection
                  }
                >
                  <ReturnGoodsContactDetails
                    title="BSH_RETURNGOODS_WORKFLOW_SUBHEAD2"
                    customerName={customerName}
                    street={street}
                    postCode={postCode}
                    city={city}
                    addressCountry={addressCountry}
                    phone={phone}
                  />
                </Box>
                <Box
                  className={
                    isMobileWidth
                      ? classes.detailsSectionMobile
                      : classes.detailsSection
                  }
                >
                  <ReturnGoodsDateDetails
                    vertical
                    title="BSH_RETURNGOODS_WORKFLOW_SUBHEAD1"
                    pickupDate={pickupDate}
                    time={time}
                  />
                </Box>
              </Box>
            </motion.section>
          )}
        </AnimatePresence>
      </Box>
    </>
  );
};

export default ReturnGoodsInfoSummary;
