import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import cloudFile from '../../../assets/img/file_upload/cloud-file.svg';
import cloud1 from '../../../assets/img/file_upload/cloud-1.svg';
import cloud2 from '../../../assets/img/file_upload/cloud-2.svg';
import cloudFront from '../../../assets/img/file_upload/cloud-front.svg';
import cloudGreen from '../../../assets/img/file_upload/cloud-green.svg';
import { useAppWidthClass } from '../../../utils/hooks';

const transitionSpeed = 'all 500ms ease-in-out';

const useStyles = makeStyles(() => ({
  bg: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%)',
    width: 471,
    height: 194
  },
  bgMobile: {
    transform: 'translate(-44%) scale(0.6)'
  },
  bgInner: {
    position: 'relative'
  },
  file: {
    position: 'absolute',
    top: 45,
    transition: transitionSpeed
  },
  filePosition0: {
    left: 25,
    transform: 'rotate(0)'
  },
  filePosition1: {
    left: 112,
    transform: 'rotate(30deg)'
  },
  filePosition_withFiles: {
    left: 203,
    transform: 'rotate(70deg)'
  },
  filePosition2: {
    left: 304,
    transform: 'rotate(0)'
  },
  filePosition3: {
    left: 366,
    transform: 'rotate(50deg) scale(1.28)'
  },
  cloud1: {
    position: 'absolute',
    top: 90,
    transition: transitionSpeed
  },
  cloud1Position0: {
    left: 80
  },
  cloud1Position1: {
    left: 32
  },
  cloud1Position_withFiles: {
    left: -16
  },
  cloud1Position2: {
    left: -72
  },
  cloud1Position3: {
    left: -152
  },
  cloud2: {
    position: 'absolute',
    top: 63,
    transition: transitionSpeed
  },
  cloud2Position0: {
    left: 298
  },
  cloud2Position1: {
    left: 250
  },
  cloud2Position_withFiles: {
    left: 218
  },
  cloud2Position2: {
    left: 194
  },
  cloud2Position3: {
    left: 42
  },
  cloudFront: {
    position: 'absolute',
    top: 59,
    transition: transitionSpeed
  },
  cloudFrontPosition0: {
    left: 384
  },
  cloudFrontPosition1: {
    left: 288
  },
  cloudFrontPosition_withFiles: {
    left: 192
  },
  cloudFrontPosition2: {
    left: 104
  },
  cloudFrontPosition3: {
    left: -55
  },
  cloudGreen: {
    position: 'absolute',
    top: 0,
    transition: transitionSpeed
  },
  cloudGreenPosition0: {
    left: 391
  },
  cloudGreenPosition1: {
    left: 303
  },
  cloudGreenPosition_withFiles: {
    left: 303
  },
  cloudGreenPosition2: {
    left: 167
  },
  cloudGreenPosition3: {
    left: 63
  }
}));

const UploadFileModalHeaderAnimation = ({ modalStep, hasFiles }) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  return (
    <div className={clsx(classes.bg, isMobileWidth && classes.bgMobile)}>
      <div className={classes.bgInner}>
        <img
          className={clsx(
            classes.cloudGreen,
            classes[
              `cloudGreenPosition${
                modalStep === 1 && hasFiles ? '_withFiles' : modalStep
              }`
            ]
          )}
          src={cloudGreen}
          alt="cloud green"
        />
        <img
          className={clsx(
            classes.cloud1,
            classes[
              `cloud1Position${
                modalStep === 1 && hasFiles ? '_withFiles' : modalStep
              }`
            ]
          )}
          src={cloud1}
          alt="cloud 1"
        />
        <img
          className={clsx(
            classes.cloud2,
            classes[
              `cloud2Position${
                modalStep === 1 && hasFiles ? '_withFiles' : modalStep
              }`
            ]
          )}
          src={cloud2}
          alt="cloud 2"
        />
        <img
          className={clsx(
            classes.file,
            classes[
              `filePosition${
                modalStep === 1 && hasFiles ? '_withFiles' : modalStep
              }`
            ]
          )}
          src={cloudFile}
          alt="file in clouds"
        />
        <img
          className={clsx(
            classes.cloudFront,
            classes[
              `cloudFrontPosition${
                modalStep === 1 && hasFiles ? '_withFiles' : modalStep
              }`
            ]
          )}
          src={cloudFront}
          alt="cloud in the front"
        />
      </div>
    </div>
  );
};

export default UploadFileModalHeaderAnimation;
