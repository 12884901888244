import { email as emailRegex } from '../constants/regex';

export const email = (value = '') =>
  !emailRegex.test((value || '').trim()) ? 'Invalid email address' : undefined;

export const required = value =>
  value || typeof value === 'number' ? undefined : 'Required';

export const notEmptyValidator = (text = '') =>
  !!(text && text.trim()) ? undefined : 'Required';

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
