import { createAction } from 'redux-act';

export const setTranslations = createAction('Set translations');

export const getAvailableLanguages = createAction('Get available languages');
export const availableLanguagesReceived = createAction(
  'Available languages received'
);

export const setLanguage = createAction('Set language if exists');
export const setLanguageFinal = createAction('Set language value');

export const selectLanguageDefault = createAction(
  'Select language default function'
);
export const selectLanguage = createAction('Select language');
export const selectBrowserLanguage = createAction('Select browser language');

export const getTranslations = createAction('Get translations');

export const translationsReset = createAction('Reset translations');

export const customerTextsReset = createAction('Reset customer texts');
