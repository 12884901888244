import React from 'react';
import { parsePhoneFromString } from '@troy/shared/src/utils/phone';

import { installmentRates, validRates } from '../../../constants/payment';

import PaymentCalculator from '../PaymentCalculator';

const InstallmentContent = ({
  currency,
  locale,
  balance,
  firstName,
  lastName,
  onSubmit,
  className,
  email,
  mobilePhone,
  phone,
  component: Component = PaymentCalculator,
  ...props
}) => {
  const name = firstName + ((firstName && ' ') + lastName || '');
  const validRatesArr = validRates(balance, installmentRates);
  const initialValues = {
    rate: validRatesArr[0],
    iban: '',
    accountHolder: name,
    day: 1,
    agree: false,
    vvingkAgree: false,
    email: email,
    mobilePhone: parsePhoneFromString(mobilePhone || phone).value
  };
  return (
    <Component
      currency={currency}
      locale={locale}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validRates={validRatesArr}
      className={className}
      {...props}
    />
  );
};

export default InstallmentContent;
