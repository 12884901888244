import { createReducer } from 'redux-act';

import * as actions from '../actions/customers';

const DEFAULT_STATE = {
  customers: [],
  returnGoodsLoading: false,
  returnGoodsError: false
};

export default createReducer(
  {
    [actions.customersReceived]: (state, customers) => ({
      customers
    }),
    [actions.customersReset]: () => {
      return { ...DEFAULT_STATE };
    },
    [actions.submitReturnGoods]: state => ({
      ...state,
      returnGoodsLoading: true,
      returnGoodsError: false
    }),
    [actions.submitReturnGoodsOk]: state => ({
      ...state,
      returnGoodsLoading: false,
      returnGoodsError: false
    }),
    [actions.submitReturnGoodsError]: state => ({
      ...state,
      returnGoodsLoading: false,
      returnGoodsError: true
    })
  },
  DEFAULT_STATE
);
