import React from 'react';
import { FormControl as MaterialFormControl, InputLabel } from '@mui/material';
import { ClassNameMap } from '@mui/styles/withStyles/withStyles';
import { Translation } from '../index';

interface FormControlProps {
  variant?: 'standard' | 'outlined' | 'filled';
  focused?: boolean;
  error?: boolean;
  label?: string;
  labelClasses?: Partial<ClassNameMap>;
  labelShrink?: boolean;
  id?: string;
  translated?: boolean;
  className?: string;
  disabled?: boolean;
}

const FormControl: React.FC<FormControlProps> = ({
  variant,
  focused,
  error,
  label,
  labelClasses,
  labelShrink,
  className,
  id,
  translated,
  disabled,
  children
}) => (
  <MaterialFormControl variant={variant} className={className}>
    {label ? (
      <InputLabel
        classes={labelClasses}
        focused={focused}
        shrink={labelShrink}
        error={error}
        disabled={disabled}
        id={id}
      >
        {translated ? (
          <Translation inline keyIfNoValue>
            {label}
          </Translation>
        ) : (
          label
        )}
      </InputLabel>
    ) : null}
    {children}
  </MaterialFormControl>
);

export default FormControl;
