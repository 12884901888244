import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cookies from 'react-cookies';

import {
  communicationToneSelector,
  defaultLanguageSelector,
  emailSelector,
  firstNameSelector,
  isChatBotVisibleSelector,
  isLiveChatVisibleSelector,
  isRocketChatWidgetVisibleSelector,
  languageSelector,
  lastNameSelector,
  phoneOrMobileSelector,
  tokenSelector,
  translationsSelector,
  usernameSelector
} from '../../../store/selectors';
import { uploadFile } from '../../../store/actions';

import { isTokenValid } from '../../../utils/jwt';
import { SELECT_VALUES } from '../../post-box/UploadFileModal/constants';

import { hideCurrentLiveAgentButton, showLiveAgentChat } from './liveAgentChat';
import { showChatBot } from './quriobot';
import { hideRocketChatWidget, showRocketChatWidget } from './rocketChatWidget';
import {
  COOKIES_FUNCTIONAL_SELECTED_KEY,
  COOKIES_SELECTED_KEY
} from '../../main/CookieAgreement';

const getUploadTypesForQurio = translations => {
  return SELECT_VALUES.map((v, index) => ({
    value: index + 1,
    label: translations[v.label] || v.label
  }));
};

const getUploadTypesForApi = translations => {
  return SELECT_VALUES.map((v, index) => ({
    value: v.value,
    label: translations[v.label] || v.label
  }));
};

const ChatComponent = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const isAuthenticated = isTokenValid(token);

  const [areChatCookiesAllowed, setAreChatCookiesAllowed] = useState(false);

  const checkCookieSelectionResult = () => {
    const cookiesSelected = cookies.load(COOKIES_SELECTED_KEY) === '1';
    const functionalCookiesSelected =
      cookies.load(COOKIES_FUNCTIONAL_SELECTED_KEY) === '1';

    if (cookiesSelected) {
      setAreChatCookiesAllowed(functionalCookiesSelected);
      return true;
    }
    return false;
  };

  useEffect(() => {
    const cookieModalClosed = checkCookieSelectionResult();

    if (!cookieModalClosed) {
      const handler = setInterval(() => {
        const cookieModalClosed = checkCookieSelectionResult();
        if (cookieModalClosed) {
          clearInterval(handler);
        }
      }, 1000);
    }
  }, []);

  const rocketChatWidget =
    useSelector(isRocketChatWidgetVisibleSelector) && isAuthenticated;
  const chatShow = useSelector(isLiveChatVisibleSelector);
  const chatBotShow = useSelector(isChatBotVisibleSelector);

  const defaultLanguageFull = useSelector(defaultLanguageSelector);
  const language = useSelector(languageSelector) || defaultLanguageFull;
  const firstName = useSelector(firstNameSelector);
  const lastName = useSelector(lastNameSelector);
  const email = useSelector(emailSelector);
  const caseId = useSelector(usernameSelector);

  const translations = useSelector(translationsSelector);
  const uploadTypesForQurio = getUploadTypesForQurio(translations);
  const uploadTypesForApi = getUploadTypesForApi(translations);

  const customerEmail = useSelector(emailSelector);
  const customerPhone = useSelector(phoneOrMobileSelector);

  const communicationTone = useSelector(communicationToneSelector);

  const uploadCallback = (files, type, method, value, description = '') => {
    dispatch(
      uploadFile({
        files,
        type,
        method,
        value,
        remember: false,
        description
      })
    );
  };

  useEffect(() => {
    if (areChatCookiesAllowed) {
      if (rocketChatWidget) {
        showRocketChatWidget(language, firstName, lastName, email, caseId);
        showChatBot(isAuthenticated, false);
        hideCurrentLiveAgentButton();
      } else if (chatBotShow) {
        hideRocketChatWidget();
        showChatBot(
          isAuthenticated,
          true,
          language,
          firstName,
          lastName,
          email,
          caseId,
          uploadTypesForQurio,
          uploadTypesForApi,
          uploadCallback,
          customerEmail,
          customerPhone,
          communicationTone
        );
      } else if (chatShow) {
        hideRocketChatWidget();
        showLiveAgentChat(
          null,
          isAuthenticated,
          language,
          email,
          firstName,
          lastName,
          caseId,
          communicationTone
        );
      } else {
        hideRocketChatWidget();
        showChatBot(isAuthenticated, false);
        hideCurrentLiveAgentButton();
      }
    }
  }, [
    areChatCookiesAllowed,
    chatBotShow,
    chatShow,
    language,
    firstName,
    lastName,
    email,
    caseId
  ]);

  return null;
};

export default ChatComponent;
