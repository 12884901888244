import { createAction } from 'redux-act';

export const setUI = createAction('Set UI');
export const uiReset = createAction('Reset UI');

export const setRedirectOnPreviousPage = createAction(
  'Set redirect on previous page for force relogin'
);

export const setShowGoogleLink = createAction('Set Show Google link');

export const setShowCelebrationAnimation = createAction('Set show celebration');
