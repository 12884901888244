import React from 'react';
import Lottie from 'lottie-react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

import { setShowCelebrationAnimation } from '../../../store/actions';
import { showCelebrationAnimationSelector } from '../../../store/selectors';

import celebrationAnimation from '../../../assets/animations/celebration.json';

const useStyles = makeStyles(() => ({
  animation: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    left: '0',
    top: '0',
    pointerEvents: 'none'
  }
}));

const Celebration = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showAnimation = useSelector(showCelebrationAnimationSelector);

  const onAnimationComplete = () =>
    dispatch(setShowCelebrationAnimation(false));

  return (
    showAnimation && (
      <Lottie
        className={classes.animation}
        animationData={celebrationAnimation}
        loop={false}
        onComplete={onAnimationComplete}
      />
    )
  );
};

export default Celebration;
