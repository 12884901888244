import React from 'react';

import {
  checkMolliePaymentStatus,
  getQuestions,
  sendAnswers
} from '../../store/actions';
import {
  isAuthenticatedAppLoadingSelector,
  isTypeformFeedbackSelector
} from '../../store/selectors';
import { connectTo } from '../../utils';
import { getUrlParams } from '../../utils/httpUtil';
import {
  IDEAL_INSTALLMENT_VALUE,
  PAY_LATER_VALUE
} from '../../constants/payment';

import { Decision } from '@troy/shared/src/components/common';

import { SkeletonModule } from '../../components/common';
import {
  SimpleFeedback,
  TypeformFeedback
} from '../../components/feedback/index.troy';
import { Thanks } from '../index.troy';

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    const params = getUrlParams();
    this.state = { page: params['page'] };
  }

  componentDidMount() {
    const { molliePaymentId, checkMolliePaymentStatus } = this.props;
    if (molliePaymentId) {
      checkMolliePaymentStatus(molliePaymentId);
    }
  }

  render() {
    const {
      isAuthenticatedAppLoading,
      molliePaymentId,
      language,
      questions,
      getQuestions,
      sendAnswers
    } = this.props;
    if (molliePaymentId) {
      return null;
    }

    if (isAuthenticatedAppLoading) {
      return (
        <div style={{ margin: 16 }}>
          <SkeletonModule type="widgets" />
          <SkeletonModule />
        </div>
      );
    }

    switch (this.state.page) {
      case IDEAL_INSTALLMENT_VALUE: {
        return (
          <Thanks
            addManDogNL
            noGoogleLinkMode
            addSignature
            headerText="FEEDBACK_THANKS_HEADLINE"
            bodyText="FEEDBACK_INSTALLMENT_THANKS_TEXT"
          />
        );
      }
      case PAY_LATER_VALUE: {
        return (
          <Thanks
            addManDogNL
            manDogPayLater
            noGoogleLinkMode
            addSignature
            headerText="FEEDBACK_THANKS_HEADLINE"
            bodyText="FEEDBACK_PAYLATER_THANKS_TEXT"
          />
        );
      }
      default: {
        return (
          <>
            <Decision selector={isTypeformFeedbackSelector}>
              <TypeformFeedback language={language} />
            </Decision>
            <Decision reverse selector={isTypeformFeedbackSelector}>
              <SimpleFeedback
                language={language}
                questions={questions}
                getQuestions={getQuestions}
                sendAnswers={sendAnswers}
              />
            </Decision>
          </>
        );
      }
    }
  }
}

export default connectTo(
  state => {
    const {
      questions: { feedback },
      translations: { language },
      ui: {
        feedback: { topImage }
      },
      payment: { molliePaymentId }
    } = state;

    return {
      questions: feedback,
      language,
      showTopImage: topImage,
      molliePaymentId,
      isAuthenticatedAppLoading: isAuthenticatedAppLoadingSelector(state)
    };
  },
  { getQuestions, sendAnswers, checkMolliePaymentStatus },
  Feedback
);
