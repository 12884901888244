import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { Module } from '@troy/shared/src/components/common';

import { portalTimelineBackgroundSelector } from '../../../store/selectors';
import { BlurWithLogin, Translation } from '../../common';

import { mockData } from './mockData';
import TimelineSlider from './TimelineSlider';
import greenLight from '../../../assets/img/status/01_ampelwide-gruen.png';
import redLight from '../../../assets/img/status/03_ampelwide-rot.png';

const TIMELINE_CENSORED = 'TIMELINE_CENSORED';
const TIMELINE_HEADER = isClientPortal =>
  `BOX_TIMELINE_HEADER${isClientPortal ? '_CLIENT' : ''}`;

const sliderSettings = (isMobileSize, sliderClass, initialSlide) => ({
  dots: false,
  infinite: false,
  initialSlide: initialSlide,
  slidesToShow: isMobileSize ? 1 : 3,
  slidesToScroll: 1,
  vertical: false,
  verticalSwiping: false,
  useTransform: false,
  swipeToSlide: true,
  className: sliderClass,
  arrows: false
});

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: '40px 16px 16px 16px',
    background: props => props.bg
  },
  rootSmall: {
    paddingTop: 16
  },
  titleRow: {
    minHeight: 64,
    paddingLeft: 16,
    paddingRight: 85,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.primary.contrastText
  },
  status: {
    width: 77,
    height: 136,
    position: 'absolute',
    top: 0,
    right: 0
  },
  slider: {
    margin: '24px 16px 0 16px',

    '& .slick-track': {
      display: 'flex'
    },

    '& .slick-slide': {
      height: 'inherit',

      '& > div': {
        display: 'flex',
        height: '100%'
      }
    },

    '&:before': {
      position: 'absolute',
      content: '""',
      left: 0,
      right: 0,
      top: 5,
      height: 2,
      background: theme.palette.text.disabled
    }
  },
  sliderSmall: {
    margin: '24px 28px 0 28px'
  }
}));

const Timeline = ({
  className,
  id,
  isTabletSize,
  isMobileSize,
  items,
  blur,
  isClientPortal,
  isPaidFully
}) => {
  const bg = useSelector(portalTimelineBackgroundSelector);
  const classes = useStyles({ bg });
  const activeIndex = (items || []).findIndex(({ current }) => current);

  const settings = sliderSettings(
    isMobileSize,
    clsx(classes.slider, isMobileSize && classes.sliderSmall),
    activeIndex !== -1
      ? isMobileSize
        ? activeIndex
        : activeIndex - 1
      : undefined
  );

  return (
    <Module
      id={id}
      className={clsx(
        classes.root,
        isMobileSize && classes.rootSmall,
        className
      )}
    >
      <Box className={classes.titleRow}>
        <Typography variant="h3" id="timeline-header">
          <Translation isFragment>
            {TIMELINE_HEADER(isClientPortal)}
          </Translation>
        </Typography>
        <img
          id={`timeline-header-status-${isPaidFully ? 'green' : 'red'}`}
          className={classes.status}
          src={isPaidFully ? greenLight : redLight}
          alt="status"
        />
      </Box>
      <BlurWithLogin
        blur={blur}
        descriptionKey={TIMELINE_CENSORED}
        buttonId="page.home.component.timeline.logout-button"
        mockData={
          <TimelineSlider
            isTabletSize={isTabletSize}
            isMobileSize={isMobileSize}
            settings={settings}
            items={mockData}
            isClientPortal={isClientPortal}
            isPaidFully={isPaidFully}
          />
        }
      >
        {items && (
          <TimelineSlider
            isTabletSize={isTabletSize}
            isMobileSize={isMobileSize}
            settings={settings}
            items={items}
            isClientPortal={isClientPortal}
            isPaidFully={isPaidFully}
          />
        )}
      </BlurWithLogin>
    </Module>
  );
};

export default Timeline;
