import { CustomerPortalContactBarItemsType } from '@troy/shared/src/types/whitelabel';
import { SupportedIcon } from '@troy/shared/src/components/common';

import { createEmailSubjectLine } from '../utils';

interface ContactItem {
  label: string;
  icon: SupportedIcon;
  link: string;
  id: string;
}

const mapItems = (
  translations: Record<string, string>,
  portalContactBarItems: CustomerPortalContactBarItemsType,
  caseId?: string
): ContactItem[] => {
  const {
    phoneLabel,
    phoneValue,
    whatsappLabel,
    whatsappValue,
    SMSLabel,
    SMSValue,
    emailLabel,
    emailValue
  } = portalContactBarItems;
  return [
    {
      label: phoneLabel,
      icon: 'contactTel',
      link: `tel:${translations[phoneValue] || phoneValue}`,
      id: 'page.component.home-container.contact-phone'
    },
    {
      label: whatsappLabel,
      icon: 'contactWhatsapp',
      link: `https://wa.me/${translations[whatsappValue] || whatsappValue}`,
      id: 'page.component.home-container.contact-whatsapp'
    },
    {
      label: SMSLabel,
      icon: 'contactChat',
      link: `sms:${translations[SMSValue] || SMSValue}`,
      id: 'page.component.home-container.contact-chat'
    },
    {
      label: emailLabel,
      icon: 'contactMail',
      link: `mailto:${
        translations[emailValue] || emailValue
      }${createEmailSubjectLine(caseId, translations)}`,
      id: 'page.component.home-container.contact-email'
    }
  ];
};

export const contacts = (
  translations: Record<string, string>,
  portalContactBarItems: CustomerPortalContactBarItemsType,
  caseId?: string
): ContactItem[] => {
  const items = mapItems(translations, portalContactBarItems, caseId);
  return items.filter(i => i.label);
};
