import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';

import { phoneValidator } from '@troy/shared/src/utils/validation';
import { parsePhoneFromString } from '@troy/shared/src/utils/phone';

import { email as emailValidator } from '../../../utils/validation';
import {
  EMAIL_PLACEHOLDER,
  getBalanceWithCurrency,
  PHONE_PLACEHOLDER,
  validRates
} from '../../../constants/payment';
import { useAppWidthClass } from '../../../utils/hooks';

import { ThemeButton } from '../../common';

import {
  PaymentFormInput,
  PaymentFormRow,
  PaymentFormRowContent,
  PaymentFormRowLabel,
  PaymentFormSelect,
  PaymentFormText
} from '../index.troy';

import ideal from '../../../assets/img/payment/ideal.png';

const selectFieldInit = number => {
  const arr = [];
  for (let i = 0; i < number; i++) {
    arr.push({
      label: `${i + 1}`,
      value: i + 1,
      id: `page.payments.component.ideal-installment.day-select-${i + 1}-item`
    });
  }
  return arr;
};

const installmentsInit = (currency, locale, validRates) => {
  const result = [];

  for (let i = 0; i < validRates.length; i++) {
    result.push({
      label: `${
        currency
          ? getBalanceWithCurrency(validRates[i], currency, locale)
          : validRates[i]
      }`,
      value: validRates[i],
      id: `page.payments.component.ideal-installment.amount-select-${validRates[i]}-item`
    });
  }

  return result;
};

const selectFieldOptions = selectFieldInit(31);

const useStyles = makeStyles(theme => ({
  form: {
    margin: '4px 0 40px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  submit: {
    margin: '40px auto 0 auto',
    minWidth: 354
  },
  submitCompact: {
    minWidth: 290
  },
  logo: {
    width: 28,
    height: 28
  }
}));

const IDEALInstallmentContent = ({
  formRef,
  currency,
  locale,
  balance,
  email,
  mobilePhone,
  phone,
  onSubmit,
  className,
  invalid
}) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  const validRatesArr = validRates(balance);
  const installmentsOptions = installmentsInit(currency, locale, validRatesArr);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        amount: validRatesArr[0],
        day: 1,
        email: email,
        mobilePhone: parsePhoneFromString(mobilePhone || phone).value
      }}
      onSubmit={({ mobilePhone, ...values }) => {
        onSubmit({
          ...values,
          mobilePhone:
            (mobilePhone && parsePhoneFromString(mobilePhone).value) || null
        });
      }}
    >
      {({ values }) => {
        const emailError = !!values.email && emailValidator(values.email);
        const phoneError = phoneValidator(values.mobilePhone);
        return (
          <Form className={clsx(classes.form, className)}>
            <PaymentFormRow center smallMargin>
              <PaymentFormRowLabel id="installment-form-amount-label" wider>
                PAYMENT_INSTALLMENT_AMOUNT_LEFT
              </PaymentFormRowLabel>
              <PaymentFormRowContent>
                <PaymentFormSelect
                  id="installment-form-amount"
                  labelId="installment-form-amount-label"
                  name="amount"
                  options={installmentsOptions}
                  detailText="PAYMENT_INSTALLMENT_AMOUNT_RIGHT"
                />
              </PaymentFormRowContent>
            </PaymentFormRow>
            <PaymentFormRow smallMargin>
              <PaymentFormText>
                PAYMENT_INSTALLMENT_AMOUNT_BENEATH
              </PaymentFormText>
            </PaymentFormRow>
            <PaymentFormRow center smallMargin>
              <PaymentFormRowLabel id="installment-form-day-label" wider>
                PAYMENT_INSTALLMENT_CALENDER_LEFT
              </PaymentFormRowLabel>
              <PaymentFormRowContent>
                <PaymentFormSelect
                  id="installment-form-day"
                  labelId="installment-form-day-label"
                  name="day"
                  options={selectFieldOptions}
                  detailText="PAYMENT_INSTALLMENT_CALENDER_RIGHT"
                />
              </PaymentFormRowContent>
            </PaymentFormRow>
            <PaymentFormRow smallMargin>
              <PaymentFormText>
                PAYMENT_INSTALLMENT_CALENDER_BENEATH
              </PaymentFormText>
            </PaymentFormRow>
            <PaymentFormRow mediumMargin>
              <PaymentFormRowLabel htmlFor="installment-form-email" wider>
                PAYMENT_EMAILADDRESS_LEFT
              </PaymentFormRowLabel>
              <PaymentFormRowContent>
                <PaymentFormInput
                  id="installment-form-email"
                  name="email"
                  placeholder={EMAIL_PLACEHOLDER}
                  validator={emailValidator}
                  error={emailError}
                  hint="PAYMENT_EMAILADDRESS_BENEATH"
                />
              </PaymentFormRowContent>
            </PaymentFormRow>
            <PaymentFormRow mediumMargin>
              <PaymentFormRowLabel
                htmlFor="installment-form-mobile-phone"
                wider
              >
                PAYMENT_MOBILENUMBER_LEFT
              </PaymentFormRowLabel>
              <PaymentFormRowContent>
                <PaymentFormInput
                  id="installment-form-mobile-phone"
                  name="mobilePhone"
                  placeholder={PHONE_PLACEHOLDER}
                  validator={phoneValidator}
                  error={phoneError}
                  hint="PAYMENT_MOBILENUMBER_BENEATH"
                />
              </PaymentFormRowContent>
            </PaymentFormRow>
            <ThemeButton
              id="page.payments.component.ideal-installment.button"
              className={clsx(
                classes.submit,
                isMobileWidth && classes.submitCompact
              )}
              variant="contained"
              type="submit"
              size="large"
              disabled={invalid}
              textKey="PAYMENT_INSTALLMENT_BUTTON"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

IDEALInstallmentContent.propTypes = {
  onSelectChange: PropTypes.func,
  picture: PropTypes.string,
  form: PropTypes.any,
  onSubmit: PropTypes.func
};

export default IDEALInstallmentContent;

