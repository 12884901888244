import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { MenuItem, Select as MaterialSelect, SelectProps } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { FieldValidator } from 'formik';

import { onClickWrapper } from '../../../utils/ui';
import { SelectOption } from '../../../types/select';
import { FormikFieldRenderProps } from '../../../types/formik';

import Translation from '../Translation';
import FormControl from '../FormControl';
import FormField from '../FormField';

interface FormSelectFieldProps {
  name?: string;
  id?: SelectProps['id'];
  menuOnTop?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  open?: boolean;
  onOpen?: SelectProps['onOpen'];
  onClose?: SelectProps['onClose'];
  options?: SelectOption[];
  value?: SelectOption['value'];
  onChange?: SelectInputProps['onChange'];
  onFocus?: SelectInputProps['onFocus'];
  onBlur?: SelectInputProps['onBlur'];
  onKeyPress?: (event: React.KeyboardEvent<HTMLElement>) => void;
  className?: string;
  classNameInput?: string;
  classNameRoot?: string;
  classNamePaper?: string;
  classNameMenuItem?: string;
  maxSelectHeight?: string | number;
  multiple?: boolean;
  iconComponent?: (props: PropsWithChildren<HTMLElement>) => React.ReactElement;
  validate?: FieldValidator;
  label?: string;
  labelShrink?: boolean;
  classNameWrapper?: string;
  translated?: boolean;
  disabled?: boolean;
  error?: boolean;
  displayEmpty?: boolean;
  renderValue?: SelectProps['renderValue'];
  renderOption?: (option: SelectOption) => React.ReactElement;
  onMenuItemSelected?: () => void;
}

export const SelectFieldComponent: React.FC<
  FormSelectFieldProps & FormikFieldRenderProps
> = ({
  variant,
  id,
  menuOnTop,
  options,
  open,
  onOpen,
  onClose,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  className,
  classNameInput,
  classNameRoot,
  classNamePaper,
  classNameMenuItem,
  maxSelectHeight,
  field,
  value,
  multiple,
  iconComponent,
  label,
  translated,
  disabled,
  error,
  displayEmpty,
  name,
  renderValue,
  renderOption,
  onMenuItemSelected
}) => (
  <MaterialSelect
    id={id}
    variant={variant}
    open={open}
    onOpen={onOpen}
    onClose={onClose}
    MenuProps={{
      autoFocus: false,
      anchorOrigin: {
        horizontal: 'left',
        vertical: menuOnTop ? 'top' : 'bottom'
      },
      transformOrigin: {
        vertical: menuOnTop ? 'bottom' : 'top',
        horizontal: 'left'
      },
      PaperProps: {
        className: classNamePaper,
        style: {
          marginTop: menuOnTop ? -10 : 10,
          maxHeight: maxSelectHeight ? maxSelectHeight : 300
        }
      }
    }}
    name={name}
    label={label}
    IconComponent={
      iconComponent &&
      ((props: PropsWithChildren<HTMLElement>): React.ReactElement =>
        iconComponent(props))
    }
    multiple={multiple}
    className={className}
    value={field ? field.value : value}
    renderValue={renderValue}
    classes={{
      select: clsx(classNameRoot, classNameInput)
    }}
    onChange={onClickWrapper(id, field ? field.onChange : onChange)}
    onFocus={onFocus}
    onBlur={onBlur}
    onKeyPress={onKeyPress}
    displayEmpty={displayEmpty}
    disabled={disabled}
    error={error}
  >
    {options?.map((option, key) => (
      <MenuItem
        key={key}
        id={option.id}
        value={option.value}
        classes={{
          root: classNameMenuItem
        }}
        onClick={onMenuItemSelected}
      >
        {!!renderOption ? (
          renderOption(option)
        ) : translated ? (
          <Translation>{option.label}</Translation>
        ) : (
          option.label
        )}
      </MenuItem>
    ))}
  </MaterialSelect>
);

const LabeledSelectField: React.FC<FormSelectFieldProps> = ({
  variant,
  label,
  labelShrink,
  classNameWrapper,
  disabled,
  ...props
}) => {
  return label ? (
    <FormControl
      disabled={disabled}
      variant={variant}
      label={label}
      labelShrink={labelShrink}
      className={classNameWrapper}
    >
      <SelectFieldComponent
        disabled={disabled}
        variant={variant}
        label={label}
        {...props}
      />
    </FormControl>
  ) : (
    <SelectFieldComponent disabled={disabled} variant={variant} {...props} />
  );
};

const FormSelectField: React.FC<FormSelectFieldProps> = props => {
  const {
    name,
    id,
    variant,
    open,
    onOpen,
    onClose,
    options,
    value,
    onChange,
    onBlur,
    onKeyPress,
    className,
    classNameInput,
    classNameRoot,
    classNamePaper,
    classNameMenuItem,
    maxSelectHeight,
    multiple,
    iconComponent,
    validate,
    label,
    labelShrink,
    classNameWrapper,
    translated,
    disabled,
    error,
    displayEmpty,
    renderValue,
    renderOption,
    onMenuItemSelected
  } = props;

  const customProps = {
    id,
    options,
    value,
    variant,
    open,
    onOpen,
    onClose,
    onChange,
    onBlur,
    onKeyPress,
    className,
    classNameInput,
    classNameRoot,
    classNamePaper,
    classNameMenuItem,
    classNameWrapper,
    maxSelectHeight,
    multiple,
    iconComponent,
    label,
    labelShrink,
    translated,
    disabled,
    error,
    displayEmpty,
    renderValue,
    renderOption,
    onMenuItemSelected
  };

  return name ? (
    <FormField
      component={LabeledSelectField}
      name={name}
      onChange={onChange}
      value={value}
      validate={validate}
      {...customProps}
    />
  ) : label ? (
    <FormControl
      disabled={disabled}
      variant={variant}
      label={label}
      labelShrink={labelShrink}
      className={classNameWrapper}
    >
      <SelectFieldComponent {...props} />
    </FormControl>
  ) : (
    <SelectFieldComponent {...props} />
  );
};

export default FormSelectField;
