import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import {
  CompanyName,
  useTranslationsContext
} from '@troy/shared/src/components/common';

import {
  companyNameSelector,
  portalGreetingMainB2BKeySelector,
  portalGreetingMainKeySelector,
  portalGreetingMainPaidKeySelector
} from '../../../store/selectors';
import { Translation } from '../../common';

const useStyles = makeStyles(theme => ({
  company: {
    marginBottom: theme.spacing(1)
  },
  text: {
    marginTop: 16
  }
}));

const Welcome = ({ className, textClassName, isPaidFully }) => {
  const classes = useStyles();
  const { translate } = useTranslationsContext();
  const companyName = useSelector(companyNameSelector);
  const hasCompany = !!companyName;
  const mainKey = useSelector(portalGreetingMainKeySelector);
  const mainB2BKey = useSelector(portalGreetingMainB2BKeySelector);
  const mainPaidKey = useSelector(portalGreetingMainPaidKeySelector);

  return (
    <Box className={className}>
      <CompanyName className={classes.company} companyName={companyName} />
      <Typography
        component="h2"
        variant="h3"
        color="secondary"
        id="welcome-greeting-title"
        dangerouslySetInnerHTML={{
          __html: translate(
            hasCompany ? 'MAIN_SALUTATION_B2B' : 'MAIN_SALUTATION',
            true
          )
        }}
      />
      <Typography
        variant="body1"
        color="textPrimary"
        className={clsx(classes.text, textClassName)}
      >
        <Translation
          keyIfNoValue
          withSignature
          inline
          id="welcome-greeting-message"
        >
          {isPaidFully ? mainPaidKey : hasCompany ? mainB2BKey : mainKey}
        </Translation>
      </Typography>
    </Box>
  );
};

Welcome.propTypes = {
  className: PropTypes.string,
  isTabletSize: PropTypes.bool
};

export default Welcome;
