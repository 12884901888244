import { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    boxSizing: 'border-box',
    margin: 'auto',
    width: '100%'
  },
  padding: {
    padding: '0 16px'
  },
  desktop: {
    maxWidth: 960
  },
  tablet: {
    maxWidth: 600
  },
  mobile: {
    maxWidth: 400
  }
}));

export const useAppWidthClass = (
  noPaddingDesktop,
  noPaddingTablet,
  noPaddingMobile
) => {
  const classes = useStyles();
  const theme = useTheme();
  const isTabletWidth = useMediaQuery(theme.breakpoints.only('sm'), {
    noSsr: true
  });
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true
  });
  const isDesktopWidth = useMemo(
    () => !isTabletWidth && !isMobileWidth,
    [isTabletWidth, isMobileWidth]
  );

  return {
    widthClass: clsx(
      classes.root,
      isDesktopWidth && classes.desktop,
      isDesktopWidth && !noPaddingDesktop && classes.padding,
      isTabletWidth && classes.tablet,
      isTabletWidth && !noPaddingTablet && classes.padding,
      isMobileWidth && classes.mobile,
      isMobileWidth && !noPaddingMobile && classes.padding
    ),
    isTabletWidth,
    isMobileWidth,
    isDesktopWidth
  };
};
