import React from 'react';
import {
  postBoxDirectionFilterCountsSelector,
  postBoxItemsSelector
} from '@troy/shared/src/store/selectors';

import { connectTo } from '../../utils';
import { setFileModalOpened } from '../../store/actions';
import {
  isAuthenticatedAppLoadingSelector,
  isLiabilityPaidSelector
} from '../../store/selectors';

import { usePostboxStyles } from '../../components/post-box/PostBoxContent/index.troy';
import { PostBox } from '../../components/post-box/index.troy';

const PostBoxWrapper = props => {
  const classes = usePostboxStyles();
  return <PostBox classes={classes} {...props} />;
};

export default connectTo(
  state => {
    const {
      auth: { internal },
      ui: {
        postBox: {
          showInternalNotesForCustomer,
          blur: {
            fileUpload: fileUploadBlur,
            notificationsList: notificationsListBlur
          },
          hide: { fileUpload: fileUploadHidden }
        }
      }
    } = state;

    return {
      internal,
      showInternalNotesForCustomer,
      notifications: postBoxItemsSelector(state),
      directionFilterCounts: postBoxDirectionFilterCountsSelector(state),
      fileUploadHidden,
      fileUploadBlur,
      notificationsListBlur,
      isAuthenticatedAppLoading: isAuthenticatedAppLoadingSelector(state),
      isLiabilityPaid: isLiabilityPaidSelector(state)
    };
  },
  { setFileModalOpened },
  PostBoxWrapper
);
