import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton } from '@mui/material';
import clsx from 'clsx';
import { alpha } from '@mui/material/styles';

import { CustomIcon } from '@troy/shared/src/components/common';
import { phoneValidator } from '@troy/shared/src/utils/validation';

import {
  EMAIL_FIELD,
  SMS_FIELD,
  WHATS_APP_FIELD
} from '../../../constants/response-channel';
import { email as emailValidation } from '../../../utils/validation';
import { useAppWidthClass } from '../../../utils/hooks';
import { isFileUploadLoadingSelector } from '../../../store/selectors';

import { ThemeButton } from '../../common';

import UploadFileModalTypeSelect from './UploadFileModalTypeSelect';
import UploadFileModalDropzone from './UploadFileModalDropzone';
import UploadFileModalReminder from './UploadFileModalReminder';
import UploadFileModalDescription from './UploadFileModalDescription';
import UploadFileModalResponseChannel from './UploadFileModalResponseChannel';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: 408
  },
  formMobile: {
    height: 'unset',
    minHeight: 264
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    zIndex: 1,
    bottom: 0,
    padding: `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(
      2
    )} ${theme.spacing(2)}`,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    background: alpha(theme.palette.background.default, 0.46),
    backdropFilter: 'blur(6px)'
  },
  buttonsMobile: {
    marginTop: theme.spacing(2)
  },
  gradient: {
    width: 'calc(100% + 32px)',
    marginLeft: theme.spacing(-2),
    height: theme.spacing(2),
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 78.3%)'
  },
  buttonsInner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  back: {
    height: 40,
    width: 40,
    border: `1px solid ${theme.palette.secondary.main}`,

    '&:disabled': {
      borderColor: theme.palette.text.disabled
    }
  },
  submit: {
    minWidth: `200px !important`,
    marginLeft: 'auto'
  },
  submitMobile: {
    flexGrow: 1,
    marginLeft: theme.spacing(1)
  },
  submitLoading: {
    flexGrow: 0,
    margin: 'auto'
  },
  submitLoadingDesktop: {
    marginRight: 12,
    minWidth: '0 !important'
  }
}));

const UploadFileModalForm = ({
  values,
  isValid,
  setFieldValue,
  touched,
  handleSubmit,
  modalStep,
  setModalStep,
  onClose,
  setHasBottomMobileSpacing
}) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();
  const [reminderModalOpen, setReminderModalOpen] = useState(false);

  const isUploadLoading = useSelector(isFileUploadLoadingSelector);

  const goBackModalStep = () => {
    const s = modalStep - 1;
    if (s < 0) {
      onClose();
    } else {
      setModalStep(modalStep - 1);
    }
  };

  const increaseModalStep = e => {
    e.preventDefault();
    let s = modalStep + 1;
    s %= 4;
    setModalStep(s);
    if (s === 1) {
      setReminderModalOpen(true);
    }
  };

  const onReminderOpen = () => setReminderModalOpen(true);

  const onReminderConfirm = () => setReminderModalOpen(false);

  const hasFiles = values && values.files && values.files.length > 0;

  const isFieldDisabled = name => values.method !== name;

  const emailDisabled = isFieldDisabled(EMAIL_FIELD);
  const smsDisabled = isFieldDisabled(SMS_FIELD);
  const invalidEmail = !!(
    !emailDisabled && emailValidation(values[EMAIL_FIELD])
  );
  const invalidSms = !!(
    !smsDisabled && !!phoneValidator(values[SMS_FIELD].value)
  );
  const invalidWhatsapp = !!(
    !isFieldDisabled(WHATS_APP_FIELD) &&
    !!phoneValidator(values[WHATS_APP_FIELD].value)
  );
  const isChannelFormInvalid =
    modalStep === 3 &&
    (!values.method || invalidEmail || invalidSms || invalidWhatsapp);

  return (
    <form
      className={clsx(classes.form, isMobileWidth && classes.formMobile)}
      id="upload-file-form"
      onSubmit={handleSubmit}
    >
      {modalStep === 0 && <UploadFileModalTypeSelect />}
      {modalStep === 1 && (
        <UploadFileModalDropzone
          values={values}
          setFieldValue={setFieldValue}
          disabled={reminderModalOpen}
          onInfoButtonClick={onReminderOpen}
        />
      )}
      {modalStep === 1 && reminderModalOpen && (
        <UploadFileModalReminder
          type={values.type}
          onReminderConfirm={onReminderConfirm}
        />
      )}
      {modalStep === 2 && <UploadFileModalDescription files={values.files} />}
      {modalStep === 3 && (
        <UploadFileModalResponseChannel
          values={values}
          touched={touched}
          setFieldValue={setFieldValue}
          invalidEmail={invalidEmail}
          invalidSms={invalidSms}
          invalidWhatsapp={invalidWhatsapp}
          setHasBottomMobileSpacing={setHasBottomMobileSpacing}
        />
      )}
      <div
        className={clsx(
          classes.buttons,
          isMobileWidth && classes.buttonsMobile
        )}
      >
        <div className={classes.gradient} />
        <div className={classes.buttonsInner}>
          {!isMobileWidth && (
            <ThemeButton
              id="page.postbox.component.upload-file-modal.back-button"
              variant="text"
              color="secondary"
              textKey={
                modalStep === 0
                  ? 'ALL_UXCOPIES_BTN_CANCEL'
                  : 'ALL_UXCOPIES_BTN_BACK'
              }
              disabled={isUploadLoading || reminderModalOpen}
              onClick={goBackModalStep}
              startIcon={modalStep === 0 ? undefined : 'chevronLeft'}
              startIconColor={
                isUploadLoading || reminderModalOpen
                  ? 'textDisabled'
                  : 'secondaryMain'
              }
            />
          )}
          {isMobileWidth && modalStep > 0 && (
            <IconButton
              className={clsx(classes.back)}
              id="page.postbox.component.upload-file-modal.back-button"
              aria-label="Back"
              onClick={goBackModalStep}
              color="secondary"
              size="large"
              disabled={isUploadLoading || reminderModalOpen}
            >
              <CustomIcon
                icon="chevronLeft"
                size="xs"
                variant={reminderModalOpen ? 'textDisabled' : 'secondary'}
              />
            </IconButton>
          )}
          <ThemeButton
            className={clsx(
              isMobileWidth ? classes.submitMobile : classes.submit,
              isUploadLoading && classes.submitLoading,
              !isMobileWidth && isUploadLoading && classes.submitLoadingDesktop
            )}
            id="page.postbox.component.upload-file-modal.next-button"
            variant="contained"
            color="primary"
            type={modalStep < 3 ? 'button' : 'submit'}
            textKey={
              modalStep === 0
                ? 'UPLOAD_BUTTON_LETSGO'
                : modalStep === 3
                ? 'RESPONSE_CHANNEL_CONFIRM'
                : 'ALL_UXCOPIES_BTN_NEXT'
            }
            isLoading={isUploadLoading}
            disabled={
              !isValid ||
              (modalStep === 1 && !hasFiles) ||
              reminderModalOpen ||
              isChannelFormInvalid
            }
            onClick={modalStep < 3 ? increaseModalStep : undefined}
            endIcon={
              isUploadLoading
                ? undefined
                : modalStep < 3
                ? 'chevronRight'
                : 'confirm'
            }
            endIconColor="primaryContrast"
          />
        </div>
      </div>
    </form>
  );
};

export default UploadFileModalForm;
