import { createAction } from 'redux-act';

export const setPaymentUserInfo = createAction('Set payment info');
export const setPaymentDecisions = createAction('Set payment decisions');
export const setPaymentMethod = createAction('Set payment method');
export const resetPaymentMethod = createAction('Reset payment method');

export const beginPaymentSession = createAction('Begin payment session');
export const beginMollieSession = createAction('Begin Mollie sesssion');
export const beginIdealInstallmentSession = createAction(
  'Begin Ideal Installment session'
);
export const payLater = createAction('Pay Later');
export const wppDataReceived = createAction('WPP Data received');

export const checkMolliePaymentStatus = createAction(
  'Check Mollie payment status'
);
export const setMolliePaymentId = createAction('Set Mollie payment id');
export const resetMolliePayment = createAction('Reset Mollie payment info');

export const payOffline = createAction('Pay offline');
export const payOfflineDataReceived = createAction('Pay offline received');
export const sendMockInformation = createAction('Send mock information');

export const paySepaDirectDebit = createAction('Pay Sepa Direct Debit');
export const payMollieSofort = createAction('Pay Mollie Sofort');
export const payMollieBanContact = createAction('Pay Mollie BanContact');
export const paymentReset = createAction('Reset payment');

export const offlineSepaSubmit = createAction('Offline SEPA submit');
export const setPaymentIsLoading = createAction('Set payment is loading');
