//The files allowed here should be matching the server side on
//server/document-service/src/main/kotlin/de/troybleiben/document/DocumentValidator.kt
export const PDF = 'application/pdf';
export const DOC = 'application/msword';
export const DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const PJPEG = 'image/pjpeg';
export const JPEG = 'image/jpeg'; // .jfif, .pjp
export const JPG = 'image/jpg';
export const PNG = 'image/png';
