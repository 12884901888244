import React from 'react';
import PropTypes from 'prop-types';

import MaterialGrid from '@mui/material/Grid';

const Grid = React.forwardRef(
  (
    {
      children,
      alignContent,
      alignItems,
      container,
      direction,
      item,
      justify,
      xs,
      sm,
      md,
      lg,
      xl,
      spacing,
      wrap,
      zeroMinWidth,
      className,
      component
    },
    ref
  ) => (
    <MaterialGrid
      {...{
        ref,
        alignContent,
        alignItems,
        container,
        direction,
        item,
        justify,
        xs,
        sm,
        md,
        lg,
        xl,
        spacing,
        wrap,
        zeroMinWidth,
        className,
        component
      }}
    >
      {children}
    </MaterialGrid>
  )
);

Grid.propTypes = {
  children: PropTypes.node,
  alignContent: PropTypes.string,
  alignItems: PropTypes.string,
  container: PropTypes.bool,
  direction: PropTypes.string,
  item: PropTypes.bool,
  justify: PropTypes.string,
  xs: PropTypes.any,
  sm: PropTypes.any,
  md: PropTypes.any,
  lg: PropTypes.any,
  xl: PropTypes.any,
  spacing: PropTypes.number,
  wrap: PropTypes.string,
  zeroMinWidth: PropTypes.bool,
  className: PropTypes.string
};

export default Grid;
