import React from 'react';
import styles from './sap.module.scss';
import { Select } from '../../common';
import SapDateRangePicker from '../../common/DateRangePicker/index.sap';
import Search from '../Search/index.sap';
import {
  CUSTOMER_MESSAGE_TYPES_SAP,
  filterOptions as options,
  filterTypeOptions
} from '../../../constants/post-box.sap';

const channelNames = () => {
  const result = [
    {
      label: 'All',
      value: 'all',
      id: `page.sap-iframe.channel-select.all-item`
    }
  ];

  for (let i = 0; i < options.length; i++) {
    result.push({
      label: options[i].label,
      value: options[i].name,
      id: `page.sap-iframe.channel-select.${options[i].name}-item`
    });
  }

  return result;
};

const typeNames = () => {
  const result = [];

  for (let i = 0; i < filterTypeOptions.length; i++) {
    result.push({
      label: filterTypeOptions[i].label,
      value: filterTypeOptions[i].name,
      id: `page.sap-iframe.component.type-select.${filterTypeOptions[i].name}-item`
    });
  }

  return result;
};

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.onSelectedChannelChange = this.onSelectedChannelChange.bind(this);
    this.onSelectedTypeChange = this.onSelectedTypeChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);

    this.state = {
      selectedTypeValue: [],
      selectedChannelValue: [],
      startDate: null,
      endDate: null,
      focusedInput: null
    };
  }

  onSelectedTypeChange(event) {
    const { onTypeFilterChange } = this.props;

    if (event.target && event.target.value) {
      let values = event.target.value;
      let isAll = values.includes('all');
      let allValue = [];

      if (isAll) {
        if (
          values.length === typeNames().length - 1 &&
          this.state.selectedTypeValue.includes('all')
        ) {
          values = values.filter(value => value !== 'all');
          this.setState({ selectedTypeValue: [...values] });
          let result = [];
          values.forEach(value => {
            CUSTOMER_MESSAGE_TYPES_SAP.forEach(field => {
              let label = field.label;
              if (value === label) {
                const types = field.types;
                result.push(...types);
              }
            });
          });
          onTypeFilterChange(result);
        } else {
          for (let i = 0; i < typeNames().length; i++) {
            allValue.push(typeNames()[i].value);
          }
          this.setState({ selectedTypeValue: [...allValue] });
          onTypeFilterChange(null);
        }
      } else if (values.length === typeNames().length - 1 && !isAll) {
        if (this.state.selectedTypeValue.length === typeNames().length) {
          this.setState({ selectedTypeValue: [] });
          onTypeFilterChange(-1);
        } else {
          for (let i = 0; i < typeNames().length; i++) {
            allValue.push(typeNames()[i].value);
          }
          this.setState({ selectedTypeValue: [...allValue] });
          onTypeFilterChange(null);
        }
      } else {
        this.setState({ selectedTypeValue: [...values] });
        let result = [];
        values.forEach(value => {
          CUSTOMER_MESSAGE_TYPES_SAP.forEach(field => {
            let label = field.label;
            if (value === label) {
              const types = field.types;
              result.push(...types);
            }
          });
        });
        onTypeFilterChange(result);
      }
    }
  }

  onSelectedChannelChange(event) {
    const { onOptionChange } = this.props;
    if (event.target && event.target.value) {
      let values = event.target.value;
      let isAll = values.includes('all');
      let allValue = [];
      let result = [];
      if (isAll && values.length !== channelNames().length - 1) {
        for (let i = 0; i < channelNames().length; i++) {
          allValue.push(channelNames()[i].value);
        }
        this.setState({ selectedChannelValue: [...allValue] });
        onOptionChange([]);
      } else if (values.length === channelNames().length - 1 && !isAll) {
        if (this.state.selectedChannelValue.length === channelNames().length) {
          this.setState({ selectedChannelValue: [] });
          onOptionChange(-1);
        } else {
          for (let i = 0; i < channelNames().length; i++) {
            allValue.push(channelNames()[i].value);
          }
          this.setState({ selectedChannelValue: [...allValue] });
          onOptionChange([]);
        }
      } else {
        values = values.filter(value => value !== 'all');
        this.setState({ selectedChannelValue: [...values] });
        values.forEach(val => (result[val] = true));
        onOptionChange(result);
      }
    }
  }

  onDateChange(startDate, endDate) {
    const { onDateRangeChange } = this.props;
    this.setState({ startDate, endDate });
    onDateRangeChange(startDate, endDate);
  }

  render() {
    const {
      onFilterClick,
      onSearchClick,
      onSearchChange,
      onSearchClear,
      searchValue,
      scrollVisible,
      filterCount
    } = this.props;

    return (
      <div className={styles[`filter__form${scrollVisible ? '-sticky' : ''}`]}>
        <Search
          onSearchClick={onSearchClick}
          onSearchChange={onSearchChange}
          onSearchClear={onSearchClear}
          searchValue={searchValue}
        />
        <div className={styles['filter__form-type']}>
          <div className={styles['filter__form-type-text']}>
            <span>Type </span>
          </div>
          <Select
            multiple
            className={styles['filter__form-type-select']}
            classNameInput={styles['filter__form-type-select-input']}
            classNameRoot={styles['filter__form-type-select-root']}
            classNameMenuItem={styles['filter__form-type-select-menu-item']}
            value={this.state.selectedTypeValue}
            onChange={this.onSelectedTypeChange}
            iconComponent={
              <button
                id="page.sap-postbox.component.filter.type-select-icon"
                className={styles['filter__form-select-button']}
              >
                <i className={styles['icon-navigation-down-arrow']}></i>
              </button>
            }
            options={typeNames()}
          />
        </div>
        <div className={styles['filter__form-channel']}>
          <div className={styles['filter__form-channel-text']}>
            <span>Channel </span>
          </div>
          <Select
            multiple
            translated
            className={styles['filter__form-channel-select']}
            classNameInput={styles['filter__form-channel-select-input']}
            classNameRoot={styles['filter__form-channel-select-root']}
            classNameMenuItem={styles['filter__form-type-select-menu-item']}
            iconComponent={
              <button
                id="page.sap-postbox.component.filter.channel-select-icon"
                className={styles['filter__form-select-button']}
              >
                <i className={styles['icon-navigation-down-arrow']}></i>
              </button>
            }
            value={this.state.selectedChannelValue}
            onChange={this.onSelectedChannelChange}
            options={channelNames()}
          />
        </div>

        <div className={styles['filter__form-date-picker']}>
          <div className={styles['filter__form-date-picker-text']}>
            <span>Date </span>
          </div>
          <div className={styles['filter__form-date-picker-input']}>
            <SapDateRangePicker
              id="page.sap-postbox.component.filter.date-range-picker"
              startDate={this.state.startDate}
              startDateId="filter__form-date-picker_start_date_id"
              endDate={this.state.endDate}
              endDateId="filter__form-date-picker_end_date_id"
              onDatesChange={({ startDate, endDate }) =>
                this.onDateChange(startDate, endDate)
              }
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              displayFormat="DD/MM/YYYY"
              showDefaultInputIcon={
                !this.state.startDate && !this.state.endDate
              }
              inputIconPosition="after"
              small
              isOutsideRange={() => false}
              reopenPickerOnClearDates
              showClearDates={this.state.startDate || this.state.endDate}
            />
          </div>
        </div>
        <div className={styles['filter__form-filter-counter-wrapper']}>
          Adapt Filter ({filterCount})
        </div>
        <div className={styles['filter__form-filter-button-wrapper']}>
          <div
            id="page.sap-postbox.component.filter.go-button"
            onClick={onFilterClick}
            className={styles['filter__form-filter-button']}
          >
            Go
          </div>
        </div>
      </div>
    );
  }
}

const TroyFilterSAP = props => <Filter {...{ styles, ...props }} />;

export default TroyFilterSAP;
