import React from 'react';
import PropTypes from 'prop-types';
import styles from './sap.module.scss';
import headPic from '../../../assets/img/allgemein/04_textmarker.png';
import clsx from 'clsx';
import { Translation, Link, Blurred } from '../../common';
import BlurWithLogin from '../../common/BlurWithLogin/index.sap';
import { getBalanceWithCurrency } from '../../../constants/payment';

const LOGIN_DESCRIPTION_KEY = 'LIABILITY_STATEMENT_CENSORED';

export const demandTableView = ({ styles, items }) => (
  <div className={styles['demand-table__table-wrapper']}>
    <div className={styles['demand-table__table']}>
      <div
        className={clsx(
          styles['demand-table__table-row'],
          styles['demand-table__table-header']
        )}
      >
        <div className={styles['demand-table__table-cell']}>Position</div>
        <div className={styles['demand-table__table-cell']}>Betrag</div>
      </div>
      {items &&
        items.map(({ amount, currency, subject }, key) => (
          <div className={styles['demand-table__table-row']} key={key}>
            <div className={styles['demand-table__table-cell']}>{subject}</div>
            <div className={styles['demand-table__table-cell-value']}>
              {getBalanceWithCurrency(amount, currency)}
            </div>
          </div>
        ))}
    </div>
  </div>
);

const DemandTableDocuments = ({
  styles,
  title,
  subtitle,
  link,
  isMobileOnly,
  token,
  blur
}) => {
  return (
    <div
      className={
        styles[
          `demand-table__document-wrapper${isMobileOnly ? '_mobile-only' : ''}`
        ]
      }
    >
      {title && (
        <div className={styles['demand-table__document-title-wrapper']}>
          <Translation className={styles['demand-table__document-title']}>
            {title}
          </Translation>
        </div>
      )}

      <Blurred blur={blur}>
        <div className={styles['demand-table__document-bottom-container']}>
          {subtitle && (
            <div className={styles['demand-table__document-subtitle-wrapper']}>
              <Translation
                className={styles['demand-table__document-subtitle']}
              >
                {subtitle}
              </Translation>
            </div>
          )}
          <div className={styles['demand-table__document-link-wrapper']}>
            <Link
              raw
              id="page.sap-home.component.demand-table.document-link"
              to={link(token)}
              className={styles['demand-table__document-link']}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                id="page.sap-home.component.demand-table.document-button"
                className={styles['demand-table__document-button']}
              >
                Generate PDF
              </div>
            </Link>
          </div>
        </div>
      </Blurred>
    </div>
  );
};

export const mockData = [
  {
    amount: 100,
    currency: 'EUR',
    subject: 'Mock Subject 1'
  },
  {
    amount: 200,
    currency: 'EUR',
    subject: 'Mock Subject 2'
  },
  {
    amount: 300,
    currency: 'EUR',
    subject: 'Mock Subject 3'
  },
  {
    amount: 400,
    currency: 'EUR',
    subject: 'Mock Subject 4'
  },
  {
    amount: 500,
    currency: 'EUR',
    subject: 'Mock Subject 5'
  },
  {
    amount: 600,
    currency: 'EUR',
    subject: 'Mock Subject 6'
  },
  {
    amount: 700,
    currency: 'EUR',
    subject: 'Mock Subject 7'
  },
  {
    amount: 800,
    currency: 'EUR',
    subject: 'Mock Subject 8'
  },
  {
    amount: 900,
    currency: 'EUR',
    subject: 'Mock Subject 9'
  },
  {
    amount: 1000,
    currency: 'EUR',
    subject: 'Mock Subject 10'
  }
];

const DemandTable = ({
  items,
  balance,
  currency,
  blur,
  styles,
  extended,
  isSAPIframe,
  token
}) => {
  return (
    <>
      <div className={styles['demand-table__wrapper']}>
        <div className={styles['demand-table__content']}>
          <BlurWithLogin
            blur={blur}
            descriptionKey={LOGIN_DESCRIPTION_KEY}
            buttonClassName={styles['demand-table__blur-button']}
            mockData={demandTableView({ styles, items: mockData })}
            buttonId="page.sap-home.component.demand-table.logout-button"
          >
            {demandTableView({ styles, items })}
          </BlurWithLogin>
          <div className={styles['demand-table__summary']}>
            <div className={styles['demand-table__summary-wrapper']}>
              <div className={styles['demand-table__summary-header']}>
                Gesamtbetrag:&nbsp;
              </div>
              <div>
                {balance != null
                  ? getBalanceWithCurrency(balance, currency)
                  : '(unbekannt)'}
              </div>
            </div>
          </div>
        </div>
        <div className={styles['demand-table__extended']}>
          {extended &&
            extended.length &&
            extended.map(({ id, title, label, link, mobileOnly, blur }) => (
              <DemandTableDocuments
                {...{ styles, title, link, token, blur }}
                subtitle={label}
                isMobileOnly={mobileOnly}
                key={id}
              />
            ))}
        </div>
      </div>
    </>
  );
};

DemandTable.propTypes = {
  items: PropTypes.array,
  balance: PropTypes.number,
  currency: PropTypes.string,
  blur: PropTypes.bool,
  styles: PropTypes.object,
  headPic: PropTypes.string,
  extended: PropTypes.array
};

const SAPDemandTable = ({
  items,
  extended,
  balance,
  currency,
  blur,
  isSAPIframe
}) => (
  <DemandTable
    {...{
      items,
      balance,
      currency,
      blur,
      styles,
      headPic,
      extended,
      isSAPIframe
    }}
  />
);

SAPDemandTable.propTypes = {
  items: PropTypes.array,
  balance: PropTypes.number,
  currency: PropTypes.string,
  blur: PropTypes.bool,
  extended: PropTypes.array
};

export default SAPDemandTable;
