import React from 'react';
import { useSelector } from 'react-redux';

import { portalLoginLogoSelector } from '../../store/selectors';

import Logo from '../../components/common/Logo/Logo.troy';
import Link from '../../components/common/Link';

import styles from './index.module.scss';
import gitInfo from './git-info.json';

const Version = () => {
  const logo = useSelector(portalLoginLogoSelector);

  return (
    <div id="version-wrapper" className={styles['version__wrapper']}>
      <div>{(gitInfo && `hash: ${gitInfo.hash}`) || 'No data about hash'}</div>
      <div>
        {(gitInfo && `message: ${gitInfo.message}`) || 'No data about message'}
      </div>
      <Link id="page.version.component.version.home-link" to="/">
        <Logo src={logo} />
      </Link>
    </div>
  );
};

export default Version;
