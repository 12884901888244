import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';

import {
  CustomIcon,
  Text,
  useTranslationsContext
} from '@troy/shared/src/components/common';

import { useAppWidthClass } from '../../../utils/hooks';

import { ThemeButton } from '../../common';
import { TRACKING_ID } from './constants';

const useStyles = makeStyles(theme => ({
  modal: {
    width: 345,
    position: 'absolute',
    left: '50%',
    top: '52%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    padding: `${theme.spacing(4.625)} ${theme.spacing(3)}`,
    background: alpha(theme.customColors.gradientColor1, 0.12),
    boxShadow: `0px 4px 16px ${alpha(theme.palette.secondary.main, 0.26)},
       inset 0px 39px 56px -36px ${alpha(
         theme.palette.background.default,
         0.87
       )},
       inset 0px 7px 11px -4px ${alpha(theme.palette.background.default, 0.3)},
       inset 0px -18px 56px -64px ${alpha(
         theme.customColors.gradientColor1,
         0.3
       )},
       inset 0px 4px 18px ${alpha(theme.customColors.gradientColor1, 0.3)},
       inset 0px 1px 40px ${alpha(theme.customColors.gradientColor1, 0.2)}`,
    backdropFilter: 'blur(6px)',
    borderRadius: theme.shape.borderRadius * 10,
    boxSizing: 'border-box'
  },
  modalMobile: {
    width: 'auto',
    left: 0,
    top: 'unset',
    right: 0,
    bottom: theme.spacing(4.5),
    transform: 'none',
    margin: `0 ${theme.spacing(3.75)}`
  },
  row: {
    display: 'flex',
    marginTop: theme.spacing(1)
  },
  icon: {
    marginTop: theme.spacing(0.5)
  },
  text: {
    marginLeft: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(3),
    width: '100%'
  }
}));

const UploadFileModalReminder = ({ type, onReminderConfirm }) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();
  const { translate } = useTranslationsContext();

  const textKey = `UPLOAD_FILE_TYPE_${type}_HINTS`;
  let text = translate(textKey, false);
  if (!text) {
    // in case translation is missing, so it would not be broken
    text =
      'Please make sure there are;your own bank-details;recipient’s bank-details;the amount and reference;';
  }
  const lines = text.split(';').filter(t => !!t);

  return (
    <div className={clsx(classes.modal, isMobileWidth && classes.modalMobile)}>
      <Text component="h3" variant="body1" color="textPrimary">
        {lines[0]}
      </Text>
      {lines.slice(1).map(text => (
        <div className={classes.row} key={text}>
          <CustomIcon
            className={classes.icon}
            icon="confirm"
            size="xs"
            variant="textPrimary"
          />
          <Text
            className={classes.text}
            component="span"
            variant="body1Bold"
            color="textPrimary"
          >
            {text}
          </Text>
        </div>
      ))}
      <ThemeButton
        className={classes.button}
        id={`page.postbox.component.upload-file-modal.acknowledge-${TRACKING_ID[type]}`}
        variant="contained"
        color="secondary"
        textKey="UPLOAD_HINT_ACKNOWLEDGE_BUTTON"
        endIcon="confirm"
        endIconColor="secondaryContrast"
        onClick={onReminderConfirm}
      />
    </div>
  );
};

export default UploadFileModalReminder;
