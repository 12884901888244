import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './store/reducers';
import middleware from './middleware';

export default createStore(
  combineReducers(reducers),
  composeWithDevTools(applyMiddleware(...middleware))
);
