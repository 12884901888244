import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { portalDirectorSignatureSelector } from '../../../store/selectors';
import styles from './index.module.scss';

export default ({ className }) => {
  const directorSignature = useSelector(portalDirectorSignatureSelector);

  return (
    <img
      className={clsx(styles['signature'], className)}
      src={directorSignature}
      alt="signature-pic"
    />
  );
};
