import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import {
  portalLoginBackgroundSelector,
  portalLoginBackgroundSmallSelector
} from '../../../store/selectors';
import { useAppWidthClass } from '../../../utils/hooks';

import { Welcome } from '../index.troy';
import { WelcomeDescription, WelcomeTitle } from '../Welcome';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    backgroundImage: props => `url(${props.bg})`,
    backgroundSize: 'cover, cover'
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  wrapperDesktop: {
    width: '100%',
    margin: '16px 0',
    display: 'flex'
  },
  welcomeDesktop: {
    maxWidth: 624,
    justifyContent: 'center'
  },
  contentDesktop: {
    width: 310,
    marginLeft: 'auto'
  },
  wrapperTablet: {
    width: 310,
    margin: '24px 0 56px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  contentTablet: {
    margin: '32px 0'
  },
  wrapperMobile: {
    width: 310,
    margin: '16px 0',
    maxWidth: '100%'
  }
}));

const LoginLayout = ({ children }) => {
  const loginBg = useSelector(portalLoginBackgroundSelector);
  const loginBgSmall = useSelector(portalLoginBackgroundSmallSelector);

  const { widthClass, isDesktopWidth, isTabletWidth, isMobileWidth } =
    useAppWidthClass(true);

  const classes = useStyles({
    bg: isMobileWidth && !!loginBgSmall ? loginBgSmall : loginBg
  });

  return (
    <Box className={classes.root}>
      <Box className={clsx(classes.content, widthClass)}>
        <Box
          className={clsx(
            isDesktopWidth && classes.wrapperDesktop,
            isTabletWidth && classes.wrapperTablet,
            isMobileWidth && classes.wrapperMobile
          )}
        >
          {isDesktopWidth && (
            <Welcome
              className={classes.welcomeDesktop}
              id="login-welcome-desktop"
            />
          )}
          {isTabletWidth && <WelcomeTitle isTabletWidth />}
          <Box
            className={clsx(
              isDesktopWidth && classes.contentDesktop,
              isTabletWidth && classes.contentTablet
            )}
          >
            {children}
          </Box>
          {isTabletWidth && <WelcomeDescription isTabletWidth />}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginLayout;
