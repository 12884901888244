import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { HOME } from '../../../constants/routes';
import { useAppWidthClass } from '../../../utils/hooks';
import ContactsBarContent from './ContactsBarContent';
import {
  HEADER_BAR_HEIGHT,
  HEADER_CONTACTS_HEIGHT,
  HEADER_NAVIGATION_HEIGHT
} from '../../../constants/ui';
import { hasPayButtonInHeader } from '../../../utils/ui';
import { isAuthenticatedAppLoadingSelector } from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 990,
    width: '100vw',
    boxShadow: theme.customShadows.menu,
    transition: theme.transitions.custom.header
  },
  payBarSizeLoading: {
    height: 32
  },
  payBarSize: {
    height: 88
  },
  payBarSizeMobile: {
    height: 56
  },
  removeShadow: {
    boxShadow: 'none'
  },
  content: {
    position: 'relative',
    zIndex: 5,
    height: HEADER_CONTACTS_HEIGHT,
    alignItems: 'center'
  }
}));

const ContactsBar = ({
  isPaidFully,
  currentRoute,
  headerAnimationShift,
  isDefaultPayBarSticky,
  items,
  children
}) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();
  const isAuthenticatedAppLoading = useSelector(
    isAuthenticatedAppLoadingSelector
  );
  const isHome = currentRoute === HOME;
  const isRouteWithPayBarInContacts = hasPayButtonInHeader(
    currentRoute,
    isPaidFully
  );

  return isMobileWidth && isHome ? null : (
    <Box
      className={clsx(
        classes.root,
        !isMobileWidth &&
          isRouteWithPayBarInContacts &&
          isAuthenticatedAppLoading &&
          classes.payBarSizeLoading,
        !isMobileWidth &&
          isRouteWithPayBarInContacts &&
          !isAuthenticatedAppLoading &&
          classes.payBarSize,
        isMobileWidth &&
          isRouteWithPayBarInContacts &&
          !isAuthenticatedAppLoading &&
          classes.payBarSizeMobile,
        isDefaultPayBarSticky && classes.removeShadow
      )}
      style={{
        top: `${
          HEADER_BAR_HEIGHT + HEADER_NAVIGATION_HEIGHT - headerAnimationShift
        }px`
      }}
    >
      {!isMobileWidth && (
        <ContactsBarContent className={classes.content} items={items} />
      )}
      {children}
    </Box>
  );
};

export default ContactsBar;
