import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import { CustomIcon, Module } from '@troy/shared/src/components/common';

import { Blurred, ThemeButton, Translation } from '../../common';
import { DISCOURSE_DUTY_PDF } from '../../../constants/api';
import { getOneTimeToken } from '../../../utils/token';

export const DISCOURSE_DUTY_TITLE = 'DISCOURSE_DUTY_TITLE';
export const DISCOURSE_DUTY_TEXT = 'DISCOURSE_DUTY_TEXT';
export const DOWNLOAD_TEXT = 'Download';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px 56px 40px 32px'
  },
  rootCompact: {
    flexDirection: 'column',
    padding: '40px 32px 32px 32px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    marginLeft: 16
  },
  description: {
    ...theme.typography.body1,
    marginTop: 16,
    color: theme.palette.text.primary
  },
  button: {
    margin: 'auto 0 auto 56px'
  },
  buttonCompact: {
    margin: '40px auto 0 auto'
  },
  buttonBlur: {
    display: 'flex'
  }
}));

const DemandExplanation = ({ id, isTabletSize, blur }) => {
  const classes = useStyles();

  const onButtonClick = () => {
    getOneTimeToken().then(token => {
      if (token) {
        window.open(DISCOURSE_DUTY_PDF(token), '_blank');
      }
    });
  };

  return (
    <Module
      id={id}
      className={clsx(classes.root, isTabletSize && classes.rootCompact)}
    >
      <Box className={classes.content}>
        <Box className={classes.titleRow}>
          <CustomIcon
            ariaHidden
            icon="questionIcon"
            size="medium"
            variant="secondary"
          />
          <Typography
            className={classes.title}
            component="h2"
            variant="h3"
            color="secondary"
          >
            <Translation inline keyIfNoValue>
              {DISCOURSE_DUTY_TITLE}
            </Translation>
          </Typography>
        </Box>
        <Blurred blur={blur}>
          <Translation className={classes.description} keyIfNoValue>
            {DISCOURSE_DUTY_TEXT}
          </Translation>
        </Blurred>
      </Box>
      <Blurred blur={blur} className={classes.buttonBlur}>
        <ThemeButton
          id="page.home.component.demand-explanation.discourse-duty-button"
          className={clsx(
            classes.button,
            isTabletSize && classes.buttonCompact
          )}
          color="secondary"
          variant="outlined"
          size="medium"
          startIcon="downloadIcon"
          startIconColor="secondary"
          onClick={blur ? undefined : onButtonClick}
          textKey={DOWNLOAD_TEXT}
        />
      </Blurred>
    </Module>
  );
};

DemandExplanation.propTypes = {
  isTabletSize: PropTypes.bool,
  blur: PropTypes.bool
};

export default DemandExplanation;
