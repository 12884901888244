import {BE, DE, NL} from './browser';
import { randomCorrelationID } from '../utils/api';
import { getSearchUrl } from '../utils/httpUtil';

export const CORRELATION_ID = randomCorrelationID();

export const BACKEND = process.env.REACT_APP_API_URL;

const MOCK = process.env.REACT_APP_MOCK_URL;

export const LOGIN = `${BACKEND}users/login`;
export const LOGOUT = `${BACKEND}users/logout`;
export const REFRESHTOKEN = `${BACKEND}token/refresh`;
export const GET_USER = `${BACKEND}user`;

export const FORGOTPASSWORD = `${BACKEND}forgotpassword`;
export const RESETPASSWORD = `${BACKEND}resetpassword`;

export const USERS = `${BACKEND}my/user`;
export const USER = id => `${BACKEND}my/user/${id}`;

export const GROUP_USERS = `${BACKEND}my/users`;

export const ME = `${BACKEND}me`;

export const GET_DECISIONS = `${BACKEND}decisions`;
export const PAYMENTS = id => `${BACKEND}payments/${id}`;
export const GET_WPP_DATA = `${BACKEND}payment/beginSession`;
export const MOLLIE_SOFORT_PAYMENT = `${BACKEND}payment/mollie/sofort`;
export const CREDIT_CARD_MOLLIE_PAYMENT = `${BACKEND}payment/mollie/credit-card`;
export const PAYPAL_MOLLIE_PAYMENT = `${BACKEND}payment/mollie/paypal`;
export const PAY_OFFLINE = `${BACKEND}payment/payoffline`;
export const IDEAL_MOLLIE_PAYMENT = `${BACKEND}payment/mollie/ideal`;
export const IDEAL_INSTALLMENT = `${BACKEND}payment/installment-nl`;
export const PAY_LATER = `${BACKEND}payment/pay-later`;
export const SEND_MOCK_CONFIRMATION = `${BACKEND}payment/sendMockConfirmation`;
export const PAYMENT_STATUS = id => `${BACKEND}payment/status?paymentId=${id}`;
export const MOLLIE_BAN_CONTACT_PAYMENT = `${BACKEND}payment/mollie/bancontact`;

export const MAKE_BANK_TRANSFER = token =>
  `${BACKEND}payment/banktransfer.pdf?jwtToken=${token}`;

export const SEND_UI_EVENT = (element, url) =>
  `${BACKEND}track/click?element=${element}&url=${url}`;

export const GET_QUESTIONS = countryExperience =>
  `${BACKEND}survey/questions?countryExperience=${countryExperience}`;
export const SEND_ANSWERS = `${BACKEND}survey/answers`;

export const LIABILITY_STATEMENT_PDF = token =>
  `${BACKEND}document/liabilitystatement.pdf?oneTimeToken=${token}`;

export const DISCOURSE_DUTY_PDF = token =>
  `${BACKEND}document/discourse_duty.pdf?oneTimeToken=${token}`;

const PRIVACY_STATEMENT_MARKUPS = {
  [DE]: 'datenschutzerklaerung',
  [NL]: 'privacy',
  [BE]: 'privacy'
};
export const GET_PRIVACY_STATEMENT = language =>
  `https://www.troy-bleiben.de/wp-json/wp/v2/pages?slug=${
    PRIVACY_STATEMENT_MARKUPS[language || DE]
  }`;

const LEGAL_NOTES_MARKUPS = {
  [DE]: 'impressum',
  [NL]: 'cookieverklaring',
  [BE]: 'cookieverklaring'
};
export const GET_IMPRESSUM = language =>
  `https://www.troy-bleiben.de/wp-json/wp/v2/pages?slug=${
    LEGAL_NOTES_MARKUPS[language || DE]
  }`;

export const GET_NOTIFICATIONS_MOCK = `${MOCK}/notifications`;
export const SEND_CALCULATOR_FORM = `${BACKEND}/payment/installment`;

export const SEND_PROFILE_CHANGES = (type, values) => {
  const api = `${BACKEND}event/log?type=${type}`;
  if (values) {
    const keyArr = Object.entries(values);
    if (keyArr.length) {
      return `${api}&${keyArr
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`;
    }
  }
  return api;
};

export const PAY_SEPA_DIRECT_DEBIT = `${BACKEND}payment/sepadirectdebit`;
export const UPLOAD_FILE = `${BACKEND}document/upload`;

export const SAP_AUTH = businessPartnerID =>
  `${BACKEND}sap/auth?sapBusinessPartnerId=${businessPartnerID}`;

export const LANGUAGE = `${BACKEND}language`;

export const ONE_TIME_TOKEN = `${BACKEND}one-time-token/create`;
export const GET_TOKEN_ON_ONE_TIME = `${BACKEND}one-time-token/login`;

export const RETURN_GOODS = `${BACKEND}blue-movement/return-goods`;

export const SECOND_FACTOR_LOGIN = `${BACKEND}users/login/second-factor`;
