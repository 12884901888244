import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

export const connectTo = (mapStateToProps, actions, Container) => {
  const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);
  return connect(mapStateToProps, mapDispatchToProps)(Container);
};

export const filterMapToArray = (filterObj, objectToFilter) => {
  return Object.keys(objectToFilter).reduce((acc, value) => {
    if (filterObj[value]) acc.push(objectToFilter[value]);
    return acc;
  }, []);
};

export const filterMapToObj = (filterObj, arrayToMap, onField, valueField) => {
  return arrayToMap.reduce((acc, obj) => {
    if (filterObj[obj[onField]]) acc[filterObj[obj[onField]]] = obj[valueField];
    return acc;
  }, {});
};

export const mapToObj = (arrayToMap, onField, valueField) => {
  return arrayToMap.reduce((acc, obj) => {
    acc[obj[onField]] = obj[valueField];
    return acc;
  }, {});
};

export const importAllImages = r => {
  let keys = r.keys();
  const values = keys.map(r);
  keys = keys.map(key => {
    const splitArr = key.split('/');
    return splitArr[splitArr.length - 1];
  });
  return keys.reduce((acc, key, ind) => ({ ...acc, [key]: values[ind] }), {});
};

export const shrinkName = (name, maxLen) => {
  if (name.length > maxLen) {
    const extensionIdx = name.lastIndexOf('.');
    const shrink = '(...)';
    if (extensionIdx !== -1) {
      const extensionLen = name.length - extensionIdx;

      if (extensionLen <= name.length / 2) {
        return (
          name.slice(0, maxLen - extensionLen - shrink.length) +
          shrink +
          name.slice(extensionIdx)
        );
      } else {
        // if extension is too long
        return (
          name.slice(0, extensionIdx) +
          name.slice(extensionIdx, maxLen - shrink.length) +
          shrink
        );
      }
    } else {
      // if there's no extension
      return name.slice(0, maxLen - shrink.length) + shrink;
    }
  } else {
    return name;
  }
};

export const createEmailSubjectLine = (caseId, translations) =>
  !!caseId ? `?subject=${translations['TCM_CASEID']}: ${caseId}` : '';
