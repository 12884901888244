import { call, put } from 'redux-saga/effects';

import { get } from '../../utils/httpUtil';
import { GET_IMPRESSUM, GET_PRIVACY_STATEMENT } from '../../constants/api';

import { DOMAIN_LANGUAGE } from '../../constants/browser';

import {
  handleError,
  impressumPageDownloaded,
  privacyStatementPageDownloaded
} from '../actions';

export function* getImpressum() {
  try {
    const data = yield call(
      get,
      GET_IMPRESSUM(DOMAIN_LANGUAGE),
      false,
      true,
      true
    );
    yield put(impressumPageDownloaded(data[0]));
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* getPrivacyStatement() {
  try {
    const data = yield call(
      get,
      GET_PRIVACY_STATEMENT(DOMAIN_LANGUAGE),
      false,
      true,
      true
    );
    yield put(privacyStatementPageDownloaded(data[0]));
  } catch (error) {
    yield put(handleError(error));
  }
}
