import {BE, DE, DOMAIN_LANGUAGE, NL} from '../../constants/browser';
import { envKeyString } from '../../constants/env';

const quriobotIDs = {
  [DE]: {
    loggedIn: {
      prod: '9nJaZmp23wEpvxPR/V895xbyLkKEeqZYd',
      test: '9nJaZmp23wEpvxPR/KoPqxmzDPzEg5eNl',
      demo: '9nJaZmp23wEpvxPR/KoPqxmzDPzEg5eNl',
      dev: '9nJaZmp23wEpvxPR/wKYPWr64kBbd0px4'
    },
    loggedOut: {
      prod: '9nJaZmp23wEpvxPR/DYRAQrGeDwbW05dx',
      test: '9nJaZmp23wEpvxPR/DYRAQrGeDwbW05dx',
      demo: '9nJaZmp23wEpvxPR/DYRAQrGeDwbW05dx',
      dev: '9nJaZmp23wEpvxPR/DYRAQrGeDwbW05dx'
    }
  },
  [NL]: {
    loggedIn: {
      prod: '9nJaZmp23wEpvxPR/lD1XMmd24xEPVZKN',
      test: '9nJaZmp23wEpvxPR/nj7qyrYgYNbkgxzB',
      demo: '9nJaZmp23wEpvxPR/nj7qyrYgYNbkgxzB',
      dev: '9nJaZmp23wEpvxPR/nj7qyrYgYNbkgxzB'
    },
    loggedOut: {
      prod: '9nJaZmp23wEpvxPR/PkZNLEkQYxm5XGaR',
      test: '9nJaZmp23wEpvxPR/PkZNLEkQYxm5XGaR',
      demo: '9nJaZmp23wEpvxPR/PkZNLEkQYxm5XGaR',
      dev: '9nJaZmp23wEpvxPR/PkZNLEkQYxm5XGaR'
    }
  },
  [BE]: {
    loggedIn: {
      prod: '9nJaZmp23wEpvxPR/QeWyGENyVoraLRj0',
      test: '9nJaZmp23wEpvxPR/8qJgArRzygrdvoR9',
      demo: '9nJaZmp23wEpvxPR/8qJgArRzygrdvoR9',
      dev: '9nJaZmp23wEpvxPR/8qJgArRzygrdvoR9'
    },
    loggedOut: {
      prod: '9nJaZmp23wEpvxPR/MY12wr8RqObd8ARO',
      test: '9nJaZmp23wEpvxPR/MY12wr8RqObd8ARO',
      demo: '9nJaZmp23wEpvxPR/MY12wr8RqObd8ARO',
      dev: '9nJaZmp23wEpvxPR/MY12wr8RqObd8ARO'
    }
  }
};

const ONLINE = 'online';
const OFFLINE = 'offline';

const liveAgentIDs = {
  [DE]: {
    [ONLINE]: {
      prod: 'iuz8ak81',
      test: '6t8y1w3j',
      demo: '6t8y1w3j',
      dev: '5d58z4nj'
    },
    [OFFLINE]: {
      prod: 'q974e88u',
      test: '6t8y1w3j',
      demo: '6t8y1w3j',
      dev: 'fpdow54s'
    }
  },
  [NL]: {
    [ONLINE]: {
      prod: 't2kbr92b',
      test: 'bh09wfov',
      demo: 'bh09wfov',
      dev: 'bh09wfov'
    },
    [OFFLINE]: {
      prod: 'd89iuaco',
      test: 'bh09wfov',
      demo: 'bh09wfov',
      dev: 'fpdow54s'
    }
  },
  [BE]: {
    [ONLINE]: {
      prod: 't2kbr92b',
      test: 'bh09wfov',
      demo: 'bh09wfov',
      dev: 'bh09wfov'
    },
    [OFFLINE]: {
      prod: 'd89iuaco',
      test: 'bh09wfov',
      demo: 'bh09wfov',
      dev: 'fpdow54s'
    }
  }
};

export const getQuriobotID = isLoggedIn => {
  const ids =
    quriobotIDs[DOMAIN_LANGUAGE][isLoggedIn ? 'loggedIn' : 'loggedOut'];
  return ids[envKeyString()];
};

export const getLiveAgentId = isBusinessHoursOnlineAgentChat => {
  const ids =
    liveAgentIDs[DOMAIN_LANGUAGE][
      isBusinessHoursOnlineAgentChat ? ONLINE : OFFLINE
    ];
  return ids[envKeyString()];
};

export const liveAgentSrcUrl =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://troy.ladesk.com/scripts/track.js'
    : process.env.REACT_APP_ENV === 'development'
    ? 'https://troy-dev.ladesk.com/scripts/track.js'
    : 'https://troy-test.ladesk.com/scripts/track.js';

export const liveAgentDomain =
  process.env.REACT_APP_ENV === 'production'
    ? 'troy.ladesk.com'
    : process.env.REACT_APP_ENV === 'development'
    ? 'troy-dev.ladesk.com'
    : 'troy-test.ladesk.com';
