import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';

import {
  authLoadingSelector,
  portalLoginLogoSelector,
  translationsSelector
} from '../../../store/selectors';
import { useAppWidthClass } from '../../../utils/hooks';

import {
  CustomIcon,
  Paper,
  Text,
  TextInput,
  Tooltip
} from '@troy/shared/src/components/common';

import { ThemeButton } from '../../common';
import { WelcomeTitle } from '../Welcome';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    borderRadius: theme.shape.borderRadius * 6,
    boxSizing: 'border-box',
    boxShadow: theme.customShadows.login
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    height: 48
  },
  welcome: {
    marginTop: theme.spacing(2)
  },
  userName: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
  password: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(4)
  },
  helpButton: {
    marginTop: theme.spacing(2.25)
  },
  helpTooltip: {
    display: 'flex',
    alignItems: 'center'
  },
  helpMessage: {
    marginLeft: theme.spacing(1)
  }
}));

const LoginForm = ({ onSubmit }) => {
  const classes = useStyles();
  const loginLogo = useSelector(portalLoginLogoSelector);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { isMobileWidth } = useAppWidthClass();

  const isLoading = useSelector(authLoadingSelector);
  const t = useSelector(translationsSelector);

  const onToggleVisibility = () => setIsPasswordVisible(!isPasswordVisible);

  const showTooltip = () => setTooltipVisible(true);

  const closeTooltip = () => setTooltipVisible(false);

  return (
    <Paper className={classes.root}>
      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={onSubmit}
      >
        <Form className={classes.form}>
          <img src={loginLogo} alt="troy-logo" className={classes.logo} />
          {isMobileWidth && (
            <WelcomeTitle className={classes.welcome} isMobileWidth />
          )}
          <TextInput
            withBorder
            labelFixed
            required
            id="login-form-username"
            className={classes.userName}
            name="username"
            placeholder="LOGIN"
            inputPlaceholder="1234567890"
            startIcon="profile"
          />
          <TextInput
            withBorder
            labelFixed
            required
            id="login-form-password"
            className={classes.password}
            name="password"
            type={isPasswordVisible ? 'tex' : 'password'}
            placeholder="PASSWORD"
            inputPlaceholder="12345"
            startIcon="key"
            button={
              <IconButton
                id="login-form-password-visibility-toggle"
                color="default"
                size="large"
                onClick={onToggleVisibility}
                aria-label={
                  t[
                    isPasswordVisible
                      ? 'LOGIN_HIDE_PASSWORD'
                      : 'LOGIN_SHOW_PASSWORD'
                  ]
                }
              >
                <CustomIcon
                  icon={isPasswordVisible ? 'visibleIcon' : 'invisibleIcon'}
                  variant="primary"
                  size="small"
                />
              </IconButton>
            }
          />
          <ThemeButton
            fullWidth
            hasAnimation
            className={classes.button}
            id="page.login.component.login-form.login-button"
            type="submit"
            variant="contained"
            textKey="LOGIN"
            isLoading={isLoading}
          />
          <Tooltip
            arrow
            open={tooltipVisible}
            title={
              <div className={classes.helpTooltip}>
                <CustomIcon
                  icon="info"
                  size="small"
                  variant="primaryContrast"
                />
                <Text
                  translated
                  className={classes.helpMessage}
                  variant="subtitle1"
                  component="span"
                  color="primaryContrast"
                >
                  LOGIN_CRED_TOOLTIP
                </Text>
              </div>
            }
            placement="bottom"
          >
            <ThemeButton
              className={classes.helpButton}
              id="page.login.component.login-form.help-button"
              variant="text"
              fontStyle="body2"
              color="hint"
              size="medium"
              textKey="LOGIN_CRED_HELP_BTN"
              onMouseOver={showTooltip}
              onMouseLeave={closeTooltip}
              onFocus={showTooltip}
              onBlur={closeTooltip}
            />
          </Tooltip>
        </Form>
      </Formik>
    </Paper>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func
};

export default LoginForm;
