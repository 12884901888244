import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, List } from '@mui/material';

import { CustomIcon, Dropzone, Text } from '@troy/shared/src/components/common';
import { enqueueSnackbar } from '@troy/shared/src/store/actions';

import {
  DOC,
  DOCX,
  JPEG,
  JPG,
  PDF,
  PJPEG,
  PNG
} from '../../../constants/file-upload';
import { useAppWidthClass } from '../../../utils/hooks';
import { FilePreview } from '../../common';

const ACCEPTED_TYPES = [PDF, DOC, DOCX, PJPEG, JPEG, JPG, PNG];
const MAX_SIZE = 25 * 1024 * 1024; // 25 Mb
const MAX_FILE_COUNT = 10;

const filesCheck = files => !!files && !!files.length;
const fileValidator = files =>
  filesCheck(files) ? undefined : 'Files are required';

const useStyles = makeStyles(theme => ({
  dropzoneWrapper: {
    width: 418,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 300ms ease-in-out'
  },
  dropzoneWrapperDesktopFiles: {
    width: '100%'
  },
  dropzoneWrapperMobile: {
    width: 309
  },
  dropzone: {
    flexShrink: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(5),
    border: `2px dashed ${theme.palette.text.disabled}`,
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius * 4,
    transition: theme.transitions.custom.fast,

    '&:hover, &:focus': {
      borderColor: theme.palette.primary.main,
      outline: 'none'
    }
  },
  dropzoneMobile: {
    width: '100%',
    minHeight: 104,
    padding: `${theme.spacing(5)} ${theme.spacing(3)} ${theme.spacing(
      5
    )} ${theme.spacing(3)}`
  },
  dropzoneActive: {
    borderColor: theme.palette.primary.main,
    outline: 'none'
  },
  dropzoneFilesMobile: {
    paddingBottom: theme.spacing(3)
  },
  dropzoneFilesDesktop: {
    flexDirection: 'row',
    margin: `${theme.spacing(1)} 0`
  },
  explanation: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1
  },
  explanationFilesDesktop: {
    maxWidth: 233
  },
  uploadExplanation: {
    marginLeft: theme.spacing(1),
    transition: theme.transitions.custom.fast
  },
  info: {
    margin: '-5px -5px -5px auto'
  },
  rejected: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    '& > svg': {
      marginRight: theme.spacing(2)
    }
  },
  separator: {
    flexShrink: 0,
    width: 1,
    minHeight: 168,
    height: '100%',
    background: theme.palette.text.disabled
  },
  filler: {
    width: 53,
    flexShrink: 1
  },
  fileList: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  fileListFilesDesktop: {
    width: '100%',
    maxWidth: 233,
    justifyContent: 'center'
  },
  fileListMobile: {
    marginTop: theme.spacing(2)
  },
  file: {
    marginTop: theme.spacing(1),

    '&:first-of-type': {
      marginTop: 0
    }
  }
}));

const UploadFileModalDropzone = ({
  values,
  disabled,
  setFieldValue,
  onInfoButtonClick
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMobileWidth } = useAppWidthClass();

  const [isDragActive, setDragActive] = useState(false);
  const [hasRejectedFiles, setHasRejectedFiles] = useState(false);
  const [hasFileInputFocus, setHasFileInputFocus] = useState(false);

  const onInfoClick = e => {
    e.stopPropagation();
    onInfoButtonClick();
  };

  const onFileRemove = (e, file, files, change) => {
    if (files.length) {
      e.stopPropagation();
      setHasRejectedFiles(false);
      const filesCopy = files.slice();
      const fileIdx = filesCopy.indexOf(file);
      filesCopy.splice(fileIdx, 1);
      change(filesCopy);
    }
  };

  const onDropzoneFileExcess = () => {
    dispatch(
      enqueueSnackbar({
        message: 'UPLOAD_FILESIZE_ERROR',
        options: {
          variant: 'error'
        }
      })
    );
  };

  const onDropzoneUploadHandler = hasSomeRejected => {
    setHasRejectedFiles(hasSomeRejected);
  };

  const handleFileInputFocus = () => setHasFileInputFocus(true);

  const handleFileInputBlur = () => setHasFileInputFocus(false);

  const hasFiles = values && values.files && values.files.length > 0;

  return (
    <div
      className={clsx(
        classes.dropzoneWrapper,
        isMobileWidth && classes.dropzoneWrapperMobile,
        hasFiles && !isMobileWidth && classes.dropzoneWrapperDesktopFiles
      )}
    >
      <Dropzone
        multiple
        multipleAdding
        name="files"
        maxFileCount={MAX_FILE_COUNT}
        maxSize={MAX_SIZE}
        accept={ACCEPTED_TYPES}
        className={clsx(
          classes.dropzone,
          (isDragActive || hasFileInputFocus) && classes.dropzoneActive,
          isMobileWidth && classes.dropzoneMobile,
          hasFiles && isMobileWidth && classes.dropzoneFilesMobile,
          hasFiles && !isMobileWidth && classes.dropzoneFilesDesktop
        )}
        id="page.postbox.component.upload-file-modal.file-dropzone"
        validate={fileValidator}
        onMaxSizeExcess={onDropzoneFileExcess}
        onDropzoneUploadHandler={onDropzoneUploadHandler}
        setDragActive={setDragActive}
        disabled={
          disabled ||
          (values && values.files && values.files.length >= MAX_FILE_COUNT)
        }
        onFileInputFocus={handleFileInputFocus}
        onFileInputBlur={handleFileInputBlur}
        inputAriaLabelledBy="page.postbox.component.upload-file-modal.upload-explanation"
      >
        <div
          className={clsx(
            classes.explanation,
            hasFiles && !isMobileWidth && classes.explanationFilesDesktop
          )}
        >
          <CustomIcon
            icon="add"
            size="small"
            variant={isDragActive ? 'primary' : 'disabled'}
          />
          <Text
            translated
            id="page.postbox.component.upload-file-modal.upload-explanation"
            className={classes.uploadExplanation}
            component="span"
            variant="h4"
            color={isDragActive ? 'primaryMain' : 'textDisabled'}
          >
            UPLOAD_EXPLANATION
          </Text>
          <IconButton
            className={classes.info}
            id="page.postbox.component.upload-file-modal.info-button"
            aria-label="Info"
            onClick={onInfoClick}
            color="primary"
            size="small"
          >
            <CustomIcon icon="info" size="small" variant="primary" />
          </IconButton>
        </div>
        {hasFiles && !isMobileWidth && (
          <>
            <div className={classes.filler} />
            <div className={classes.separator} />
            <div className={classes.filler} />
          </>
        )}
        {hasFiles && (
          <List
            className={clsx(
              classes.fileList,
              isMobileWidth && classes.fileListMobile,
              hasFiles && !isMobileWidth && classes.fileListFilesDesktop
            )}
          >
            {values.files.map((file, index) => (
              <FilePreview
                key={index}
                className={classes.file}
                file={file}
                removeButtonId={`page.postbox.component.file-upload-modal.file-remove-button${
                  index > 0 ? `.${index}` : ''
                }`}
                onRemove={e =>
                  onFileRemove(e, file, values.files, files =>
                    setFieldValue('files', files)
                  )
                }
                disabled={disabled}
              />
            ))}
          </List>
        )}
      </Dropzone>
      {hasRejectedFiles && (
        <div className={classes.rejected}>
          <CustomIcon icon="alert" variant="warningDark" size="small" />
          <Text translated component="p" variant="body2" color="warningDark">
            UPLOAD_EXTENSIONS_ERROR
          </Text>
        </div>
      )}
    </div>
  );
};

export default UploadFileModalDropzone;
