import React from 'react';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';

import { CONFIDENTIALITY } from '../../../constants/routes';
import { Link, ThemeButton, Translation } from '../../common';
import { useAppWidthClass } from '../../../utils/hooks';
import cookiesImg from '../../../assets/img/cookies/cookies.svg';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  contentMobile: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  cookiesImg: {
    height: 76
  },
  cookiesImgMobile: {
    height: 56,
    margin: 'auto'
  },
  info: {
    marginLeft: theme.spacing(4)
  },
  infoMobile: {
    marginLeft: 0,
    marginTop: theme.spacing(4)
  },
  link: {
    ...theme.typography.body1,
    display: 'inline-block',
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    textDecoration: 'none',
    transition: theme.transitions.custom.fast,

    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  button: {
    marginTop: theme.spacing(5),

    '&:nth-of-type(1n+2)': {
      marginTop: theme.spacing(2)
    }
  }
}));

const CookieAgreementStartScreen = ({
  onOkClick,
  onCancelClick,
  onCookieDetailsClick
}) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  return (
    <>
      <div
        className={clsx(
          classes.content,
          isMobileWidth && classes.contentMobile
        )}
      >
        <img
          className={clsx(
            classes.cookiesImg,
            isMobileWidth && classes.cookiesImgMobile
          )}
          src={cookiesImg}
          alt="illustration"
        />
        <div
          className={clsx(classes.info, isMobileWidth && classes.infoMobile)}
        >
          <Typography variant="body1" color="textPrimary">
            <Translation inline>COOKIE_BANNER_TEXT</Translation>
          </Typography>
          <Link
            id="page.component.cookie-agreement.cookie-link"
            className={classes.link}
            to={CONFIDENTIALITY}
          >
            <Translation inline>COOKIE_BANNER_LINK</Translation>
          </Link>
        </div>
      </div>
      <ThemeButton
        fullWidth
        className={classes.button}
        id="page.component.cookie-agreement.ok-button"
        variant="contained"
        textKey="COOKIE_BANNER_BUTTON"
        onClick={onOkClick}
      />
      <ThemeButton
        fullWidth
        className={classes.button}
        id="page.component.cookie-agreement.cancel-button"
        variant="outlined"
        color="primaryLight"
        textKey="COOKIE_BANNER_BUTTON_DECLINE"
        onClick={onCancelClick}
      />
      <ThemeButton
        fullWidth
        className={classes.button}
        id="page.component.cookie-agreement.settings-button"
        size="medium"
        color="textSecondary"
        onClick={onCookieDetailsClick}
        textKey="COOKIE_BANNER_SETTINGS"
        endIcon="expandMore"
        endIconColor="textSecondary"
      />
    </>
  );
};

export default CookieAgreementStartScreen;
