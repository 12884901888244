import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { CommonSelect } from '../../common';
import { SELECT_VALUES } from './constants';
import { useAppWidthClass } from '../../../utils/hooks';

const typeCheck = type => !!type;
const typeValidator = type =>
  typeCheck(type) ? undefined : 'Type is required';

const useStyles = makeStyles(() => ({
  select: {
    width: 311,
    margin: 'auto'
  },
  selectMobile: {
    margin: 'auto 0 auto 0'
  }
}));

const UploadFileModalTypeSelect = () => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  return (
    <div className={isMobileWidth ? classes.selectMobile : classes.select}>
      <CommonSelect
        id="upload-file-form-type"
        labelId="upload-file-form-type-label"
        name="type"
        validate={typeValidator}
        options={SELECT_VALUES}
        translated
      />
    </div>
  );
};

export default UploadFileModalTypeSelect;
