import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { onClickWrapper } from '../../../utils/ui';

interface LinkProps {
  raw?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  children: React.ReactNode;
  to?: string;
  className?: string;
  id?: string;
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ raw, onClick, children, to, className, id, ...props }, ref) =>
    raw ? (
      <a
        ref={ref}
        href={to}
        onClick={onClickWrapper(id, onClick)}
        className={className}
        id={id}
        {...props}
      >
        {children}
      </a>
    ) : (
      <RouterLink
        ref={ref}
        to={to}
        className={className}
        id={id}
        onClick={onClickWrapper(id, onClick)}
        {...props}
      >
        {children}
      </RouterLink>
    )
);

Link.displayName = 'Link';

export default Link;
