import React from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';

import { connectTo } from '../../utils';
import { logout, selectLanguage } from '../../store/actions';
import * as routes from '../../constants/routes';

import { CustomFooter, Footer, Header } from '../../components/main/index.troy';

import styles from './index.module.scss';
import {
  caseIdSelector,
  flagSelectLanguagesSelector,
  portalBrandNameSelector,
  portalContactBarItemsSelector,
  portalFooterDataSelector,
  portalHasCustomFooterSelector
} from '../../store/selectors';
import { contacts } from '../../constants/contacts';

const getPageTitles = isAuthenticated => ({
  [routes.HOME]: isAuthenticated ? 'PAGETITLE_WELCOME' : 'PAGETITLE_LOGIN',
  [routes.PAYMENTS]: 'PAGETITLE_PAYMENTS',
  [routes.POST_BOX]: 'PAGETITLE_POSTBOX',
  [routes.THANKS]: 'PAGETITLE_THANKYOU',
  [routes.FEEDBACK]: 'PAGETITLE_FEEDBACK',
  [routes.VERSION]: 'PAGETITLE_VERSION',
  [routes.RETURN_GOODS]: 'PAGETITLE_RETURN',
  [routes.PAYMENT_SUCCESS]: 'PAGETITLE_PAYMENTSUCCESS',
  [routes.ERROR]: 'PAGETITLE_ERROR',
  [routes.COMPANY_INFO]: 'PAGETITLE_IMPRINT',
  [routes.CONFIDENTIALITY]: 'PAGETITLE_DATAPRIVACY'
});

class MainLayout extends React.Component {
  componentDidMount() {
    this.setTitle();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, translations, isAuthenticated } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }

    if (
      location.pathname !== prevProps.location.pathname ||
      translations !== prevProps.translations ||
      isAuthenticated !== prevProps.isAuthenticated
    ) {
      this.setTitle();
    }
  }

  setTitle = () => {
    const { location, translations, isAuthenticated, brandName } = this.props;
    const pageTitles = getPageTitles(isAuthenticated);
    const titleKey = pageTitles[location.pathname];
    if (titleKey && translations[titleKey]) {
      // TODO change back to translation key PAGETITLE_BRAND once it is working
      document.title = `${translations[titleKey]} | ${brandName.toLowerCase()}`;
    }
  };

  onLogout = () => {
    const { logout } = this.props;
    logout();
  };

  onLanguageSelect = newCode => {
    const { selectLanguage, isAuthenticated } = this.props;
    selectLanguage({ code: newCode, isAuthenticated });
  };

  render() {
    const {
      children,
      isAuthenticated,
      availableLanguages,
      language,
      logout,
      hasStaticTexts,
      location,
      translations,
      hasCustomFooter,
      customFooterData,
      portalContactBarItems,
      caseId
    } = this.props;

    // login page do not show skeletons
    if (
      !isAuthenticated &&
      [routes.HOME, routes.LOGIN].includes(location.pathname) &&
      !hasStaticTexts
    ) {
      return null;
    }
    const contactItems = contacts(translations, portalContactBarItems, caseId);

    return (
      <div
        className={clsx(
          styles['main-layout__wrapper'],
          contactItems.length > 0 &&
            isAuthenticated &&
            styles['main-layout__wrapper-mobile-padding']
        )}
      >
        <Header
          isAuthenticated={isAuthenticated}
          onLogout={this.onLogout}
          languages={availableLanguages}
          onLanguageSelect={this.onLanguageSelect}
          language={language}
        />
        {children}
        {hasCustomFooter && (
          <CustomFooter
            isAuthenticated={isAuthenticated}
            logout={logout}
            footerData={customFooterData}
          />
        )}
        {!hasCustomFooter && (
          <Footer isAuthenticated={isAuthenticated} logout={logout} />
        )}
      </div>
    );
  }
}

export default withRouter(
  connectTo(
    state => {
      const {
        translations: { language, hasStaticTexts, translations }
      } = state;

      return {
        availableLanguages: flagSelectLanguagesSelector(state),
        language,
        hasStaticTexts,
        translations,
        hasCustomFooter: portalHasCustomFooterSelector(state),
        customFooterData: portalFooterDataSelector(state),
        brandName: portalBrandNameSelector(state),
        portalContactBarItems: portalContactBarItemsSelector(state),
        caseId: caseIdSelector(state)
      };
    },
    { logout, selectLanguage },
    MainLayout
  )
);
