import { put, select } from 'redux-saga/effects';

import { callHttp } from '../../utils/api';
import { postFormData } from '../../utils/httpUtil';
import { UPLOAD_FILE } from '../../constants/api';

import { setError, setLoading, setUploadSuccess } from '../actions';
import {
  enqueueSnackbar,
  getNotifications
} from '@troy/shared/src/store/actions';

export function* uploadFile({ payload: { files, remember, ...values } }) {
  const formData = new FormData();

  Object.entries(values).forEach(([key, value]) => formData.append(key, value));
  files.forEach(value => formData.append('file', value));

  const translations = yield select(state => state.translations.translations);

  yield put(setLoading(true));
  try {
    yield callHttp(postFormData, UPLOAD_FILE, formData);
    yield put(getNotifications());
    yield put(setUploadSuccess(true));

    return yield put(
      enqueueSnackbar({
        message: translations['UPLOAD_FILE_SUCCESSFUL'],
        options: {
          variant: 'success'
        }
      })
    );
  } catch (err) {
    yield put(setError(err));
    return yield put(
      enqueueSnackbar({
        message: err.message || 'Error during upload!',
        options: {
          variant: 'error'
        }
      })
    );
  } finally {
    yield put(setLoading(false));
  }
}
