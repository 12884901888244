import React from 'react';
import { Box } from '@mui/material';

import { onClickWrapper } from '../../../utils/ui';
import { PORTAL_DE, PORTAL_NL } from '../../../utils/portal';
import { createEmailSubjectLine } from '../../../utils';

import { CustomIcon } from '@troy/shared/src/components/common';

import { Link, Translation } from '../../common';
import Footer from './index';

import nviLogo from '../../../assets/img/footer/footer_nvi_logo.png';
import inkassoKeurmerk from '../../../assets/img/footer/footer_inkasso_keurmerk.png';
import FooterNavigationLinks from '../FooterNavigationLinks';

const EMAIL_KEY = 'TROY_EMAIL';
const PHONE_KEY = 'TROY_PHONE';
const FAX_KEY = 'TROY_FAX';

const columns = {
  [PORTAL_DE]: {
    address: ({ className }) => (
      <Translation className={className}>TROY_ADDRESS</Translation>
    ),
    contacts: ({ className, linkClass, iconClass, translations, caseId }) => (
      <Box className={className}>
        <Link
          raw
          id="page.component.footer.email-link"
          className={linkClass}
          to={`mailto:${translations[EMAIL_KEY]}${createEmailSubjectLine(
            caseId,
            translations
          )}`}
        >
          <CustomIcon
            className={iconClass}
            icon="mailIcon"
            variant="primary"
            size="xs"
          />
          <Translation inline>{EMAIL_KEY}</Translation>
        </Link>
        <Link
          raw
          id="page.component.footer.phone-link"
          className={linkClass}
          to={`tel:${translations[PHONE_KEY]}`}
        >
          <CustomIcon
            className={iconClass}
            icon="contactTel"
            variant="primary"
            size="xs"
          />
          <Translation inline>{PHONE_KEY}</Translation>
        </Link>
        <Link
          raw
          id="page.component.footer.fax-link"
          className={linkClass}
          to={`fax:${translations[FAX_KEY]}`}
        >
          <CustomIcon
            className={iconClass}
            icon="letter"
            variant="primary"
            size="xs"
          />
          <Translation inline>{FAX_KEY}</Translation>
        </Link>
      </Box>
    ),
    links: ({ className, linkClass, isAuthenticated, logout }) => (
      <Box className={className}>
        <FooterNavigationLinks
          linkClass={linkClass}
          hasLogout={isAuthenticated}
          logout={logout}
        />
      </Box>
    )
    // bankInfo: ({ className, itemClass, labelClass, valueClass }) => (
    //   <Box className={className}>
    //     <Box className={itemClass}>
    //       <span className={labelClass}>IBAN</span>
    //       <Translation inline className={valueClass}>
    //         IBAN
    //       </Translation>
    //     </Box>
    //     <Box className={itemClass}>
    //       <span className={labelClass}>BIC</span>
    //       <Translation inline className={valueClass}>
    //         BIC
    //       </Translation>
    //     </Box>
    //   </Box>
    // )
  },
  [PORTAL_NL]: {
    address: ({ className }) => (
      <Translation className={className}>TROY_ADDRESS</Translation>
    ),
    contacts: ({ className, linkClass, iconClass, translations, caseId }) => (
      <Box className={className}>
        <Link
          raw
          id="page.component.footer.home-link"
          className={linkClass}
          to="https://www.troy.nl"
          target="_blank"
        >
          <CustomIcon
            className={iconClass}
            icon="languageIcon"
            variant="primary"
            size="xs"
          />
          <span>www.troy.nl</span>
        </Link>
        <Link
          raw
          id="page.component.footer.email-link"
          className={linkClass}
          to={`mailto:${translations[EMAIL_KEY]}${createEmailSubjectLine(
            caseId,
            translations
          )}`}
        >
          <CustomIcon
            className={iconClass}
            icon="mailIcon"
            variant="primary"
            size="xs"
          />
          <Translation inline>{EMAIL_KEY}</Translation>
        </Link>
        <Link
          raw
          id="page.component.footer.phone-link"
          className={linkClass}
          to={`tel:${translations[PHONE_KEY]}`}
        >
          <CustomIcon
            className={iconClass}
            icon="contactTel"
            variant="primary"
            size="xs"
          />
          <Translation inline>{PHONE_KEY}</Translation>
        </Link>
      </Box>
    ),
    links: ({
      className,
      linkClass,
      isAuthenticated,
      showLogout = true,
      logout
    }) => (
      <Box className={className}>
        <FooterNavigationLinks
          linkClass={linkClass}
          hasLogout={isAuthenticated && showLogout}
          logout={logout}
        />
      </Box>
    ),
    logout: ({ className, linkClass, isAuthenticated, logout }) =>
      isAuthenticated ? (
        <Box className={className}>
          <button
            id="footer-logout-link"
            className={linkClass}
            onClick={onClickWrapper('page.component.footer.logout-button', () =>
              logout()
            )}
          >
            <Translation inline>LOGOUT_BUTTON</Translation>
          </button>
        </Box>
      ) : null,
    nviLogos: ({ className }) => (
      <Box className={className}>
        <img
          src={nviLogo}
          alt="NVI Logo"
          width={105}
          style={{ marginTop: '-11px' }}
        />
        <img src={inkassoKeurmerk} alt="Inkasso Keurmerk" width={45} />
      </Box>
    )
  }
};

const TroyFooter = ({ isAuthenticated, logout }) => (
  <Footer isAuthenticated={isAuthenticated} logout={logout} columns={columns} />
);

export default TroyFooter;
