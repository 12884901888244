import React from 'react';
import { Dialog } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import useDimensions from 'react-cool-dimensions';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  paperClassName?: string;
  backdropRootClassName?: string;
  fullWidth?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  paperClassName,
  backdropRootClassName,
  children,
  fullWidth = true
}): React.ReactElement => {
  const { observe, height } = useDimensions();

  return (
    <Dialog
      classes={{ paper: paperClassName }}
      BackdropProps={{ classes: { root: backdropRootClassName } }}
      open={isOpen}
      onClose={onClose}
      fullWidth={fullWidth}
    >
      <Scrollbars style={{ width: '100%', height }}>
        <div ref={observe}>{children}</div>
      </Scrollbars>
    </Dialog>
  );
};

export default Modal;
