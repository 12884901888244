import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { FieldErrorMessage } from '@troy/shared/src/components/common';
import { TextField, Translation } from '../../common';
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: theme.shape.borderRadius * 4,
    overflow: 'hidden',
    boxSizing: 'border-box',

    '& fieldset': {
      display: 'none'
    }
  },
  wrapperDisabled: {
    borderColor: theme.palette.text.disabled
  },
  wrapperError: {
    borderColor: theme.palette.warning.dark
  },
  wrapperOk: {
    borderColor: theme.palette.primary.main
  },
  input: {
    ...theme.typography.body1,
    padding: '3px 15px',
    height: 'auto',
    color: theme.palette.text.primary,

    '&::placeholder': {
      color: theme.palette.text.hint,
      lineHeight: 'normal'
    },
    '&::-moz-placeholder': {
      color: theme.palette.text.hint
    },
    '&:-ms-input-placeholder': {
      color: theme.palette.text.hint
    }
  },
  inputDisabled: {
    color: `${theme.palette.text.disabled} !important`
  },
  inputError: {
    color: theme.palette.warning.dark,
    background: alpha(theme.palette.warning.dark, 0.1)
  },
  error: {
    ...theme.typography.subtitle2,
    marginLeft: 16,
    marginTop: 4,
    color: theme.palette.warning.dark
  },
  hint: {
    ...theme.typography.subtitle2,
    marginLeft: 16,
    marginTop: 8,
    color: theme.palette.text.hint
  }
}));

const PaymentFormInput = ({
  id,
  disabled,
  disabledVisually,
  readonly,
  value,
  name,
  placeholder,
  onFormat,
  validator,
  error,
  errorMessage,
  hint,
  onFocus,
  touched,
  currentValue,
  minimumInputForErrorFeedback
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const classes = useStyles();

  const handleFocus = e => {
    setHasFocus(true);
    if (!!onFocus) {
      onFocus(e);
    }
  };

  if (
    !!minimumInputForErrorFeedback &&
    !touched &&
    currentValue?.length < minimumInputForErrorFeedback &&
    error
  ) {
    error = false;
  }

  return (
    <>
      <TextField
        id={id}
        readonly={readonly}
        value={value}
        name={name}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={() => setHasFocus(false)}
        onFormat={onFormat}
        validate={validator}
        disabled={disabled}
        className={clsx(
          classes.wrapper,
          disabledVisually && classes.wrapperDisabled,
          error && classes.wrapperError,
          !error && hasFocus && classes.wrapperOk
        )}
        classNameInput={clsx(
          classes.input,
          disabledVisually && classes.inputDisabled,
          !hasFocus && error && classes.inputError
        )}
      />
      <FieldErrorMessage
        visible={!hasFocus}
        error={error}
        errorMessageKey={errorMessage}
      />
      {hint && <Translation className={classes.hint}>{hint}</Translation>}
    </>
  );
};

export default PaymentFormInput;
