import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import IntlTelInput from 'react-intl-tel-input';
import flag2x from 'react-intl-tel-input/dist/flags@2x.png';
import { parsePhoneFromString } from '@troy/shared/src/utils/phone';
import {
  FieldErrorMessage,
  FormField
} from '@troy/shared/src/components/common';
import { parsePhoneWithNewCountry } from '../../../utils/phone';
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: theme.shape.borderRadius * 4,

    '& .iti-flag': {
      backgroundImage: `url('${flag2x}')`,
      backgroundSize: '5630px 15px'
    },
    '& .flag-container': {
      display: 'flex',

      '& .selected-flag': {
        alignSelf: 'center',
        marginLeft: '6px !important',
        width: '12px !important',
        height: '12px !important',
        padding: '0 !important',
        borderRadius: '100% !important',
        overflow: 'hidden !important',
        transform: 'scale(1.666666666666) !important',

        '& .iti-arrow, .arrow': {
          display: 'none'
        }
      },
      '& .country-list': {
        position: 'fixed',
        top: 'unset !important',
        width: 254,
        marginTop: 35,
        padding: '12px 0',
        border: `1px solid ${theme.palette.text.primary}`,
        boxShadow: theme.customShadows.module
      }
    }
  },
  rootDisabled: {
    borderColor: theme.palette.text.disabled
  },
  input: {
    ...theme.typography.body1,
    background: 'transparent',
    border: 0,
    borderRadius: theme.shape.borderRadius * 4,
    width: '100%',
    padding: '3px 15px 3px 40px !important',
    color: theme.palette.text.primary,
    textAlign: 'left',

    '&:focus-visible': {
      outline: 'none'
    },
    '&::placeholder': {
      color: theme.palette.text.hint
    },
    '&::-moz-placeholder': {
      color: theme.palette.text.hint
    },
    '&:-ms-input-placeholder': {
      color: theme.palette.text.hint
    }
  },
  inputDisabled: {
    color: theme.palette.text.disabled
  },
  inputError: {
    color: theme.palette.warning.dark,
    background: alpha(theme.palette.warning.dark, 0.1)
  }
}));

const PhoneInput = React.forwardRef(
  (
    {
      field,
      name,
      id,
      form,
      inputClassName,
      containerClassName,
      preferredCountries,
      onChange,
      disabled,
      value,
      formatOnInit,
      autoPlaceholder,
      inputProps,
      onFocus,
      onBlur,
      onKeyPress,
      disabledVisually,
      error,
      errorMessage,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    const theme = useTheme();
    const localRef = useRef();
    const lastValueRef = useRef(field.value);
    const [hasFocus, setHasFocus] = useState(false);

    const handleKeyPress = e => {
      if (!!onKeyPress) {
        onKeyPress(e);
      }
    };

    const handleFocus = (a, b, c, d, f, event) => {
      setHasFocus(true);

      if (!!onFocus) {
        onFocus(event);
      }
    };

    const handleBlur = () => {
      setHasFocus(false);
      if (!!onBlur) {
        onBlur();
      }
    };

    return (
      <>
        <IntlTelInput
          ref={ref || localRef}
          {...field}
          fieldName={name}
          fieldId={id}
          style={
            disabledVisually
              ? { borderColor: theme.palette.text.disabled }
              : error
              ? { borderColor: theme.palette.warning.dark }
              : !error && hasFocus
              ? { borderColor: theme.palette.primary.main }
              : {}
          }
          containerClassName={clsx(
            'intl-tel-input',
            classes.root,
            containerClassName
          )}
          inputClassName={clsx(
            classes.input,
            disabledVisually && classes.inputDisabled,
            !hasFocus && error && classes.inputError,
            inputClassName
          )}
          onPhoneNumberChange={(status, val) => {
            const newValue = parsePhoneFromString(val);

            if (
              val.startsWith('00') &&
              newValue.isValid &&
              newValue.country.code
            ) {
              (ref || localRef).current.setFlag(
                newValue.country.code.toLowerCase()
              );
            }
            lastValueRef.current = newValue;
            form && form.setFieldValue
              ? form.setFieldValue(name, newValue)
              : onChange(newValue);
          }}
          onSelectFlag={(oldValue, country) => {
            const newValue = parsePhoneWithNewCountry(
              lastValueRef.current.value,
              country.iso2.toUpperCase(),
              '+' + country.dialCode
            );

            lastValueRef.current = newValue;
            form && form.setFieldValue
              ? form.setFieldValue(name, newValue)
              : onChange(newValue);
          }}
          disabled={disabled}
          value={
            (field && field.value && field.value.value) ||
            (value && value.value) ||
            ''
          }
          formatOnInit={formatOnInit}
          preferredCountries={preferredCountries}
          autoPlaceholder={autoPlaceholder}
          onPhoneNumberFocus={handleFocus}
          onPhoneNumberBlur={handleBlur}
          telInputProps={{
            onKeyPress: handleKeyPress
          }}
          {...props}
        />
        <FieldErrorMessage
          visible={!hasFocus}
          error={error}
          errorMessageKey={errorMessage}
        />
      </>
    );
  }
);

const FormPhoneInput = React.forwardRef(({ name, onChange, ...props }, ref) =>
  name ? (
    <FormField
      component={PhoneInput}
      name={name}
      onChange={onChange}
      {...{ ...props, ref }}
    />
  ) : (
    <PhoneInput {...props} {...{ ref, onChange }} />
  )
);

PhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  containerClassName: PropTypes.string
};

export default FormPhoneInput;
