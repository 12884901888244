import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { CustomIcon, Text } from '@troy/shared/src/components/common';

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(2)
  },
  details: {
    display: 'flex'
  },
  icon: {
    marginRight: theme.spacing(2)
  }
}));

const ReturnGoodsContactDetails = ({
  title,
  customerName,
  street,
  postCode,
  city,
  addressCountry,
  phone
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.section}>
        <Text translated component="h3" variant="h4" color="textPrimary">
          {title}
        </Text>
      </Box>
      <Box className={classes.section}>
        <Box className={classes.details}>
          <Box className={classes.icon}>
            <CustomIcon icon="profile" variant="primary" size="small" />
          </Box>
          <Box>
            <Text component="p" variant="body1" color="textSecondary">
              {customerName}
            </Text>
            <Text component="p" variant="body1" color="textSecondary">
              {street}
            </Text>
            <Text component="p" variant="body1" color="textSecondary">
              {postCode} {city}
            </Text>
            <Text component="p" variant="body1" color="textSecondary">
              {addressCountry}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box className={classes.section}>
        <Box className={classes.details}>
          <Box className={classes.icon}>
            <CustomIcon icon="phone" variant="primary" size="small" />
          </Box>
          <Box>
            <Text component="p" variant="body1" color="textSecondary">
              {phone}
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReturnGoodsContactDetails;
