import React from 'react';

import { connectTo } from '../../utils';
import {
  resetPaymentMethod,
  checkMolliePaymentStatus,
  errorRedirect
} from '../../store/actions';

import { SEPA_DIRECT_DEBIT_VALUE } from '../../constants/payment';
import { PAYMENT_FAIL, PAYMENT_CANCEL } from '../../constants/errorStatuses';

import { Translation } from '../../components/common';

import { getUrlParams } from '../../utils/httpUtil';

import styles from './index.module.scss';

class PaymentRedirect extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      location: { search },
      errorRedirect,
      molliePaymentId
    } = this.props;

    if (!molliePaymentId) {
      const parsed = getUrlParams(search);
      switch (parsed.status) {
        case 'failed':
          errorRedirect(PAYMENT_FAIL);
          break;
        case 'cancel':
          errorRedirect(PAYMENT_CANCEL);
          break;
        case 'success':
        default:
          break;
      }
    }
  }

  componentDidMount() {
    const { checkMolliePaymentStatus, molliePaymentId } = this.props;
    if (molliePaymentId) {
      checkMolliePaymentStatus(molliePaymentId);
    }
  }

  componentWillUnmount() {
    const { resetPaymentMethod } = this.props;
    resetPaymentMethod();
  }

  render() {
    const { method, molliePaymentId } = this.props;

    return !molliePaymentId ? (
      <div className={styles['payment-success__wrapper']}>
        <Translation
          id="payment-success-content"
          className={styles['payment-success__content']}
        >
          {method === SEPA_DIRECT_DEBIT_VALUE
            ? 'PAYMENT_PENDING_SEPA_DIRECT_DEBIT'
            : 'PAYMENT_PENDING'}
        </Translation>
      </div>
    ) : null;
  }
}

export default connectTo(
  ({ payment: { method, molliePaymentId } }) => ({ method, molliePaymentId }),
  {
    resetPaymentMethod,
    checkMolliePaymentStatus,
    errorRedirect
  },
  PaymentRedirect
);
