import React from 'react';
import { useSelector } from 'react-redux';

import { PAYMENTS } from '../../../constants/routes';
import { PAY_NOW_TEXT_KEY } from '../../../constants/payment';
import { customPaymentUrlSelector } from '../../../store/selectors';
import { Link, ThemeButton } from '../index';

const PayNowButton = ({ className, id }) => {
  const customPaymentUrl = useSelector(customPaymentUrlSelector);
  let to = customPaymentUrl || PAYMENTS;

  return (
    <ThemeButton
      className={className}
      variant="contained"
      component={React.forwardRef((props, ref) => (
        <Link
          {...props}
          raw={!!customPaymentUrl}
          to={to}
          ref={ref}
          id={id}
          target={!!customPaymentUrl ? '_blank' : undefined}
        />
      ))}
      textKey={PAY_NOW_TEXT_KEY}
    />
  );
};

export default PayNowButton;
