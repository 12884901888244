export default {
  rightLane: [
    {
      apiDirection: 'TO_CUSTOMER',
      apiChannel: 'EMAIL',
      colorScheme: 'Secondary',
      singleLaneSide: 'left',
      doubleLaneColumn: 'external',
      doubleLaneSide: 'right',
      datetime: '2023-04-27T12:25:11.312132Z',
      headerIcon: 'mailIcon',
      headerText: 'E-Mail: Oh, you have some CSS Skills?',
      headerTag: null,
      files: [
        {
          url: 'https://www.google.com/',
          title: 'I-am-not-a-file.jpg',
          icon: 'imageIcon'
        }
      ],
      rawChannel: 'email',
      directionFilterCategory: 'FILTER_INBOX',
      channelFilterCategory: 'EMAIL',
      activeState: 'ACTIVE',
      eventId: null,
      content:
        'Hey there! It looks like you have at least the CSS-Skills to disable our CSS Blurring in this partial Login-Version of the troy_customer center',
      contentType: 'text',
      mlValue: null,
      mlConfidence: null
    },
    {
      apiDirection: 'FROM_CUSTOMER',
      apiChannel: 'CUSTOMERCENTER',
      colorScheme: 'Primary',
      singleLaneSide: 'right',
      doubleLaneColumn: 'internal',
      doubleLaneSide: 'right',
      datetime: '2023-04-26T09:15:00.484743Z',
      headerIcon: 'uploadIcon',
      headerText: 'E-Mail: Can we help you in any other way maybe?',
      headerTag: null,
      files: [],
      rawChannel: 'customercenter',
      directionFilterCategory: 'FILTER_SENT',
      channelFilterCategory: 'CUSTOMER_CENTER',
      activeState: null,
      eventId: null,
      content:
        'Before we move on, let’s first find out if we can help you in any way to resolve your open invoices. Feel free to use our 2FA Methods to log in securely and check out our self-service options.',
      contentType: 'text',
      mlValue: null,
      mlConfidence: null
    },
    {
      apiDirection: 'TO_CUSTOMER',
      apiChannel: 'PHONE',
      colorScheme: 'Secondary',
      singleLaneSide: 'left',
      doubleLaneColumn: 'external',
      doubleLaneSide: 'right',
      datetime: '2023-04-19T06:54:11.312132Z',
      headerIcon: 'telephoneOut',
      headerText: 'A fictive phone call, Outbound from troy',
      headerTag: null,
      files: [],
      rawChannel: 'email',
      directionFilterCategory: 'FILTER_INBOX',
      channelFilterCategory: 'EMAIL',
      activeState: 'ACTIVE',
      eventId: null,
      content: '',
      contentType: 'text',
      mlValue: null,
      mlConfidence: null
    },
    {
      apiDirection: 'TO_CUSTOMER',
      apiChannel: 'PHONE',
      colorScheme: 'Secondary',
      singleLaneSide: 'left',
      doubleLaneColumn: 'external',
      doubleLaneSide: 'right',
      datetime: '2023-04-12T22:05:11.312132Z',
      headerIcon: 'telephoneOut',
      headerText: 'Telephone Outbound: Can you do more in programming?',
      headerTag: null,
      files: [],
      rawChannel: 'email',
      directionFilterCategory: 'FILTER_INBOX',
      channelFilterCategory: 'EMAIL',
      activeState: 'ACTIVE',
      eventId: null,
      content:
        'So, let’s assume you have successfully resolved your open invoices, then would you mind telling us if you can do more in programming and software engineering?\n\nIf yes - feel free to reach out to us and tell us, how you found this text. Best wishes - Your troy product team',
      contentType: 'text',
      mlValue: null,
      mlConfidence: null
    },
    {
      apiDirection: 'TO_CUSTOMER_FROM_LAWYER',
      apiChannel: 'EMAIL',
      colorScheme: 'Warning',
      singleLaneSide: 'left',
      doubleLaneColumn: 'external',
      doubleLaneSide: 'right',
      datetime: '2023-04-10T22:05:11.312132Z',
      headerIcon: 'mailIcon',
      headerText: 'E-Mail: Just a demo Lawyer-Mail',
      headerTag: 'TypeOfOtherSide.LAWYER',
      files: [
        {
          url: 'https://www.google.com/',
          title: 'Email',
          icon: 'mailIcon'
        }
      ],
      rawChannel: 'email',
      directionFilterCategory: 'FILTER_INBOX',
      channelFilterCategory: 'EMAIL',
      activeState: 'ACTIVE',
      eventId: null,
      content:
        'This is an important notice from a lawyer. He is a Director of Lorem-Ipsum.',
      contentType: 'text',
      mlValue: null,
      mlConfidence: null
    }
  ],
  leftLane: []
};
