import { createSelector } from 'reselect';

const stateSelector = state => state.liability;

export const isLiabilityPaidSelector = createSelector(
  stateSelector,
  ({ liabilityStatus }) =>
    liabilityStatus && liabilityStatus.traffic_light === 'GREEN'
);

export const liabilityFormalSalutationSelector = createSelector(
  stateSelector,
  ({ category }) => (category ? category.formal_salutation : null)
);

export const communicationToneSelector = createSelector(
  liabilityFormalSalutationSelector,
  liabilityFormal =>
    liabilityFormal === 'true'
      ? 'formal'
      : liabilityFormal === 'false'
      ? 'informal'
      : 'default'
);

export const liabilityReturnGoodsPickupSelector = createSelector(
  stateSelector,
  ({ pickup }) => pickup
);

export const liabilityReturnGoodsPickupDateSelector = createSelector(
  liabilityReturnGoodsPickupSelector,
  pickup => (pickup ? pickup.pickup_date : null)
);

export const liabilityReturnGoodsPickupTimeRangeSelector = createSelector(
  liabilityReturnGoodsPickupSelector,
  pickup => (pickup ? pickup.pickup_timerange : null)
);

export const liabilityReturnGoodsPickupAddressSelector = createSelector(
  liabilityReturnGoodsPickupSelector,
  pickup => (pickup ? pickup.pickup_address : null)
);

export const liabilityReturnGoodsPickupAddressDataSelector = createSelector(
  liabilityReturnGoodsPickupAddressSelector,
  address => {
    if (!address) {
      return {};
    }
    const zipCity = address.city;
    return {
      customerName: address.name,
      street: address.street_and_house_nr,
      postCode: zipCity.substr(0, zipCity.indexOf(' ')),
      city: zipCity.substr(zipCity.indexOf(' ') + 1),
      addressCountry: address.country
    };
  }
);

export const liabilityReturnGoodsPickupContactSelector = createSelector(
  liabilityReturnGoodsPickupSelector,
  pickup =>
    pickup && pickup.pickup_contact ? pickup.pickup_contact.identifier : null
);

export const liabilityReturnGoodsPickupStateSelector = createSelector(
  liabilityReturnGoodsPickupSelector,
  pickup => (pickup ? pickup.pickup_state : null)
);

export const liabilityClientCustomerRefNoSelector = createSelector(
  stateSelector,
  state => state.clientCustomerRefNo
);

export const caseIdSelector = createSelector(stateSelector, liability =>
  liability ? liability.refNo : ''
);
