import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import IBAN from 'iban';

import { callHttp } from '../../utils/api';
import { post } from '../../utils/httpUtil';
import { SEND_CALCULATOR_FORM } from '../../constants/api';

import { CALCULATOR_AWAIT } from '../../constants/routes';
import { handleError } from '../actions';

export function* sendCalculatorInfo({ payload }) {
  const {
    rate,
    iban,
    day,
    currency,
    accountHolder,
    email,
    mobilePhone,
    firstInstallmentDate
  } = payload;

  const installmentPlans = {
    request: {
      amount: {
        amount: rate,
        currency: currency
      },
      payOnDayOfMonth: day,
      firstInstallmentDate,
      email: email && email.trim(),
      mobilePhone: mobilePhone && mobilePhone.trim(),
      mandate: {
        iban: IBAN.electronicFormat(iban),
        accountHolder: accountHolder && accountHolder.trim()
      }
    }
  };

  try {
    const { installmentId } = yield callHttp(
      post,
      SEND_CALCULATOR_FORM,
      installmentPlans
    );
    if (installmentId) {
      yield put(push(CALCULATOR_AWAIT));
    }
  } catch (err) {
    yield put(handleError(err));
  }
}
