import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { login } from '../../store/actions';
import { LoginForm, LoginLayout } from '../../components/login/index.troy';

const LoginPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onLogin = values => {
    dispatch(login(values));
  };

  return (
    <LoginLayout>
      <LoginForm onSubmit={onLogin} />
    </LoginLayout>
  );
};

export default LoginPage;
