import queryString from 'query-string';

import store from '../store';
import { CORRELATION_ID } from '../constants/api';
import { getOneTimeToken } from './token';
import { ABORT_ERROR } from '../constants/errorStatuses';

export class RequestError {
  constructor(status, message) {
    this.status = status;
    this.message = message;
  }
}

export const getPureUrl = url => queryString.parseUrl(url).url;

export const getSearchString = url => queryString.extract(url);

export const getUrlParams = (url = window.location.search) =>
  queryString.parse(url);

export const getPortalUrlData = () => {
  const {
    oneTimeToken,
    jwt: jwtFromUrl,
    businessPartnerID,
    ...otherUrlParams
  } = getUrlParams();

  const hasJwtInUrl = !!jwtFromUrl && jwtFromUrl.length > 0;
  const hasOneTimeTokenInUrl = !!oneTimeToken && oneTimeToken.length > 0;

  return {
    oneTimeToken,
    jwtFromUrl,
    businessPartnerID,
    hasJwtInUrl,
    hasOneTimeTokenInUrl,
    otherUrlParams
  };
};

export const getSearchQuery = keyValueObj => queryString.stringify(keyValueObj);

export const getSearchUrl = ({ url, query }) =>
  queryString.stringifyUrl({ url, query });

export const onAttachmentClick = (_, url) => () => {
  let newWindow;
  if (!window.Cypress) {
    newWindow = window.open('', '_blank');
  }

  getOneTimeToken().then(token => {
    if (token) {
      const pureUrl = getPureUrl(url);
      const query = getUrlParams(getSearchString(url));
      const link = getSearchUrl({
        url: pureUrl,
        query: {
          ...query,
          oneTimeToken: token
        }
      });

      if (!window.Cypress) {
        newWindow.location = link;
      } else {
        window.open(link, '_blank');
      }
    }
  });
};

export const headers = ({
  isFormData = false,
  useToken,
  skipCorrelationID,
  skipCustomHeaders
} = {}) => {
  let headerObj = {};

  if (!skipCorrelationID) {
    headerObj['Troy-Correlation-Id'] = CORRELATION_ID;
  }

  if (!isFormData) {
    headerObj['Content-Type'] = 'application/json';
  }

  const state = store.getState();

  const token = state.auth.token;

  if (useToken && (token || typeof useToken === 'string')) {
    headerObj.Authorization = `Bearer ${token || useToken}`;
  }

  if (!skipCustomHeaders) {
    const customHeaders = state.headers;
    if (customHeaders) {
      headerObj = { ...customHeaders, ...headerObj };
    }
  }

  return headerObj;
};

export const makePostOptions = (data, options) => ({
  method: 'POST',
  mode: 'cors',
  headers: headers(options),
  body: JSON.stringify(data)
});

export const makePatchOptions = (data, options) => ({
  ...makePostOptions(data, options),
  method: 'PATCH'
});

export const makePostFormDataOptions = (data, options) => ({
  method: 'POST',
  headers: headers({ ...options, isFormData: true }),
  body: data
});

export const getOptions = options => ({
  method: 'GET',
  mode: 'cors',
  headers: headers(options)
});

export const deleteOptions = () => ({
  method: 'DELETE',
  mode: 'cors',
  headers: headers()
});

export const deleteManyOptions = () => ({
  method: 'DELETE',
  mode: 'cors',
  headers: headers()
});

export const deleteManyUrl = (url, data) =>
  url + '?' + data.map((v, i) => 'users[]=' + v).join('&');

const request = (url, options, includeHeaders) =>
  fetch(url, options)
    .then(response => {
      const { status } = response;

      if (status === 204) return {};

      const contentType = response.headers.get('content-type');

      let responseBody;
      if (
        contentType &&
        (contentType.includes('application/json') ||
          contentType.includes('application/problem+json'))
      ) {
        responseBody = response.json().then(body => {
          if (body.errors && body.status && (body.title || body.detail)) {
            throw new RequestError(
              body.status,
              body.errors || body.detail || body.title
            );
          }
          return body;
        });
      } else if (contentType && contentType.includes('application/pdf')) {
        responseBody = response.blob();
      } else {
        responseBody = response.blob();
      }

      // success case
      if (status >= 200 && status < 300) {
        if (includeHeaders) {
          return responseBody.then(body => ({
            body,
            headers: response.headers
          }));
        }
        return responseBody;
      }

      // status was not ok return error
      return responseBody.then(body => {
        throw new RequestError(status, body.detail || body.message || body);
      });
    })
    .catch(err => {
      if (err.name === 'AbortError') {
        throw new RequestError(ABORT_ERROR, ABORT_ERROR);
      }
      if (!err.status) {
        err.status = 500;
      }
      throw new RequestError(err.status, err.message);
    });

export const get = (
  url,
  useToken,
  skipCorrelationID,
  skipCustomHeaders,
  includeResponseHeaders
) =>
  request(
    url,
    getOptions({ useToken, skipCorrelationID, skipCustomHeaders }),
    includeResponseHeaders
  );
export const post = (url, data, useToken) =>
  request(url, makePostOptions(data, { useToken }));
export const postFormData = (url, data, useToken) =>
  request(url, makePostFormDataOptions(data, { useToken }));
export const del = (url, id) => request(url + id, deleteOptions());
export const delBody = (url, data) =>
  request(deleteManyUrl(url, data), deleteManyOptions());
export const patch = (url, data) => request(url, makePatchOptions(data));
