import React from 'react';

interface CustomFooterRowProps {
  row?: string;
  linkClass?: string;
}

const CustomFooterRow: React.FC<CustomFooterRowProps> = ({
  row,
  linkClass
}) => {
  if (row.startsWith('http')) {
    return (
      <a className={linkClass} href={row} target="_blank" rel="noreferrer">
        {row}
      </a>
    );
  } else if (row.match(/\+\d+/)) {
    return (
      <a className={linkClass} href={`tel:${row}`}>
        {row}
      </a>
    );
  }
  return <span dangerouslySetInnerHTML={{ __html: row }} />;
};

export default CustomFooterRow;
