import React from 'react';
import Hyphenated from 'react-hyphen';

import de from 'hyphenated-de';
import en from 'hyphenated-en-us';

const languages = { de, en };

export default ({ children }) => (
  <Hyphenated language={de}>{children}</Hyphenated>
);
