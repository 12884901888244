import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { onClickWrapper } from '../../../utils/ui';
import { FormField } from '@troy/shared/src/components/common';

const CustomDatePicker = ({ input, id, ...props }) => (
  <DatePicker
    {...props}
    onChange={onClickWrapper(id, input ? input.onChange : props.onChange)}
    selected={input ? input.value : props.value}
  />
);

export default ({ name, validate, onChange, ...props }) =>
  name ? (
    <FormField
      {...{ name, validate, onChange, props }}
      component={CustomDatePicker}
    />
  ) : (
    <CustomDatePicker {...{ name, onChange }} {...props} />
  );
