import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import BlurredComponent from '../BlurredComponent';
import Card from '../Card';
import Translation from '../Translation';
import Link from '../Link';

import { setRedirectOnPreviousPage } from '../../../store/actions';

import { LOGIN } from '../../../constants/routes';
import { connectTo } from '../../../utils';

import styles from './index.module.scss';

class BlurWithLogin extends React.Component {
  onClick = () => {
    const { setRedirectOnPreviousPage } = this.props;
    setRedirectOnPreviousPage(true);
  };

  render() {
    const {
      blur,
      children,
      mockData,
      mockImg,
      descriptionKey,
      className,
      buttonClassName,
      descriptionClassName,
      id
    } = this.props;

    return blur ? (
      <BlurredComponent
        contentClassName={className}
        {...{ mockData, mockImg, blur }}
      >
        <Card className={styles['blur-login__wrapper']}>
          <Translation
            className={clsx(
              styles['blur-login__description'],
              descriptionClassName
            )}
          >
            {descriptionKey}
          </Translation>
          <Link
            to={LOGIN}
            id={id + '-link'}
            className={styles['blur-login__link']}
          >
            <div
              id={id + '-button'}
              className={buttonClassName}
              onClick={this.onClick}
            >
              <Translation>LOGIN_NOW</Translation>
            </div>
          </Link>
        </Card>
      </BlurredComponent>
    ) : (
      children
    );
  }
}

BlurWithLogin.propTypes = {
  blur: PropTypes.bool,
  children: PropTypes.node,
  mockData: PropTypes.node,
  description: PropTypes.string,
  buttonClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
  buttonId: PropTypes.string
};

export default connectTo(
  state => ({}),
  { setRedirectOnPreviousPage },
  BlurWithLogin
);
