import { makeStyles } from '@mui/styles';
import { LanguageSelect } from '@troy/shared/src/components/common';

const useStyles = makeStyles(theme => ({
  selectButton: {
    margin: '4px 0',
    height: 'unset',
    '&:hover, &:focus': {
      background: theme.palette.action.disabled
    }
  },
  selectButtonIcon: {
    '& *[fill="red"]': {
      fill: theme.palette.text.primary
    },
    '& *[stroke="red"]': {
      stroke: theme.palette.text.primary
    }
  },
  menuItem: {
    background: theme.palette.background.default,
    '&:hover, &:focus': {
      background: theme.palette.action.hover,
      color: theme.palette.primary.contrastText,
      '& path[fill="red"]': {
        fill: theme.palette.primary.contrastText
      }
    },
    '& path[fill="red"]': {
      fill: theme.palette.text.primary
    }
  }
}));

const CustomLanguageSelect = ({ isCompact, onChange, languages, value }) => {
  const classes = useStyles();

  return (
    <LanguageSelect
      isCompact={isCompact}
      onChange={onChange}
      languages={languages}
      value={value}
      selectButtonClassName={classes.selectButton}
      selectButtonIconClassName={classes.selectButtonIcon}
      menuItemClassName={classes.menuItem}
    />
  );
};

export default CustomLanguageSelect;
