import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import { useAppWidthClass } from '../../../utils/hooks';

import { CustomIcon, Module } from '@troy/shared/src/components/common';

import { ThemeButton, Translation } from '../../common';

export const HEADER = 'UPLOAD_HEADER';
export const DESCRIPTION = 'UPLOAD_DESCRIPTION';
export const BUTTON = 'UPLOAD_BUTTON';

export const EXTENSIONS = 'UPLOAD_EXTENSIONS';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: 40
  },
  rootSmall: {
    flexDirection: 'column',
    justifyContent: 'unset'
  },
  rootCompact: {
    padding: '20px 16px 24px 16px'
  },
  instructions: {
    flexBasis: '54%',
    display: 'flex',
    flexDirection: 'column'
  },
  instructionsSmall: {
    flexBasis: 'unset'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconSmall: {
    width: '56px',
    height: 'auto'
  },
  heading: {
    marginLeft: 24,
    color: theme.palette.secondary.main
  },
  headingSmall: {
    marginLeft: 16
  },
  description: {
    marginTop: 24,
    color: theme.palette.text.primary
  },
  descriptionSmall: {
    marginTop: 16
  },
  uploadSection: {
    marginLeft: 64,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    justifyContent: 'center'
  },
  uploadSectionSmall: {
    marginLeft: 0,
    marginTop: 24
  },
  button: {
    alignSelf: 'center'
  },
  supportedTypes: {
    marginTop: 8,
    color: theme.palette.text.hint,
    textAlign: 'center'
  }
}));

const UploadFile = ({ className, onButtonClick }) => {
  const classes = useStyles();
  const { isTabletWidth, isMobileWidth } = useAppWidthClass();
  const isTabletDown = isTabletWidth || isMobileWidth;

  return (
    <Module
      className={clsx(
        classes.root,
        isTabletDown && classes.rootSmall,
        isMobileWidth && classes.rootCompact,
        className
      )}
    >
      <Box
        className={clsx(
          classes.instructions,
          isTabletDown && classes.instructionsSmall
        )}
      >
        <Box className={classes.title}>
          <CustomIcon
            ariaHidden
            className={isTabletDown && classes.iconSmall}
            icon="uploadLogo"
            size="custom"
            variant="secondary"
          />
          <Typography
            className={clsx(
              classes.heading,
              isTabletDown && classes.headingSmall
            )}
            component="h1"
            variant={isTabletDown ? 'h4' : 'h2'}
          >
            <Translation inline>{HEADER}</Translation>
          </Typography>
        </Box>
        <Typography
          className={clsx(
            classes.description,
            isTabletDown && classes.descriptionSmall
          )}
          component="p"
          variant="body2"
        >
          <Translation isFragment>{DESCRIPTION}</Translation>
        </Typography>
      </Box>
      <Box
        className={clsx(
          classes.uploadSection,
          isTabletDown && classes.uploadSectionSmall
        )}
      >
        <ThemeButton
          className={classes.button}
          id="page.postbox.component.upload-file.upload-file-button"
          variant="contained"
          textKey={BUTTON}
          onClick={onButtonClick}
        />
        <Typography
          className={classes.supportedTypes}
          component="span"
          variant="subtitle1"
        >
          <Translation inline>{EXTENSIONS}</Translation>
        </Typography>
      </Box>
    </Module>
  );
};

export default UploadFile;
