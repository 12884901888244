import { createReducer } from 'redux-act';

import * as routes from '../../constants/routes';
import * as actions from '../actions/ui';
import * as api from '../../constants/api';

import {
  CREDIT_CARD_MOLLIE_VALUE,
  CREDIT_CARD_VALUE,
  IDEAL_INSTALLMENT_VALUE,
  IDEAL_MOLLIE_VALUE,
  INSTALLMENT_VALUE,
  OFFLINE_SEPA_VALUE,
  PAY_LATER_VALUE,
  PAYPAL_MOLLIE_VALUE,
  PAYPAL_VALUE,
  SEPA_DIRECT_DEBIT_VALUE,
  SOFORT_MOLLIE_VALUE,
  SOFORT_VALUE,
  BAN_CONTACT_MOLLIE_VALUE,
  BAN_CONTACT_VALUE
} from '../../constants/payment';

import { filterMapToArray } from '../../utils';

import ideal from '../../assets/img/payment/ideal.png';
import ideal_installment from '../../assets/img/payment/ideal_installment.png';
import pay_later from '../../assets/img/payment/pay_later.png';
import visa_maestro from '../../assets/img/payment/visa-maestro.png';
import paypal from '../../assets/img/payment/paypal.png';
import sofortLogo from '../../assets/img/payment/sofort.png';
import sepaLogo from '../../assets/img/payment/sepa.png';
import ban_contact_logo from '../../assets/img/payment/bancontact.png';

export const NAVBAR_HOME_KEY = 'NAVBAR_HOME';
const NAVBAR_POSTBOX_KEY = 'NAVBAR_POSTBOX';

const DISCOURSE_DUTY_BUTTOM_TITLE = 'DISCOURSE_DUTY_BUTTOM_TITLE';
const DISCOURSE_DUTY_BUTTOM = 'DISCOURSE_DUTY_BUTTOM';
const LIABILITY_STATEMENT_TITLE = 'CLAIM_STATEMENTS';
const LIABILITY_STATEMENT_DETAIL = 'LIABILITY_STATEMENT_DETAIL';

export const headersMap = {
  Home: {
    label: NAVBAR_HOME_KEY,
    icon: 'navHome',
    id: 'component.home-header.home-tab',
    value: 1,
    route: routes.HOME
  },
  PostBox: {
    label: NAVBAR_POSTBOX_KEY,
    icon: 'navPostBox',
    id: 'component.home-header.post-box-tab',
    value: 2,
    route: routes.POST_BOX
  }
};

export const paymentMethodsMap = () => ({
  IDEALMollie: {
    label: 'PAYMENT_METHOD_IDEAL_MOLLIE',
    image: ideal,
    value: IDEAL_MOLLIE_VALUE,
    displayName: 'PAYMENT_METHOD_IDEAL_MOLLIE',
    id: 'ideal-mollie'
  },
  IDEALInstallment: {
    label: 'PAYMENT_METHOD_IDEAL_INSTALLMENT',
    image: ideal_installment,
    value: IDEAL_INSTALLMENT_VALUE,
    displayName: 'PAYMENT_METHOD_IDEAL_INSTALLMENT',
    id: 'ideal-installment'
  },
  PayLater: {
    label: 'PAYMENT_METHOD_PAY_LATER',
    image: pay_later,
    value: PAY_LATER_VALUE,
    displayName: 'PAYMENT_METHOD_PAY_LATER',
    id: 'pay-later'
  },
  VisaMaestro: {
    label: 'PAYMENT_METHOD_CREDITCARD',
    image: visa_maestro,
    value: CREDIT_CARD_VALUE,
    displayName: 'PAYMENT_METHOD_CREDITCARD',
    id: 'visa-mastercard'
  },
  VisaMaestroMollie: {
    label: 'PAYMENT_METHOD_CREDITCARD',
    image: visa_maestro,
    value: CREDIT_CARD_MOLLIE_VALUE,
    displayName: 'PAYMENT_METHOD_CREDITCARD',
    id: 'visa-mastercard-mollie'
  },
  Paypal: {
    label: 'PAYMENT_METHOD_PAYPAL',
    image: paypal,
    value: PAYPAL_VALUE,
    displayName: 'PAYMENT_METHOD_PAYPAL',
    id: 'paypal'
  },
  PaypalMollie: {
    label: 'PAYMENT_METHOD_PAYPAL',
    image: paypal,
    value: PAYPAL_MOLLIE_VALUE,
    displayName: 'PAYMENT_METHOD_PAYPAL',
    id: 'paypal-mollie'
  },
  OfflineSepa: {
    label: 'PAYMENT_METHOD_SEPA',
    image: sepaLogo,
    value: OFFLINE_SEPA_VALUE,
    displayName: 'PAYMENT_METHOD_SEPA',
    id: 'offline-sepa'
  },
  Sofort: {
    label: 'PAYMENT_METHOD_SOFORT',
    image: sofortLogo,
    value: SOFORT_VALUE,
    displayName: 'PAYMENT_METHOD_SOFORT',
    id: 'sofort'
  },
  SofortMollie: {
    label: 'PAYMENT_METHOD_SOFORT',
    image: sofortLogo,
    value: SOFORT_MOLLIE_VALUE,
    displayName: 'PAYMENT_METHOD_SOFORT',
    id: 'sofort-mollie'
  },
  SepaDirectDebit: {
    label: 'PAYMENT_METHOD_SEPA_DEBIT',
    image: sepaLogo,
    value: SEPA_DIRECT_DEBIT_VALUE,
    displayName: 'PAYMENT_METHOD_SEPA_DEBIT',
    id: 'sepa-direct-debit'
  },
  Installment: {
    label: 'PAYMENT_METHOD_SEPA_REQUEST_PAYMENT',
    image: sepaLogo,
    value: INSTALLMENT_VALUE,
    displayName: 'PAYMENT_METHOD_SEPA_REQUEST_PAYMENT',
    id: 'installment'
  },
  BanContact: {
    label: 'PAYMENT_METHOD_BAN_CONTACT',
    image: ban_contact_logo,
    value: BAN_CONTACT_VALUE,
    displayName: 'PAYMENT_METHOD_BAN_CONTACT',
    id: 'ban-contact'
  },
  BanContactMollie: {
    label: 'PAYMENT_METHOD_BAN_CONTACT',
    image: ban_contact_logo,
    value: BAN_CONTACT_MOLLIE_VALUE,
    displayName: 'PAYMENT_METHOD_BAN_CONTACT',
    id: 'ban-contact-mollie'
  }
});

export const headerList = Object.keys(headersMap).map(key => headersMap[key]);

const liabilityStatementMap = {
  liabilityStatementExtended: {
    id: 'liabilityStatement.extended',
    title: LIABILITY_STATEMENT_TITLE,
    label: LIABILITY_STATEMENT_DETAIL,
    link: api.LIABILITY_STATEMENT_PDF
  },
  discourseDuties: {
    id: 'liabilityStatement.discourseDuties',
    title: DISCOURSE_DUTY_BUTTOM_TITLE,
    label: DISCOURSE_DUTY_BUTTOM,
    link: api.DISCOURSE_DUTY_PDF,
    mobileOnly: true
  }
};

const DEFAULT_STATE = {
  returnGoods: null,
  isFormalCommunication: false,
  showTranslationTooltips: false,
  redirectOnPreviousPage: false, // page to open after redirect on login from BlurWithLogin component,
  showCelebrationAnimation: false,
  chat: {
    show: false
  },
  chatBot: {
    show: false
  },
  rocketChatShow: false,
  payment: {
    methods: [],
    hide: true,
    offlineTransferHide: true,
    installmentHide: true,
    accountAccessInterest: false
  },
  home: {
    headerList: null,
    liabilityStatementList: null,
    blur: {
      demandTable: false,
      timeline: false
    },
    hide: {
      greetings: true,
      contactBox: true,
      payNow: true,
      timeline: true,
      demandTable: true,
      demandExplanation: true
    }
  },
  postBox: {
    showInternalNotesForCustomer: false,
    hide: {
      fileUpload: true
    },
    blur: {
      fileUpload: false
    }
  },
  feedback: {
    topImage: false
  },
  thanks: {
    googleLink: {
      decisionValue: false,
      show: false
    }
  }
};

export default createReducer(
  {
    [actions.setUI]: (state, { decisions }) => {
      const showPaymentsMap = {
        IDEALMollie: decisions['troy.payment.ideal'],
        IDEALInstallment: decisions['troy.payment.installment'],
        PayLater: decisions['troy.payment.pay_later'],
        VisaMaestro: decisions['troy.feature.payment.creditcard_3ds'],
        VisaMaestroMollie: decisions['troy.feature.payment.creditcard_mollie'],
        Paypal: decisions['troy.payment.paypal'],
        PaypalMollie: decisions['troy.payment.paypal_mollie'],
        SepaDirectDebit: decisions['troy.payment.directdebit'],
        Sofort: decisions['troy.payment.sofort'],
        SofortMollie: decisions['troy.payment.sofort_mollie'],
        OfflineSepa: decisions['troy.payment.offlinetransfer'],
        Installment: decisions['troy.payment.sepa_direct_debit_installment'],
        BanContact: decisions['troy.payment.bancontact'],
        BanContactMollie: decisions['troy.payment.bancontact_mollie']
      };

      const showHeadersMap = {
        Home: true,
        PostBox: decisions['troy.ui.DESKTOP.menu.postbox']
      };

      const showLiabilityStatementMap = {
        liabilityStatementExtended:
          !decisions['troy.ui.hidden.LIABILITY_STATEMENT_PDF'],
        discourseDuties: !decisions['troy.ui.hidden.DISCOURSE_DUTY_PDF']
      };

      const liabilityStatementMapWithBlur = {
        liabilityStatementExtended: {
          ...liabilityStatementMap.liabilityStatementExtended,
          blur: decisions['troy.ui.censored.LIABILITY_STATEMENT_PDF']
        },
        discourseDuties: {
          ...liabilityStatementMap.discourseDuties,
          blur: decisions['troy.ui.censored.DISCOURSE_DUTY_PDF']
        }
      };

      return {
        ...state,
        returnGoods: decisions['troy.ui.feature.bluemovement.return.qualified'],
        isFormalCommunication: decisions['troy.text.formal'],
        chat: {
          show: !!decisions['troy.feature.chat.simple']
        },
        chatBot: {
          show: !!decisions['troy.feature.textbot']
        },
        rocketChatShow: !!decisions['troy.ui.customer.explore.rocket-chat'],
        showTranslationTooltips:
          decisions['troy.ui.admin.dynamictext.tooltips'],
        payment: {
          ...state.payment,
          methods: filterMapToArray(showPaymentsMap, paymentMethodsMap()),
          hide:
            decisions['troy.ui.payment.hidelist'] ||
            decisions['troy.ui.payment.hidelist'] === undefined,
          offlineTransferHide: decisions['troy.payment.offlinetransfer'],
          accountAccessInterest:
            decisions['troy.ui.data.account-access-interest']
        },
        home: {
          ...state.home,
          blur: {
            greetings: false, // decisions['troy.ui.censored.GREETING'],
            contactBox: false, // decisions['troy.ui.censored.CONTACT_BOX'],
            payNow: false, // decisions['troy.ui.censored.HEADLINE_WITH_PAY_BUTTON'],
            demandTable: decisions['troy.ui.censored.LIABILITY_STATEMENT'],
            timeline: decisions['troy.ui.censored.BOX_TIMELINE'],
            demandExplanation: decisions['troy.ui.censored.DISCOURSE_DUTY_PDF']
          },
          hide: {
            ...state.home.hide,
            greetings: decisions['troy.ui.hidden.GREETING'],
            contactBox: decisions['troy.ui.hidden.CONTACT_BOX'],
            payNow: decisions['troy.ui.hidden.HEADLINE_WITH_PAY_BUTTON'],
            timeline: decisions['troy.ui.hidden.BOX_TIMELINE'],
            demandTable: decisions['troy.ui.hidden.LIABILITY_STATEMENT'],
            demandExplanation: decisions['troy.ui.hidden.DISCOURSE_DUTY_PDF']
          },
          headerList: filterMapToArray(showHeadersMap, headersMap),
          liabilityStatementList: filterMapToArray(
            showLiabilityStatementMap,
            liabilityStatementMapWithBlur
          )
        },
        postBox: {
          showInternalNotesForCustomer:
            decisions['troy.feature.servicelog.internal-notes'],
          hide: {
            fileUpload: decisions['troy.ui.hidden.CUSTOMER_FILE_UPLOAD']
          },
          blur: {
            fileUpload: decisions['troy.ui.censored.CUSTOMER_FILE_UPLOAD'],
            notificationsList:
              decisions['troy.ui.censored.POST_BOX_NOTIFICATIONS']
          }
        },
        feedback: {
          topImage: decisions['troy.ui.feedback.show_image'],
          typeformFeedback: decisions['troy.feature.switch-survey']
        },
        thanks: {
          googleLink: {
            ...state.thanks.googleLink,
            decisionValue:
              decisions['troy.feature.show_google_review_on_feedback']
          }
        }
      };
    },
    [actions.setRedirectOnPreviousPage]: (state, value) => ({
      ...state,
      redirectOnPreviousPage: value
    }),
    [actions.setShowGoogleLink]: (state, value) => ({
      ...state,
      thanks: {
        ...state.thanks,
        googleLink: {
          ...state.thanks.googleLink,
          show: state.thanks.googleLink.decisionValue && value
        }
      }
    }),
    [actions.setShowCelebrationAnimation]: (state, value) => ({
      ...state,
      showCelebrationAnimation: value
    }),
    [actions.uiReset]: () => {
      return { ...DEFAULT_STATE };
    }
  },
  DEFAULT_STATE
);
