import React from 'react';
import PropTypes from 'prop-types';

import { Blurred, Link } from '../../common';

import smartphone from '../../../assets/img/allgemein/08_smartphone-messenger.png';

const ContactBox = ({ link, blur, styles }) => (
  <Blurred blur={blur}>
    <div className={styles['contact-box__wrapper']}>
      <img
        className={styles['contact-box__pic']}
        src={smartphone}
        alt="smartphone-pic"
      />
      <br />
      <div className={styles['contact-box__text-wrapper']}>
        <div className={styles['contact-box__header']}>
          Nichts mehr verpassen
        </div>
        <div className={styles['contact-box__text']}>
          Bei uns bestimmst du selbst, wie wir dich am besten kontaktieren!
        </div>
        <div className={styles['contact-box__link']}>
          <Link raw id="page.home.component.contact-box.link" to={link}>
            Einstellungen >
          </Link>
        </div>
      </div>
    </div>
  </Blurred>
);

ContactBox.propTypes = {
  link: PropTypes.string,
  styles: PropTypes.object
};

export default ContactBox;
