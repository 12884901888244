import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { Skeleton } from '@mui/material';

import { useAppWidthClass } from '../../../utils/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 188,
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 6,
    boxSizing: 'border-box',
    boxShadow: theme.customShadows.module,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '&:nth-of-type(1n+2)': {
      marginTop: 24
    }
  },
  rootMobile: {
    minHeight: 139,
    paddingLeft: 32,
    alignItems: 'flex-start'
  },
  topMargin: {
    marginTop: 24
  },
  bottomMargin: {
    marginBottom: 24
  },
  smallTwoContainer: {
    display: 'flex',
    flexDirection: 'row',

    '&:nth-of-type(1n+2)': {
      marginTop: 24
    }
  },
  smallTwoContainerTablet: {
    flexDirection: 'column'
  },
  smallTwoRoot: {
    height: 139,
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 6,
    boxShadow: theme.customShadows.module,
    padding: '0 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    boxSizing: 'border-box',

    '&:first-of-type': {
      width: 606
    },
    '&:last-of-type': {
      marginLeft: 24,
      flexGrow: 1
    }
  },
  smallTwoLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& > *:last-of-type': {
      marginLeft: 32
    }
  },
  smallTwoRootTablet: {
    alignItems: 'center',

    '&:first-of-type': {
      width: '100%'
    },
    '&:last-of-type': {
      width: '100%',
      marginLeft: 0,
      marginTop: 24
    }
  },
  widgets: {
    padding: '0 58px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  widgetsMobile: {
    padding: '32px 0 32px 32px',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  center: {
    marginLeft: 120
  },
  centerTablet: {
    marginLeft: 60
  },
  centerMobile: {
    width: '100%',
    marginTop: 24
  },
  skeleton: {
    borderRadius: theme.shape.borderRadius * 2,

    '&:nth-of-type(1n+2)': {
      marginTop: 16
    }
  },
  wave: {
    '&:after': {
      background: `linear-gradient(90deg, transparent, ${theme.palette.text.disabled}, transparent)`
    }
  },
  pulse: {
    background: theme.palette.text.disabled
  }
}));

const SIMPLE_BAR_WIDTHS = {
  desktop: ['473px', '597px', '473px', '597px'],
  tablet: ['340px', '450px', '340px', '450px'],
  mobile: ['64%', '81%']
};
const MEDIA_BAR_WIDTHS = {
  desktop: ['272px', '343px', '272px', '343px'],
  tablet: ['210px', '280px', '210px', '280px'],
  mobile: ['64%', '81%', '64%', '81%']
};
const CIRCLE = {
  desktop: 108,
  tablet: 80,
  mobile: 40
};
const SMALL_BAR_WIDTHS = {
  desktop: ['185px', '234px'],
  tablet: ['185px', '234px'],
  mobile: ['185px', '234px']
};

export const SkeletonBar = ({ width, animation = 'wave' }) => {
  const classes = useStyles();

  return (
    <Skeleton
      classes={{
        root: classes.skeleton,
        wave: classes.wave,
        pulse: classes.pulse
      }}
      animation={animation}
      height={12}
      width={width}
    />
  );
};

const SkeletonModule = ({ topMargin, bottomMargin, type }) => {
  const classes = useStyles();
  const { isTabletWidth, isMobileWidth, isDesktopWidth } = useAppWidthClass();

  let size = isDesktopWidth ? 'desktop' : isTabletWidth ? 'tablet' : 'mobile';

  const isWidgets = type === 'widgets';
  const isSmallTwo = type === 'smallTwo' && !isMobileWidth;

  if (isWidgets) {
    return (
      <Box
        className={clsx(
          classes.root,
          topMargin && classes.topMargin,
          bottomMargin && classes.bottomMargin
        )}
      >
        <Box
          className={clsx(
            classes.widgets,
            isMobileWidth && classes.widgetsMobile
          )}
        >
          <Skeleton
            classes={{ wave: classes.wave }}
            variant="circular"
            width={CIRCLE[size]}
            height={CIRCLE[size]}
          />
          <Box
            className={clsx(
              isDesktopWidth && classes.center,
              isTabletWidth && classes.centerTablet,
              isMobileWidth && classes.centerMobile
            )}
          >
            {MEDIA_BAR_WIDTHS[size].map((w, index) => (
              <SkeletonBar key={index} width={w} />
            ))}
          </Box>
        </Box>
      </Box>
    );
  } else if (isSmallTwo) {
    return (
      <Box
        className={clsx(
          classes.smallTwoContainer,
          isTabletWidth && classes.smallTwoContainerTablet,
          topMargin && classes.topMargin,
          bottomMargin && classes.bottomMargin
        )}
      >
        <Box
          className={clsx(
            classes.smallTwoRoot,
            isTabletWidth && classes.smallTwoRootTablet,
            classes.smallTwoLeft
          )}
        >
          <Box>
            {SMALL_BAR_WIDTHS[size].map((w, index) => (
              <SkeletonBar key={index} width={w} />
            ))}
          </Box>
          <Box>
            {SMALL_BAR_WIDTHS[size].map((w, index) => (
              <SkeletonBar key={index} width={w} />
            ))}
          </Box>
        </Box>
        <Box
          className={clsx(
            classes.smallTwoRoot,
            isTabletWidth && classes.smallTwoRootTablet
          )}
        >
          {SMALL_BAR_WIDTHS[size].map((w, index) => (
            <SkeletonBar key={index} width={w} />
          ))}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        className={clsx(
          classes.root,
          isMobileWidth && classes.rootMobile,
          topMargin && classes.topMargin,
          bottomMargin && classes.bottomMargin
        )}
      >
        {SIMPLE_BAR_WIDTHS[size].map((w, index) => (
          <SkeletonBar key={index} width={w} />
        ))}
      </Box>
    );
  }
};

export default SkeletonModule;
