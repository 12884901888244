import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { enqueueSnackbar } from '@troy/shared/src/store/actions';
import { CustomIcon, Text } from '@troy/shared/src/components/common';

import {
  isPostBoxFileUploadBlurred,
  isPostBoxFileUploadHidden,
  translationsSelector
} from '../../../store/selectors';
import { setFileModalOpened } from '../../../store/actions';
import { onClickWrapper } from '../../../utils/ui';
import { useAppWidthClass } from '../../../utils/hooks';

import { Blurred, ThemeButton } from '../../common';
import { PaymentFormText } from '../index.troy';
import UploadFileModal from '../../post-box/UploadFileModal';
import { PROOF_OF_PAYMENT } from '../../post-box/UploadFileModal/constants';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    cursor: 'default'
  },
  table: {
    padding: `${theme.spacing(1)} 0`
  },
  tableCompact: {
    padding: 0
  },
  row: {
    whiteSpace: 'nowrap'
  },
  label: {
    ...theme.typography.body1Bold,
    display: 'inline-block',
    minWidth: 88,
    color: theme.palette.secondary.main
  },
  labelCompact: {
    ...theme.typography.body2Bold
  },
  valueContainer: {
    position: 'relative',
    display: 'inline-block',

    '&:hover': {
      color: theme.palette.secondary.dark,

      '& svg': {
        display: 'block'
      }
    }
  },
  valueContainerCompact: {
    minWidth: 198
  },
  valueWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  value: {
    ...theme.typography.body1,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    transition: theme.transitions.custom.fast
  },
  valueCompact: {
    ...theme.typography.body2
  },
  copy: {
    marginLeft: theme.spacing(2),
    display: 'none'
  },
  upload: {
    margin: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(0.5)} 0`,
    padding: theme.spacing(2),
    background: theme.palette.primary.contrastText,
    boxShadow: theme.customShadows.activePrimary,
    borderRadius: theme.shape.borderRadius * 4
  },
  uploadCompact: {
    margin: `${theme.spacing(2)} ${theme.spacing(1)} 0 ${theme.spacing(1)}`
  },
  button: {
    marginTop: theme.spacing(3)
  }
}));

const TABLE = (username, translations) => [
  {
    id: 'iban',
    label: 'IBAN',
    value: translations['SEPA_PAYMENT_PANEL_IBAN']
  },
  {
    id: 'bic',
    label: 'BIC',
    value: translations['SEPA_PAYMENT_PANEL_BIC']
  },
  {
    id: 'purpose',
    label: translations['SEPA_PAYMENT_PANEL_PURPOSE_TEXT'],
    value: `Aktenzeichen ${username}`
  }
];

const SepaContent = ({ username }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMobileWidth } = useAppWidthClass();

  const translations = useSelector(translationsSelector);

  const postBoxFileUploadHidden = useSelector(isPostBoxFileUploadHidden);
  const postBoxFileUploadBlurred = useSelector(isPostBoxFileUploadBlurred);

  const onCopy = () =>
    dispatch(
      enqueueSnackbar({
        message: 'UNIC_UXCOPIES_COPY_COPIED',
        options: {
          variant: 'success'
        }
      })
    );

  const onOpenUploadModal = () => dispatch(setFileModalOpened(true));

  return (
    <div className={classes.root} id="offline-sepa">
      <PaymentFormText>SEPA_PAYMENT_PANEL_PAYMENT_TEXT</PaymentFormText>
      <div
        className={clsx(classes.table, isMobileWidth && classes.tableCompact)}
      >
        {TABLE(username, translations).map(({ id, label, value }) => (
          <div className={classes.row} key={label}>
            <span
              className={clsx(
                classes.label,
                isMobileWidth && classes.labelCompact
              )}
            >
              {label}
            </span>
            <span
              className={clsx(
                classes.valueContainer,
                isMobileWidth && classes.valueContainerCompact
              )}
            >
              <CopyToClipboard
                onCopy={onClickWrapper(
                  'page.payments.offline_sepa.copy-to-clipboard',
                  onCopy
                )}
                text={value}
              >
                <span className={classes.valueWrapper}>
                  <span
                    id={`page-payments-offline-sepa-${id}`}
                    className={clsx(
                      classes.value,
                      isMobileWidth && classes.valueCompact
                    )}
                    role="button"
                  >
                    {value}
                  </span>
                  <CustomIcon
                    className={classes.copy}
                    icon="copyPaste"
                    size="xs"
                    variant="secondaryMain"
                  />
                </span>
              </CopyToClipboard>
            </span>
          </div>
        ))}
      </div>
      {!postBoxFileUploadHidden && (
        <div
          className={clsx(
            classes.upload,
            isMobileWidth && classes.uploadCompact
          )}
        >
          <Text
            translated
            component="p"
            variant="body2"
            color="textPrimary"
            align="center"
          >
            SEPA_PAYMENT_PANEL_NOTE
          </Text>
          <Blurred blur={postBoxFileUploadBlurred}>
            <ThemeButton
              fullWidth
              className={classes.button}
              id="page.home.component.payment-list.offline-sepa.upload"
              variant="outlined"
              color="primary"
              textKey="UPLOAD_BUTTON"
              size="medium"
              onClick={onOpenUploadModal}
            />
          </Blurred>
        </div>
      )}
      <UploadFileModal preSelectedType={PROOF_OF_PAYMENT} />
    </div>
  );
};

export default SepaContent;
