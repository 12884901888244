import { createReducer } from 'redux-act';

import * as actions from '../actions/payment';

import { isInIframe } from '../../utils/browser';
import { getPortalCurrencyOverride } from '../../utils/portal';

const liabilityStatusMap = {
  due_currency: 'dueCurrency',
  total_outstanding_balance: 'totalBalance'
};

const PAYMENT_ID_KEY = 'paymentId';

const DEFAULT_STATE = {
  paymentUserInfo: {},
  payment: {},
  wppData: {},
  show: {
    feedback: null
  },
  method: '',
  molliePaymentId: isInIframe() ? '' : sessionStorage.getItem(PAYMENT_ID_KEY),
  isLoading: false
};

export default createReducer(
  {
    [actions.setPaymentDecisions]: (state, decisions) => ({
      ...state,
      show: {
        feedback: decisions['troy.feature.feedback']
      }
    }),
    [actions.setPaymentUserInfo]: (state, { liability, currencyOverride }) => {
      const liabilityStatus = Object.keys(liability.liability_status).reduce(
        (acc, key) => {
          let newKey = liabilityStatusMap[key] ? liabilityStatusMap[key] : key;
          return { ...acc, [newKey]: liability.liability_status[key] };
        },
        {}
      );
      return {
        ...state,
        paymentUserInfo: {
          ...liabilityStatus,
          dueCurrency: getPortalCurrencyOverride(
            liabilityStatus.dueCurrency || 'EUR',
            currencyOverride
          )
        }
      };
    },
    [actions.setPaymentMethod]: (state, method) => ({
      ...state,
      method
    }),
    [actions.resetPaymentMethod]: state => ({
      ...state,
      method: DEFAULT_STATE.method
    }),
    [actions.setMolliePaymentId]: (state, paymentId) => {
      if (!isInIframe()) {
        sessionStorage.setItem(PAYMENT_ID_KEY, paymentId);
      }
      return {
        ...state,
        molliePaymentId: paymentId
      };
    },
    [actions.resetMolliePayment]: state => {
      if (!isInIframe()) {
        sessionStorage.removeItem(PAYMENT_ID_KEY);
      }
      return {
        ...state,
        molliePaymentId: null
      };
    },
    [actions.wppDataReceived]: (state, wppData) => {
      window.WPP.embeddedPayUrl(wppData.redirectUrl);
      return { ...state, wppData };
    },
    [actions.payOfflineDataReceived]: (state, data) => {
      return {
        ...state,
        payment: {
          created: data.created,
          id: data.id,
          state: data.state
        }
      };
    },
    [actions.setPaymentIsLoading]: (state, isLoading) => ({
      ...state,
      isLoading
    }),
    [actions.paymentReset]: () => ({ ...DEFAULT_STATE })
  },
  DEFAULT_STATE
);
