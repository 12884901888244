import React from 'react';

import { onClickWrapper } from '../../../utils/ui';
import { COMPANY_INFO, CONFIDENTIALITY } from '../../../constants/routes';

import { Link, Translation } from '../../common';

interface FooterNavigationLinksProps {
  linkClass?: string;
  hasLogout?: boolean;
  logout: () => void;
}

const FooterNavigationLinks: React.FC<FooterNavigationLinksProps> = ({
  linkClass,
  hasLogout,
  logout
}): React.ReactElement => (
  <>
    <Link
      id="page.component.footer.imprint-link"
      className={linkClass}
      to={COMPANY_INFO}
    >
      <Translation inline>NAVBAR_IMPRINT</Translation>
    </Link>
    <Link
      id="page.component.footer.cookies-link"
      className={linkClass}
      to={CONFIDENTIALITY}
    >
      <Translation inline>NAVBAR_COOKIES&DATAPROTECTION</Translation>
    </Link>
    {hasLogout && (
      <button
        id="footer-logout-link"
        className={linkClass}
        onClick={onClickWrapper('page.component.footer.logout-button', () =>
          logout()
        )}
      >
        <Translation inline>LOGOUT_BUTTON</Translation>
      </button>
    )}
  </>
);

export default FooterNavigationLinks;
