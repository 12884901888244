import { createSelector } from 'reselect';

const stateSelector = state => state.customers;

export const returnGoodsLoadingSelector = createSelector(
  stateSelector,
  ({ returnGoodsLoading }) => returnGoodsLoading
);

export const returnGoodsErrorSelector = createSelector(
  stateSelector,
  ({ returnGoodsError }) => returnGoodsError
);
