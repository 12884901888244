import { call, put } from 'redux-saga/effects';

import { get } from '../../utils/httpUtil';
import { handleError, setToken } from '../actions';
import { SAP_AUTH } from '../../constants/api';

export function* sapAuthentication(request) {
  try {
    const { payload } = request;
    const url = SAP_AUTH(payload);
    const { authToken } = yield call(get, url);
    yield put(setToken(authToken));
  } catch (err) {
    yield put(handleError(err));
  }
}
