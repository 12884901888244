import React, { useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import { Module } from '@troy/shared/src/components/common';

import { Blurred, ThemeButton, Translation } from '../../common';
import { getOneTimeToken } from '../../../utils/token';

const MOCK_SUBTITLE = 'mock subtitle';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    textAlign: 'center',

    '& > *:nth-child(1n+2)': {
      marginLeft: 16
    }
  },
  rootMobile: {
    flexDirection: 'column',

    '& > *:nth-child(1n+2)': {
      marginLeft: 0,
      marginTop: 24
    }
  },
  item: {
    flexBasis: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 32px'
  },
  title: {
    color: theme.palette.text.primary
  },
  grow: {
    flexGrow: 1,
    minHeight: 24
  },
  button: {
    margin: 'auto auto 0 auto',
    width: '100%',
    maxWidth: 228
  }
}));

const DemandTableDocuments = ({
  className,
  isTabletSize,
  isMobileSize,
  items,
  showAll
}) => {
  const classes = useStyles();

  const onClick = useCallback(
    link => async () => {
      const oneTimeToken = await getOneTimeToken();
      if (oneTimeToken) {
        window.open(link(oneTimeToken), '_blank');
      }
    },
    []
  );

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Box
      className={clsx(
        classes.root,
        isMobileSize && classes.rootMobile,
        className
      )}
    >
      {items.map(({ id, title, label, link, mobileOnly, blur }) => {
        const buttonId = `page.home.component.demand-table.document-button.${id}`;

        return (
          (showAll ||
            !mobileOnly ||
            ((isTabletSize || isMobileSize) && mobileOnly)) && (
            <Module key={buttonId} className={classes.item}>
              <Typography
                className={classes.title}
                component="h3"
                variant="body2"
              >
                <Translation isFragment>{title}</Translation>
              </Typography>
              <Blurred blur={blur}>
                <Typography
                  className={classes.title}
                  component="p"
                  variant="body2"
                >
                  <Translation inline keyIfNoValue>
                    {label && !blur ? label : MOCK_SUBTITLE}
                  </Translation>
                </Typography>
                <Box className={classes.grow} />
                <ThemeButton
                  id={buttonId}
                  className={classes.button}
                  color="secondary"
                  variant="outlined"
                  size="medium"
                  startIcon="downloadIcon"
                  startIconColor="secondary"
                  onClick={blur ? undefined : onClick(link)}
                  textKey={'Download'}
                />
              </Blurred>
            </Module>
          )
        );
      })}
    </Box>
  );
};

export default DemandTableDocuments;
