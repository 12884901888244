export const mockData = [
  {
    amount: 100,
    currency: 'EUR',
    subject: 'Mock Subject 1'
  },
  {
    amount: 200,
    currency: 'EUR',
    subject: 'Mock Subject 2'
  },
  {
    amount: 300,
    currency: 'EUR',
    subject: 'Mock Subject 3'
  },
  {
    amount: 400,
    currency: 'EUR',
    subject: 'Mock Subject 4'
  },
  {
    amount: 500,
    currency: 'EUR',
    subject: 'Mock Subject 5'
  },
  {
    amount: 600,
    currency: 'EUR',
    subject: 'Mock Subject 6'
  },
  {
    amount: 700,
    currency: 'EUR',
    subject: 'Mock Subject 7'
  },
  {
    amount: 800,
    currency: 'EUR',
    subject: 'Mock Subject 8'
  },
  {
    amount: 900,
    currency: 'EUR',
    subject: 'Mock Subject 9'
  },
  {
    amount: 1000,
    currency: 'EUR',
    subject: 'Mock Subject 10'
  }
];
