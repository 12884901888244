import { createSelector } from 'reselect';
import { isDomainDE } from '../../constants/browser';
import { isTokenValid } from '../../utils/jwt';

import { languageSelector } from './translations';
import { tokenSelector, usernameSelector } from './auth';
import { isAuthenticatedAppLoadingSelector } from './loading';

const stateSelector = state => state.ui;

export const isTypeformFeedbackSelector = createSelector(
  stateSelector,
  state => state.feedback.typeformFeedback
);

export const isFormalCommunicationSelector = createSelector(
  stateSelector,
  state => state.isFormalCommunication
);

export const hasAccountAccessInterestSelector = createSelector(
  languageSelector,
  stateSelector,
  (lang, state) =>
    isDomainDE() && lang === 'de_DE' && state.payment.accountAccessInterest
);

export const showBlueMovementReturnGoods = createSelector(
  stateSelector,
  state => state.returnGoods
);

// do not show bots when customer is still loading
const shouldHideChatBotsWhileLoadingSelector = createSelector(
  tokenSelector,
  isAuthenticatedAppLoadingSelector,
  usernameSelector,
  (token, isLoading, caseId) => isTokenValid(token) && isLoading && !caseId
);

export const isLiveChatVisibleSelector = createSelector(
  stateSelector,
  shouldHideChatBotsWhileLoadingSelector,
  (state, isLoading) => !isLoading && !!state.chat && state.chat.show
);

export const isChatBotVisibleSelector = createSelector(
  stateSelector,
  shouldHideChatBotsWhileLoadingSelector,
  (state, isLoading) => !isLoading && !!state.chatBot && state.chatBot.show
);

export const isRocketChatWidgetVisibleSelector = createSelector(
  stateSelector,
  shouldHideChatBotsWhileLoadingSelector,
  (state, isLoading) => !isLoading && state.rocketChatShow
);

export const showTranslationTooltipsSelector = createSelector(
  stateSelector,
  state => state.showTranslationTooltips
);

export const isPostBoxFileUploadHidden = createSelector(
  stateSelector,
  state => state.postBox.hide.fileUpload
);

export const isPostBoxFileUploadBlurred = createSelector(
  stateSelector,
  state => state.postBox.blur.fileUpload
);

export const showCelebrationAnimationSelector = createSelector(
  stateSelector,
  state => state.showCelebrationAnimation
);
