import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { useAppWidthClass } from '../../../utils/hooks';
import {
  currentAttemptsMade2FASelector,
  is2FABlockedSelector,
  is2FALoadingSelector,
  is2FAModalOpenSelector,
  typeOf2FASelector
} from '../../../store/selectors';
import { set2FAModalOpen, submit2FALogin } from '../../../store/actions';

import { Modal, ModalElement } from '../../common';
import TwoFAForm from '../TwoFAForm';
import TwoFAHelper from '../TwoFAHelper';

const useStyles = makeStyles(theme => ({
  backdrop: {
    background: theme.palette.background.dark
  },
  modalPaper: {
    maxWidth: 352,
    width: '100%',
    borderRadius: theme.shape.borderRadius * 6
  }
}));

const TwoFAModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMobileWidth } = useAppWidthClass();

  const isModalOpen = useSelector(is2FAModalOpenSelector);
  const type = useSelector(typeOf2FASelector);
  const currentAttemptsMade = useSelector(currentAttemptsMade2FASelector);
  const isBlocked = useSelector(is2FABlockedSelector);
  const isLoading = useSelector(is2FALoadingSelector);

  const handleClose = () => dispatch(set2FAModalOpen(false));

  const [helperOpened, setHelperOpened] = useState(false);

  const onTwoFAHelpClick = useCallback(() => {
    setHelperOpened(true);
  }, []);
  const onTwoFAHelperClose = useCallback(() => {
    setHelperOpened(false);
  }, []);

  const onSubmit = code => dispatch(submit2FALogin({ type, code }));

  return (
    <>
      <Modal
        onClose={!isLoading ? handleClose : undefined}
        onPaperClick={!isLoading ? handleClose : undefined}
        isOpen={isModalOpen && !helperOpened}
        backdropClassName={classes.backdrop}
        paperClassName={clsx(
          classes.modalPaper,
          isMobileWidth && classes.modalPaperMobile
        )}
      >
        <TwoFAForm
          onSubmit={onSubmit}
          onLoginHelpClick={onTwoFAHelpClick}
          type={type}
          attempt={currentAttemptsMade}
          isBlocked={isBlocked}
          isLoading={isLoading}
        />
      </Modal>
      <Modal
        withFullSizePaper
        backdropClassName={classes.backdrop}
        isOpen={helperOpened}
      >
        <ModalElement className={classes.modalPaper}>
          <TwoFAHelper type={type} onButtonClick={onTwoFAHelperClose} />
        </ModalElement>
      </Modal>
    </>
  );
};

export default TwoFAModal;
