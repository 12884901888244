import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { HEADER_ANIMATION_Y, HEADER_BAR_HEIGHT } from '../../../constants/ui';
import { useAppWidthClass } from '../../../utils/hooks';
import { ThemeButton, LanguageSelect } from '../../common';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.contrastText
  },
  fixed: {
    position: 'fixed',
    zIndex: 1100,
    left: 0,
    right: 0,
    transition: theme.transitions.custom.header,
    width: '100vw'
  },
  content: {
    height: HEADER_BAR_HEIGHT,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  line: {
    margin: '0 16px 0 14px',
    width: 1,
    height: '100%',
    background: theme.palette.text.primary
  }
}));

const Header = ({
  isAuthenticated,
  onLanguageSelect,
  onLogout,
  languages,
  language
}) => {
  const classes = useStyles();
  const { widthClass } = useAppWidthClass();
  const [top, setTop] = useState(0);

  const handleScroll = () => {
    let newTop = 0;
    if (window.pageYOffset > HEADER_ANIMATION_Y) {
      newTop = Math.max(
        HEADER_ANIMATION_Y - window.pageYOffset,
        -HEADER_BAR_HEIGHT
      );
    }
    setTop(newTop);
  };

  useEffect(() => {
    if (isAuthenticated) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (isAuthenticated) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isAuthenticated]);

  return (
    <Box
      className={clsx(classes.root, isAuthenticated && classes.fixed)}
      style={isAuthenticated ? { top: `${top}px` } : {}}
    >
      <Box className={clsx(widthClass, classes.content)}>
        {!!languages && Object.keys(languages).length > 0 && (
          <>
            <Box
              id="page.component.main-layout.flag-select-wrapper"
              data-selected-lang={language ? language : undefined}
            >
              <LanguageSelect
                id="page.component.main-layout.flag-select"
                onChange={onLanguageSelect}
                languages={languages}
                value={language}
              />
            </Box>
            {isAuthenticated && <Box className={classes.line} />}
          </>
        )}
        {isAuthenticated && (
          <ThemeButton
            id="page.component.main-layout.logout-button"
            size="medium"
            color="inherit"
            fontStyle="subtitle3"
            onClick={onLogout}
            textKey="LOGOUT_BUTTON"
          />
        )}
      </Box>
    </Box>
  );
};

Header.propTypes = {
  styles: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  onLanguageSelect: PropTypes.func,
  onLogout: PropTypes.func
};

export default Header;
