import { createReducer } from 'redux-act';

import * as actions from '../actions/error';

const DEFAULT_STATE = {
  status: '',
  info: null
};

export default createReducer(
  {
    [actions.setError]: (state, info) => ({ ...state, info }),
    [actions.setErrorStatus]: (state, status) => ({ ...state, status }),
    [actions.errorReset]: state => ({ ...DEFAULT_STATE })
  },
  DEFAULT_STATE
);
