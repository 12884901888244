import { call, put, select } from 'redux-saga/effects';
import IBAN from 'iban';
import { push } from 'react-router-redux';

import { callHttp, getContentDispositionFileName } from '../../utils/api';
import { get, post } from '../../utils/httpUtil';
import {
  CREDIT_CARD_MOLLIE_PAYMENT,
  GET_WPP_DATA,
  IDEAL_INSTALLMENT,
  IDEAL_MOLLIE_PAYMENT,
  MAKE_BANK_TRANSFER,
  MOLLIE_SOFORT_PAYMENT,
  PAY_LATER,
  PAY_OFFLINE,
  PAY_SEPA_DIRECT_DEBIT,
  PAYMENT_STATUS,
  PAYMENTS,
  PAYPAL_MOLLIE_PAYMENT,
  SEND_MOCK_CONFIRMATION,
  MOLLIE_BAN_CONTACT_PAYMENT
} from '../../constants/api';

import {
  errorRedirect,
  getCustomers,
  getDecisions,
  handleError,
  payOfflineDataReceived,
  resetMolliePayment,
  setMolliePaymentId,
  setPaymentIsLoading,
  setPaymentMethod,
  wppDataReceived
} from '../actions';

import { FEEDBACK, PAYMENT_REDIRECT } from '../../constants/routes';
import {
  CREDIT_CARD_MOLLIE_VALUE,
  IDEAL_INSTALLMENT_VALUE,
  PAY_LATER_VALUE,
  PAYPAL_MOLLIE_VALUE,
  SEPA_DIRECT_DEBIT_VALUE,
  SOFORT_MOLLIE_VALUE,
  BAN_CONTACT_MOLLIE_VALUE
} from '../../constants/payment';
import {
  PAYMENT_CANCEL,
  PAYMENT_EXPIRED,
  PAYMENT_FAIL,
  PAYMENT_PENDING,
  PAYMENT_UNAUTHORIZED
} from '../../constants/errorStatuses';
import { tokenSelector } from '../selectors';
import { downloadBlobFile } from '../../utils/browser';

export function* beginPaymentSession({ payload }) {
  try {
    const requestPayload = {
      paymentsession: {
        type: payload.type,
        amount: {
          amount: payload.amount,
          currency: payload.currency
        },
        originSite: window.location.href
      }
    };
    const wppData = yield callHttp(post, GET_WPP_DATA, requestPayload);
    yield put(wppDataReceived(wppData));
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* beginMollieSession({ payload: { amount, currency, type } }) {
  try {
    const body = {
      request: {
        amount: {
          amount,
          currency
        },
        molliePaymentType: 'FULL',
        redirectUrl: `${window.location.origin}${FEEDBACK}?page=${type}`
      }
    };

    const data = yield callHttp(
      post,
      type === SOFORT_MOLLIE_VALUE
        ? MOLLIE_SOFORT_PAYMENT
        :type === BAN_CONTACT_MOLLIE_VALUE
        ? MOLLIE_BAN_CONTACT_PAYMENT
        : type === CREDIT_CARD_MOLLIE_VALUE
        ? CREDIT_CARD_MOLLIE_PAYMENT
        : type === PAYPAL_MOLLIE_VALUE
        ? PAYPAL_MOLLIE_PAYMENT
        : IDEAL_MOLLIE_PAYMENT,
      body
    );

    const paymentId = data.id;
    yield put(setMolliePaymentId(paymentId));

    const redirectUrl = data.redirectUrl;
    window.location.href = redirectUrl;
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* beginIdealInstallmentSession({
  payload: { amount, currency, day, email, mobilePhone = null }
}) {
  try {
    const body = {
      request: {
        amount: {
          amount,
          currency
        },
        payOnDayOfMonth: day,
        email: email && email.trim(),
        mobilePhone: mobilePhone && mobilePhone.trim(),
        paymentMadeCountry: 'NL',
        redirectUrl: `${window.location.origin}${FEEDBACK}?page=${IDEAL_INSTALLMENT_VALUE}`
      }
    };

    const data = yield callHttp(post, IDEAL_INSTALLMENT, body);
    const redirectUrl = data.redirectUrl;
    window.location.href = redirectUrl;
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* payLater({
  payload: { day, email = null, mobilePhone: phoneNumber = null }
}) {
  try {
    const date = new Date();
    date.setDate(date.getDate() + day);

    const body = {
      request: {
        day: date.getDate(),
        month: date.getMonth() + 1,
        email: email && email.trim(),
        phoneNumber: phoneNumber && phoneNumber.trim()
      }
    };

    yield callHttp(post, PAY_LATER, body);
    yield put(getDecisions());
    yield put(getCustomers());

    yield put(push({ pathname: FEEDBACK, search: `?page=${PAY_LATER_VALUE}` }));
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* payOffline({ payload }) {
  try {
    const requestPayload = {
      offlinepayment: {
        amount: {
          amount: payload.amount,
          currency: payload.currency
        }
      }
    };
    let payOfflineData = yield callHttp(post, PAY_OFFLINE, requestPayload);
    yield put(payOfflineDataReceived(payOfflineData));
    payOfflineData = yield callHttp(get, PAYMENTS(payOfflineData.id));
    yield put(payOfflineDataReceived(payOfflineData));
    yield callHttp(post, SEND_MOCK_CONFIRMATION, {});
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* paySepaDirectDebit({ payload }) {
  const { iban, date, accountHolder } = payload;

  const directDebitBody = {
    request: {
      payOnDay: date,
      mandate: {
        iban: IBAN.electronicFormat(iban),
        accountHolder
      }
    }
  };

  try {
    const { paymentId } = yield callHttp(
      post,
      PAY_SEPA_DIRECT_DEBIT,
      directDebitBody
    );
    if (paymentId) {
      yield put(setPaymentMethod(SEPA_DIRECT_DEBIT_VALUE));
      yield put(push(PAYMENT_REDIRECT));
    }
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* checkMolliePaymentStatus({ payload: id }) {
  try {
    const { status } = yield callHttp(get, PAYMENT_STATUS(id));
    switch (status) {
      case 'FAILED':
        yield put(errorRedirect(PAYMENT_FAIL));
        break;
      case 'OPEN':
      case 'CANCELED':
        yield put(errorRedirect(PAYMENT_CANCEL));
        break;
      case 'PENDING':
        yield put(errorRedirect(PAYMENT_PENDING));
        break;
      case 'UNAUTHORIZED':
        yield put(errorRedirect(PAYMENT_UNAUTHORIZED));
        break;
      case 'EXPIRED':
        yield put(errorRedirect(PAYMENT_EXPIRED));
        break;
      case 'PAID':
      default:
        break;
    }
    yield put(resetMolliePayment());
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* offlineSepaSubmit() {
  try {
    yield put(setPaymentIsLoading(true));

    const token = yield select(tokenSelector);
    const { body, headers } = yield call(
      get,
      MAKE_BANK_TRANSFER(token),
      true,
      false,
      false,
      true
    );

    downloadBlobFile(
      body,
      getContentDispositionFileName(headers.get('content-disposition'))
    );
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setPaymentIsLoading(false));
  }
}
