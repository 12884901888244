import React from 'react';
import { useSelector } from 'react-redux';

import {
  portalLogoHeightSelector,
  portalLogoSelector
} from '../../../store/selectors';

const Logo = ({ className, src }) => {
  const logo = useSelector(portalLogoSelector);
  const logoHeight = useSelector(portalLogoHeightSelector);

  return (
    <img
      src={src || logo}
      height={logoHeight}
      alt="Logo"
      className={className}
    />
  );
};

export default Logo;
