import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppWidthClass } from '../../../utils/hooks';
import { SkeletonBar } from '../../common';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.text.primary,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rootDesktop: {
    height: 162
  },
  rootTablet: {
    height: 220
  },
  rootMobile: {
    height: 163
  },
  column: {
    marginLeft: 67
  }
}));

const FooterSkeleton = ({ className }) => {
  const classes = useStyles();
  const { isDesktopWidth, isTabletWidth, isMobileWidth } = useAppWidthClass();
  const columns = isDesktopWidth ? 3 : isTabletWidth ? 2 : 1;

  return (
    <Box
      className={clsx(
        classes.root,
        isDesktopWidth && classes.rootDesktop,
        isTabletWidth && classes.rootTablet,
        isMobileWidth && classes.rootMobile,
        className
      )}
    >
      <Box>
        <SkeletonBar width="24px" animation="pulse" />
        <SkeletonBar width="24px" animation="pulse" />
      </Box>
      {Array(columns)
        .fill(0)
        .map((_, index) => (
          <Box key={index} className={classes.column}>
            <SkeletonBar width="180px" animation="pulse" />
            <SkeletonBar width="220px" animation="pulse" />
          </Box>
        ))}
    </Box>
  );
};

export default FooterSkeleton;
