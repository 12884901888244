import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomIcon, FormCheckbox } from '@troy/shared/src/components/common';

import {
  hasAccountAccessInterestSelector,
  usernameSelector
} from '../../../store/selectors';
import { getAccountAccessVariantForUserName } from '../../../utils/random';
import { useAppWidthClass } from '../../../utils/hooks';
import { onClickWrapper } from '../../../utils/ui';

import { ThemeButton } from '../index';

const BUTTON_TEXT = {
  1: 'Jetzt beste Rate ermitteln',
  2: 'Jetzt besten Tag ermitteln',
  3: 'Jetzt besten Tag & Rate ermitteln',
  4: 'Jetzt sofort Zusage erhalten'
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: 16,
    borderRadius: theme.shape.borderRadius * 4,
    background: theme.palette.grey[100]
  },
  description: {
    marginTop: 16,
    color: theme.palette.text.primary
  },
  descriptionDisabled: {
    color: theme.palette.text.disabled
  },
  checkbox: {
    marginTop: 16
  },
  submit: {
    marginTop: 16,
    width: '100%'
  },
  response: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 16
  },
  message: {
    marginLeft: 16,
    whiteSpace: 'pre-wrap'
  }
}));

const AccountAccessInterest = ({ className, variant, children = null }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const isMounted = useRef(false);
  const { isMobileWidth } = useAppWidthClass();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const userName = useSelector(usernameSelector);
  const hasAccountAccessInterest = useSelector(
    hasAccountAccessInterestSelector
  );
  const accountAccessVariant = getAccountAccessVariantForUserName(userName);
  const visible = hasAccountAccessInterest && accountAccessVariant === variant;

  const toggle = () => setChecked(checked => !checked);

  const onClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      if (isMounted.current) {
        setSubmitted(true);
      }
    }, 1000);
  };

  const id = `access2account-variant${accountAccessVariant}`;

  return visible ? (
    <Box className={clsx(classes.container, className)}>
      {children}
      <Typography
        variant="body1"
        className={clsx(
          classes.description,
          submitted && classes.descriptionDisabled
        )}
      >
        Wir können Ihnen dabei helfen, den besten Tag und die Höhe für die
        Ratenzahlung zu finden. Hierfür benötigen wir Ihr Einverständnis,
        Einsicht auf Ihr Bankkonto zu erhalten.
      </Typography>
      {!submitted && (
        <>
          <FormCheckbox
            id="access2account.checkbox"
            className={classes.checkbox}
            variant="small"
            color="secondary"
            label="Ja, ich gebe der troy gmbh hiermit die Erlaubnis, in meinem Namen Kontoinformationen abzurufen."
            value={checked}
            onClick={toggle}
          />
          <ThemeButton
            id={id}
            className={classes.submit}
            onClick={onClickWrapper(id, onClick)}
            variant="contained"
            color="secondary"
            size="large"
            disabled={!checked || isLoading}
            textKey={BUTTON_TEXT[variant]}
            startIcon={isMobileWidth ? undefined : 'confirm'}
            startIconColor="secondaryContrast"
          />
        </>
      )}
      {submitted && (
        <Box className={classes.response}>
          <CustomIcon icon="info" variant="secondary" size="small" />
          <Typography
            variant="body2"
            color="secondary"
            className={clsx(classes.message)}
          >
            {'Leider steht diese Funktion aus technischen Gründen aktuell nicht ' +
              'zur Verfügung.\n\nNatürlich können Sie trotzdem einen Ratenplan ' +
              'beantragen - bitte scrollen Sie dazu ans Ende der Seite.'}
          </Typography>
        </Box>
      )}
    </Box>
  ) : (
    children
  );
};

export default AccountAccessInterest;
