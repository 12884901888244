const getRect = element => element.getBoundingClientRect();

export const addEllipsis = container => {
  const spans = container.children;

  for (let i = 0; i < spans.length; i++) {
    const s = spans[i];
    const overRight = getRect(s).right > getRect(container).right;
    const overBottom = getRect(s).bottom > getRect(container).bottom;
    if (overRight || overBottom) {
      const isMultiLine = getRect(s).height > 16;

      if (isMultiLine) {
        s.style.display = 'block';
        s.style.whiteSpace = 'noWrap';
        s.style.overflow = 'hidden';
        s.style.textOverflow = 'ellipsis';
      } else if (i > 0) {
        s.style.display = 'block';

        const previous = spans[i - 1];
        const len = previous.textContent.length;
        previous.style.display = 'block';
        if (len > 4) {
          previous.textContent =
            previous.textContent.substring(0, len - 4) + '... ';
        } else {
          previous.textContent = '... ';
        }
      }

      break;
    }
  }
};
