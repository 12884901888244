import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { PAY_NOW_TEXT_KEY } from '../../../constants/payment';
import { fadeInAnimationItem } from '../../../constants/animations';
import { useAppWidthClass } from '../../../utils/hooks';
import {
  portalModuleRadialBackgroundSelector,
  translationsSelector
} from '../../../store/selectors';

import { RadioGroup, Translation } from '../../common';
import { PaymentNow } from '../index.troy';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 928,
    padding: '40px 90px 80px 96px',
    margin: '18px 16px 32px 16px',
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 6,
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box'
  },
  rootMedium: {
    maxWidth: 'unset',
    padding: '16px 24px 24px 24px'
  },
  rootSmall: {
    maxWidth: 'unset',
    padding: '16px 0 16px 0'
  },
  description: {
    margin: '16px auto 0 auto',
    padding: '0 16px',
    maxWidth: 394
  },
  content: {
    display: 'flex',
    marginTop: 48
  },
  contentMedium: {
    flexDirection: 'column',
    marginTop: 42
  },
  contentSmall: {
    flexDirection: 'column',
    marginTop: 24
  },
  mediumHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'center'
  },
  paySectionMedium: {
    '&:not(:empty)': {
      minWidth: 190,
      marginLeft: 56
    }
  },
  methods: {
    width: 447
  },
  methodsSmall: {
    width: 'auto',
    padding: '0 16px 0 16px'
  },
  methodsMedium: {
    width: 'auto',
    padding: '18px 0 0 11px'
  },
  methodRow: {
    width: '100%',
    marginRight: 0
  },
  methodsRowSmall: {
    marginLeft: 0
  },
  animationItem: {
    width: '100%',

    '&:not(:first-of-type) > label': {
      borderTop: `1px solid ${theme.palette.text.disabled}`
    }
  },
  radio: {
    margin: 'auto 7px auto -9px',
    color: theme.palette.text.primary,

    '& svg': {
      fontSize: '1rem'
    }
  },
  optionContent: {
    marginLeft: 22
  },
  optionContentSmall: {
    width: '100%',
    marginLeft: 0
  },
  paySection: {
    minWidth: 190,
    marginLeft: 'auto',
    textAlign: 'center'
  },
  paySectionSmall: {
    height: 0,
    minWidth: 'auto',
    marginRight: 'auto'
  },
  paySectionSticky: {
    position: 'sticky',
    top: 120
  },
  payNow: {
    marginTop: theme.spacing(3)
  },
  payNowSmall: {
    marginTop: theme.spacing(1)
  },
  totalHeaderMobile: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.secondary.contrastText,
    background: props => props.bg,
    borderRadius: `${theme.shape.borderRadius * 6}px ${
      theme.shape.borderRadius * 6
    }px 0px 0px`
  },
  totalHeaderTablet: {
    textAlign: 'center'
  },
  totalHeaderAmount: {
    color: theme.palette.secondary.main
  },
  totalHeaderAmountMobile: {
    color: theme.palette.secondary.contrastText
  },
  line: {
    margin: '8px 6px',
    height: 1,
    background: theme.palette.text.primary
  },
  lineMobile: {
    margin: '8px 0',
    height: 1,
    background: theme.palette.secondary.contrastText
  },
  totalHeaderText: {
    ...theme.typography.h3,
    color: theme.palette.text.primary
  },
  totalHeaderTextTablet: {
    ...theme.typography.body1Bold,
    color: theme.palette.text.primary
  },
  totalHeaderTextMobile: {
    ...theme.typography.body2Bold,
    color: theme.palette.secondary.contrastText
  }
}));

const TotalSection = ({ amount, isMobile, isTablet }) => {
  const bg = useSelector(portalModuleRadialBackgroundSelector);
  const classes = useStyles({ bg });

  return (
    <section
      className={clsx(
        isMobile && classes.totalHeaderMobile,
        isTablet && classes.totalHeaderTablet
      )}
    >
      <Typography
        variant={isMobile ? 'h3' : 'h2'}
        component="p"
        className={
          isMobile ? classes.totalHeaderAmountMobile : classes.totalHeaderAmount
        }
      >
        {amount}
      </Typography>
      <Box className={isMobile ? classes.lineMobile : classes.line} />
      <Typography
        className={clsx(
          classes.totalHeaderText,
          isMobile && classes.totalHeaderTextMobile,
          isTablet && classes.totalHeaderTextTablet
        )}
        component="p"
      >
        <Translation inline keyIfNoValue>
          {'Total'}
        </Translation>
      </Typography>
    </section>
  );
};

const PaymentsContent = ({
  amount,
  methods,
  methodName,
  methodValue,
  onMethodChange,
  onPayNow,
  payNowDisabled,
  isPaymentLoading
}) => {
  const classes = useStyles();
  const { isDesktopWidth, isTabletWidth, isMobileWidth } = useAppWidthClass();
  const t = useSelector(translationsSelector);

  let methodsStyled = methods.map(method => {
    let content = null;
    if (method.content !== null) {
      content = (
        <Box
          className={clsx(
            classes.optionContent,
            isMobileWidth && classes.optionContentSmall
          )}
        >
          {method.content}
        </Box>
      );
    }
    return {
      ...method,
      content
    };
  });

  return (
    <main
      id="payments-content"
      className={clsx(
        classes.root,
        isTabletWidth && classes.rootMedium,
        isMobileWidth && classes.rootSmall
      )}
    >
      <Typography component="h1" variant="h3" color="secondary" align="center">
        <Translation inline>PAYMENT_PAGE_HEADER</Translation>
      </Typography>
      <Typography
        className={classes.description}
        component="div"
        variant="body1"
        color="textPrimary"
        align="center"
      >
        <Translation inline>
          {methodsStyled.length > 0 ? 'PAY_BOX_TOP' : 'NO_CURRENT_PAYMENT'}
        </Translation>
      </Typography>
      {methodsStyled.length > 0 && (
        <Box
          id="payments-methods"
          className={clsx(
            classes.content,
            isTabletWidth && classes.contentMedium,
            isMobileWidth && classes.contentSmall
          )}
        >
          {(isMobileWidth || isTabletWidth) && (
            <article
              className={clsx(isTabletWidth && classes.mediumHeader)}
              aria-label={t['PAYMENT_DETAILS']}
            >
              <TotalSection
                isMobile={isMobileWidth}
                isTablet={isTabletWidth}
                amount={amount}
              />
              {isTabletWidth && (
                <section className={classes.paySectionMedium}>
                  <PaymentNow
                    methodName={methodName}
                    methodValue={methodValue}
                    onPayNow={onPayNow}
                    disabled={payNowDisabled}
                    payNowLabel={PAY_NOW_TEXT_KEY}
                    isPaymentLoading={isPaymentLoading}
                  />
                </section>
              )}
            </article>
          )}
          <article
            className={clsx(
              classes.methods,
              isMobileWidth && classes.methodsSmall,
              isTabletWidth && classes.methodsMedium
            )}
            aria-label={t['PAYMENT_METHODS']}
          >
            <RadioGroup
              value={methodValue}
              options={methodsStyled}
              onChange={onMethodChange}
              radioClassName={clsx(
                classes.methodRow,
                isMobileWidth && classes.methodsRowSmall
              )}
              radioIconClassName={classes.radio}
              ariaControlsId="payment-method"
              animationProps={{
                initial: 'hidden',
                animate: 'visible',
                variants: fadeInAnimationItem,
                transition: { delay: 0.2 },
                className: classes.animationItem
              }}
              animationIndexFunction={index => ({
                transition: { delay: 0.2 + index * 0.075 }
              })}
            />
          </article>
          {!isTabletWidth && (
            <article
              className={clsx(
                classes.paySection,
                isMobileWidth && classes.paySectionSmall
              )}
              aria-label={t['PAYMENT_DETAILS']}
            >
              <Box className={clsx(isDesktopWidth && classes.paySectionSticky)}>
                {!isMobileWidth && <TotalSection amount={amount} />}
                <section
                  className={clsx(
                    classes.payNow,
                    isMobileWidth && classes.payNowSmall
                  )}
                >
                  <PaymentNow
                    methodName={methodName}
                    methodValue={methodValue}
                    onPayNow={onPayNow}
                    disabled={payNowDisabled}
                    payNowLabel={PAY_NOW_TEXT_KEY}
                    isPaymentLoading={isPaymentLoading}
                  />
                </section>
              </Box>
            </article>
          )}
        </Box>
      )}
    </main>
  );
};

export default PaymentsContent;
