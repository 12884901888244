import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import { Stepper, Text } from '@troy/shared/src/components/common';
import { parsePhoneFromString } from '@troy/shared/src/utils/phone';

import { useAppWidthClass } from '../../../utils/hooks';
import {
  EMAIL_FIELD,
  SMS_FIELD,
  WHATS_APP_FIELD
} from '../../../constants/response-channel';
import { uploadFile } from '../../../store/actions';
import { emailSelector, phoneOrMobileSelector } from '../../../store/selectors';

import { ModalLayout } from '../../common';
import { SELECT_VALUES } from './constants';
import UploadFileModalForm from './UploadFileModalForm';
import UploadFileModalHeaderAnimation from './UploadFileModalHeaderAnimation';

const useStyles = makeStyles(theme => ({
  layout: {
    paddingBottom: 0
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column'
  },
  contentMobile: {
    marginTop: theme.spacing(10)
  },
  bottomMobileSpacing: {
    paddingBottom: theme.spacing(10)
  },
  stepper: {
    width: 271,
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)} 0 ${theme.spacing(
      2.5
    )}`,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  stepperMobile: {
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)} ${theme.spacing(
      5
    )} ${theme.spacing(2.5)}`
  }
}));

const UploadFileModalContent = ({ preSelectedType, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMobileWidth } = useAppWidthClass();
  const [modalStep, setModalStep] = useState(0);
  const [hasFiles, setHasFiles] = useState(false);
  const [hasBottomMobileSpacing, setHasBottomMobileSpacing] = useState(false);

  const mobileOrPhone = useSelector(phoneOrMobileSelector);
  const email = useSelector(emailSelector);

  const handleSubmit = ({
    description,
    files,
    type,
    method,
    remember,
    ...rest
  }) => {
    dispatch(
      uploadFile({
        description,
        type,
        files,
        remember,
        contactType: method,
        contactValue: rest[method].value || rest[method]
      })
    );
  };

  return (
    <ModalLayout
      className={classes.layout}
      title="UPLOAD_FILE_DIALOG_TITLE"
      headingVariant="h3"
      headingClass={classes.title}
      onClose={onClose}
      closeButtonId="postbox.upload.close"
    >
      <UploadFileModalHeaderAnimation
        modalStep={modalStep}
        hasFiles={hasFiles}
      />
      <div
        className={clsx(
          classes.content,
          isMobileWidth && classes.contentMobile,
          hasBottomMobileSpacing && classes.bottomMobileSpacing
        )}
      >
        <Text
          translated
          align="center"
          component="h3"
          variant="h2"
          color="textPrimary"
        >
          {
            [
              'UPLOAD_FILE_TITLE',
              'UPLOAD_CHOOSEFILE_TITLE',
              'UPLOAD_MESSAGE_TITLE',
              'RESPONSE_CHANNEL_HEADER'
            ][modalStep]
          }
        </Text>
        <Stepper
          className={isMobileWidth ? classes.stepperMobile : classes.stepper}
          stepCount={4}
          activeStep={modalStep}
        />
        <Formik
          validateOnMount
          initialValues={{
            description: '',
            files: [],
            type: preSelectedType || SELECT_VALUES[0].value,
            method: null,
            [SMS_FIELD]: parsePhoneFromString(mobileOrPhone),
            [EMAIL_FIELD]: email,
            [WHATS_APP_FIELD]: parsePhoneFromString(mobileOrPhone),
            remember: false
          }}
          onSubmit={handleSubmit}
        >
          {({ values, isValid, setFieldValue, handleSubmit, touched }) => {
            useEffect(() => {
              setHasFiles(values.files.length > 0);
            }, [values.files]);
            return (
              <UploadFileModalForm
                values={values}
                isValid={isValid}
                setFieldValue={setFieldValue}
                touched={touched}
                handleSubmit={handleSubmit}
                modalStep={modalStep}
                setModalStep={setModalStep}
                onClose={onClose}
                setHasBottomMobileSpacing={setHasBottomMobileSpacing}
              />
            );
          }}
        </Formik>
      </div>
    </ModalLayout>
  );
};

export default UploadFileModalContent;
