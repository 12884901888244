import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    alignItems: 'center'
  }
}));

const LayoutCircularProgess = ({ color, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.loader}>
      <CircularProgress color={color || 'primary'} {...props} />
    </Box>
  );
};

export default LayoutCircularProgess;
