import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { get, post } from '../../utils/httpUtil';
import { callHttp } from '../../utils/api';
import { GET_QUESTIONS, SEND_ANSWERS } from '../../constants/api';

import { handleError, setQuestions, setShowGoogleLink } from '../actions';

import { THANKS } from '../../constants/routes';
import { DOMAIN_LANGUAGE } from '../../constants/browser';

export function* getQuestions() {
  try {
    const data = yield callHttp(get, GET_QUESTIONS(DOMAIN_LANGUAGE));
    yield put(setQuestions(data.surveyList));
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* sendAnswers({ payload: { answers } }) {
  const body = {
    surveyResultList: {
      surveyResultList: answers
    }
  };

  try {
    yield callHttp(post, SEND_ANSWERS, body);

    const showGoogleLink =
      answers &&
      answers.length &&
      answers.reduce((acc, { value }) => acc + value, 0) / answers.length >= 4;

    yield put(setShowGoogleLink(showGoogleLink));

    yield put(push(THANKS));
  } catch (error) {
    yield put(handleError(error));
  }
}
