import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { useAppWidthClass } from '../../../utils/hooks';
import ContactsBarContent from './ContactsBarContent';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    zIndex: 100,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    boxShadow: theme.customShadows.menu,
    background: theme.palette.secondary.contrastText
  },
  content: {
    height: 64,
    paddingTop: 8,
    overflow: 'scroll',
    justifyContent: 'unset',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const ContactsBarMobile = ({ items }) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  return !isMobileWidth ? null : (
    <Box className={clsx(classes.root)}>
      <ContactsBarContent className={classes.content} items={items} />
    </Box>
  );
};

export default ContactsBarMobile;
