import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import makeStyles from '@mui/styles/makeStyles';

import {
  isFileUploadModalOpenedSelector,
  isFileUploadSuccessfulSelector
} from '../../../store/selectors';
import { setFileModalOpened } from '../../../store/actions';
import { useAppWidthClass } from '../../../utils/hooks';
import { isMobileDevice } from '../../../utils/browser';

import { Modal, ModalElement } from '../../common';

import UploadFileModalContent from './UploadFileModalContent';
import UploadFileModalConfirmation from '../UploadFileModalConfirmation';

const useStyles = makeStyles(() => ({
  modal: {
    overflowX: 'hidden'
  }
}));

const UploadFileModal = ({ preSelectedType }) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();
  const isMobilePhone = useMemo(() => isMobileDevice(), [isMobileDevice]);
  const dispatch = useDispatch();
  const [showSubmitAnimation, setShowSubmitAnimation] = useState(false);

  const isModalOpened = useSelector(isFileUploadModalOpenedSelector);
  const isUploadSuccessful = useSelector(isFileUploadSuccessfulSelector);

  useEffect(() => {
    if (!showSubmitAnimation && isUploadSuccessful) {
      setShowSubmitAnimation(true);
    }
  }, [showSubmitAnimation, isUploadSuccessful]);

  const onUploadModalClose = () => {
    dispatch(setFileModalOpened(false));
    setShowSubmitAnimation(false);
  };

  const closeAction = showSubmitAnimation ? undefined : onUploadModalClose;

  return (
    <Modal
      withFullSizePaper
      isOpen={isModalOpened}
      onClose={closeAction}
      onPaperClick={isMobilePhone ? undefined : closeAction}
    >
      <AnimateSharedLayout>
        <AnimatePresence>
          {!showSubmitAnimation && (
            <motion.div
              layoutId="upload"
              style={{
                width: isMobileWidth ? 375 : 754,
                maxWidth: '100%'
              }}
            >
              <ModalElement className={classes.modal}>
                <UploadFileModalContent
                  preSelectedType={preSelectedType}
                  onClose={closeAction}
                />
              </ModalElement>
            </motion.div>
          )}
          {showSubmitAnimation && (
            <motion.div layoutId="upload" transition={{ duration: 0.3 }}>
              <ModalElement>
                <UploadFileModalConfirmation onClose={onUploadModalClose} />
              </ModalElement>
            </motion.div>
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </Modal>
  );
};

export default UploadFileModal;
