import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';

import history from './config/history';
import logger from './utils/logger';

const routeMiddleware = routerMiddleware(history);
export const sagaMiddleware = createSagaMiddleware();

const createLoggerMiddleware = logger => store => next => action => {
  try {
    return next(action);
  } catch (e) {
    logger.onError(e, {
      action,
      type: 'redux'
    });
  }
};
const loggerMiddleware = createLoggerMiddleware(logger);

const middlewares = [sagaMiddleware, routeMiddleware, loggerMiddleware];
export default middlewares;
