import React, { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { bic, iban } from '../../../constants/regex';
import { onClickWrapper } from '../../../utils/ui';
import { TextWithLinksProps } from '../index';

interface PostBoxItemAddCopyIbanBicProps {
  text: string;
  className: string;
  onCopy: () => void;
  mountCallback?: () => void;
}

const LINK_ID = 'case-view.communication.notification.link';

const PostBoxItemAddCopyIbanBic: React.FC<PostBoxItemAddCopyIbanBicProps> = ({
  text,
  className,
  onCopy,
  mountCallback
}): React.ReactElement => {
  useEffect(() => {
    if (mountCallback) {
      mountCallback();
    }
  }, []);

  const copyRegex = new RegExp(iban.source + '[,\\n] BIC:? ' + bic.source, 'g');

  let match;
  const matches = [];
  while ((match = copyRegex.exec(text))) {
    matches.push({ index: match.index, value: match[0] });
  }

  if (matches.length) {
    let lastIndex = 0;
    const elements = [];
    matches.forEach(({ index, value }) => {
      if (index > lastIndex) {
        elements.push(
          <TextWithLinksProps
            id={LINK_ID}
            text={text.substring(lastIndex, index)}
          />
        );
      }

      elements.push(
        <CopyToClipboard
          onCopy={(): void =>
            onClickWrapper(
              'case-view.communication.notification.copy-to-clipboard',
              onCopy
            )()
          }
          text={value}
        >
          <span className={className}>{value}</span>
        </CopyToClipboard>
      );
      lastIndex = index + value.length;
    });

    if (text.length > lastIndex) {
      elements.push(
        <TextWithLinksProps id={LINK_ID} text={text.substring(lastIndex)} />
      );
    }

    return elements.length === 1 ? (
      elements[0]
    ) : (
      <>
        {elements.map((el, idx) => ({
          ...el,
          key: idx
        }))}
      </>
    );
  } else {
    return <TextWithLinksProps id={LINK_ID} text={text} />;
  }
};

export default PostBoxItemAddCopyIbanBic;
