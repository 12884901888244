import { createAction } from 'redux-act';

export const set2FAInfo = createAction('set 2FA initial data');

export const set2FAModalOpen = createAction('set 2FA modal open state');

export const submit2FALogin = createAction('2FA Login');
export const handle2FALoginError = createAction('2FA Login error');

export const twoFAReset = createAction('reset 2FA state');
