import { put, select } from 'redux-saga/effects';

import { callHttp } from '../../utils/api';

import { GET_NOTIFICATIONS } from '../../constants/graphql';
import client from '../../config/graphql';
import { notificationsReceived } from '@troy/shared/src/store/actions';
import { handleError } from '../actions';
import { isTokenInternalSelector } from '../selectors';

export function* getNotifications() {
  try {
    const response = yield callHttp(client.query, { query: GET_NOTIFICATIONS });
    const customers = response.data.customers;
    if (customers) {
      const isTokenInternal = yield select(isTokenInternalSelector);
      yield put(
        notificationsReceived({
          items: customers[0].liabilities[0].serviceLog,
          isTokenInternal
        })
      );
    } else {
      throw new Error(response.errors.message);
    }
  } catch (err) {
    yield put(handleError(err));
  }
}
