import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { HOME } from '../../../constants/routes';
import { useAppWidthClass } from '../../../utils/hooks';
import {
  liabilityReturnGoodsPickupAddressDataSelector,
  liabilityReturnGoodsPickupContactSelector,
  liabilityReturnGoodsPickupDateSelector,
  liabilityReturnGoodsPickupTimeRangeSelector,
  translationsSelector
} from '../../../store/selectors';
import { NAVBAR_HOME_KEY } from '../../../store/reducers/ui';
import { Text } from '@troy/shared/src/components/common';

import { ThemeButton } from '../../../components/common';
import illustration from '../../../assets/img/rented-goods/img_paperplane.svg';

import { ReturnGoodsContactDetails, ReturnGoodsDateDetails } from '../index';

const useStyles = makeStyles(theme => ({
  titleRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  illustration: {
    height: 40
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto'
  },
  description: {
    marginTop: theme.spacing(4),
    textAlign: 'center'
  },
  section: {
    marginTop: theme.spacing(2)
  },
  pickup: {
    display: 'flex',
    alignItems: 'center'
  },
  date: {
    marginLeft: theme.spacing(1)
  },
  time: {
    marginLeft: theme.spacing(2)
  },
  line: {
    display: 'inline-block',
    margin: `${theme.spacing(1)} 0`,
    width: '100%',
    height: 1,
    background: theme.palette.text.disabled
  },
  confirmationText: {
    paddingTop: theme.spacing(2),
    textAlign: 'center'
  },
  textLeft: {
    textAlign: 'left'
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',

    '& > button': {
      width: 240
    }
  }
}));

const ReturnGoodsSummaryView = ({ contentClass }) => {
  const t = useSelector(translationsSelector);
  const classes = useStyles();
  const history = useHistory();
  const { isDesktopWidth } = useAppWidthClass();

  const { customerName, street, postCode, city, addressCountry } = useSelector(
    liabilityReturnGoodsPickupAddressDataSelector
  );

  const phone = useSelector(liabilityReturnGoodsPickupContactSelector);
  const pickupDate = useSelector(liabilityReturnGoodsPickupDateSelector);
  const time = useSelector(liabilityReturnGoodsPickupTimeRangeSelector);

  const onHomeClick = () => history.push(HOME);

  return (
    <>
      <Box className={classes.titleRow}>
        <img
          className={classes.illustration}
          src={illustration}
          alt="illustration"
        />
        <Text
          translated
          className={classes.title}
          component="h1"
          variant="h3"
          color="secondary"
        >
          BSH_RETURNGOODS_THANKYOU_HEADER
        </Text>
      </Box>
      <Box className={clsx(classes.content, contentClass)}>
        <Text
          className={clsx(
            classes.description,
            !isDesktopWidth && classes.textLeft
          )}
          component="p"
          variant="body1"
          color="textPrimary"
        >
          {(t['BSH_RETURNGOODS_THANKYOU_COPY'] || '').replace(
            '<FIRSTNAME>',
            customerName
          )}
        </Text>
        <Box className={classes.section}>
          <Text translated component="h2" variant="h3" color="textPrimary">
            BSH_RETURNGOODS_MICROCOPY_SUMMARY
          </Text>
        </Box>
        <ReturnGoodsDateDetails
          title="BSH_RETURNGOODS_WORKFLOW_SUBHEAD1"
          pickupDate={pickupDate}
          time={time}
        />
        <Box className={classes.section}>
          <Box className={classes.line} />
        </Box>
        <ReturnGoodsContactDetails
          title="BSH_RETURNGOODS_WORKFLOW_SUBHEAD2"
          customerName={customerName}
          street={street}
          postCode={postCode}
          city={city}
          addressCountry={addressCountry}
          phone={phone}
        />
        <Box className={classes.section}>
          <Text
            translated
            className={clsx(
              classes.confirmationText,
              !isDesktopWidth && classes.textLeft
            )}
            component="p"
            variant="body1"
            color="textPrimary"
          >
            BSH_RETURNGOODS_THANKYOU_QUESTIONS
          </Text>
        </Box>
        <Box className={classes.buttons}>
          <ThemeButton
            id="page.return-goods.summary.home-button"
            variant="contained"
            size="large"
            endIcon="chevronRight"
            endIconColor="primaryContrast"
            textKey={NAVBAR_HOME_KEY}
            onClick={onHomeClick}
          />
        </Box>
      </Box>
    </>
  );
};

export default ReturnGoodsSummaryView;
