import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { CustomIcon } from '@troy/shared/src/components/common';
import { svgFill } from '@troy/shared/src/utils/styles';

import { portalBrandNameSelector } from '../../../store/selectors';
import { onClickWrapper } from '../../../utils/ui';
import { Translation } from '../index';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flexShrink: 0,
    textTransform: 'none',
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    width 200ms linear,
    min-width 200ms linear,
    padding 200ms linear,
    margin 200ms linear,
    color 100ms ease-in-out`,

    '&:disabled': {
      background: theme.palette.text.disabled,
      color: theme.palette.primary.contrastText
    }
  },
  rootLoading: {
    minWidth: '40px !important',
    width: '40px !important',
    padding: '0px !important',
    pointerEvents: 'none !important',
    color: 'transparent !important'
  },
  body1Bold: {
    ...theme.typography.body1Bold
  },
  body2: {
    ...theme.typography.body2
  },
  subtitle1: {
    ...theme.typography.subtitle1
  },
  subtitle3: {
    ...theme.typography.subtitle3
  },
  medium: {
    padding: '0 8px',
    height: 32,
    borderRadius: Number(theme.shape.borderRadius) * 2
  },
  mediumText: {
    padding: '0 8px',
    height: 32,
    borderRadius: Number(theme.shape.borderRadius) * 2
  },
  mediumContained: {
    padding: '0 28px',
    height: 32,
    borderRadius: Number(theme.shape.borderRadius) * 4
  },
  mediumOutlined: {
    padding: '0 27px',
    height: 32,
    borderRadius: Number(theme.shape.borderRadius) * 4
  },
  large: {
    height: 40,
    padding: '0 24px',
    borderRadius: Number(theme.shape.borderRadius) * 2
  },
  largeContained: {
    minWidth: 174,
    height: 40,
    padding: '0 24px',
    borderRadius: Number(theme.shape.borderRadius) * 6
  },
  largeOutlined: {
    minWidth: 174,
    height: 40,
    padding: '0 23px',
    borderRadius: Number(theme.shape.borderRadius) * 6
  },
  largeText: {
    minWidth: 98,
    height: 40,
    padding: '0 19px',
    borderRadius: Number(theme.shape.borderRadius) * 6
  },
  primaryContained: {
    '&:hover': {
      background: theme.palette.primary.light,
      color: theme.palette.secondary.dark,

      ...svgFill(theme.transitions.custom.fast, theme.palette.secondary.dark)
    }
  },
  secondaryContained: {
    '&:hover': {
      background: theme.palette.secondary.light
    }
  },
  secondaryOutlined: {
    borderColor: theme.palette.secondary.main
  },
  primaryLightOutlined: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.light
  },
  secondaryText: {
    '&:disabled': {
      background: theme.palette.background.default,
      color: theme.palette.text.disabled
    }
  },
  hint: {
    color: theme.palette.text.hint
  },
  hintText: {
    color: theme.palette.text.hint
  },
  hintOutlined: {
    color: theme.palette.text.hint
  },
  textSecondary: {
    color: theme.palette.text.secondary
  },
  textPrimary: {
    color: theme.palette.text.primary
  },
  icon: {
    marginLeft: 0
  },
  loader: {
    display: 'flex',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',

    '& circle': {
      stroke: theme.palette.primary.contrastText
    }
  },
  '@keyframes textIn': {
    '0%': {
      position: 'absolute',
      overflow: 'visible',
      opacity: 0,
      whiteSpace: 'nowrap'
    },
    '99%': {
      position: 'absolute',
      overflow: 'visible',
      whiteSpace: 'nowrap'
    },
    '100%': {
      position: 'static',
      opacity: 1
    }
  },
  '@keyframes textOut': {
    from: {
      position: 'absolute',
      whiteSpace: 'nowrap',
      overflow: 'visible',
      opacity: 1
    },
    to: {
      position: 'absolute',
      whiteSpace: 'nowrap',
      overflow: 'visible',
      opacity: 0
    }
  },
  textAnimateIn: {
    animation: '$textIn',
    animationDuration: '200ms',
    animationIterationCount: '1',
    animationFillMode: 'forwards'
  },
  textAnimateOut: {
    animation: '$textOut',
    animationDuration: '200ms',
    animationIterationCount: '1',
    animationFillMode: 'forwards'
  },
  fullWidth: {
    width: '100%'
  },
  rootNutun: {
    background: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.text.primary,
    borderRadius: Number(theme.shape.borderRadius) * 2,

    '&:hover': {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,

      ...svgFill(
        theme.transitions.custom.fast,
        theme.palette.primary.contrastText
      )
    }
  },
  primaryLightNutun: {
    borderColor: theme.palette.primary.light,

    '&:hover': {
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,

      ...svgFill(
        theme.transitions.custom.fast,
        theme.palette.primary.contrastText
      )
    }
  },
  secondaryNutun: {
    background: theme.palette.secondary.contrastText,
    borderColor: theme.palette.secondary.main,

    '&:hover': {
      background: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,

      ...svgFill(
        theme.transitions.custom.fast,
        theme.palette.secondary.contrastText
      )
    }
  },
  disabledNutun: {
    border: `${theme.palette.text.secondary} !important`,
    color: `${theme.palette.text.secondary} !important`
  },
  loadingNutun: {
    border: 'transparent',

    '& circle': {
      stroke: theme.palette.primary.main
    }
  }
}));

const ThemeButton = React.forwardRef(
  (
    {
      className,
      id,
      component,
      textKey,
      color = 'primary',
      size = 'large',
      variant,
      fontStyle = 'body1Bold',
      onClick,
      onMouseOver,
      onMouseLeave,
      onFocus,
      onBlur,
      type = 'button',
      startIcon,
      startIconColor,
      endIcon,
      endIconColor,
      iconClassName,
      startElement,
      endElement,
      disabled,
      hasAnimation,
      isLoading,
      fullWidth
    },
    ref
  ) => {
    const classes = useStyles();
    const brandName = useSelector(portalBrandNameSelector);

    let colorClass;
    let sizeClass;
    let extraClasses;
    let disableRipple = false;
    let startIconColorVariant = startIconColor;
    let endIconColorVariant = endIconColor;

    const useNormalStyles =
      size === 'medium' && fontStyle.startsWith('subtitle');

    if (brandName === 'Nutun' && !useNormalStyles) {
      disableRipple = true;
      startIconColorVariant = disabled ? 'textSecondary' : 'textPrimary';
      endIconColorVariant = disabled ? 'textSecondary' : 'textPrimary';
      const variantNew = variant === 'outlined' ? 'contained' : variant;
      let variantCapitalized = '';
      if (variantNew) {
        variantCapitalized =
          variantNew.charAt(0).toUpperCase() + variantNew.slice(1);
      }
      sizeClass = `${size}${variantCapitalized}`;
      extraClasses = clsx(
        classes.rootNutun,
        classes[`${size}Nutun`],
        classes[`${color}Nutun`],
        disabled && classes.disabledNutun,
        isLoading && classes.loadingNutun
      );
    } else {
      let variantCapitalized = '';
      if (variant) {
        variantCapitalized = variant.charAt(0).toUpperCase() + variant.slice(1);
      }
      colorClass = `${color}${variantCapitalized}`;
      sizeClass = `${size}${variantCapitalized}`;
    }

    return (
      <Button
        ref={ref}
        disableElevation
        disableRipple={disableRipple}
        variant={variant}
        id={id}
        classes={{
          root: clsx(
            classes.root,
            classes[fontStyle],
            classes[sizeClass],
            classes[colorClass],
            extraClasses,
            className,
            isLoading && classes.rootLoading,
            fullWidth && classes.fullWidth
          ),
          startIcon: classes.icon
        }}
        color={color === 'primary' || color === 'secondary' ? color : 'inherit'}
        component={component}
        onClick={
          isLoading ? undefined : id ? onClickWrapper(id, onClick) : onClick
        }
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type}
        startIcon={
          startIcon && !(hasAnimation && isLoading) ? (
            <CustomIcon
              className={clsx(classes.icon, iconClassName)}
              icon={startIcon}
              variant={startIconColorVariant}
              size="xs"
            />
          ) : (
            startElement
          )
        }
        endIcon={
          endIcon && !(hasAnimation && isLoading) ? (
            <CustomIcon
              className={clsx(classes.icon, iconClassName)}
              icon={endIcon}
              variant={endIconColorVariant}
              size="xs"
            />
          ) : (
            endElement
          )
        }
        disabled={disabled}
      >
        <Translation
          keyIfNoValue
          inline
          noWrap
          className={clsx(
            hasAnimation && !isLoading && classes.textAnimateIn,
            hasAnimation && isLoading && classes.textAnimateOut
          )}
        >
          {textKey}
        </Translation>
        <Fade in={isLoading} timeout={{ enter: 200, exit: 100 }} unmountOnExit>
          <Box className={classes.loader}>
            <CircularProgress size={30} />
          </Box>
        </Fade>
      </Button>
    );
  }
);

export default ThemeButton;
