import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useAppWidthClass } from '../../../utils/hooks';
import { Translation } from '../../common';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.text.primary
  },
  rootCompact: {
    ...theme.typography.body2
  }
}));

const PaymentFormText = ({ children }) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  return (
    <Translation
      className={clsx(classes.root, isMobileWidth && classes.rootCompact)}
    >
      {children}
    </Translation>
  );
};

export default PaymentFormText;
