import GA4React from 'ga-4-react';
import { isDomainNL } from '../constants/browser';
import { isDomainBE } from '../constants/browser';

export const createGA4 = () => {
  return new GA4React(
    process.env[`REACT_APP_GA4_ID_${(isDomainNL() || isDomainBE()) ? 'NL' : 'DE'}`],
    {},
    [],
    5000,
    { nonce: ['troy-script-is-async', 'troy-second-script'] } // static nonces for now
  );
};
