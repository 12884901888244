import React from 'react';
import IBAN from 'iban';
import { Form, Formik } from 'formik';
import { addDays, addMonths } from 'date-fns';

import { Button, Checkbox, Paper } from '@troy/shared/src/components/common';

import { DatePicker, TextField, Translation } from '../../common';

const ibanFormatter = iban => {
  if (!iban) return '';
  return IBAN.printFormat(iban, ['-']);
};

const ibanCheck = iban => iban && IBAN.isValid(iban);
const nameCheck = name => !!name;
const dateCheck = date => !!date;

const ibanValidator = iban => (ibanCheck(iban) ? undefined : 'Invalid IBAN');
const nameValidator = (name = '') => (nameCheck(name) ? undefined : 'Required');
const agreeValidator = agree => (agree ? undefined : 'Must be agreed');
const dateValidator = date => (dateCheck(date) ? undefined : 'Required');

const startDate = addDays(new Date(), 6);
const endDate = addMonths(new Date(), 1);

const weekends = (() => {
  const weekendsBetweenDates = [];
  for (let date = startDate; date <= endDate; date = addDays(date, 1)) {
    if (date.getDay() % 6 === 0) {
      weekendsBetweenDates.push(date);
    }
  }
  return weekendsBetweenDates;
})();

const PaymentCalculator = ({ styles, id, onSubmit, className, formRef }) => {
  return (
    <div
      className={className || styles['recommend-help-calculator__form-wrapper']}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          iban: '',
          accountHolder: '',
          date: null,
          agree: false
        }}
        onSubmit={({ date, ...values }) =>
          onSubmit({
            ...values,
            date: [date.getFullYear(), date.getMonth() + 1, date.getDate()]
          })
        }
      >
        {({ values, errors }) => {
          return (
            <Form className={styles['recommend-help-calculator__form']}>
              <div className={styles['recommend-help-calculator__row']}>
                <Translation
                  className={styles['recommend-help-calculator__label']}
                >
                  SEPA_DEBIT_PAYMENT_PANEL_IBAN_TEXT
                </Translation>
                <div
                  className={
                    styles['recommend-help-calculator__input-error-wrapper']
                  }
                >
                  <TextField
                    name="iban"
                    placeholder="DE00 0000 0000 0000 0000 00"
                    className={
                      styles['recommend-help-calculator__input-wrapper']
                    }
                    classNameInput={
                      styles[
                        !values.iban || ibanCheck(values.iban)
                          ? 'recommend-help-calculator__input-field'
                          : 'recommend-help-calculator__input-field-red'
                      ]
                    }
                    classNameRoot={
                      styles['recommend-help-calculator__input-root']
                    }
                    onFormat={ibanFormatter}
                    validate={ibanValidator}
                  />
                  {values.iban && !ibanCheck(values.iban) && (
                    <div
                      className={
                        styles['recommend-help-calculator__input-error']
                      }
                    >
                      Die eingegebene IBAN ist nicht gültig
                    </div>
                  )}
                </div>
              </div>

              <div className={styles['recommend-help-calculator__row']}>
                <Translation
                  className={styles['recommend-help-calculator__label']}
                >
                  SEPA_DEBIT_PAYMENT_PANEL_OWNER
                </Translation>
                <TextField
                  name="accountHolder"
                  className={styles['recommend-help-calculator__input-wrapper']}
                  classNameInput={
                    styles[
                      nameCheck(values.accountHolder)
                        ? 'recommend-help-calculator__input-field'
                        : 'recommend-help-calculator__input-field-red'
                    ]
                  }
                  classNameRoot={
                    styles['recommend-help-calculator__input-root']
                  }
                  validate={nameValidator}
                />
              </div>

              <div className={styles['recommend-help-calculator__row']}>
                <Translation
                  className={styles['recommend-help-calculator__label']}
                >
                  SEPA_DEBIT_PAYMENT_PANEL_MOVE_IN_DATE
                </Translation>
                <DatePicker
                  name="date"
                  id={`${id}.date-picker`}
                  validate={dateValidator}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <TextField
                      className={
                        styles['recommend-help-calculator__input-wrapper']
                      }
                      classNameInput={
                        styles[
                          !values.date || dateCheck(values.date)
                            ? 'recommend-help-calculator__input-field'
                            : 'recommend-help-calculator__input-field-red'
                        ]
                      }
                      classNameRoot={
                        styles['recommend-help-calculator__input-root']
                      }
                      readonly
                    />
                  }
                  className={
                    styles['recommend-help-calculator__datepicker-wrapper']
                  }
                  minDate={startDate}
                  maxDate={endDate}
                  excludeDates={weekends}
                />
              </div>

              <div>
                <Paper
                  className={styles['recommend-help-calculator__text-area']}
                >
                  <Translation>SEPA_MANDATE_LEGAL</Translation>
                </Paper>
              </div>

              <div className={styles['recommend-help-calculator__checkbox']}>
                <div
                  className={
                    styles['recommend-help-calculator__checkbox-description']
                  }
                >
                  <Translation>SEPA_MANDATE_CHECKBOX</Translation>
                </div>

                <Checkbox
                  className={
                    styles['recommend-help-calculator__checkbox-input']
                  }
                  name="agree"
                  id={`${id}.agree-checkbox`}
                  validate={agreeValidator}
                />
              </div>

              <Button
                id={`${id}.button`}
                className={styles['recommend-help-calculator__button']}
                type="submit"
                disabled={Object.values(errors).length}
              >
                <Translation>SEPA_DEBIT_PAYMENT_PANEL_BUTTON_TEXT</Translation>
              </Button>
            </Form>
          );
        }}
      </Formik>
      <div className={styles['recommend-help-calculator__remark-wrapper']}>
        <div className={styles['recommend-help-calculator__remark']}>
          <Translation>DIRECT_DEBIT_FINEPRINT</Translation>
        </div>
      </div>
    </div>
  );
};

export default PaymentCalculator;
