import React from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { notEmptyValidator } from '../../../utils/validation';
import { DOMAIN_LANGUAGE } from '../../../constants/browser';
import { onPhoneKeyPress } from '../../../utils/phone';
import { translationsSelector } from '../../../store/selectors';

import Modal from '../../common/Modal';
import { CustomIcon, Text } from '@troy/shared/src/components/common';

import { PhoneInput, ThemeButton } from '../../common';
import {
  PaymentFormInput,
  PaymentFormRow,
  PaymentFormRowContent,
  PaymentFormRowLabel
} from '../../payments/index.troy';
import { Form, Formik } from 'formik';

const useStyles = makeStyles(theme => ({
  modal: {
    width: 309,
    margin: theme.spacing(2),
    padding: `${theme.spacing(8)} ${theme.spacing(2)} ${theme.spacing(
      2
    )} ${theme.spacing(2)}`,
    borderRadius: theme.shape.borderRadius * 6,
    boxShadow: theme.customShadows.active
  },
  close: {
    position: 'absolute',
    top: 8,
    right: 8,
    padding: 8
  },
  title: {
    textAlign: 'center'
  },
  description: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  fields: {
    paddingTop: theme.spacing(3)
  },
  submit: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
  phoneField: {
    width: '100%',

    '& ul': {
      top: 'unset !important'
    }
  }
}));

const ReturnGoodsDetailsModalContent = ({ onSubmit, isOpen, onClose }) => {
  const classes = useStyles();
  const t = useSelector(translationsSelector);

  return (
    <Modal paperClassName={classes.modal} isOpen={isOpen} onClose={onClose}>
      {onClose && (
        <IconButton
          id="return-goods.modal.close"
          color="default"
          className={classes.close}
          onClick={onClose}
          size="large"
        >
          <CustomIcon icon="dismiss" variant="hint" size="small" />
        </IconButton>
      )}
      <Text
        translated
        className={classes.title}
        component="h2"
        variant="h3"
        color="secondary"
      >
        BSH_RETURNGOODS_CHANGEDATA_HEADER
      </Text>
      <Text
        translated
        className={classes.description}
        component="p"
        variant="body1"
        color="textPrimary"
      >
        BSH_RETURNGOODS_CHANGEDATA_COPY
      </Text>
      <Box className={classes.fields}>
        <Formik
          initialValues={{
            customerName: '',
            street: '',
            postCode: '',
            city: '',
            country: '',
            phone: ''
          }}
          onSubmit={values => onSubmit(values)}
        >
          {({ values, isValid }) => {
            return (
              <Form className={classes.fields}>
                <PaymentFormRow compact smallMargin>
                  <PaymentFormRowLabel htmlFor="customerName" compact>
                    {t && t['TCM_CUSTOMERNAME']}
                  </PaymentFormRowLabel>
                  <PaymentFormRowContent compact>
                    <PaymentFormInput
                      id="customerName"
                      name="customerName"
                      placeholder={t && t['TCM_CUSTOMERNAME']}
                      validator={notEmptyValidator}
                      error={notEmptyValidator(values.customerName)}
                      errorMessage={'BSH_RETURNGOODS_WORKFLOW_ERRORS'}
                    />
                  </PaymentFormRowContent>
                </PaymentFormRow>
                <PaymentFormRow compact smallMargin>
                  <PaymentFormRowLabel htmlFor="street" compact>
                    {t && t['TCM_CUSTOMER_STREET']}
                  </PaymentFormRowLabel>
                  <PaymentFormRowContent compact>
                    <PaymentFormInput
                      id="street"
                      name="street"
                      placeholder={t && t['TCM_CUSTOMER_STREET']}
                      validator={notEmptyValidator}
                      error={notEmptyValidator(values.street)}
                      errorMessage={'BSH_RETURNGOODS_WORKFLOW_ERRORS'}
                    />
                  </PaymentFormRowContent>
                </PaymentFormRow>
                <PaymentFormRow compact smallMargin>
                  <PaymentFormRowLabel htmlFor="postCode" compact>
                    {t && t['TCM_CUSTOMER_ZIPCODE']}
                  </PaymentFormRowLabel>
                  <PaymentFormRowContent compact>
                    <PaymentFormInput
                      id="postCode"
                      name="postCode"
                      placeholder={t && t['TCM_CUSTOMER_ZIPCODE']}
                      validator={notEmptyValidator}
                      error={notEmptyValidator(values.postCode)}
                      errorMessage={'BSH_RETURNGOODS_WORKFLOW_ERRORS'}
                    />
                  </PaymentFormRowContent>
                </PaymentFormRow>
                <PaymentFormRow compact smallMargin>
                  <PaymentFormRowLabel htmlFor="city" compact>
                    {t && t['TCM_CUSTOMER_CITY']}
                  </PaymentFormRowLabel>
                  <PaymentFormRowContent compact>
                    <PaymentFormInput
                      id="city"
                      name="city"
                      placeholder={t && t['TCM_CUSTOMER_CITY']}
                      validator={notEmptyValidator}
                      error={notEmptyValidator(values.city)}
                      errorMessage={'BSH_RETURNGOODS_WORKFLOW_ERRORS'}
                    />
                  </PaymentFormRowContent>
                </PaymentFormRow>
                <PaymentFormRow compact smallMargin>
                  <PaymentFormRowLabel htmlFor="country" compact>
                    {t && t['TCM_CUSTOMER_COUNTRY']}
                  </PaymentFormRowLabel>
                  <PaymentFormRowContent compact>
                    <PaymentFormInput
                      id="country"
                      name="country"
                      placeholder={t && t['TCM_CUSTOMER_COUNTRY']}
                      validator={notEmptyValidator}
                      error={notEmptyValidator(values.country)}
                      errorMessage={'BSH_RETURNGOODS_WORKFLOW_ERRORS'}
                    />
                  </PaymentFormRowContent>
                </PaymentFormRow>
                <PaymentFormRow compact smallMargin>
                  <PaymentFormRowLabel htmlFor="phone" compact>
                    TCM_CUSTOMER_PHONE
                  </PaymentFormRowLabel>
                  <PaymentFormRowContent compact>
                    <PhoneInput
                      containerClassName={classes.phoneField}
                      id="phone"
                      name="phone"
                      preferredCountries={[DOMAIN_LANGUAGE.toLowerCase()]}
                      formatOnInit={false}
                      error={!values.phone.isValid}
                      errorMessage={'RESPONSE_CHANNEL_NEW_PHONE'}
                      onKeyPress={onPhoneKeyPress}
                    />
                  </PaymentFormRowContent>
                </PaymentFormRow>
                <ThemeButton
                  className={classes.submit}
                  id="page.return-goods.details-modal.confirm-button"
                  disabled={!isValid || !values.phone.isValid}
                  type="submit"
                  variant="contained"
                  size="large"
                  endIcon="confirm"
                  endIconColor="primaryContrast"
                  textKey={'TCM_COMMUNICATION_SAVE'}
                />
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
};

export default ReturnGoodsDetailsModalContent;
