import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useAppWidthClass } from '../../../utils/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 24,
    flexGrow: 1
  },
  compact: {
    margin: '8px auto 0 0',
    width: '100%'
  }
}));

const PaymentFormRowContent = ({ children, compact }) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  return (
    <Box
      className={clsx(
        classes.root,
        (compact || isMobileWidth) && classes.compact
      )}
    >
      {children}
    </Box>
  );
};

export default PaymentFormRowContent;
