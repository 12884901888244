import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useAppWidthClass } from '../../../utils/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    boxShadow: theme.customShadows.module,
    borderRadius: theme.shape.borderRadius * 6,
    color: theme.palette.text.primary,
    width: 'calc(100% - 64px)',
    maxWidth: 690,
    maxHeight: 'calc(100vh - 64px)',
    margin: 32,
    overflowY: 'auto'
  },
  rootSmall: {
    width: 'calc(100% - 32px)',
    margin: 16
  }
}));

const ModalElement = ({ className, children }) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  return (
    <Box
      className={clsx(
        classes.root,
        isMobileWidth && classes.rootSmall,
        className
      )}
    >
      {children}
    </Box>
  );
};

export default ModalElement;
