import React from 'react';
import PropTypes from 'prop-types';

import MaterialTabs from '@mui/material/Tabs';
import MaterialTab from '@mui/material/Tab';

import Link from '../Link';
import { onClickWrapper } from '../../../utils/ui';
import { connectTo } from '../../../utils';

const Tab = props => (
  <MaterialTab {...props} onClick={onClickWrapper(props.id, props.onClick)} />
);

const LinkTab = ({ route, ...props }) => (
  <MaterialTab {...props} component={Link} to={route} />
);

Tab.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  value: PropTypes.any,
  id: PropTypes.string
};

LinkTab.propTypes = {
  ...Tab.propTypes,
  to: PropTypes.string
};

const Tabs = ({
  className,
  value,
  onChange,
  options,
  labelClassName,
  scrollable,
  iconClassName,
  indicatorClassName,
  selectedClassName,
  tabClassName,
  flexContainerClassName,
  translated,
  isMobile,
  translations
}) => {
  const hasScroll = scrollable ? { scrollable, scrollButtons: 'auto' } : {};
  const tabClasses = {
    root: tabClassName,
    wrapper: labelClassName,
    selected: selectedClassName
  };
  const tabsClasses = {
    indicator: indicatorClassName,
    flexContainer: flexContainerClassName
  };

  return (
    <MaterialTabs
      className={className}
      value={value}
      onChange={onChange}
      {...hasScroll}
      classes={tabsClasses}
    >
      {options.map(({ icon: Icon, label, id, value, route }, key) => {
        const imgIcon = Icon ? <Icon className={iconClassName} /> : null;
        const props = {
          ...{ key, id, value, route },
          label: isMobile ? '' : translated ? translations[label] || '' : label,
          icon: imgIcon,
          classes: tabClasses
        };
        return route ? <LinkTab {...props} /> : <Tab {...props} />;
      })}
    </MaterialTabs>
  );
};

Tabs.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  iconClassName: PropTypes.string
};

export default connectTo(
  ({ translations: { translations } }) => ({
    translations
  }),
  {},
  Tabs
);
