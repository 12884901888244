import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { set2FAModalOpen } from '../../../store/actions';
import { required } from '../../../utils/validation';
import { LOGIN } from '../../../constants/routes';
import {
  TWO_FA_TYPE_CLIENT_UNIQUE_ID,
  TWO_FA_TYPE_ZIPCODE
} from '../../../constants/2fa';

import { Text, TextInput } from '@troy/shared/src/components/common';
import { Link, ThemeButton } from '../../common';

const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(4)
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(5)
  },
  controls: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(1)
    },
    '& a': {
      textDecoration: 'none'
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5)
    },
    textAlign: 'center'
  },
  submitAnimated: {
    width: 'initial'
  },
  helpWrapper: {
    textAlign: 'center'
  }
}));

const ZIP_CODE_CHARS_REGEX = /^[0-9 \-A-Za-z]*$/;

const PREFIX_MAP = {
  [TWO_FA_TYPE_ZIPCODE]: 'ZIP_',
  [TWO_FA_TYPE_CLIENT_UNIQUE_ID]: 'SSN_'
};

const TwoFAForm = ({
  onSubmit,
  onLoginHelpClick,
  type,
  attempt = 0,
  isBlocked,
  isLoading
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const submitRef = useRef();

  const handleClose = () => dispatch(set2FAModalOpen(false));

  const invalidSubmit = !isLoading && attempt !== 0;

  const onTwoFAKeyPress = useCallback(event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (type === TWO_FA_TYPE_ZIPCODE && !ZIP_CODE_CHARS_REGEX.test(keyValue)) {
      event.preventDefault();
    }
  }, []);

  return (
    <Formik
      initialValues={{ '2fa-code': '' }}
      onSubmit={values => {
        onSubmit(values['2fa-code']);
      }}
    >
      <Form className={classes.form}>
        <Text
          translated
          className={classes.text}
          component="p"
          variant="body1"
          color={invalidSubmit ? 'warningDark' : 'textPrimary'}
        >
          {!invalidSubmit
            ? 'ZIP_LOGIN_WELCOME'
            : `${PREFIX_MAP[type]}LOGIN_ATTEMPT${Math.min(attempt, 3)}_ERROR`}
        </Text>
        {isBlocked ? (
          <Text
            translated
            className={classes.text}
            component="p"
            variant="body1"
            color="textPrimary"
          >
            {type === TWO_FA_TYPE_CLIENT_UNIQUE_ID
              ? 'TWOFACTOR_LOGIN_FAILED'
              : 'ZIP_LOGIN_REGULAR_LOGIN_TEXT'}
          </Text>
        ) : (
          <TextInput
            withBorder
            required
            id="2fa-code-form-2fa-code"
            onKeyPress={onTwoFAKeyPress}
            className={classes.input}
            color={invalidSubmit ? 'warningDark' : undefined}
            startIconVariant={invalidSubmit ? 'warningDark' : undefined}
            name="2fa-code"
            placeholder={`${PREFIX_MAP[type]}LOGIN_FORMHINT`}
            startIcon="key"
            validate={required}
            onEnterPress={() =>
              !isBlocked && !isLoading && submitRef.current.click()
            }
          />
        )}
        <div className={classes.controls}>
          {!isBlocked && (
            <ThemeButton
              fullWidth
              hasAnimation
              ref={submitRef}
              className={clsx(
                classes.submit,
                isLoading ? classes.submitAnimated : null
              )}
              id="page.home.component.2fa-code-form.submit-button"
              type="submit"
              variant="contained"
              textKey="Login"
              isLoading={isLoading}
            />
          )}
          <Link
            to={LOGIN}
            id="page.home.component.2fa-code-form.regular-login-button"
          >
            <ThemeButton
              fullWidth
              id="page.home.component.2fa-code-form.regular-login-button"
              variant={isBlocked ? 'contained' : 'outlined'}
              color="primary"
              textKey="REGULAR_LOGIN_BUTTON_LABEL"
              startIcon={isBlocked ? null : 'chevronLeft'}
              startIconColor={isBlocked ? null : 'primary'}
              endIcon={!isBlocked ? null : 'chevronRight'}
              endIconColor={!isBlocked ? null : 'primaryContrast'}
              onClick={handleClose}
            />
          </Link>
        </div>
        {!isBlocked && (
          <div className={classes.helpWrapper}>
            <ThemeButton
              onClick={onLoginHelpClick}
              id="page.home.component.2fa-code-form.help-button"
              size="medium"
              textKey="ZIP_LOGIN_HELP_BUTTON_LABEL"
              startIcon="questionIcon"
              color="hint"
              startIconColor="hint"
            />
          </div>
        )}
      </Form>
    </Formik>
  );
};

export default TwoFAForm;
