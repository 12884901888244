import React from 'react';

import Notification from '../Notification/index.sap';

const NotificationList = ({ notifications, authToken, searchText }) => (
  <div className="notification-list__wrapper">
    {notifications && notifications.length
      ? notifications.map(
          (
            { type, text, datetime, mainDoc, docs, subject, channel },
            index
          ) => (
            <Notification
              {...{
                type,
                text,
                datetime,
                mainDoc,
                docs,
                subject,
                channel
              }}
              authToken={authToken}
              key={index}
              searchText={searchText}
            />
          )
        )
      : null}
  </div>
);

export default NotificationList;
