import { createAction } from 'redux-act';

export const login = createAction('Login');
export const loginOk = createAction('Login successful');
export const loginError = createAction('Login error');
export const logout = createAction('Logout');
export const logoutOk = createAction('Logout succesful');
export const setToken = createAction('Set token');
export const setAuthTokenLoading = createAction('Set auth token loading');
export const removeToken = createAction('Remove token');
export const getJWTOnOneTimeToken = createAction(
  'Get JWT token on OneTime token'
);
export const setAuthData = createAction('Set authentication data');
export const clearStore = createAction('Clear store');
export const clearURLHistory = createAction('Clear URL and history');
export const getUser = createAction('Get user info');

export const resetPassword = createAction('Reset password');
export const forgotPassword = createAction('Forgot password');
export const forgotPasswordOk = createAction('Forgot password successful');
export const resetPasswordOk = createAction('Reset password successful');
