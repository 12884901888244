import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

import {
  portalHomeHeaderKeySelector,
  portalModuleRadialBackgroundSelector
} from '../../../store/selectors';
import { Blurred, Translation } from '../../common';
import { fadeInAnimationItem } from '../../../constants/animations';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    boxSizing: 'border-box',
    background: props => props.bg
  },
  rootMobile: {
    padding: '26px 16px'
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.contrastText
  },
  description: {
    marginTop: 16,
    textAlign: 'center',
    color: theme.palette.background.default
  }
}));

const PayNow = ({ isPaidFully, isTabletSize, isMobileSize, blur }) => {
  const bg = useSelector(portalModuleRadialBackgroundSelector);
  const classes = useStyles({ bg });
  const headerKey = useSelector(portalHomeHeaderKeySelector);

  return (
    <Box className={clsx(classes.root, isMobileSize && classes.rootMobile)}>
      <Blurred blur={blur}>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeInAnimationItem}
          transition={{ delay: 0.2 }}
        >
          <Typography
            className={classes.title}
            variant={isTabletSize || isMobileSize ? 'h3' : 'h2'}
            component="h1"
          >
            <Translation inline keyIfNoValue>
              {headerKey}
            </Translation>
          </Typography>
        </motion.div>
        {!isMobileSize && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInAnimationItem}
            transition={{ delay: 0.3 }}
          >
            <Typography
              className={classes.description}
              variant="body1"
              component="p"
            >
              <Translation keyIfNoValue inline>
                {isPaidFully ? 'SUBHEADER_PAID' : 'SUBHEADER'}
              </Translation>
            </Typography>
          </motion.div>
        )}
      </Blurred>
    </Box>
  );
};

PayNow.propTypes = {
  isTabletSize: PropTypes.bool,
  isMobileSize: PropTypes.bool,
  blur: PropTypes.bool
};

export default PayNow;
