import parsePhoneNumber from 'libphonenumber-js';

export interface PhoneValidation {
  value: string;
  isValid: boolean;
  country: { dial?: string; code?: string };
}

export const parsePhoneFromString = (input?: string): PhoneValidation => {
  const inputNumber = input == null ? '' : input.trim();

  if (inputNumber.startsWith('00') || inputNumber.startsWith('+')) {
    let numberAdjusted = inputNumber;
    if (numberAdjusted.startsWith('00')) {
      numberAdjusted = '+' + inputNumber.substring(2);
    }
    const n = parsePhoneNumber(numberAdjusted, 'DE');

    if (n) {
      return {
        value: n.isValid() ? n.number : inputNumber,
        isValid: n.isValid(),
        country: {
          dial: n.isValid() ? '+' + n.countryCallingCode : null,
          code: n.isValid() ? n.country : null
        }
      };
    }
    return {
      value: inputNumber,
      isValid: false,
      country: { dial: null, code: null }
    };
  }
  return {
    value: inputNumber,
    isValid:
      inputNumber.length >= 6 &&
      inputNumber.length <= 20 &&
      /[0-9]/.test(inputNumber),
    country: { dial: null, code: null }
  };
};
