import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  caseIdSelector,
  portalContactBarItemsSelector,
  translationsSelector
} from '../../store/selectors';
import { connectTo } from '../../utils';
import {
  HEADER_ANIMATION_Y,
  HEADER_BAR_HEIGHT,
  HEADER_CONTACTS_HEIGHT,
  HEADER_NAVIGATION_HEIGHT,
  HEADER_PAY_BAR_HEIGHT
} from '../../constants/ui';
import { hasPayButtonInHeader } from '../../utils/ui';
import { useAppWidthClass } from '../../utils/hooks';

import {
  ContactsBar,
  ContactsBarMobile,
  HeaderNavigation,
  PayNowBar
} from '../../components/home-container/index.troy';
import { contacts } from '../../constants/contacts';

const HOME_WRAPPER_ID = 'home-container-wrapper';
const HOME_CONTENT_ID = 'home-container-content';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.grey['200']
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const HomeContainer = ({
  isAuthenticated,
  headerList,
  location,
  liabilityStatus,
  children
}) => {
  const classes = useStyles();
  const headerRef = useRef();
  const { isMobileWidth, widthClass } = useAppWidthClass(true, true, true);
  const [isDefaultPayBarSticky, setDefaultPayBarSticky] = useState(false);
  const [headerAnimationShift, setHeaderAnimationShift] = useState(0);

  const translations = useSelector(translationsSelector);
  const portalContactBarItems = useSelector(portalContactBarItemsSelector);
  const caseId = useSelector(caseIdSelector);

  let items = contacts(translations, portalContactBarItems, caseId);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = useCallback(() => {
    const headerElement = headerRef.current;
    if (headerElement) {
      let shift = 0;

      if (window.pageYOffset > HEADER_ANIMATION_Y) {
        shift = Math.max(
          window.pageYOffset - HEADER_ANIMATION_Y,
          -HEADER_BAR_HEIGHT
        );
      }

      setHeaderAnimationShift(shift);
    }
  }, [headerRef, setHeaderAnimationShift]);

  if (!isAuthenticated) {
    return (
      <Box id={HOME_WRAPPER_ID} className={classes.wrapper}>
        <Box className={classes.content}>
          <Box id={HOME_CONTENT_ID} className={widthClass}>
            {children}
          </Box>
        </Box>
      </Box>
    );
  }

  const isPaidFully =
    liabilityStatus && liabilityStatus.traffic_light === 'GREEN';

  const hasPayButton = hasPayButtonInHeader(location.pathname, isPaidFully);

  return (
    <Box id={HOME_WRAPPER_ID} className={classes.wrapper}>
      <HeaderNavigation
        ref={headerRef}
        headerAnimationShift={headerAnimationShift}
        currentRoute={location.pathname}
        headerList={headerList}
      >
        {!isPaidFully && (
          <PayNowBar isInHeader currentRoute={location.pathname} />
        )}
      </HeaderNavigation>
      <Box
        className={classes.content}
        style={{
          paddingTop: `${
            HEADER_BAR_HEIGHT +
            HEADER_NAVIGATION_HEIGHT +
            (isMobileWidth ? 0 : HEADER_CONTACTS_HEIGHT) +
            (hasPayButton ? HEADER_PAY_BAR_HEIGHT : 0)
          }px`
        }}
      >
        {items.length > 0 && (
          <ContactsBar
            isPaidFully={isPaidFully}
            currentRoute={location.pathname}
            headerAnimationShift={headerAnimationShift}
            isDefaultPayBarSticky={isDefaultPayBarSticky}
            items={items}
          >
            {!isPaidFully && (
              <PayNowBar
                currentRoute={location.pathname}
                isDefaultPayBarSticky={isDefaultPayBarSticky}
                setIsDefaultPayBarSticky={setDefaultPayBarSticky}
                headerAnimationShift={headerAnimationShift}
              />
            )}
          </ContactsBar>
        )}
        <Box id={HOME_CONTENT_ID} className={widthClass}>
          {children}
          {items.length > 0 && <ContactsBarMobile items={items} />}
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(
  connectTo(
    ({
      ui: {
        home: { headerList }
      },
      liability: { liabilityStatus }
    }) => ({
      headerList,
      liabilityStatus
    }),
    {},
    HomeContainer
  )
);
