import React from 'react';
import styles from './sap.module.scss';
import { Link } from '../../common';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { Paper } from '@troy/shared/src/components/common';

export const maxTextSize = 150;

export const createLink = (url, authToken) => {
  let regexp = new RegExp(/\?/);
  return regexp.test(url)
    ? url + '&jwtToken=' + authToken
    : url + '?jwtToken=' + authToken;
};

export const convertUniCodes = text => {
  if (text && text.indexOf('&#') >= 0) {
    return text.replace(/&#(\w+);/g, (m, code) => String.fromCodePoint(code));
  }
  return text;
};

export const formatText = (text, isOpen, maxSize) => {
  let convertedText = convertUniCodes(text);

  if (convertedText.length > maxSize) {
    return isOpen
      ? convertedText
      : convertedText.slice(0, maxTextSize) + ' ...';
  } else {
    return convertedText;
  }
};

const TROY = 'Troy';
const CUSTOMER = 'Customer';
class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.channelIconPicker = this.channelIconPicker.bind(this);
    this.customizeChannelName = this.customizeChannelName.bind(this);
    this.customizeDate = this.customizeDate.bind(this);

    this.state = {
      isOpen: false
    };
  }

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  customizeDate(datetime) {
    let currentDate = datetime && datetime.split('-');
    let certainDate = moment(currentDate[0].trim(), 'DD/MM/YYYY').format(
      'DD/MM/YYYY'
    );
    let timeStamp = currentDate[1] && currentDate[1].trim().split(' ');
    let time = timeStamp && timeStamp[0].replace('.', ':');

    return `${certainDate} ${time ? `at ${time}` : ''}`;
  }

  customizeChannelName(type, channel) {
    let userType = TROY;

    if (type === 'client') {
      userType = CUSTOMER;
    }

    if (channel === 'SMS') {
      return `${userType} via Sms`;
    } else if (channel === 'EMAIL') {
      return `${userType} via e-Mail`;
    } else if (channel === 'CHAT') {
      return `${userType} via Chat`;
    } else if (channel === 'LETTER') {
      return `${userType} via Letter`;
    } else if (channel === 'CUSTOMERCENTER') {
      return `${userType} added a file`;
    }
  }

  channelIconPicker(channel) {
    if (channel === 'SMS') {
      return <i className={styles['icon-iphone']} />;
    } else if (channel === 'EMAIL') {
      return <i className={styles['icon-email']} />;
    } else if (channel === 'CHAT') {
      return <i className={styles['icon-comment']} />;
    } else if (channel === 'LETTER') {
      return <i className={styles['icon-letter']} />;
    } else if (channel === 'CUSTOMERCENTER') {
      return <i className={styles['icon-document']} />;
    }
  }

  render() {
    const {
      styles,
      text,
      type,
      mainDoc,
      authToken,
      channel,
      datetime,
      searchText
    } = this.props;
    return (
      <div className={styles[`notification__wrapper_${type}`]}>
        <Paper className={styles[`notification__container_${type}`]}>
          <div>
            <div className={styles[`notification__container_${type}-header`]}>
              <div
                className={
                  styles[`notification__container_${type}-header-left-wrapper`]
                }
              >
                <div
                  className={
                    styles[`notification__container_${type}-header-image`]
                  }
                >
                  <i className={styles['icon-person-placeholder']} />
                </div>
                <div
                  className={
                    styles[`notification__container_${type}-header-subject`]
                  }
                >
                  <div
                    className={
                      styles[
                        `notification__container_${type}-header-subject-name`
                      ]
                    }
                  >
                    {this.customizeChannelName(type, channel)}
                  </div>
                  <div
                    className={
                      styles[
                        `notification__container_${type}-header-subject-date`
                      ]
                    }
                  >
                    {this.customizeDate(datetime)}
                  </div>
                </div>
              </div>
              <div
                className={
                  styles[`notification__container_${type}-header-right-wrapper`]
                }
              >
                <div
                  className={
                    styles[`notification__container_${type}-header-logo`]
                  }
                >
                  {mainDoc ? (
                    <Link
                      raw
                      id="page.sap-postbox.component.notification.attachment-link"
                      to={createLink(mainDoc.url, authToken)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.channelIconPicker(channel)}
                    </Link>
                  ) : (
                    this.channelIconPicker(channel)
                  )}
                </div>
              </div>
            </div>
            <div>
              {text ? (
                <>
                  <div className={styles[`notification__body_${type}`]}>
                    {searchText ? (
                      <Highlighter
                        highlightClassName=""
                        searchWords={searchText.split(' ')}
                        autoEscape={true}
                        textToHighlight={formatText(
                          text,
                          this.state.isOpen,
                          maxTextSize
                        )}
                      />
                    ) : (
                      formatText(text, this.state.isOpen, maxTextSize)
                    )}
                  </div>
                  {channel === 'CUSTOMERCENTER' && mainDoc ? (
                    <div className={styles['notification__body-download']}>
                      <Link
                        raw
                        id="page.sap-postbox.component.notification.download-link"
                        to={createLink(mainDoc.url, authToken)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </Link>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {convertUniCodes(text).length <= maxTextSize ? null : (
                    <div
                      onClick={this.handleClick}
                      className={styles['notification__show-more']}
                    >
                      {this.state.isOpen ? 'Show less' : 'More'}
                    </div>
                  )}
                </>
              ) : mainDoc ? (
                <div className={styles['notification__body-download']}>
                  <Link
                    raw
                    id="page.sap-postbox.component.notification.download-link"
                    to={createLink(mainDoc.url, authToken)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </Link>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

const SAPNotification = props => <Notification {...{ styles, ...props }} />;

export default SAPNotification;
