import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { de, enUS, fr, nl, sv, tr } from 'date-fns/locale';

import { createMuiTroyTheme } from '@troy/shared/src/config/theme';
import { initPortal } from '@troy/shared/src/store/actions';
import {
  LoggerComponent,
  SnackbarManager,
  SnackbarProviderStyled,
  TranslationProvider
} from '@troy/shared/src/components/common';
import FontsNumerical from '@troy/shared/src/components/fonts/FontsNumerical';
import fontsLoader from '@troy/shared/src/components/fonts/fontsWhitelabel';

import {
  Celebration,
  ChatComponent,
  LayoutCircularProgess
} from './components/common';

import {
  CalculatorAwait,
  CompanyInfo,
  ConfidentialityPage,
  CustomerDetail,
  Error as ErrorPage,
  Feedback,
  Home,
  Login,
  PaymentRedirect,
  Payments,
  PaymentSuccess,
  PostBox,
  ReturnGoods,
  SAPIframe,
  Thanks,
  Version
} from './pages/index.troy';
import MainLayout from './containers/MainLayout/MainLayout.troy';
import HomeContainer from './containers/HomeContainer/index.troy';

import client from './config/graphql';
import history from './config/history';
import store from './store';
import { sagaMiddleware } from './middleware';
import saga from './store/sagas';
import logger from './utils/logger';
import { setErrorStatus } from './store/actions';

import * as routes from './constants/routes';
import { NOT_FOUND } from './constants/errorStatuses';
import { isTokenValid } from './utils/jwt';
import { languageFromFullCode } from './constants/browser';
import {
  isAuthTokenLoading,
  isPortalInitialized,
  languageSelector,
  portalConfigurationSelector,
  portalCountyExperienceSelector,
  portalIdentifierSelector,
  tokenSelector,
  translationsSelector
} from './store/selectors';
import { registerOnClickWrapperHandler } from './utils/ui';
import { CookieAgreement } from './components/main/index.troy';
import { TwoFAModal } from './components/2fa-modal';

const localeMap = {
  en: enUS,
  de: de,
  tr: tr,
  nl: nl,
  sv: sv,
  fr: fr
};

const headerRoutes = [{ path: routes.POST_BOX, component: PostBox }];

const AVAILABLE_HOMECONTAINER_ROUTES_AUTHENTICATED = [
  routes.ERROR,
  routes.COMPANY_INFO,
  routes.CONFIDENTIALITY,
  routes.THANKS,
  routes.FEEDBACK,
  routes.POST_BOX,
  routes.HOME,
  routes.PAYMENTS,
  routes.PAYMENT,
  routes.PAYMENT_REDIRECT,
  routes.PAYMENT_SUCCESS,
  routes.CALCULATOR_AWAIT,
  routes.RETURN_GOODS
];

const AVAILABLE_HOMECONTAINER_ROUTES = [
  routes.ERROR,
  routes.COMPANY_INFO,
  routes.CONFIDENTIALITY,
  routes.FEEDBACK,
  routes.THANKS
];

const UnAuthenticatedRoutes = ({ setErrorStatus }) => {
  return (
    <MainLayout>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path={routes.LOGIN} component={Login} />
        <Route path={routes.VERSION} component={Version} />
        <Route
          render={props => {
            return AVAILABLE_HOMECONTAINER_ROUTES.indexOf(
              props.location.pathname
            ) !== -1 ? (
              <HomeContainer>
                <Route path={routes.FEEDBACK} component={Feedback} />
                <Route path={routes.THANKS} component={Thanks} />
                <Route path={routes.COMPANY_INFO} component={CompanyInfo} />
                <Route
                  path={routes.CONFIDENTIALITY}
                  component={ConfidentialityPage}
                />
                <Route path={routes.ERROR} component={ErrorPage} />
              </HomeContainer>
            ) : (
              (() => {
                setErrorStatus(NOT_FOUND);
                return <Redirect to={routes.ERROR} />;
              })()
            );
          }}
        />
      </Switch>
    </MainLayout>
  );
};

const AuthenticatedRoutes = ({ setErrorStatus }) => {
  return (
    <Switch>
      <Route
        path={routes.CUSTOMER_DETAIL}
        render={props => <CustomerDetail isAuthenticated {...props} />}
      />
      <Route
        path={routes.SAP_IFRAME}
        component={props => <SAPIframe isAuthenticated {...props} />}
      />
      <Route
        path={routes.LOGIN}
        component={props => (
          <MainLayout reLoginMode>
            <Login {...props} />
          </MainLayout>
        )}
      />
      <Route path={routes.VERSION} component={Version} />
      <MainLayout isAuthenticated>
        <Route
          render={props => {
            return AVAILABLE_HOMECONTAINER_ROUTES_AUTHENTICATED.indexOf(
              props.location.pathname
            ) !== -1 ? (
              <HomeContainer isAuthenticated>
                <Route exact path="/" component={Home} />

                {headerRoutes.map((route, key) => (
                  <Route {...route} key={key} />
                ))}
                <Route path={routes.THANKS} component={Thanks} />
                <Route path={routes.FEEDBACK} component={Feedback} />
                <Route path={routes.PAYMENTS} component={Payments} />
                <Route path={routes.PAYMENT} component={Payments} />
                <Route
                  path={routes.PAYMENT_SUCCESS}
                  component={PaymentSuccess}
                />
                <Route
                  path={routes.PAYMENT_REDIRECT}
                  component={PaymentRedirect}
                />
                <Route
                  path={routes.CALCULATOR_AWAIT}
                  component={CalculatorAwait}
                />
                <Route path={routes.COMPANY_INFO} component={CompanyInfo} />
                <Route
                  path={routes.CONFIDENTIALITY}
                  component={ConfidentialityPage}
                />
                <Route path={routes.RETURN_GOODS} component={ReturnGoods} />
                <Route
                  exact
                  path={routes.ERROR}
                  component={props => <ErrorPage isAuthenticated {...props} />}
                />
              </HomeContainer>
            ) : (
              (() => {
                setErrorStatus(NOT_FOUND);
                return <Redirect to={routes.ERROR} />;
              })()
            );
          }}
        />
      </MainLayout>
    </Switch>
  );
};

const AppRouter = () => {
  const dispatch = useDispatch();

  registerOnClickWrapperHandler();

  useEffect(() => {
    dispatch(initPortal());
  }, [dispatch]);

  const isInitialized = useSelector(isPortalInitialized);
  const portalConf = useSelector(portalConfigurationSelector);
  const portalCountyExperience = useSelector(portalCountyExperienceSelector);
  const portalIdentifier = useSelector(portalIdentifierSelector);

  useEffect(() => {
    if (!!portalCountyExperience && !!portalIdentifier) {
      fontsLoader(portalIdentifier).catch(err => console.error(err));
    }
  }, [portalCountyExperience, portalIdentifier]);

  const token = useSelector(tokenSelector);
  const tokenLoading = useSelector(isAuthTokenLoading);

  const language = useSelector(languageSelector);

  const locale = languageFromFullCode(language);

  const pathname = window.location.pathname;
  const isSAPIframe = pathname === routes.SAP_IFRAME;
  const isCustomerDetail = pathname === routes.CUSTOMER_DETAIL;

  const isAuthenticated = isTokenValid(token);

  const setErrorStatusCode = useCallback(
    code => dispatch(setErrorStatus(code)),
    [dispatch, setErrorStatus]
  );

  return (
    <>
      {!!tokenLoading && <LayoutCircularProgess />}
      {!!isInitialized && !!portalCountyExperience && (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap[locale]}
        >
          <TranslationProvider translationsDataSelector={translationsSelector}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={createMuiTroyTheme(portalConf)}>
                <SnackbarProviderStyled>
                  <Router history={history}>
                    {!isAuthenticated ? (
                      <UnAuthenticatedRoutes
                        setErrorStatus={setErrorStatusCode}
                      />
                    ) : (
                      <AuthenticatedRoutes
                        setErrorStatus={setErrorStatusCode}
                      />
                    )}
                    <TwoFAModal />
                    <CookieAgreement />
                  </Router>
                  {!isSAPIframe && !isCustomerDetail && !window.Cypress && (
                    <ChatComponent />
                  )}
                  <Celebration />
                  <SnackbarManager />
                </SnackbarProviderStyled>
              </ThemeProvider>
            </StyledEngineProvider>
          </TranslationProvider>
        </LocalizationProvider>
      )}
    </>
  );
};

const App = () => (
  <LoggerComponent logger={logger}>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <AppRouter />
        <FontsNumerical />
      </ApolloProvider>
    </Provider>
  </LoggerComponent>
);

export default App;

sagaMiddleware.run(saga);
