import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import cookies from 'react-cookies';
import { useLocation } from 'react-router-dom';

import { useAppWidthClass } from '../../../utils/hooks';
import { isInIframe } from '../../../utils/browser';
import * as routes from '../../../constants/routes';
import { DOMAIN_LANGUAGE } from '../../../constants/browser';
import { initializeTagManager } from '../../../config/gtm';
import { createGA4 } from '../../../config/ga4';

import { Modal } from '../../common';
import CookieAgreementStartScreen from './CookieAgreementStartScreen';
import CookieAgreementDetailsScreen from './CookieAgreementDetailsScreen';

export const COOKIES_SELECTED_KEY =
  'CookiesSelectedCustomerCenter' + DOMAIN_LANGUAGE.toUpperCase();
export const COOKIES_FUNCTIONAL_SELECTED_KEY =
  'CookiesFunctionalSelectedCustomerCenter' + DOMAIN_LANGUAGE.toUpperCase();
const COOKIES_ANALYTICAL_SELECTED_KEY =
  'CookiesAnalyticalSelectedCustomerCenter' + DOMAIN_LANGUAGE.toUpperCase();

const useStyles = makeStyles(theme => ({
  backdrop: {
    background: theme.palette.background.dark
  },
  wrapper: {
    maxWidth: 550,
    width: '100%',
    margin: 16,
    borderRadius: theme.shape.borderRadius * 6
  },
  wrapperMobile: {
    marginTop: '12vh',
    maxHeight: 'calc(100% - 12vh - 6vh)'
  },
  container: {
    padding: `${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(
      4
    )} ${theme.spacing(5)}`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  containerMobile: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`
  }
}));

const CookieAgreement = () => {
  const classes = useStyles();
  const location = useLocation();
  const { isMobileWidth } = useAppWidthClass();

  const [hideModal, setHideModal] = useState(
    cookies.load(COOKIES_SELECTED_KEY) === '1'
  );
  const [showDetailsScreen, setShowDetailsScreen] = useState(false);

  const startAnalytics = () => {
    if (!window.Cypress) {
      initializeTagManager();
      createGA4().initialize();
    }
  };

  useEffect(() => {
    if (isInIframe()) {
      // troy_client iframe
      saveCookiesCloseModal(true, true);
      return;
    }
    const selected = cookies.load(COOKIES_SELECTED_KEY) === '1';
    setHideModal(selected);
    if (selected) {
      const analytical = cookies.load(COOKIES_ANALYTICAL_SELECTED_KEY) === '1';

      if (analytical) {
        startAnalytics();
      }
    }
  }, []);

  const saveCookiesCloseModal = (functional, analytical) => {
    setHideModal(true);
    cookies.save(COOKIES_SELECTED_KEY, '1');
    cookies.save(COOKIES_FUNCTIONAL_SELECTED_KEY, functional ? '1' : '0');
    cookies.save(COOKIES_ANALYTICAL_SELECTED_KEY, analytical ? '1' : '0');

    if (analytical) {
      startAnalytics();
    }
  };

  const onOkClick = () => {
    saveCookiesCloseModal(true, true);
  };

  const onCancelClick = () => {
    saveCookiesCloseModal(false, false);
  };

  const onSaveClick = (functional, analytical) => {
    saveCookiesCloseModal(functional, analytical);
  };

  const handleDetailsClick = () => {
    setShowDetailsScreen(true);
  };

  const handleBackClick = () => {
    setShowDetailsScreen(false);
  };

  const open = !hideModal && location.pathname !== routes.CONFIDENTIALITY;

  return (
    <Modal
      isOpen={open}
      backdropClassName={classes.backdrop}
      paperClassName={clsx(
        classes.wrapper,
        isMobileWidth && classes.wrapperMobile
      )}
    >
      <div
        className={clsx(
          classes.container,
          isMobileWidth && classes.containerMobile
        )}
      >
        {!showDetailsScreen && (
          <CookieAgreementStartScreen
            onOkClick={onOkClick}
            onCancelClick={onCancelClick}
            onCookieDetailsClick={handleDetailsClick}
          />
        )}
        {showDetailsScreen && (
          <CookieAgreementDetailsScreen
            isMobileWidth={isMobileWidth}
            onSaveClick={onSaveClick}
            onCancelClick={onCancelClick}
            onBackClick={handleBackClick}
          />
        )}
      </div>
    </Modal>
  );
};

export default CookieAgreement;
