import React from 'react';
import clsx from 'clsx';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CustomIcon } from '@troy/shared/src/components/common';
import { svgFill } from '@troy/shared/src/utils/styles';

import { useAppWidthClass } from '../../../utils/hooks';
import { onClickWrapper } from '../../../utils/ui';
import { HOME } from '../../../constants/routes';
import {
  HEADER_BAR_HEIGHT,
  HEADER_NAVIGATION_HEIGHT
} from '../../../constants/ui';
import { portalHeaderBackgroundSelector } from '../../../store/selectors';

import { Link, Translation } from '../../common';
import TroyLogo from '../../common/Logo/Logo.troy';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    width: '100vw',
    zIndex: 1000,
    transition: theme.transitions.custom.header
  },
  navWrapper: {
    position: 'relative',
    boxShadow: theme.customShadows.menu,
    background: props => props.bg
  },
  nav: {
    height: HEADER_NAVIGATION_HEIGHT,
    display: 'flex',
    alignItems: 'center'
  },
  home: {
    marginRight: 'auto'
  },
  logo: {
    verticalAlign: 'middle'
  },
  navigationButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 16,
    padding: 0,
    width: 64,
    height: 64,
    borderRadius: theme.shape.borderRadius * 4,
    background: 'transparent',
    color: theme.palette.primary.contrastText,
    boxShadow: 'none',
    transition: theme.transitions.custom.fast,
    textTransform: 'uppercase',
    textDecoration: 'none',
    cursor: 'pointer',

    '&:hover': {
      background: theme.palette.secondary.main
    }
  },
  navigationButtonActive: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.secondary.main),
    background: theme.palette.primary.contrastText,
    color: theme.palette.secondary.main,

    '&:hover': {
      ...svgFill(
        theme.transitions.custom.fast,
        theme.palette.primary.contrastText
      ),
      background: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText
    }
  },
  icon: {
    margin: '8px 0 2px 0'
  }
}));

const HeaderNavigation = React.forwardRef(
  ({ headerAnimationShift, currentRoute, headerList, children }, ref) => {
    const bg = useSelector(portalHeaderBackgroundSelector);
    const classes = useStyles({ bg });
    const history = useHistory();
    const { widthClass } = useAppWidthClass();

    const top = Math.max(HEADER_BAR_HEIGHT - headerAnimationShift, 0);

    return (
      <Box ref={ref} className={classes.root} style={{ top: `${top}px` }}>
        <Box className={classes.navWrapper}>
          <nav role="navigation" className={clsx(widthClass, classes.nav)}>
            <Link
              id="page.component.home-header.logo-link"
              to={HOME}
              className={classes.home}
            >
              <TroyLogo className={classes.logo} />
            </Link>
            {!!headerList &&
              headerList.map(({ id, icon, label, route }) => (
                <Button
                  key={id}
                  id={id}
                  variant="contained"
                  component="a"
                  onClick={onClickWrapper(id, () => history.push(route))}
                  className={clsx(
                    classes.navigationButton,
                    currentRoute === route && classes.navigationButtonActive
                  )}
                >
                  <CustomIcon
                    className={classes.icon}
                    icon={icon}
                    size="medium"
                    variant="secondaryContrast"
                  />
                  <Typography variant="subtitle1" component="span">
                    <Translation isFragment>{label}</Translation>
                  </Typography>
                </Button>
              ))}
          </nav>
        </Box>
        {children}
      </Box>
    );
  }
);

export default HeaderNavigation;
