import React from 'react';
import clsx from 'clsx';
import { FilePreview, TextField } from '../../common';
import makeStyles from '@mui/styles/makeStyles';
import { useAppWidthClass } from '../../../utils/hooks';
import { useTranslationsContext } from '@troy/shared/src/components/common';
import { List } from '@mui/material';

const useStyles = makeStyles(theme => ({
  details: {
    width: '100%',
    display: 'flex',
    margin: 'auto 0'
  },
  detailsMobile: {
    margin: '0'
  },
  description: {
    width: 301
  },
  descriptionMobile: {
    width: '100%'
  },
  descriptionContent: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: theme.shape.borderRadius * 4,

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`
    }
  },
  descriptionInput: {
    height: '100%',
    ...theme.typography.body1,
    '&::placeholder': {
      color: theme.palette.text.hint
    },
    '&::-moz-placeholder': {
      color: theme.palette.text.hint
    },
    '&:-ms-input-placeholder': {
      color: theme.palette.text.hint
    }
  },
  descriptionOutline: {
    borderColor: `${theme.palette.text.primary} !important`
  },
  filler: {
    flexShrink: 1,
    width: theme.spacing(3.5)
  },
  files: {
    width: 301,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  file: {
    marginTop: theme.spacing(1),

    '&:first-of-type': {
      marginTop: 0
    }
  }
}));

const UploadFileModalDescription = ({ files }) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();
  const { translate } = useTranslationsContext();

  return (
    <div
      className={clsx(classes.details, isMobileWidth && classes.detailsMobile)}
    >
      <TextField
        name="description"
        id="upload-file-form-description"
        className={clsx(
          classes.description,
          isMobileWidth && classes.descriptionMobile
        )}
        classNameRoot={classes.descriptionContent}
        classNameInput={classes.descriptionInput}
        classNameNotched={classes.descriptionOutline}
        placeholder={translate('UPLOAD_FILE_DESCIPTION_PLACEHOLDER')}
        multiline
        rows={isMobileWidth ? 7 : 8}
      />
      {!isMobileWidth && (
        <>
          <div className={classes.filler} />
          <div className={classes.filler} />
          <List className={classes.files}>
            {files.map((file, index) => (
              <FilePreview key={index} className={classes.file} file={file} />
            ))}
          </List>
        </>
      )}
    </div>
  );
};

export default UploadFileModalDescription;
