import { routerReducer as routing } from 'react-router-redux';

import auth from './auth';
import customers from './customers';
import decisions from './decisions';
import payment from './payment';
import liability from './liability';
import translations from './translations';
import ui from './ui';
import error from './error';
import questions from './questions';
import wordpress from './wordpress';
import headers from './headers';
import profile from './profile';
import fileUpload from './file-upload';
import sap from './sap';
import twoFA from './2fa';
import sharedReducers from '@troy/shared/src/store/reducers';

export default {
  auth,
  customers,
  decisions,
  payment,
  liability,
  translations,
  ui,
  error,
  questions,
  wordpress,
  headers,
  profile,
  fileUpload,
  sap,
  routing,
  twoFA,
  ...sharedReducers
};
