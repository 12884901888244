import React from 'react';
import styles from './troy.module.scss';

import DocumentPage from '../../components/common/DocumentPage/index.troy';
import { getPrivacyStatement } from '../../store/actions';
import { connectTo } from '../../utils';

class ConfidentialityPage extends React.Component {
  componentDidMount() {
    const { getPrivacyStatement } = this.props;
    getPrivacyStatement();
  }

  render() {
    const { html } = this.props;
    return <DocumentPage {...{ styles, html }} />;
  }
}

export default connectTo(
  ({
    wordpress: {
      privacyStatement: { content }
    }
  }) => ({
    html: content
  }),
  { getPrivacyStatement },
  ConfidentialityPage
);
