import { createSelector } from 'reselect';

const stateSelector = state => state.profile;

export const firstNameSelector = createSelector(
  stateSelector,
  profile => profile.firstname
);

export const lastNameSelector = createSelector(
  stateSelector,
  profile => profile.lastname
);

export const companyNameSelector = createSelector(
  stateSelector,
  profile => profile.companyName
);

export const customerNameSelector = createSelector(
  firstNameSelector,
  lastNameSelector,
  (firstName, lastName) =>
    `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}`
);

export const emailSelector = createSelector(
  stateSelector,
  profile => profile.email
);

export const mobilePhoneSelector = createSelector(
  stateSelector,
  profile => profile.mobilePhone
);

export const phoneSelector = createSelector(
  stateSelector,
  profile => profile.phone
);

export const phoneOrMobileSelector = createSelector(
  mobilePhoneSelector,
  phoneSelector,
  (mobilePhone, phone) => mobilePhone || phone
);

export const streetSelector = createSelector(
  stateSelector,
  profile => profile.street
);

export const postCodeSelector = createSelector(
  stateSelector,
  profile => profile.postcode
);

export const citySelector = createSelector(
  stateSelector,
  profile => profile.city
);

export const countrySelector = createSelector(
  stateSelector,
  profile => profile.country
);
