import React from 'react';
import Linkify from 'react-linkify';
import { onClickWrapper } from '../../../utils/ui';

interface TextWithLinksPropsParams {
  id?: string;
  text?: string;
}

const TextWithLinksProps: React.FC<TextWithLinksPropsParams> = ({
  id,
  text
}) => {
  return (
    <Linkify
      componentDecorator={(
        decoratedHref: string,
        decoratedText: string,
        key: number
      ): React.ReactElement => (
        <a
          href={decoratedHref}
          key={key}
          onClick={onClickWrapper(id)}
          target="_blank"
          rel="noreferrer"
        >
          {decoratedText}
        </a>
      )}
    >
      {text}
    </Linkify>
  );
};

export default TextWithLinksProps;
