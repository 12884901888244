import { createSelector } from 'reselect';

export const decisionsSelector = state => state.decisions;

export const hasDecisionsSelector = createSelector(
  decisionsSelector,
  decisions => Object.keys(decisions).length > 0
);

export const showPostboxLiveAgentChatDebugSelector = createSelector(
  decisionsSelector,
  decisions => decisions && decisions['troy.ui.show.chat_header']
);

export const hasNutunPaymentSelector = createSelector(
  decisionsSelector,
  decisions => decisions && decisions['troy.ui.feature.nutun.payment_simple']
);

export const hasWhatsAppResponseChannelSelector = createSelector(
  decisionsSelector,
  decisions => decisions && decisions['troy.feature.customerupload.whatsapp']
);

export const hasFAQPageLinksSelector = createSelector(
  decisionsSelector,
  decisions => decisions && decisions['troy.ui.customer.show.generic-faq']
);
