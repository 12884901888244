export const LOGIN = '/login';
export const ERROR = '/error';
export const VERSION = '/version';
export const CUSTOMER_DETAIL = '/customer-detail';
export const SAP_IFRAME = '/sap-iframe';
export const COMPANY_INFO = '/impressum';
export const CONFIDENTIALITY = '/datenschutzerklaerung';
export const THANKS = '/thanks';
export const FEEDBACK = '/feedback';
export const POST_BOX = '/post-box';
export const RETURN_GOODS = '/return-goods';

export const HOME = '/';
export const PAYMENTS = '/payments';
export const PAYMENT = '/payment';
export const PAYMENT_REDIRECT = '/payment-redirect';
export const PAYMENT_SUCCESS = '/payment_success';
export const CALCULATOR_AWAIT = '/calculator-await';
