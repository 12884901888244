import images from '@troy/shared/src/assets/img/matched';

export const getImageFileName = imagePath => {
  const imageArr = imagePath.split('/');
  return imageArr.length ? imageArr[imageArr.length - 1] : '';
};

export const getImage = imagePath => {
  if (!imagePath) {
    return null;
  }
  if (
    imagePath.startsWith('http://') ||
    imagePath.startsWith('https://') ||
    imagePath.startsWith('/static/media/') ||
    imagePath.startsWith('data:image/')
  ) {
    return imagePath;
  }
  const imageFileName = getImageFileName(imagePath);
  return imageFileName ? images[imageFileName] : null;
};
