import React from 'react';
import clsx from 'clsx';
import { Box, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { CustomIcon } from '@troy/shared/src/components/common';

import { useAppWidthClass } from '../../../utils/hooks';
import { Translation } from '../../common';
import { useSelector } from 'react-redux';
import { translationsSelector } from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    padding: theme.spacing(2)
  },
  rootSmallHeaderMargin: {
    paddingTop: 56
  },
  close: {
    position: 'absolute',
    zIndex: 1,
    top: 8,
    right: 8,
    padding: 8
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  heading: {
    color: theme.palette.secondary.main
  }
}));

const ModalLayout = ({
  className,
  mobileHeaderMargin,
  icon,
  iconSize,
  title,
  onClose,
  closeButtonId,
  headingClass,
  headingVariant,
  children
}) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();
  const translations = useSelector(translationsSelector);

  return (
    <Box
      className={clsx(
        classes.root,
        isMobileWidth && mobileHeaderMargin && classes.rootSmallHeaderMargin,
        className
      )}
    >
      {onClose && (
        <IconButton
          id={closeButtonId}
          color="default"
          className={classes.close}
          onClick={onClose}
          size="large"
          aria-label={translations['UPLOAD_CLOSE']}
        >
          <CustomIcon icon="dismiss" variant="hint" size="small" />
        </IconButton>
      )}
      <Box className={classes.title}>
        {icon && (
          <CustomIcon
            className={classes.icon}
            icon={icon}
            size={iconSize}
            variant="secondary"
          />
        )}
        <Typography
          className={clsx(classes.heading, headingClass)}
          component="h2"
          variant={headingVariant || isMobileWidth ? 'h3' : 'h2'}
          align="center"
        >
          <Translation inline>{title}</Translation>
        </Typography>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default ModalLayout;
