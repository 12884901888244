import React, { useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';

import { Grid, LayoutCircularProgess } from '../../components/common';
import { DemandTable, LiabilityInfo } from '../../components/home/index.troy';
import { PostBox } from '../../components/post-box/index.troy';

import {
  getCustomers,
  getDecisions,
  selectLanguage,
  setFileModalOpened,
  uploadFile
} from '../../store/actions';
import { connectTo } from '../../utils';

import {
  LIABILITY_INFO,
  LIABILITY_TABLE,
  NOTIFICATIONS,
  POSTBOX
} from '../../constants/customerDetail';

import { getUrlParams } from '../../utils/httpUtil';
import { usePostboxStyles } from '../../components/post-box/PostBoxContent/index.troy';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative'
  },
  content_postbox: {
    padding: '8px'
  },
  content_notifications: {
    padding: '8px'
  },
  content_liability: {
    padding: '12px'
  },
  'content_liability-table': {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  liabilityTableContainer: {
    padding: '6px',
    flex: '1',
    display: 'flex',
    flexDirection: 'column'
  },
  demandTable: {
    flex: '1',
    justifyContent: 'space-between'
  },
  demandTableBody: {
    // this height is calculated by differing heights of all other elements on page, all the margins and padding from 100% height
    // the header height is 48px because of the 2nd line in minimized width mode
    maxHeight:
      'calc(100vh - 2 * 24px - 48px - 2 * 24px - 16px - 2 * 8px) !important'
  },
  loader: {
    display: 'flex',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const CustomerDetail = ({
  isAuthenticated,
  decisions,
  demandTableHide,
  demandTableBlur,
  liabilityItems,
  liabilityStatus,
  balance,
  currency,
  locale,
  liabilityStatementList,
  timelineHide,
  timelineBlur,
  timeline,
  token,
  notifications,
  directionFilterCounts,
  searchDecision,
  fileUploadHidden,
  uploadFile,
  isFileLoading,
  isFileModalOpened,
  setFileModalOpened,
  fileUploadBlur,
  notificationsListBlur,
  internal,
  getDecisions,
  getCustomers,
  selectLanguage
}) => {
  const classes = useStyles();
  const postBoxClasses = usePostboxStyles();

  const {
    method: urlParam,
    lang: language,
    scroll: autoScroll
  } = useMemo(() => getUrlParams(), []);

  const isPaidFully = useMemo(
    () => liabilityStatus && liabilityStatus.traffic_light === 'GREEN',
    [liabilityStatus]
  );

  useEffect(() => {
    if (language) {
      selectLanguage({ code: language, isAuthenticated });
    }
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes[`content_${urlParam}`]}>
        {urlParam === LIABILITY_INFO
          ? (liabilityItems && timeline && liabilityStatementList && (
              <Grid container spacing={3}>
                <LiabilityInfo
                  {...{
                    balance,
                    currency,
                    locale,
                    isPaidFully,
                    timelineHide,
                    timelineBlur,
                    demandTableHide,
                    demandTableBlur
                  }}
                  timelineItems={timeline}
                  demandTableItems={liabilityItems}
                  demandTableDocuments={liabilityStatementList}
                />
              </Grid>
            )) || <LayoutCircularProgess />
          : urlParam === LIABILITY_TABLE
          ? (liabilityItems && liabilityStatementList && (
              <div className={classes.liabilityTableContainer}>
                {!demandTableHide && (
                  <DemandTable
                    className={classes.demandTable}
                    tableBodyClassName={classes.demandTableBody}
                    blur={demandTableBlur}
                    items={liabilityItems}
                    balance={balance}
                    currency={currency}
                    locale={locale}
                    extended={liabilityStatementList}
                    disableScroll
                    disableExpand
                  />
                )}
              </div>
            )) || <LayoutCircularProgess />
          : null}
        {urlParam === POSTBOX || urlParam === NOTIFICATIONS ? (
          <PostBox
            {...{
              token,
              notifications,
              directionFilterCounts,
              searchDecision,
              fileUploadHidden,
              fileUploadBlur,
              notificationsListBlur,
              uploadFile,
              isFileLoading,
              isFileModalOpened,
              setFileModalOpened,
              autoScroll
            }}
            internal={internal}
            classes={postBoxClasses}
          />
        ) : null}
      </div>
    </div>
  );
};

export default connectTo(
  ({
    auth: { token, internal },
    postBox: { list: notifications, directionFilterCounts },
    decisions,
    liability: { locale, liabilityItems, timeline, liabilityStatus },
    payment: {
      paymentUserInfo: { totalBalance, dueCurrency }
    },
    ui: {
      home: {
        liabilityStatementList,
        blur: { demandTable: demandTableBlur, timeline: timelineBlur },
        hide: { timeline: timelineHide, demandTable: demandTableHide }
      },
      postBox: {
        blur: {
          fileUpload: fileUploadBlur,
          notificationsList: notificationsListBlur
        }
      }
    }
  }) => ({
    token,
    internal,
    notifications,
    directionFilterCounts,
    decisions,
    searchDecision: decisions['troy.feature.ui.postbox.textsearch'],
    liabilityItems,
    liabilityStatus,
    timeline,
    demandTableBlur,
    timelineBlur,
    timelineHide,
    demandTableHide,
    balance: totalBalance,
    currency: dueCurrency,
    locale,
    liabilityStatementList,
    fileUploadHidden: true,
    fileUploadBlur,
    notificationsListBlur
  }),
  {
    getDecisions,
    getCustomers,
    selectLanguage,
    uploadFile,
    setFileModalOpened
  },
  CustomerDetail
);
