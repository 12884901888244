import { call } from 'redux-saga/effects';

import { RequestError } from './httpUtil';
import { isTokenValid } from './jwt';

import store from '../store';

export function randomCorrelationID() {
  if (process.env.JEST_WORKER_ID !== undefined) {
    return '';
  }
  const length = 8;
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  let result = 'UI_';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  // log to console to simplify issue tracking
  console.log('Correlation ID:', result);
  return result;
}

export function* callHttp(...args) {
  if (process.env.REACT_APP_MOCK) return;

  try {
    const token = store.getState().auth.token;

    if (!isTokenValid(token)) {
      throw new RequestError(401);
    }

    const data = yield call(...args, !!token);
    return data;
  } catch (err) {
    throw err;
  }
}

export const getContentDispositionFileName = contentDisposition => {
  const regexResult = /filename="(.*)"/g.exec(contentDisposition || '');

  if (regexResult && regexResult[1]) {
    return regexResult[1];
  }
  return 'file';
};
