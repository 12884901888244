import { DE, DOMAIN_LANGUAGE, NL } from '../constants/browser';
import { envKeyString } from '../constants/env';

export const DOMAIN_TO_GTM_TAG_ID = {
  prod: {
    [DE]: 'GTM-TF2J3QM',
    [NL]: 'GTM-TWS3W99'
  },
  test: {
    [DE]: 'GTM-MCGD36H',
    [NL]: 'GTM-569MZTB'
  },
  demo: {
    [DE]: 'GTM-MCGD36H',
    [NL]: 'GTM-569MZTB'
  },
  dev: {
    [DE]: 'GTM-PFJJT5Q',
    [NL]: ''
  }
};

export const GTM_TAG_ID = DOMAIN_TO_GTM_TAG_ID[envKeyString()][DOMAIN_LANGUAGE];

// react libraries are doing unsafe inline script loading
// which does not work with CSP
export const initializeTagManager = () => {
  const scriptId = 'gtm-script';
  if (!!document.getElementById(scriptId)) {
    return;
  }
  const s = 'script';
  const l = 'dataLayer';
  const id = GTM_TAG_ID;

  window[l] = window[l] || [];
  window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  var f = document.getElementsByTagName(s)[0],
    j = document.createElement(s),
    dl = l !== 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.id = scriptId;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + id + dl;
  f.parentNode.insertBefore(j, f);

  const noscript = document.createElement('noscript');
  let iframe = document.createElement('iframe');
  iframe.src = 'https://www.googletagmanager.com/ns.html?id=' + id;
  iframe.width = '0';
  iframe.height = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);
  document.body.insertAdjacentElement('afterbegin', noscript);
};
