import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Module } from '@troy/shared/src/components/common';

import { BlurWithLogin, Translation } from '../../common';
import { getBalanceWithCurrency } from '../../../constants/payment';

import headerImg from '../../../assets/img/demand-table/claim-structure.png';

import { mockData } from './mockData';
import { DemandTableContent } from '../index.troy';

const LOGIN_DESCRIPTION_KEY = 'LIABILITY_STATEMENT_CENSORED';
const TITLE_TRANSLATION_KEY = 'LIABILITY_STATEMENT';

const CLAIM_TOTAL_AMOUNT_KEY = 'CLAIM_TOTAL_AMOUNT';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 32px',
    height: '100%',
    boxSizing: 'border-box'
  },
  rootMobile: {
    padding: '24px 16px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  image: {
    height: 40
  },
  title: {
    marginLeft: 8
  },
  table: {
    marginTop: 24,
    marginBottom: 24
  },
  summary: {
    ...theme.typography.body2Bold,
    marginTop: 'auto',
    display: 'flex',
    color: theme.palette.text.primary
  },
  summarySmall: {
    justifyContent: 'flex-end'
  },
  total: {
    ...theme.typography.numerical2,
    lineHeight: theme.typography.body2Bold.lineHeight,
    marginLeft: 'auto'
  },
  totalTablet: {
    marginLeft: 80
  }
}));

const DemandTable = ({
  className,
  tableBodyClassName,
  id,
  isTabletSize,
  isMobileSize,
  items,
  balance,
  currency,
  locale,
  blur,
  disableExpand
}) => {
  const [selectedTableRow, setSelectedTableRow] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const classes = useStyles();
  const isDesktopSize = !isTabletSize && !isMobileSize;

  return (
    <Module
      id={id}
      className={clsx(
        classes.root,
        isMobileSize && classes.rootMobile,
        className
      )}
    >
      <Box className={clsx(classes.content, !blur && classes.overflowHidden)}>
        <Box className={classes.titleRow}>
          <img className={classes.image} src={headerImg} alt="" />
          <Typography
            className={classes.title}
            component="h3"
            variant="h4"
            color="secondary"
          >
            <Translation isFragment>{TITLE_TRANSLATION_KEY}</Translation>
          </Typography>
        </Box>
        <BlurWithLogin
          blur={blur}
          descriptionKey={LOGIN_DESCRIPTION_KEY}
          buttonId="page.home.component.demand-table.logout-button"
          mockData={
            <DemandTableContent
              isMock
              className={classes.table}
              tableBodyClassName={tableBodyClassName}
              isExpandable={!disableExpand && isMobileSize}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              items={mockData}
              locale={locale}
            />
          }
        >
          {items && (
            <DemandTableContent
              className={classes.table}
              tableBodyClassName={tableBodyClassName}
              isExpandable={!disableExpand && isMobileSize}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              items={items}
              locale={locale}
              selectedTableRow={selectedTableRow}
              setSelectedTableRow={setSelectedTableRow}
            />
          )}
        </BlurWithLogin>
      </Box>
      <Box
        className={clsx(
          classes.summary,
          !isDesktopSize && classes.summarySmall
        )}
      >
        <Translation>{CLAIM_TOTAL_AMOUNT_KEY}</Translation>
        <span
          className={clsx(classes.total, isTabletSize && classes.totalTablet)}
        >
          {balance != null
            ? getBalanceWithCurrency(balance, currency, locale)
            : '(unbekannt)'}
        </span>
      </Box>
    </Module>
  );
};

DemandTable.propTypes = {
  isTabletSize: PropTypes.bool,
  isMobileSize: PropTypes.bool,
  items: PropTypes.array,
  balance: PropTypes.number,
  currency: PropTypes.string,
  blur: PropTypes.bool,
  selectedTableRow: PropTypes.number,
  setSelectedTableRo: PropTypes.func
};

export default DemandTable;
