import { createReducer } from 'redux-act';

import * as actions from '../actions/file-upload';

const DEFAULT_STATE = {
  isLoading: false,
  isModalOpened: false,
  isUploadSuccessful: false
};

export default createReducer(
  {
    [actions.setLoading]: (state, isLoading) => ({
      ...state,
      isLoading: isLoading === undefined ? !state.isLoading : isLoading
    }),
    [actions.setUploadSuccess]: (state, isSuccessfull) => ({
      ...state,
      isUploadSuccessful:
        isSuccessfull === undefined ? !state.isModalOpened : isSuccessfull
    }),
    [actions.setFileModalOpened]: (state, isOpened) => ({
      ...state,
      isModalOpened: isOpened === undefined ? !state.isModalOpened : isOpened,
      isUploadSuccessful: false
    }),
    [actions.fileUploadReset]: () => ({ ...DEFAULT_STATE })
  },
  DEFAULT_STATE
);
