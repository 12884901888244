import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { TIME_MORNING } from '../../../constants/return-goods';
import { DEFAULT_DATE_FORMAT } from '../../../utils/time';

import { CustomIcon, Text } from '@troy/shared/src/components/common';

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(2)
  },
  pickup: {
    display: 'flex',
    alignItems: 'center'
  },
  date: {
    display: 'flex',
    alignItems: 'center'
  },
  dateVertical: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  dateValue: {
    marginLeft: theme.spacing(1)
  },
  timeRight: {
    marginLeft: theme.spacing(2)
  }
}));

const ReturnGoodsDateDetails = ({ vertical, title, pickupDate, time }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.section}>
        <Text translated component="h3" variant="h4" color="textPrimary">
          {title}
        </Text>
      </Box>
      <Box
        className={clsx(
          classes.section,
          classes.pickup,
          vertical && classes.dateVertical
        )}
      >
        <Box className={classes.date}>
          <CustomIcon icon="calendar" size="small" variant="primary" />
          <Text
            className={classes.dateValue}
            component="span"
            variant="body1Bold"
            color="textPrimary"
          >
            {moment(pickupDate).format(DEFAULT_DATE_FORMAT)}
          </Text>
        </Box>
        <Text
          translated
          className={vertical ? classes.section : classes.timeRight}
          component="span"
          variant="body1"
          color="textPrimary"
        >
          {time === TIME_MORNING
            ? 'BSH_RETURNGOODS_WORKFLOW_OPTION1'
            : 'BSH_RETURNGOODS_WORKFLOW_OPTION2'}
        </Text>
      </Box>
    </>
  );
};

export default ReturnGoodsDateDetails;
