import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import MaterialSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { onClickWrapper } from '../../../utils/ui';
import { connectTo } from '../../../utils';
import { FormField } from '@troy/shared/src/components/common';

class SelectField extends React.Component {
  static renderSelect = ({
    id,
    labelId,
    options,
    onChange,
    className,
    classNameInput,
    classNameRoot,
    classNamePaper,
    classNameMenuItem,
    maxSelectHeight,
    menuOnTop,
    field,
    value,
    multiple,
    iconComponent,
    translated,
    translations
  }) => {
    return (
      <MaterialSelect
        id={id}
        labelId={labelId}
        {...field}
        MenuProps={{
          anchorOrigin: {
            horizontal: 'left',
            vertical: menuOnTop ? 'top' : 'bottom'
          },
          transformOrigin: {
            vertical: menuOnTop ? 'bottom' : 'top',
            horizontal: 'left'
          },
          PaperProps: {
            className: classNamePaper,
            style: {
              marginTop: menuOnTop ? -10 : 10,
              maxHeight: maxSelectHeight ? maxSelectHeight : 300
            }
          }
        }}
        IconComponent={iconComponent && (props => iconComponent(props))}
        multiple={multiple}
        className={className}
        value={field ? field.value : value}
        classes={{
          select: clsx(classNameRoot, classNameInput)
        }}
        onChange={onClickWrapper(id, field ? field.onChange : onChange)}
      >
        {options.map(({ label, value, id }, key) => (
          <MenuItem
            {...{ value, key, id }}
            classes={{
              root: classNameMenuItem
            }}
          >
            {translated ? translations[label] || '' : label}
          </MenuItem>
        ))}
      </MaterialSelect>
    );
  };

  render() {
    const {
      id,
      labelId,
      name,
      options,
      value,
      onChange,
      className,
      classNameInput,
      classNameRoot,
      classNamePaper,
      classNameMenuItem,
      maxSelectHeight,
      menuOnTop,
      multiple,
      iconComponent,
      validate,
      translated,
      translations
    } = this.props;

    const customProps = {
      id,
      labelId,
      options,
      value,
      onChange,
      className,
      classNameInput,
      classNameRoot,
      classNamePaper,
      classNameMenuItem,
      maxSelectHeight,
      menuOnTop,
      multiple,
      iconComponent,
      translated,
      translations
    };
    return name ? (
      <FormField
        component={SelectField.renderSelect}
        name={name}
        onChange={onChange}
        value={value}
        validate={validate}
        {...customProps}
      />
    ) : (
      SelectField.renderSelect(customProps)
    );
  }
}

SelectField.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  hasForm: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  menuOnTop: PropTypes.bool,
  iconComponent: PropTypes.func
};

export default connectTo(
  ({ translations: { translations } }) => ({
    translations
  }),
  {},
  SelectField
);
