import { createReducer } from 'redux-act';

import * as actions from '../actions/sap';
import { isInIframe } from '../../utils/browser';

const DEFAULT_STATE = {
  businessPartnerID: ''
};

export default createReducer(
  {
    [actions.setBusinessPartnerID]: (state, businessPartnerID) => {
      if (!isInIframe()) {
        localStorage.setItem('businessPartnerID', businessPartnerID);
      }
      state.businessPartnerID = businessPartnerID;
      return { ...state };
    }
  },
  DEFAULT_STATE
);
