import React from 'react';
import moment from 'moment';
import Filter from '../Filter/index.sap';
import NotificationList from '../NotificationList/index.sap';

import styles from './sap.module.scss';

import {
  filterOptions,
  filterOptionsMap
} from '../../../constants/post-box.sap';

const defaultFilters = filterOptions.reduce(
  (acc, { name }) => ({ ...acc, [name]: false }),
  {}
);

const initTabs = () => {
  return [
    {
      label: 'Messages',
      value: 'messages'
    },
    {
      label: 'Document',
      value: 'document'
    }
  ];
};

export default class PostBox extends React.Component {
  state = {
    tabValue: false,
    tabs: null,

    notifications: null,
    hasFilter: 0,

    dateFilter: false,
    channelFilter: false,
    typeFilter: false,

    searchStr: '',

    filters: defaultFilters,
    chosenStatisticFilter: null,
    startDate: null,
    endDate: null,

    pinButtonActive: false,
    downArrowButtonActive: false,
    upArrowButtonActive: false
  };

  static getDerivedStateFromProps(props, state) {
    let returnObj = null;
    if (!state.notifications && props.notifications) {
      returnObj = { ...returnObj, notifications: props.notifications };
    }
    if (!state.tabs) {
      returnObj = { ...returnObj, tabs: initTabs() };
    }
    return returnObj;
  }

  componentDidUpdate(prevProps) {
    //manage scrollable menu
    if (
      this.props.scrollVisible !== prevProps.scrollVisible &&
      !this.props.scrollVisible
    ) {
      this.setState({
        downArrowButtonActive: false
      });
    } else if (
      this.props.scrollVisible !== prevProps.scrollVisible &&
      this.props.scrollVisible &&
      !this.state.pinButtonActive
    ) {
      this.setState({
        downArrowButtonActive: true
      });
    }
  }

  onChannelFilterChange = types => {
    if (types === -1) {
      this.setState({
        hasFilter: this.state.hasFilter - 1,
        filters: [],
        channelFilter: false
      });
    } else if (!this.state.channelFilter) {
      this.setState({
        filters: types,
        hasFilter: this.state.hasFilter + 1,
        channelFilter: true
      });
    } else {
      this.setState({
        filters: types,
        channelFilter: true
      });
    }
  };

  onTypeFilterChange = type => {
    if (type === -1) {
      this.setState({
        chosenStatisticFilter: null,
        hasFilter: this.state.hasFilter - 1,
        typeFilter: false
      });
    } else if (!this.state.typeFilter) {
      this.setState({
        chosenStatisticFilter: type,
        hasFilter: this.state.hasFilter + 1,
        typeFilter: true
      });
    } else {
      this.setState({
        chosenStatisticFilter: type,
        typeFilter: true
      });
    }
  };

  onDateRangeChange = (startDate, endDate) => {
    this.setState({
      startDate: startDate,
      endDate: endDate,
      dateFilter: true
    });

    if (startDate === null && endDate === null) {
      this.setState({
        hasFilter: this.state.hasFilter - 1,
        dateFilter: false
      });
    } else if (!this.state.dateFilter) {
      this.setState({
        hasFilter: this.state.hasFilter + 1
      });
    }
  };

  onSearchChange = event => {
    if (this.state.searchStr && !event.target.value) {
      this.onSearchClear();
    } else {
      setTimeout(() => {
        this.onSearchClick();
      }, 300);
    }
    this.setState({ searchStr: event.target.value });
  };

  onSearchClear = () => {
    const { notifications } = this.props;
    this.setState({
      notifications,
      searchStr: ''
    });
  };

  onWrapperOpenClick = () => {
    this.setState({
      downArrowButtonActive: true,
      pinButtonActive: false
    });
  };

  onWrapperDownClick = () => {
    this.setState({
      downArrowButtonActive: false
    });
  };

  onWrapperPinClick = () => {
    this.setState({
      pinButtonActive: !this.state.pinButtonActive
    });
  };

  onSearchClick = () => {
    const { notifications } = this.props;
    const { searchStr } = this.state;
    const newNotifications = notifications.filter(
      ({ text }) =>
        text && text.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
    );
    this.setState({ notifications: newNotifications });
  };

  onFilterClick = () => {
    const { notifications } = this.props;
    const { filters, chosenStatisticFilter, startDate, endDate } = this.state;
    let filteredNotifications = notifications.filter(
      ({ channel, type, datetime }) => {
        let currentDate = datetime && datetime.split('-');
        let certainDate = moment(currentDate[0].trim(), 'DD/MM/YYYY');
        let validChannel =
          !Object.values(filters).some(filterValue => filterValue) ||
          filters[filterOptionsMap[channel]];
        var validType =
          !chosenStatisticFilter || chosenStatisticFilter.includes(type);
        let validDate =
          (!startDate || startDate.isSameOrBefore(certainDate)) &&
          (!endDate || endDate.isSameOrAfter(certainDate));
        return validChannel && validType && validDate;
      }
    );

    this.setState({
      notifications: filteredNotifications
    });
  };

  render() {
    const {
      notifications,
      pinButtonActive,
      downArrowButtonActive,
      filters,
      searchStr
    } = this.state;
    const { token, groupCounts, scrollVisible } = this.props;

    const hidePostMenuWhenScrollVisible =
      scrollVisible && downArrowButtonActive && !pinButtonActive;
    const hidePostMenuWhenScrollNotVisible =
      !scrollVisible && downArrowButtonActive;
    const showStickyPostMenu = !downArrowButtonActive && scrollVisible;

    return (
      <div className={styles['postbox__wrapper']}>
        <div
          className={
            styles[
              `postbox__menu${
                hidePostMenuWhenScrollNotVisible
                  ? '-sticky-hidden-without-scroll'
                  : hidePostMenuWhenScrollVisible
                  ? '-sticky-hidden-with-scroll'
                  : showStickyPostMenu
                  ? '-sticky-show'
                  : ''
              }`
            ]
          }
        >
          <Filter
            groups={groupCounts}
            onFilterClick={this.onFilterClick}
            onOptionChange={this.onChannelFilterChange}
            filterValues={filters}
            onTypeFilterChange={this.onTypeFilterChange}
            chosenStatisticFilter={this.state.chosenStatisticFilter}
            onDateRangeChange={this.onDateRangeChange}
            onSearchClick={this.onSearchClick}
            onSearchChange={this.onSearchChange}
            onSearchClear={this.onSearchClear}
            searchValue={searchStr}
            scrollVisible={downArrowButtonActive}
            filterCount={this.state.hasFilter}
          />
        </div>
        <div
          className={
            styles[
              `postbox__button-container${showStickyPostMenu ? '-sticky' : ''}`
            ]
          }
        >
          <div
            className={
              styles[`postbox__button-wrapper${scrollVisible ? '-sticky' : ''}`]
            }
          >
            {!downArrowButtonActive ? (
              <div
                id="page.sap-postbox.component.icon-buttons.up-button"
                className={styles[`postbox__button-wrapper-open`]}
                onClick={this.onWrapperOpenClick}
              >
                <i className={styles['icon-navigation-up-arrow']}></i>
              </div>
            ) : (
              <div
                id="page.sap-postbox.component.icon-buttons.down-button"
                className={styles['postbox__button-wrapper-open']}
                onClick={this.onWrapperDownClick}
              >
                <i className={styles['icon-navigation-down-arrow']}></i>
              </div>
            )}
            {!downArrowButtonActive ? (
              <div
                id="page.sap-postbox.component.icon-buttons.pin-button"
                className={
                  styles[
                    `postbox__button-wrapper-pin${
                      pinButtonActive ? '-active' : ''
                    }`
                  ]
                }
                onClick={this.onWrapperPinClick}
              >
                <i className={styles['icon-pushpin-off']}></i>
              </div>
            ) : null}
          </div>
          <div
            className={
              styles[
                `postbox__divider-horizontal${scrollVisible ? '-sticky' : ''}`
              ]
            }
          ></div>
        </div>
        <div className={styles['postbox__notification']}>
          <NotificationList
            notifications={notifications}
            authToken={token}
            searchText={searchStr}
          />
        </div>
      </div>
    );
  }
}
