import React from 'react';
import PropTypes from 'prop-types';
import {
  Card as MaterialCard,
  CardContent as MaterialCardContent
} from '@mui/material';

const Card = React.forwardRef(
  ({ children, className, contentClassName }, ref) => (
    <MaterialCard ref={ref} className={className}>
      <MaterialCardContent className={contentClassName}>
        {children}
      </MaterialCardContent>
    </MaterialCard>
  )
);

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string
};

export default Card;
