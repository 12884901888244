import { createReducer } from 'redux-act';
import * as actions from '../actions/2fa';

import {
  TWO_FA_TYPE_CLIENT_UNIQUE_ID,
  TWO_FA_TYPE_NONE,
  TWO_FA_TYPE_ZIPCODE
} from '../../constants/2fa';

const SUPPORTED_TYPES = [TWO_FA_TYPE_CLIENT_UNIQUE_ID, TWO_FA_TYPE_ZIPCODE];

const DEFAULT_STATE = {
  is2FAModalOpen: false,
  is2FAEnabled: false,
  type: TWO_FA_TYPE_NONE,
  remainingAttempts: 0,
  currentAttemptsMade: 0,
  isBlocked: true,
  isLoading: false
};

export default createReducer(
  {
    [actions.set2FAModalOpen]: (state, isOpen) => ({
      ...state,
      is2FAModalOpen: isOpen
    }),
    [actions.set2FAInfo]: (_, { liability }) => {
      const attemptsArray = liability.secondFactorAttempts;

      let isBlocked = true;
      let remainingAttempts = 0;
      let secondFactor = TWO_FA_TYPE_NONE;

      if (attemptsArray && attemptsArray.length > 0) {
        let attempt = attemptsArray.find(a =>
          SUPPORTED_TYPES.includes(a.secondFactor)
        );
        if (attempt && attempt.isBlocked) {
          attempt = attemptsArray.find(
            a =>
              SUPPORTED_TYPES.includes(a.secondFactor) &&
              a.secondFactor !== attempt.secondFactor
          );
        }
        if (attempt) {
          isBlocked = attempt.isBlocked;
          remainingAttempts = attempt.remainingAttempts;
          secondFactor = attempt.secondFactor;
        }
      }

      let type = TWO_FA_TYPE_NONE;
      if (secondFactor) {
        let allowedType = liability.mandant.secondFactors.find(
          s => s === secondFactor
        );
        if (allowedType) {
          type = allowedType;
        }
      }

      const is2FAEnabled =
        type !== TWO_FA_TYPE_NONE &&
        type === secondFactor &&
        !isBlocked &&
        remainingAttempts > 0;

      return {
        ...DEFAULT_STATE,
        is2FAEnabled,
        type,
        remainingAttempts,
        isBlocked
      };
    },
    [actions.submit2FALogin]: state => ({
      ...state,
      isLoading: true
    }),
    [actions.handle2FALoginError]: state => ({
      ...state,
      currentAttemptsMade: state.currentAttemptsMade + 1,
      isBlocked: state.remainingAttempts <= state.currentAttemptsMade + 1,
      isLoading: false
    }),
    [actions.twoFAReset]: () => ({ ...DEFAULT_STATE })
  },
  DEFAULT_STATE
);
