import React from 'react';
import PropTypes from 'prop-types';

import styles from './troy.module.scss';

import Calculator from '.';

const TroyCalculator = ({
  onSubmit,
  currency,
  validRates,
  initialValues,
  id,
  formRef,
  className
}) => (
  <Calculator
    {...{
      styles,
      onSubmit,
      currency,
      validRates,
      initialValues,
      className,
      formRef,
      id
    }}
  />
);

TroyCalculator.propTypes = {
  onSelectChange: PropTypes.func,
  onSubmit: PropTypes.func
};

export default TroyCalculator;
