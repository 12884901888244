import { parsePhoneFromString } from '@troy/shared/src/utils/phone';

export const parsePhoneWithNewCountry = (inputPhone, newIso, newDial) => {
  const data = parsePhoneFromString(inputPhone);
  const { country, value } = data;

  if (country.dial) {
    if (value.startsWith('+')) {
      const newValue = value.replace(country.dial, newDial);

      return {
        value: newValue,
        isValid: parsePhoneFromString(newValue).isValid,
        country: { dial: newDial, code: newIso }
      };
    } else if (value.startsWith('00')) {
      const oldDialNumber = country.dial.replace('+', '');
      const newDialNumber = newDial.replace('+', '');
      const newValue = value.replace(
        `00${oldDialNumber}`,
        `00${newDialNumber}`
      );

      return {
        value: newValue,
        isValid: parsePhoneFromString(newValue).isValid,
        country: { dial: newDial, code: newIso }
      };
    }
  }
  return data;
};

export const onPhoneKeyPress = event => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (!/[+0-9]/.test(keyValue)) event.preventDefault();
};
