import moment from 'moment';
import {
  LANotificationMessageTime,
  LANotificationTimeUtc
} from '../types/notifications';

const createOffsetTime = (dateTime: string): moment.Moment => {
  const tzOffset = new Date().getTimezoneOffset();
  const time = moment.utc(dateTime);
  return time.add(-tzOffset, 'minute');
};

export const formatNotificationDate = (dateTime: string): string => {
  return createOffsetTime(dateTime).format('DD.MM.YYYY');
};

export const formatNotificationHours = (dateTime: string): string => {
  return createOffsetTime(dateTime).format('HH:mm');
};

export const formatLiveAgentTime = (
  apiBaseDate: LANotificationTimeUtc,
  apiDateTime: LANotificationTimeUtc | LANotificationMessageTime = apiBaseDate
): string => {
  const tzOffset = new Date().getTimezoneOffset();
  let time = moment.utc({
    y: apiBaseDate.year,
    M: apiBaseDate.monthValue - 1,
    d: apiBaseDate.dayOfMonth,
    h: apiDateTime.hour,
    m: apiDateTime.minute,
    s: apiDateTime.second
  });

  time = time.add(-tzOffset, 'minute');
  return time.format('HH:mm');
};

export const shrinkName = (name: string, maxLen: number): string => {
  if (name.length > maxLen) {
    const extensionIdx = name.lastIndexOf('.');
    const shrink = '(...)';
    if (extensionIdx !== -1) {
      const extensionLen = name.length - extensionIdx;

      if (extensionLen <= name.length / 2) {
        return (
          name.slice(0, maxLen - extensionLen - shrink.length) +
          shrink +
          name.slice(extensionIdx)
        );
      } else {
        // if extension is too long
        return (
          name.slice(0, extensionIdx) +
          name.slice(extensionIdx, maxLen - shrink.length) +
          shrink
        );
      }
    } else {
      // if there's no extension
      return name.slice(0, maxLen - shrink.length) + shrink;
    }
  } else {
    return name;
  }
};
