import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomIcon, Module, Text } from '../common';
import PostBoxFilterContent from './PostBoxFilterContent';
import {
  UIFilterOption,
  UINotificationChannelFilter,
  UINotificationDirectionFilter
} from '../../types/notifications';

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    background: theme.palette.grey['200'],
    border: `1px solid ${theme.palette.background.default}`,
    borderRadius: Number(theme.shape.borderRadius) * 6,
    boxShadow: 'none',
    transition: 'background 100ms linear'
  },
  filterExpanded: {
    background: theme.palette.background.default
  },
  header: {
    minHeight: 55,
    flexDirection: 'row-reverse',

    '&.Mui-Expanded': {
      background: 'red'
    }
  },
  headerExpanded: {
    minHeight: '55px !important'
  },
  headerContent: {
    marginLeft: 12,
    flexGrow: 0
  },
  headerContentExpanded: {
    margin: '12px 0 12px 12px !important'
  },
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 4,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 100ms linear'
  },
  closeButtonEnabled: {
    opacity: 1,
    pointerEvents: 'all'
  },
  details: {
    padding: 0
  }
}));

interface PostBoxFilterProps {
  className?: string;
  directionFilterCounts: Record<UINotificationDirectionFilter, number>;
  onDirectionFilterChange: (type: UINotificationDirectionFilter) => void;
  directionFilter: UINotificationDirectionFilter;
  filterOptions: UIFilterOption[];
  filterValues: Record<UINotificationChannelFilter, boolean>;
  onOptionChange: (
    name: UINotificationChannelFilter
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PostBoxFilter: React.FC<PostBoxFilterProps> = ({
  className,
  directionFilterCounts,
  onDirectionFilterChange,
  directionFilter,
  filterOptions,
  filterValues,
  onOptionChange
}): React.ReactElement => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (_: never, isExpanded: boolean): void =>
    setExpanded(isExpanded);

  const onClose = (): void => setExpanded(false);

  return (
    <Module className={className}>
      <Accordion
        square
        expanded={expanded}
        onChange={handleChange}
        classes={{
          root: clsx(classes.filter, expanded && classes.filterExpanded)
        }}
      >
        <AccordionSummary
          id="postbox.filter"
          aria-controls="case-view.communication.filter.content"
          classes={{
            root: clsx(classes.header, expanded && classes.headerExpanded),
            content: clsx(
              classes.headerContent,
              expanded && classes.headerContentExpanded
            )
          }}
          expandIcon={
            <CustomIcon icon="expandMore" size="small" variant="textHint" />
          }
        >
          <Text translated component="h4" variant="body1Bold" color="textHint">
            POSTBOX_FILTER
          </Text>
          <IconButton
            className={clsx(
              classes.closeButton,
              expanded && classes.closeButtonEnabled
            )}
            aria-label="close"
            id="postbox.filter.close"
            onClick={onClose}
            size="large"
          >
            <CustomIcon size="small" icon="dismiss" variant="textHint" />
          </IconButton>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <PostBoxFilterContent
            directionFilterCounts={directionFilterCounts}
            onDirectionFilterChange={onDirectionFilterChange}
            directionFilter={directionFilter}
            filterOptions={filterOptions}
            filterValues={filterValues}
            onOptionChange={onOptionChange}
          />
        </AccordionDetails>
      </Accordion>
    </Module>
  );
};

export default PostBoxFilter;
