import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from '@apollo/client';

import { headers as httpHeaders } from '../utils/httpUtil';

// const authLink = setContext((_, { headers }) => {
//   return {
//     headers: {
//       ...httpHeaders({ useToken: true }),
//       ...headers
//     }
//   };
// });

const authLink2 = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      ...httpHeaders({ useToken: true }),
      ...headers
    }
  }));
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink2.concat(
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_API_URL,
      fetch: fetch
    })
  ),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    }
  }
});

export default client;
