import { call, put, select } from 'redux-saga/effects';
import { get } from '../../utils/httpUtil';
import { tokenSelector } from '../selectors';
import {
  decisionsReceived,
  handleError,
  setPaymentDecisions,
  setUI
} from '../actions';
import { GET_DECISIONS } from '../../constants/api';

export function* getDecisions() {
  try {
    const token = yield select(tokenSelector);
    const decisions = yield call(get, GET_DECISIONS, !!token);
    yield put(decisionsReceived(decisions));
    yield put(setPaymentDecisions(decisions));

    yield put(setUI({ decisions }));
  } catch (err) {
    yield put(handleError(err));
  }
}
