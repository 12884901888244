import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DOMAIN_LANGUAGE, NL } from '../../../constants/browser';
import { useAppWidthClass } from '../../../utils/hooks';
import { Translation } from '../../common';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 56
  },
  rootCompact: {
    height: 72
  },
  imageBox: {
    display: 'flex',
    minWidth: 116
  },
  imageBoxCompact: {
    minWidth: 98
  },
  imageBoxNL: {
    minWidth: 56
  },
  image: {
    width: 'auto',
    height: 24
  },
  imageNL: {
    height: 32
  },
  label: {
    ...theme.typography.body1Bold,
    color: theme.palette.text.primary,
    marginLeft: 8
  }
}));

const PaymentMethodLabel = ({ image, label }) => {
  const classes = useStyles();
  const { isTabletWidth, isMobileWidth } = useAppWidthClass();
  const isTabletSizeDown = isTabletWidth || isMobileWidth;

  return (
    <Box
      className={clsx(classes.root, isTabletSizeDown && classes.rootCompact)}
    >
      <Box
        className={clsx(
          classes.imageBox,
          isTabletSizeDown && classes.imageBoxCompact,
          DOMAIN_LANGUAGE === NL && classes.imageBoxNL
        )}
      >
        <img
          className={clsx(
            classes.image,
            DOMAIN_LANGUAGE === NL && classes.imageNL
          )}
          src={image}
          alt=""
          data-role="paymentMethodImage"
        />
      </Box>
      {label && (
        <Translation keyIfNoValue className={classes.label}>
          {label}
        </Translation>
      )}
    </Box>
  );
};

export default PaymentMethodLabel;
