import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { IconButton, Typography } from '@mui/material';

import { CustomIcon } from '@troy/shared/src/components/common';

import { translationsSelector } from '../../../store/selectors';
import { setShowCelebrationAnimation } from '../../../store/actions';

import { addEllipsis } from '../../../utils/textEllipsis';
import { Translation } from '../../common';
import trophy from '../../../assets/img/status/paid_trophy.png';

const TIMELINE_CAROUSEL_HEADER = isClientPortal =>
  `TIMELINE_CAROUSEL_HEADER${isClientPortal ? '_CLIENT' : ''}`;
const TIMELINE_CAROUSEL_BOTTOM = (isClientPortal, isPaidFully) =>
  `BOX_TIMELINE${isClientPortal ? '_CLIENT' : isPaidFully ? '_PAID' : ''}`;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginTop: 32,
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 4,
    padding: '24px 40px 32px 40px',
    textAlign: 'center'
  },
  rootCompact: {
    padding: '24px 21px 32px 21px'
  },
  rootSmall: {
    marginTop: 16,
    padding: '24px 16px 24px 16px'
  },
  item: {
    position: 'relative',
    margin: '0 auto',
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 16,
    textAlign: 'center',
    outline: 'none'
  },
  itemClickable: {
    cursor: 'pointer'
  },
  circle: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 8,
    height: 8,
    background: theme.palette.text.disabled,
    border: `2px solid ${theme.palette.text.hint}`,
    borderRadius: '100%'
  },
  circleCurrent: {
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  },
  circlePaid: {
    background: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main
  },
  logo: {
    height: 40,
    padding: '0 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  trophy: {
    height: 32
  },
  image: {
    maxWidth: 138,
    maxHeight: 40
  },
  details: {
    flexGrow: 1,
    minHeight: 48,
    width: 138,
    margin: `16px auto 0 auto`,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.action.disabled,
    borderRadius: theme.shape.borderRadius * 2,
    boxSizing: 'border-box'
  },
  detailsCurrent: {
    background: theme.palette.primary.main
  },
  detailsPaid: {
    background: theme.palette.secondary.main
  },
  date: {
    color: theme.palette.text.secondary
  },
  dateCurrent: {
    color: theme.palette.primary.contrastText
  },
  datePaid: {
    color: theme.palette.text.disabled
  },
  dateFuture: {
    color: theme.palette.text.hint
  },
  summary: {
    width: '100%',
    maxHeight: 64,
    overflow: 'hidden',
    color: theme.palette.text.primary,
    hyphens: 'auto',
    wordBreak: 'break-word'
  },
  summaryCurrentPaid: {
    ...theme.typography.subtitle2Bold,
    color: theme.palette.primary.contrastText
  },
  summaryFuture: {
    color: theme.palette.text.hint
  },
  explanation: {
    marginTop: 24
  },
  explanationSmall: {
    marginTop: 24
  },
  sliderWrapper: {
    position: 'relative',

    '& .slick-prev,& .slick-next': {
      position: 'absolute',
      zIndex: 1,
      top: 5,
      width: 32,
      height: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.primary.main,

      '&:hover, &:focus-visible': {
        background: theme.palette.primary.light
      },
      '&:before': {
        content: '""'
      }
    },
    '& .slick-prev': {
      left: -24
    },
    '& .slick-next': {
      right: -24,

      '& svg': {
        transform: 'rotate(180deg)'
      }
    },
    '& .slick-disabled': {
      background: theme.palette.action.disabled,
      cursor: 'default',
      pointerEvents: 'none',

      '& path': {
        stroke: theme.palette.text.hint
      }
    }
  },
  sliderWrapperCompact: {
    '& .slick-prev': {
      left: -5
    },
    '& .slick-next': {
      right: -5
    }
  },
  sliderWrapperSmall: {
    '& .slick-prev': {
      left: -10
    },
    '& .slick-next': {
      right: -10
    }
  }
}));

const SlickArrow = ({ ariaLabel, className, disabled, onClick }) => (
  <IconButton
    aria-label={ariaLabel}
    onClick={onClick}
    className={clsx(className, 'slick-arrow', disabled && 'slick-disabled')}
    aria-disabled={disabled}
    size="large"
  >
    <CustomIcon icon="chevronLeft" size="xs" variant="primaryContrast" />
  </IconButton>
);

const SlickArrowLeft = ({ disabled, onClick }) => {
  const t = useSelector(translationsSelector);

  return (
    <SlickArrow
      disabled={disabled}
      onClick={onClick}
      ariaLabel={t['TIMELINE_PREVIOUS']}
      className="slick-prev"
    />
  );
};

const SlickArrowRight = ({ disabled, onClick }) => {
  const t = useSelector(translationsSelector);

  return (
    <SlickArrow
      disabled={disabled}
      onClick={onClick}
      ariaLabel={t['TIMELINE_NEXT']}
      className="slick-next"
    />
  );
};

const TimelineItem = ({ item, currentIndex, index }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const summaryRef = useRef();

  useEffect(() => {
    addEllipsis(summaryRef.current);
  }, []);

  const onItemClick = () => dispatch(setShowCelebrationAnimation(true));

  const { logo, summary, datetime, label, current, type } = item;

  const isPaid = type === 'FULLY_PAID';
  const isFuture = currentIndex >= 0 && index > currentIndex && !isPaid;

  return (
    <div
      className={clsx(classes.item, isPaid && classes.itemClickable)}
      key={index}
      onClick={isPaid ? onItemClick : undefined}
      role={isPaid ? 'button' : undefined}
    >
      <div
        className={clsx(
          classes.circle,
          current && classes.circleCurrent,
          isPaid && classes.circlePaid
        )}
      />

      <div className={classes.logo}>
        {isPaid && (
          <img
            className={classes.trophy}
            src={trophy}
            alt={summary}
            data-timeline-item-image
          />
        )}
        {!isPaid && logo && (
          <img
            className={classes.image}
            src={logo}
            alt={summary}
            data-timeline-item-image
          />
        )}
        {!isPaid && !logo && (
          <div className={classes.image} data-timeline-item-image>
            {label}
          </div>
        )}
      </div>
      <motion.div
        className={clsx(
          classes.details,
          current && classes.detailsCurrent,
          isPaid && classes.detailsPaid
        )}
        whileHover={{ scale: 1.1 }}
        data-timeline-item-details
      >
        {datetime && (
          <Typography
            variant="subtitle2"
            component="p"
            className={clsx(
              classes.date,
              current && classes.dateCurrent,
              isPaid && classes.datePaid,
              isFuture && classes.dateFuture
            )}
          >
            {moment(datetime).format('DD.MM.YYYY')}
          </Typography>
        )}
        <Typography
          ref={summaryRef}
          variant="subtitle2"
          component="p"
          className={clsx(
            classes.summary,
            (current || isPaid) && classes.summaryCurrentPaid,
            isFuture && classes.summaryFuture
          )}
        >
          {summary}
        </Typography>
      </motion.div>
    </div>
  );
};

const TimelineSlider = ({
  isTabletSize,
  isMobileSize,
  settings,
  items,
  isClientPortal,
  isPaidFully
}) => {
  const classes = useStyles();
  const t = useSelector(translationsSelector);
  let initialSlidePosition = items.findIndex(i => !!i.current);

  let slider = useRef();
  const [hasSetPosition, setHasSetPosition] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(
    initialSlidePosition - (isMobileSize ? 0 : 1)
  );

  useEffect(() => {
    if (slider.current) {
      slider.current.innerSlider.list.parentElement.setAttribute(
        'role',
        'group'
      );
    }
  }, [slider.current]);

  // https://github.com/akiran/react-slick/issues/1946
  useEffect(() => {
    if (slider.current && !hasSetPosition) {
      slider.current?.slickGoTo(initialSlidePosition - (isMobileSize ? 0 : 1));
      setHasSetPosition(true);
    }
  }, [initialSlidePosition, hasSetPosition, slider]);

  const handleSlideChange = (_, newIndex) => {
    const items = slider.current.innerSlider.track.node.children;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      item.removeAttribute('aria-label');
    }
    items[newIndex + (isMobileSize ? 0 : 1)].setAttribute(
      'aria-label',
      t['TIMELINE_CURRENT']
    );
    setCurrentSlide(newIndex);
  };

  return (
    <section
      className={clsx(
        classes.root,
        isTabletSize && classes.rootCompact,
        isMobileSize && classes.rootSmall
      )}
    >
      <Typography variant="h4" color="textPrimary">
        <Translation inline>
          {TIMELINE_CAROUSEL_HEADER(isClientPortal)}
        </Translation>
      </Typography>
      <div
        className={clsx(
          classes.sliderWrapper,
          isTabletSize && classes.sliderWrapperCompact,
          isMobileSize && classes.sliderWrapperSmall
        )}
      >
        <SlickArrowLeft
          disabled={currentSlide === 0}
          onClick={() => slider.current?.slickPrev()}
        />
        <Slider ref={slider} {...settings} beforeChange={handleSlideChange}>
          {items.map((item, index) => (
            <TimelineItem
              key={index}
              item={item}
              currentIndex={initialSlidePosition}
              index={index}
            />
          ))}
        </Slider>
        <SlickArrowRight
          disabled={currentSlide === items.length - settings.slidesToShow}
          onClick={() => slider.current?.slickNext()}
        />
      </div>
      <Typography
        className={clsx(
          classes.explanation,
          isMobileSize && classes.explanationSmall
        )}
        variant={isMobileSize ? 'body2' : 'body1'}
        color="textSecondary"
      >
        <Translation inline>
          {TIMELINE_CAROUSEL_BOTTOM(isClientPortal, isPaidFully)}
        </Translation>
      </Typography>
    </section>
  );
};

export default TimelineSlider;
