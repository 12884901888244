import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { connectTo } from '../../utils';
import { useAppWidthClass } from '../../utils/hooks';
import {
  hasNutunPaymentSelector,
  isAuthenticatedAppLoadingSelector,
  showBlueMovementReturnGoods
} from '../../store/selectors';

import { Decision, Module } from '@troy/shared/src/components/common';

import { Grid, SkeletonModule } from '../../components/common';
import {
  ContactBox,
  DemandExplanation,
  Greetings,
  LiabilityInfo,
  Payment,
  PayNow,
  ReturnGoodsInfo
} from '../../components/home/index.troy';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px 16px 48px 16px'
  },
  rootMedium: {
    padding: '24px 16px 40px 16px'
  },
  rootSmall: {
    padding: '24px 16px 24px 16px'
  },
  tableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  fullHeight: {
    height: '100%'
  },
  documentsMain: {
    marginTop: 24
  }
}));

const HomePage = ({
  liabilityItems,
  timeline,
  paymentMethods,
  greetingsBlur,
  contactBoxBlur,
  payNowBlur,
  demandTableBlur,
  timelineBlur,
  demandExplanationBlur,
  paymentHide,
  greetingsHide,
  payNowHide,
  timelineHide,
  demandTableHide,
  contactBoxHide,
  balance,
  currency,
  locale,
  liabilityStatementList,
  demandExplanationHide,
  liabilityLogo,
  clientName,
  liabilityStatus
}) => {
  const classes = useStyles();

  const { isTabletWidth, isMobileWidth } = useAppWidthClass();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isPaidFully =
    liabilityStatus && liabilityStatus.traffic_light === 'GREEN';

  const isAuthenticatedAppLoading = useSelector(
    isAuthenticatedAppLoadingSelector
  );
  const hasNutunPayment = useSelector(hasNutunPaymentSelector);

  return (
    <Box
      id="home-content"
      className={clsx(
        classes.root,
        isTabletWidth && classes.rootMedium,
        isMobileWidth && classes.rootSmall
      )}
    >
      {isAuthenticatedAppLoading && (
        <>
          <SkeletonModule />
          <SkeletonModule type="widgets" />
          <SkeletonModule type="smallTwo" />
          <SkeletonModule />
        </>
      )}
      {!isAuthenticatedAppLoading && (
        <Grid container direction="row" spacing={3}>
          {(hasNutunPayment ||
            !payNowHide ||
            (paymentMethods && paymentMethods.length > 0)) && (
            <Grid item xs={12} component="article">
              <Module id="home-paynow-payment">
                {!payNowHide && (
                  <PayNow
                    isPaidFully={isPaidFully}
                    isTabletSize={isTabletWidth}
                    isMobileSize={isMobileWidth}
                    blur={payNowBlur}
                  />
                )}
                {!isPaidFully &&
                  (hasNutunPayment ||
                    (paymentMethods && paymentMethods.length > 0)) && (
                    <Payment
                      isMobileSize={isMobileWidth}
                      blur={payNowBlur}
                      paymentMethods={paymentMethods}
                      paymentHide={paymentHide}
                      balance={balance}
                    />
                  )}
              </Module>
            </Grid>
          )}
          <Decision selector={showBlueMovementReturnGoods}>
            <Grid item xs={12} component="article">
              <ReturnGoodsInfo
                id="home-return-goods"
                isTabletWidth={isTabletWidth}
                isMobileWidth={isMobileWidth}
              />
            </Grid>
          </Decision>
          {!greetingsHide && (
            <Grid item xs={12} component="article">
              <Greetings
                id="home-greeting"
                isPaidFully={isPaidFully}
                isMobileSize={isMobileWidth}
                isTabletSize={isTabletWidth}
                blur={greetingsBlur}
                clientLogo={liabilityLogo}
                clientName={clientName}
              />
            </Grid>
          )}
          {!contactBoxHide && (
            <Grid item component="article">
              <ContactBox blur={contactBoxBlur} link="/" />
            </Grid>
          )}
          {!isMobileWidth && !demandExplanationHide && (
            <Grid item component="article">
              <DemandExplanation
                id="home-demand-explanation"
                isTabletSize={isTabletWidth}
                blur={demandExplanationBlur}
              />
            </Grid>
          )}
          <LiabilityInfo
            {...{
              balance,
              currency,
              locale,
              isPaidFully,
              timelineHide,
              timelineBlur,
              demandTableHide,
              demandTableBlur
            }}
            timelineItems={timeline}
            demandTableItems={liabilityItems}
            demandTableDocuments={liabilityStatementList}
          />
        </Grid>
      )}
    </Box>
  );
};

export default connectTo(
  ({
    liability: {
      locale,
      liabilityItems,
      timeline,
      logo,
      liabilityStatus,
      clientName
    },
    payment: {
      paymentUserInfo: { totalBalance, dueCurrency }
    },
    ui: {
      home: {
        liabilityStatementList,
        blur: {
          greetings: greetingsBlur,
          contactBox: contactBoxBlur,
          payNow: payNowBlur,
          demandTable: demandTableBlur,
          timeline: timelineBlur,
          demandExplanation: demandExplanationBlur
        },
        hide: {
          greetings: greetingsHide,
          contactBox: contactBoxHide,
          payNow: payNowHide,
          timeline: timelineHide,
          demandTable: demandTableHide,
          demandExplanation: demandExplanationHide
        }
      },
      payment: { methods: paymentMethods, hide: paymentHide }
    },
    profile: { firstname, lastname }
  }) => ({
    liabilityItems,
    liabilityStatus,
    timeline,
    paymentMethods,
    greetingsBlur,
    contactBoxBlur,
    payNowBlur,
    demandTableBlur,
    timelineBlur,
    demandExplanationBlur,
    paymentHide,
    greetingsHide,
    payNowHide,
    timelineHide,
    demandTableHide,
    contactBoxHide,
    firstName: firstname,
    lastName: lastname,
    balance: totalBalance,
    currency: dueCurrency,
    locale,
    liabilityStatementList,
    demandExplanationHide,
    liabilityLogo: logo,
    clientName
  }),
  {},
  HomePage
);
