import React from 'react';

import styles from './troy.module.scss';

import { SAPDemandTable, SAPTimeline } from '../../components/home/index.troy';
import { PostBoxSAP } from '../../components/post-box/index.troy';
import { Grid, Tabs } from '../../components/common';

import { sapAuthentication } from '../../store/actions';
import { connectTo } from '../../utils';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const TABS_LEFT = [
  {
    label: 'Claim Table',
    icon: () => <img alt="claim-icon" />,
    value: 'claimTable'
  },
  {
    label: 'Status',
    icon: () => <img alt="status-icon" />,
    value: 'status'
  },
  {
    label: 'Qualtrics',
    icon: () => <img alt="qualtrics-icon" />,
    value: 'quatrics'
  }
];

const TABS_RIGHT = [
  {
    label: 'Messages',
    icon: () => <img alt="messages-icon" />,
    value: 'messages'
  },
  {
    label: 'Documents',
    icon: () => <img alt="documents-icon" />,
    value: 'documents'
  }
];

class SAPQualtricsResults extends React.Component {
  componentDidMount() {
    const { satisfaction } = this.props;

    var ratingByKey = [];
    for (let i = 0; i < satisfaction.length; i++) {
      // TODO HACK abuse of surveyId for questionId
      ratingByKey[satisfaction[i].surveyId] = satisfaction[i];
    }

    var npsvalue = 0;
    var npsrat = ratingByKey['QID5'];
    if (npsrat) {
      console.log('nps:' + npsrat.rating);
      npsvalue = Math.floor((npsrat.rating / 10) * 200 - 100); // TODO NPS value here
    }

    var chart = am4core.create('chartdiv_nps', am4charts.GaugeChart);
    chart.innerRadius = am4core.percent(82);
    var title = chart.titles.create();
    title.text = 'NPS Score';
    title.fontSize = 30;
    // title.marginBottom = 30;

    /**
     * Normal axis
     */

    var axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = -100;
    axis.max = 100;
    axis.strictMinMax = true;
    axis.renderer.radius = am4core.percent(80);
    axis.renderer.inside = true;
    axis.renderer.line.strokeOpacity = 1;
    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.ticks.template.length = 10;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.radius = 40;
    axis.renderer.labels.template.adapter.add('text', function (text) {
      return text + '%';
    });

    /**
     * Axis for ranges
     */

    var colorSet = new am4core.ColorSet();

    var axis2 = chart.xAxes.push(new am4charts.ValueAxis());
    axis2.min = -100;
    axis2.max = 100;
    axis2.renderer.innerRadius = 10;
    axis2.strictMinMax = true;
    axis2.renderer.labels.template.disabled = true;
    axis2.renderer.ticks.template.disabled = true;
    axis2.renderer.grid.template.disabled = true;

    var range0 = axis2.axisRanges.create();
    range0.value = -100;
    range0.endValue = 20; //npsvalue;
    range0.axisFill.fillOpacity = 1;
    range0.axisFill.fill = am4core.color('#882222'); // colorSet.getIndex(0);

    var range1 = axis2.axisRanges.create();
    range1.value = 20;
    range1.endValue = 60;
    range1.axisFill.fillOpacity = 1;
    range1.axisFill.fill = am4core.color('#888822'); //colorSet.getIndex(1);

    var range2 = axis2.axisRanges.create();
    range2.value = 60;
    range2.endValue = 100;
    range2.axisFill.fillOpacity = 1;
    range2.axisFill.fill = am4core.color('#22cc22'); //colorSet.getIndex(2);

    /**
     * Label
     */

    var label = chart.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = 45;
    label.x = am4core.percent(50);
    label.y = am4core.percent(100);
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.text = '50%';

    /**
     * Hand
     */

    var hand = chart.hands.push(new am4charts.ClockHand());
    hand.axis = axis2;
    hand.innerRadius = am4core.percent(20);
    hand.startWidth = 10;
    hand.pin.disabled = true;
    hand.value = -100;

    /*
	hand.events.on("propertychanged", function(ev) {
	  range0.endValue = ev.target.value;
	  range1.value = ev.target.value;
	  axis2.invalidate();
	});
	*/

    label.text = npsvalue + '%';
    var animation = new am4core.Animation(
      hand,
      {
        property: 'value',
        to: npsvalue
      },
      2000,
      am4core.ease.cubicOut
    ).start();

    this.chart = chart;

    // Create rating chart instance
    ////////////////////////////////
    var ratingchart = am4core.create('chartdiv_ratings', am4charts.XYChart);
    var rtitle = ratingchart.titles.create();
    rtitle.text = 'Bewertungen';
    rtitle.fontSize = 20;
    rtitle.marginBottom = 10;

    var novalue = 0.1;

    // Add data
    var chartdata = [
      {
        question: 'Mit Betreuung zufrieden',
        rating: novalue,
        min: 1,
        q1: 3.5,
        q3: 4.5,
        max: 5,
        //	  "color": chart.colors.next(),
        key: 'QID1_1'
      },
      {
        question: 'Kommunikation verständlich',
        rating: novalue,
        min: 2,
        q1: 3,
        q3: 4,
        max: 5,
        //	  "color": chart.colors.next(),
        key: 'QID1_2'
      },
      {
        question: 'Kosten nachvollziehbar',
        rating: novalue,
        min: 2,
        q1: 3,
        q3: 4,
        max: 5,
        //	  "color": chart.colors.next(),
        key: 'QID1_3'
      },
      {
        question: 'Anschreiben übersichtlich',
        rating: novalue,
        min: 2,
        q1: 3,
        q3: 4.5,
        max: 5,
        //	  "color": chart.colors.next(),
        key: 'QID1_4'
      },
      {
        question: 'Webportal übersichtlich',
        rating: novalue,
        min: 1,
        q1: 3,
        q3: 4,
        max: 5,
        //	  "color": chart.colors.next(),
        key: 'QID1_5'
      },
      {
        question: 'Insgesamt zufrieden',
        rating: novalue,
        min: 2,
        q1: 4,
        q3: 5,
        max: 5,
        //	  "color": chart.colors.next(),
        key: 'QID1_6'
      }
    ].reverse();

    for (let i = 0; i < chartdata.length; i++) {
      // set rating from satisfaction array
      var rating = ratingByKey[chartdata[i].key];
      if (rating) {
        chartdata[i].rating = rating.rating;
      }
    }

    ratingchart.data = chartdata;

    // Create axes
    var categoryAxis = ratingchart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'question';
    categoryAxis.numberFormatter.numberFormat = '#';
    //categoryAxis.renderer.inversed = true;

    var valueAxis = ratingchart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 5;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.grid.template.strokeOpacity = 1;
    //valueAxis.renderer.grid.template.stroke = am4core.color("#A0CA92");
    valueAxis.renderer.grid.template.strokeWidth = 1;
    valueAxis.renderer.minGridDistance = 30;
    // remove non-integer grids
    valueAxis.renderer.labels.template.adapter.add(
      'text',
      function (text, target) {
        return text.match(/\./) ? '' : text;
      }
    );

    // Create series

    var cseries = ratingchart.series.push(new am4charts.CandlestickSeries());
    //cseries.dataFields.dateX = "question";
    cseries.dataFields.categoryY = 'question';
    cseries.dataFields.valueX = 'q3';
    cseries.dataFields.openValueX = 'q1';
    cseries.dataFields.lowValueX = 'min';
    cseries.dataFields.highValueX = 'max';
    //cseries.dataFields.averageX = "average";
    cseries.simplifiedProcessing = true;
    cseries.tooltipText =
      'Min:${lowValueX.value}\nQ1:${openValueX.value}\nQ3:${valueX.value}\nMax:${highValueX.value}';
    cseries.strokeWidth = 5;
    cseries.color = chart.colors.next();
    cseries.columns.template.width = am4core.percent(50);
    cseries.columns.template.height = am4core.percent(50);

    //cseries.dropFromOpenState.properties.fill = chart.colors.next();
    // cseries.dropFromOpenState.properties.stroke = chart.colors.next();
    cseries.riseFromOpenState.properties.fill =
      cseries.riseFromOpenState.properties.stroke = chart.colors.next(); // same as

    var series = ratingchart.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = 'rating';
    series.dataFields.categoryY = 'question';
    series.name = 'rating';
    series.name = 'Kundenbewertung';
    series.strokeWidth = 7;
    series.tooltipText = '{categoryY}: {valueX.value}';
    series.tensionY = 0.77;
    series.stroke = am4core.color('#22cc22'); // chart.colors.next();

    /*
	series.columns.template.propertyFields.fill = "color";
	series.columns.template.tooltipText = "{valueX}";
	series.columns.template.column.stroke = am4core.color("#fff");
	series.columns.template.column.strokeOpacity = 0.2;
*/
    //	ratingchart.svgContainer.htmlElement.style.height = 500 + "px";

    this.ratingchart = ratingchart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div className="chartgroup" style={{ width: '100%' }}>
        <div id="chartdiv_nps" style={{ width: '100%', height: '300px' }}></div>
        <div
          id="chartdiv_ratings"
          style={{ width: '100%', height: '500px' }}
        ></div>
      </div>
    );
  }
}

class SAPIframe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leftTabValue: TABS_LEFT[0].value,
      rightTabValue: TABS_RIGHT[0].value,
      scrollVisible: false
    };

    this.onLeftTabChange = this.onLeftTabChange.bind(this);
    this.onRightTabChange = this.onRightTabChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    let scrollVisible = window.pageYOffset > window.innerHeight / 3;
    this.setState({
      scrollVisible
    });
  }

  onLeftTabChange(event, value) {
    this.setState({ leftTabValue: value });
  }

  onRightTabChange(event, value) {
    this.setState({ rightTabValue: value });
  }

  render() {
    const {
      demandTableBlur,
      liabilityItems,
      satisfaction,
      balance,
      currency,
      liabilityStatementList,
      timelineBlur,
      timeline,
      token,
      notifications,
      groupCounts,
      searchDecision
    } = this.props;

    return (
      <div className={styles[`sap-iframe__wrapper`]}>
        <Grid
          container
          className={styles['sap-iframe__wrapper-grid-container']}
        >
          <Grid md={4} sm={12} xs={12} item>
            <Tabs
              options={TABS_LEFT}
              value={this.state.leftTabValue}
              onChange={this.onLeftTabChange}
              tabClassName={styles['sap-iframe__tabs-tab']}
              indicatorClassName={
                styles[
                  `sap-iframe__tabs-indicator${
                    this.state.scrollVisible ? '-sticky' : ''
                  }`
                ]
              }
              selectedClassName={styles['sap-iframe__tabs-selected']}
              flexContainerClassName={
                styles[
                  `sap-iframe__tabs-container${
                    this.state.scrollVisible ? '-sticky' : ''
                  }`
                ]
              }
            />
            <div
              className={
                styles[
                  `sap-iframe__divider-horizontal${
                    this.state.scrollVisible ? '-sticky' : ''
                  }`
                ]
              }
            ></div>
            {this.state.leftTabValue === 'claimTable' ? (
              <SAPDemandTable
                blur={demandTableBlur}
                items={liabilityItems}
                balance={balance}
                currency={currency}
                extended={liabilityStatementList}
                isSAPIframe
              />
            ) : this.state.leftTabValue === 'status' ? (
              <SAPTimeline
                blur={timelineBlur}
                items={timeline}
                isClientPortal
                isSAPIframe
              />
            ) : (
              <SAPQualtricsResults satisfaction={satisfaction} />
            )}
          </Grid>
          <div className={styles[`sap-iframe__divider`]}></div>
          <Grid item md={8} sm={12} xs={12}>
            <Tabs
              options={TABS_RIGHT}
              value={this.state.rightTabValue}
              onChange={this.onRightTabChange}
              tabClassName={styles['sap-iframe__tabs-tab']}
              indicatorClassName={
                styles[
                  `sap-iframe__tabs-indicator${
                    this.state.scrollVisible ? '-sticky' : ''
                  }`
                ]
              }
              selectedClassName={styles['sap-iframe__tabs-selected']}
              flexContainerClassName={
                styles[
                  `sap-iframe__tabs-container${
                    this.state.scrollVisible ? '-sticky' : ''
                  }`
                ]
              }
            />
            <div
              className={
                styles[
                  `sap-iframe__divider-horizontal${
                    this.state.scrollVisible ? '-sticky' : ''
                  }`
                ]
              }
            ></div>
            {this.state.rightTabValue === 'messages' ? (
              <PostBoxSAP
                {...{ token, notifications, groupCounts, searchDecision }}
                scrollVisible={this.state.scrollVisible}
              />
            ) : (
              <div></div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connectTo(
  ({
    auth: { token },
    sap: { businessPartnerID },
    notifications: { list: notifications, groupCounts },
    decisions: { 'troy.feature.ui.postbox.textsearch': searchDecision },
    liability: { liabilityItems, timeline, satisfaction },
    payment: {
      paymentUserInfo: { totalBalance, dueCurrency }
    },
    ui: {
      home: {
        liabilityStatementList,
        blur: { demandTable: demandTableBlur, timeline: timelineBlur },
        hide: { timeline: timelineHide, demandTable: demandTableHide }
      }
    }
  }) => ({
    token,
    notifications,
    groupCounts,
    searchDecision,
    liabilityItems,
    timeline,
    satisfaction,
    demandTableBlur,
    timelineBlur,
    timelineHide,
    demandTableHide,
    balance: totalBalance,
    currency: dueCurrency,
    liabilityStatementList,
    businessPartnerID
  }),
  { sapAuthentication },
  SAPIframe
);
