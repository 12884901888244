import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { motion } from 'framer-motion';
import { CustomIcon } from '@troy/shared/src/components/common';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '48px 56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    display: 'block',
    width: 76,
    height: 76
  }
}));

const UploadFileModalConfirmation = ({ onClose }) => {
  const classes = useStyles();

  const handleAnimationComplete = () => {
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  return (
    <Box className={classes.container}>
      <motion.div
        layout="position"
        initial={{ opacity: 0.1 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        onAnimationComplete={handleAnimationComplete}
      >
        <CustomIcon
          icon="confirm"
          className={classes.icon}
          variant="primary"
          size="custom"
        />
      </motion.div>
    </Box>
  );
};

export default UploadFileModalConfirmation;
