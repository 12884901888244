import { createSelector } from 'reselect';

const stateSelector = state => state.auth;

export const authLoadingSelector = createSelector(
  stateSelector,
  state => state.loading
);

export const isAuthTokenLoading = createSelector(
  stateSelector,
  state => state.tokenLoading
);

export const tokenSelector = createSelector(stateSelector, auth => auth.token);

export const usernameSelector = createSelector(
  stateSelector,
  auth => auth.username
);

export const isTokenInternalSelector = createSelector(
  stateSelector,
  state => state.internal
);
