import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { parsePhoneFromString } from '@troy/shared/src/utils/phone';

import { useAppWidthClass } from '../../utils/hooks';
import {
  citySelector,
  countrySelector,
  customerNameSelector,
  liabilityReturnGoodsPickupAddressDataSelector,
  liabilityReturnGoodsPickupDateSelector,
  phoneOrMobileSelector,
  postCodeSelector,
  returnGoodsErrorSelector,
  returnGoodsLoadingSelector,
  showBlueMovementReturnGoods,
  streetSelector
} from '../../store/selectors';
import { submitReturnGoods } from '../../store/actions';
import { HOME } from '../../constants/routes';
import { Module } from '@troy/shared/src/components/common';

import { SkeletonModule } from '../../components/common';
import {
  ReturnGoodsDetailsModal,
  ReturnGoodsEditView
} from '../../components/return-goods';
import ReturnGoodsSummaryView from '../../components/return-goods/ReturnGoodsSummaryView';

const useStyles = makeStyles(theme => ({
  module: {
    margin: `${theme.spacing(5)} ${theme.spacing(2)}`,
    padding: theme.spacing(5)
  },
  moduleTablet: {
    margin: `${theme.spacing(3)} ${theme.spacing(2)}`,
    padding: theme.spacing(2)
  },
  moduleMobile: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
  },
  content: {
    maxWidth: 410
  },
  contentTabletMobile: {
    width: '100%'
  }
}));

const ReturnGoodsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isDesktopWidth, isTabletWidth, isMobileWidth } = useAppWidthClass();

  const returnGoodsAllowed = useSelector(showBlueMovementReturnGoods);
  const pickupDate = useSelector(liabilityReturnGoodsPickupDateSelector);
  const returnGoodsLoading = useSelector(returnGoodsLoadingSelector);
  const returnGoodsError = useSelector(returnGoodsErrorSelector);

  const customerNameSaved = useSelector(customerNameSelector);
  const streetSaved = useSelector(streetSelector);
  const postCodeSaved = useSelector(postCodeSelector);
  const citySaved = useSelector(citySelector);
  const addressCountrySaved = useSelector(countrySelector);
  const savedPhone = useSelector(phoneOrMobileSelector);
  const { customerName: customerNameSubmitted } = useSelector(
    liabilityReturnGoodsPickupAddressDataSelector
  );

  const [customerName, setCustomerName] = useState(customerNameSaved);
  const [street, setStreet] = useState(streetSaved);
  const [postCode, setPostCode] = useState(postCodeSaved);
  const [city, setCity] = useState(citySaved);
  const [addressCountry, setAddressCountry] = useState(addressCountrySaved);
  const [phoneNr, setPhoneNr] = useState(parsePhoneFromString(savedPhone));
  const [isModalOpen, setModalOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (
      (returnGoodsAllowed !== null && !returnGoodsAllowed) ||
      (pickupDate !== null && !dataSubmitted)
    ) {
      history.replace(HOME);
    }
  }, [returnGoodsAllowed, pickupDate, dataSubmitted]);

  useEffect(() => {
    setCustomerName(customerNameSaved);
  }, [customerNameSaved]);

  useEffect(() => {
    setStreet(streetSaved);
  }, [streetSaved]);

  useEffect(() => {
    setPostCode(postCodeSaved);
  }, [postCodeSaved]);

  useEffect(() => {
    setCity(citySaved);
  }, [citySaved]);

  useEffect(() => {
    setAddressCountry(addressCountrySaved);
  }, [addressCountrySaved]);

  useEffect(() => {
    setPhoneNr(parsePhoneFromString(savedPhone));
  }, [savedPhone]);

  const onDeclineButton = () => {
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const onModalFormSubmit = values => {
    onCloseModal();
    setCustomerName(values.customerName);
    setStreet(values.street);
    setPostCode(values.postCode);
    setCity(values.city);
    setAddressCountry(values.country);
    setPhoneNr(parsePhoneFromString(values.phone.value));
  };

  useEffect(() => {
    if (isSubmitLoading) {
      if (!returnGoodsLoading) {
        setShowSummary(!returnGoodsError);
        window.scrollTo(0, 0);
      }
    }
  }, [isSubmitLoading, returnGoodsLoading, returnGoodsError]);

  const handleConfirm = (pickupDate, time) => {
    setDataSubmitted(true);
    setSubmitLoading(true);

    dispatch(
      submitReturnGoods({
        pickupDate: moment(pickupDate).format('YYYY-MM-DD'),
        time,
        customerName,
        street,
        postCode,
        city,
        addressCountry,
        phoneNumber: phoneNr.value
      })
    );
  };

  const showSkeleton =
    !customerNameSaved || (showSummary && !customerNameSubmitted);

  if (showSkeleton) {
    return (
      <div>
        <SkeletonModule topMargin type="widgets" />
        <SkeletonModule bottomMargin />
      </div>
    );
  }

  return (
    <Module
      id="return-goods-page-content"
      className={clsx(
        classes.module,
        isTabletWidth && classes.moduleTablet,
        isMobileWidth && classes.moduleMobile
      )}
    >
      {!showSummary && (
        <ReturnGoodsEditView
          contentClass={
            isDesktopWidth ? classes.content : classes.contentTabletMobile
          }
          onDeclineButton={onDeclineButton}
          onConfirm={handleConfirm}
          customerName={customerName}
          street={street}
          postCode={postCode}
          city={city}
          addressCountry={addressCountry}
          phone={phoneNr}
          isSubmitLoading={isSubmitLoading}
        />
      )}
      {showSummary && (
        <ReturnGoodsSummaryView
          contentClass={
            isDesktopWidth ? classes.content : classes.contentTabletMobile
          }
        />
      )}
      <ReturnGoodsDetailsModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onSubmit={onModalFormSubmit}
        initialValues={{
          customerName,
          street,
          postCode,
          city,
          country: addressCountry,
          phone: phoneNr
        }}
      />
    </Module>
  );
};

export default ReturnGoodsPage;
