import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './index.module.scss';

const BlurredComponent = ({
  children,
  mockData,
  mockImg,
  blur,
  className,
  contentClassName
}) =>
  blur ? (
    <div className={styles['blurred__wrapper']}>
      {!(mockData || mockImg) ? null : (
        <div className={clsx(styles['blurred__content'], contentClassName)}>
          {children}
        </div>
      )}
      {mockImg ? (
        <img
          src={mockImg}
          className={clsx(styles['blurred__mock-data'], className)}
          alt="blur-img"
        />
      ) : (
        <div
          className={clsx(
            styles['blurred__blur'],
            styles['blurred__mock-data'],
            className
          )}
        >
          {mockData || children}
        </div>
      )}
    </div>
  ) : (
    children
  );

BlurredComponent.propTypes = {
  children: PropTypes.node,
  mockData: PropTypes.node
};

export default BlurredComponent;
