import { createReducer } from 'redux-act';

import {
  findLatestAddress,
  findLatestEmail,
  findLatestPhoneMobile
} from '@troy/shared/src/utils/utils';

import * as actions from '../actions/profile';

const DEFAULT_STATE = {};

export default createReducer(
  {
    [actions.setProfileInfo]: (state, customer) => {
      const { firstname, lastname, companyName, address, contacts } = customer;
      const emailObj = findLatestEmail(contacts);
      const mobilePhoneObj = findLatestPhoneMobile(contacts);
      const phoneObj = findLatestPhoneMobile(contacts);
      const addr = findLatestAddress(address);

      return {
        firstname,
        lastname,
        companyName,
        email: emailObj.identifier,
        mobilePhone: mobilePhoneObj.identifier,
        phone: phoneObj.identifier,
        city: addr.city,
        country: addr.country,
        postcode: addr.zip,
        street: addr.street_and_house_nr
      };
    },
    [actions.profileInfoReset]: () => ({ ...DEFAULT_STATE })
  },
  DEFAULT_STATE
);
