import { call, put, select } from 'redux-saga/effects';

import { callHttp } from '../../utils/api';
import { get, post } from '../../utils/httpUtil';
import { LANGUAGE } from '../../constants/api';
import { DOMAIN_LANGUAGE } from '../../constants/browser';
import { getCustomers } from './customers';
import { GET_TRANSLATIONS } from '../../constants/graphql';
import client from '../../config/graphql';

import {
  availableLanguagesReceived,
  handleError,
  selectLanguage as selectLanguageAction,
  setLanguageFinal,
  setTranslations
} from '../actions';
import { defaultLanguageSelector } from '../selectors';

export function* getAvailableLanguages() {
  try {
    const languages = yield call(get, LANGUAGE);
    yield put(availableLanguagesReceived(languages));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* setLanguage({ payload: languageObj }) {
  let language;
  if (languageObj && languageObj.fullCode) {
    language = languageObj.fullCode;
    yield call(getTranslations, {
      request: { fullCode: languageObj.fullCode }
    });
  } else {
    const defaultLanguage = yield select(defaultLanguageSelector);
    yield put(selectLanguageAction(defaultLanguage));
    language = defaultLanguage;
  }
  yield put(setLanguageFinal(language));
}

export function* selectLanguage({
  payload: { code: fullCode, isAuthenticated }
}) {
  const request = { fullCode, source: 'LANGUAGE_CHOICE_BUTTON' };

  yield call(selectLanguageDefault, {
    ...request,
    isDynamicText: isAuthenticated
  });

  if (!isAuthenticated) {
    yield put(setLanguageFinal(fullCode));
  }
}

export function* selectBrowserLanguage({
  payload: { isAuthenticated, withTranslations }
}) {
  const fullCode = yield select(defaultLanguageSelector);

  yield call(selectLanguageDefault, {
    withTranslations,
    fullCode,
    source: 'DEFAULT_BROWSER_SETTING'
  });

  if (!isAuthenticated) {
    yield put(setLanguageFinal(fullCode));
  }
}

export function* selectLanguageDefault({
  isDynamicText,
  withTranslations = true,
  fullCode,
  source
}) {
  const body = { request: { fullCode, source } };
  const caller = isDynamicText ? callHttp : call;

  try {
    yield caller(post, LANGUAGE, body);
    if (withTranslations) {
      yield call(getTranslations, { isDynamicText, request: { fullCode } });
    }
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* getTranslations({ isDynamicText, request }) {
  if (isDynamicText) {
    yield call(getCustomers, request);
  } else {
    yield call(getStaticTranslations, request);
  }
}

export function* getStaticTranslations({ fullCode }) {
  try {
    const response = yield call(client.query, {
      query: GET_TRANSLATIONS(fullCode, DOMAIN_LANGUAGE)
    });
    const res = response.data.staticText2;
    if (res) {
      yield put(
        setTranslations({
          translations: res.notes_list,
          isStaticText: true
        })
      );
    } else {
      throw new Error(response.errors.message);
    }
  } catch (err) {
    yield put(handleError(err));
  }
}
