import { createAction } from 'redux-act';

export const getCustomers = createAction('Get customers');
export const customersReceived = createAction('Customers received');

export const customersReset = createAction('Reset customers');

export const submitReturnGoods = createAction('Return Goods submit');
export const submitReturnGoodsOk = createAction('Return Goods successful');
export const submitReturnGoodsError = createAction('Return Goods error');
