import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { liabilityReturnGoodsPickupDateSelector } from '../../../store/selectors';
import { Module } from '@troy/shared/src/components/common';

import ReturnGoodsInfoAppointment from './ReturnGoodsInfoAppointment';
import ReturnGoodsInfoSummary from './ReturnGoodsInfoSummary';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: `${theme.spacing(5)} ${theme.spacing(6)}`
  },
  rootCompact: {
    flexDirection: 'column'
  }
}));

const ReturnGoodsInfo = ({ id, isTabletWidth, isMobileWidth }) => {
  const classes = useStyles();
  const isCompact = isTabletWidth || isMobileWidth;
  const pickupDate = useSelector(liabilityReturnGoodsPickupDateSelector);

  return (
    <Module
      id={id}
      className={clsx(classes.root, isCompact && classes.rootCompact)}
    >
      {pickupDate === null && (
        <ReturnGoodsInfoAppointment isCompact={isCompact} />
      )}
      {pickupDate !== null && (
        <ReturnGoodsInfoSummary
          isTabletWidth={isTabletWidth}
          isMobileWidth={isMobileWidth}
        />
      )}
    </Module>
  );
};

export default ReturnGoodsInfo;
