import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useAppWidthClass } from '../../../utils/hooks';
import { Translation } from '../../common';
import { InputLabel } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1Bold,
    width: 124,
    flexShrink: 0,
    color: theme.palette.text.primary,
    wordBreak: 'break-word'
  },
  wider: {
    width: 144
  },
  compact: {
    ...theme.typography.body2Bold,
    width: '100%'
  }
}));

const PaymentFormRowLabel = ({ id, htmlFor, children, wider, compact }) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  return (
    <Translation
      id={id}
      component={InputLabel}
      htmlFor={htmlFor}
      keyIfNoValue
      className={clsx(
        classes.root,
        wider && classes.wider,
        (compact || isMobileWidth) && classes.compact
      )}
    >
      {children}
    </Translation>
  );
};

export default PaymentFormRowLabel;
