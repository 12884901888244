import React from 'react';
import { Select } from '..';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { CustomIcon } from '@troy/shared/src/components/common';

const useStyles = makeStyles(theme => ({
  typeSelect: {
    ...theme.typography.body1,
    border: 'none',
    borderRadius: theme.shape.borderRadius * 10,
    overflow: 'hidden',
    width: '100%',

    '&:before, &:after': {
      content: 'unset'
    },
    '& > fieldset': {
      borderColor: theme.palette.text.primary,
      top: -4
    }
  },
  selectPaper: {
    borderRadius: theme.shape.borderRadius * 4
  },
  selectRoot: {
    padding: `${theme.spacing(1)} ${theme.spacing(6)} ${theme.spacing(
      1
    )} ${theme.spacing(2)}`
  },
  selectIcon: {
    top: '50%',
    right: 16,
    transform: 'translateY(-50%)'
  },
  selectOption: {
    '&:hover:not([aria-selected="true"])': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.main
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    '&:hover[aria-selected="true"]': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
}));

const CommonSelect = ({
  id,
  labelId,
  name,
  value,
  options,
  validate,
  translated,
  classNameRoot,
  onChange
}) => {
  const classes = useStyles();
  return (
    <Select
      id={id}
      labelId={labelId}
      name={name}
      value={value}
      validate={validate}
      className={classes.typeSelect}
      classNameRoot={clsx(classes.selectRoot, classNameRoot)}
      classNamePaper={classes.selectPaper}
      classNameMenuItem={classes.selectOption}
      options={options}
      onChange={onChange}
      translated={translated}
      iconComponent={props => (
        <CustomIcon
          ariaHidden
          className={clsx(props.className, classes.selectIcon)}
          icon="expandMore"
          size="small"
          variant="textPrimary"
        />
      )}
    />
  );
};

export default CommonSelect;
