import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import { LOGIN } from '../../../constants/routes';
import {
  set2FAModalOpen,
  setRedirectOnPreviousPage
} from '../../../store/actions';
import { is2FAEnabledSelector } from '../../../store/selectors';

import { Blurred, Card, Link, ThemeButton, Translation } from '../index';

const useStyles = makeStyles(theme => ({
  card: {
    background: theme.palette.background.default,
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.2)',
    borderRadius: theme.shape.borderRadius * 6,
    width: 350,
    maxWidth: '100%'
  },
  cardContent: {
    padding: '24px !important',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  button: {
    margin: '24px auto 0 auto'
  }
}));

const BlurWithLogin = ({
  blur,
  children,
  mockData,
  mockImg,
  descriptionKey,
  className,
  buttonId
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const is2FAEnabled = useSelector(is2FAEnabledSelector);
  const handleButtonClick = () => dispatch(set2FAModalOpen(true));

  const onLinkClick = () => {
    dispatch(setRedirectOnPreviousPage(true));
  };

  return (
    <>
      {blur && (
        <Blurred
          contentClassName={className}
          mockData={mockData}
          mockImg={mockImg}
          blur={blur}
        >
          <Card className={classes.card} contentClassName={classes.cardContent}>
            <Typography variant="body2" component="p">
              <Translation inline>{descriptionKey}</Translation>
            </Typography>
            <ThemeButton
              id={buttonId}
              className={classes.button}
              variant="contained"
              size="medium"
              onClick={is2FAEnabled ? handleButtonClick : undefined}
              component={React.forwardRef((props, ref) =>
                is2FAEnabled ? (
                  <button {...props} ref={ref} />
                ) : (
                  <Link to={LOGIN} {...props} ref={ref} onClick={onLinkClick} />
                )
              )}
              textKey="LOGIN_NOW"
            />
          </Card>
        </Blurred>
      )}
      {!blur && children}
    </>
  );
};

BlurWithLogin.propTypes = {
  blur: PropTypes.bool,
  children: PropTypes.node,
  mockData: PropTypes.node,
  mockImg: PropTypes.string,
  descriptionKey: PropTypes.string,
  className: PropTypes.string,
  buttonId: PropTypes.string
};

export default BlurWithLogin;
