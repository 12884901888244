import logo from '@troy/shared/src/assets/img/matched/troy_future.png';

export const mockData = [
  {
    datetime: '2021-03-08T12:24:03.867Z',
    logo,
    summary: 'Timeline Item 1'
  },
  {
    datetime: '2021-03-10T12:24:03.867Z',
    logo,
    summary: 'Timeline Item 2'
  },
  {
    datetime: '2021-03-11T12:24:03.867Z',
    logo,
    summary: 'Timeline Item 3'
  }
];
