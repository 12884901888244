import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Slider from 'react-slick';
import moment from 'moment';
import styles from './sap.module.scss';
import BlurWithLogin from '../../common/BlurWithLogin/index.sap';

const TIMELINE_CENSORED = 'TIMELINE_CENSORED';

export const timelineView = ({ styles, sliderSettings, items }) => (
  <div className={styles['timeline__content']}>
    <div>The status shows all steps of past and future debt collection.</div>
    <div className={styles['timeline__content-content']}>
      <Slider {...sliderSettings(items)}>
        {items &&
          items.map(({ logo, summary, datetime, label, current }, index) => (
            <div>
              <div
                className={
                  styles[`timeline__content-circle${current ? '_active' : ''}`]
                }
              />
              <div
                className={
                  styles[`timeline__content-item${current ? '_active' : ''}`]
                }
                key={index}
              >
                <div
                  className={
                    styles[
                      `timeline__content-item-datetime-wrapper${
                        current ? '_active' : ''
                      }`
                    ]
                  }
                >
                  <div>{datetime && moment(datetime).format('DD/MM/YYYY')}</div>
                  <div>{summary}</div>
                  {logo ? (
                    <img
                      className={
                        styles['timeline__content-item-datetime-wrapper-image']
                      }
                      src={logo}
                      alt={summary}
                    />
                  ) : (
                    <div>{label}</div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  </div>
);

export const mockData = [
  {
    datetime: new Date().toISOString(),
    logo: undefined,
    summary: 'Timeline Item 1'
  },
  {
    datetime: new Date().toISOString(),
    logo: undefined,
    summary: 'Timeline Item 2'
  },
  {
    datetime: new Date().toISOString(),
    logo: undefined,
    summary: 'Timeline Item 3'
  }
];

export const CustomArrow = ({ className, style, onClick, direction }) => {
  return (
    <div
      className={clsx(
        className,
        styles[`timeline__content-slick-arrow-${direction}`]
      )}
      style={style}
      onClick={onClick}
      id={`page.home.component.timeline.${direction}-arrow`}
    />
  );
};

const sliderSettings = () => slides => ({
  dots: false,
  infinite: false,
  initialSlide: slides.indexOf(slides.find(({ current }) => current)) - 1,
  slidesToShow: 3,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  className: styles['timeline__content-slider'],
  nextArrow: <CustomArrow direction="next" styles={styles} />,
  prevArrow: <CustomArrow direction="prev" styles={styles} />
});

const Timeline = ({ items, blur, styles, sliderSettings }) => (
  <div className={styles['timeline__wrapper']}>
    <div className={styles['timeline__content-wrapper']}>
      <BlurWithLogin
        blur={blur}
        descriptionKey={TIMELINE_CENSORED}
        mockData={timelineView({
          styles,
          sliderSettings,
          items: mockData
        })}
        buttonClassName={styles['timeline__content-blur-button']}
        buttonId="page.sap-home.component.timeline.logout-button"
      >
        {items &&
          items.length &&
          timelineView({ styles, sliderSettings, items })}
      </BlurWithLogin>
    </div>
  </div>
);

Timeline.propTypes = {
  items: PropTypes.array,
  blur: PropTypes.bool,
  styles: PropTypes.object,
  sliderSettings: PropTypes.func
};

class SAPTimeline extends React.Component {
  render() {
    const { items, blur } = this.props;

    return (
      <Timeline
        {...{
          items,
          blur,
          styles
        }}
        sliderSettings={sliderSettings()}
      />
    );
  }
}

SAPTimeline.propTypes = {
  items: PropTypes.array,
  translations: PropTypes.object,
  blur: PropTypes.bool
};

export default SAPTimeline;
