import { createSelector } from 'reselect';
import { anyImageFile } from '@troy/shared/src/utils/images';

import portalAssets from '../../assets/whitelabel';
import { isValidCustomFooterData } from '../../utils/portal';
import { languageCodeSelector } from './';

const stateSelector = state => state.portal;

export const isPortalInitialized = createSelector(
  stateSelector,
  state => state.isInitialized
);

export const portalConfigurationSelector = createSelector(
  stateSelector,
  state => state.portalConf || {}
);

export const portalCountyExperienceSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.countyExperience
);

export const portalBrandNameSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.brandName
);

export const portalIdentifierSelector = createSelector(
  portalConfigurationSelector,
  ({ brand, countyExperience }) =>
    `${brand ? brand.toLowerCase() : 'troy'}_${countyExperience}`
);

export const portalHeaderBackgroundSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.customerPortalHeaderBackgroundGradient
);

export const portalModuleRadialBackgroundSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.customerPortalModuleRadialBackground
);

export const portalTimelineBackgroundSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.customerPortalTimelineBackground
);

export const portalDirectorDescriptionKeySelector = createSelector(
  languageCodeSelector,
  portalConfigurationSelector,
  (lang, conf) =>
    conf.customerPortalDirectorDescriptionKey &&
    conf.customerPortalDirectorDescriptionKey[lang]
);

export const portalHomeHeaderKeySelector = createSelector(
  languageCodeSelector,
  portalConfigurationSelector,
  (lang, conf) =>
    conf.customerPortalHomeHeaderKey && conf.customerPortalHomeHeaderKey[lang]
);

export const portalOnBehalfOfClientKeySelector = createSelector(
  languageCodeSelector,
  portalConfigurationSelector,
  (lang, conf) =>
    conf.customerPortalOnBehalfOfClientKey &&
    conf.customerPortalOnBehalfOfClientKey[lang]
);

export const portalGreetingMainKeySelector = createSelector(
  languageCodeSelector,
  portalConfigurationSelector,
  (lang, conf) =>
    conf.customerPortalGreetingMainKey &&
    conf.customerPortalGreetingMainKey[lang]
);

export const portalGreetingMainB2BKeySelector = createSelector(
  languageCodeSelector,
  portalConfigurationSelector,
  (lang, conf) =>
    conf.customerPortalGreetingMainB2BKey &&
    conf.customerPortalGreetingMainB2BKey[lang]
);

export const portalGreetingMainPaidKeySelector = createSelector(
  languageCodeSelector,
  portalConfigurationSelector,
  (lang, conf) =>
    conf.customerPortalGreetingMainPaidKey &&
    conf.customerPortalGreetingMainPaidKey[lang]
);

export const portalLogoSelector = createSelector(portalIdentifierSelector, id =>
  anyImageFile(portalAssets, id, 'logo')
);

export const portalLogoHeightSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.customerPortalLogoHeight
);

export const portalLoginBackgroundSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'login_background')
);

export const portalLoginBackgroundSmallSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'login_background_small')
);

export const portalLoginLogoSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'login_logo')
);

export const portalFooterLogoSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'footer_logo')
);

export const portalFooterLogoHeightSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.customerPortalFooterLogoHeight
);

export const portalFooterLogoSideImageSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'footer_logo_side')
);

export const portalDirectorNameSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.customerPortalDirectorName
);

export const portalDirectorImageSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'director')
);

export const portalDirectorSignatureSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'director_signature')
);

export const portalTimelineLogoSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'timeline_logo')
);

export const portalTimelineLogoFutureSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'timeline_logo_future')
);

export const portalTimelineHandoverImageSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'documents_icon')
);

export const portalCurrencyLocalOverrideSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.customerPortalCurrencyLocalOverride
);

export const portalFooterDataSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.footerData
);

export const portalHasCustomFooterSelector = createSelector(
  portalConfigurationSelector,
  portalFooterDataSelector,
  (conf, footerData) =>
    conf.hasCustomFooter && isValidCustomFooterData(footerData)
);

export const portalContactBarItemsSelector = createSelector(
  portalConfigurationSelector,
  conf => (conf && conf.customerPortalContactBar) || {}
);

export const customPortalPaymentUrlSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.customerPortalPaymentUrl
);
