import { createSelector } from 'reselect';

const stateSelector = state => state.twoFA;

export const is2FAModalOpenSelector = createSelector(
  stateSelector,
  state => state.is2FAModalOpen
);

export const is2FAEnabledSelector = createSelector(
  stateSelector,
  state => state.is2FAEnabled
);

export const typeOf2FASelector = createSelector(
  stateSelector,
  state => state.type
);

export const remainingAttempts2FASelector = createSelector(
  stateSelector,
  state => state.remainingAttempts
);

export const currentAttemptsMade2FASelector = createSelector(
  stateSelector,
  state => state.currentAttemptsMade
);

export const is2FABlockedSelector = createSelector(
  stateSelector,
  state => state.isBlocked
);

export const is2FALoadingSelector = createSelector(
  stateSelector,
  state => state.isLoading
);
