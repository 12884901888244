import React from 'react';

import { validRates } from '../../../constants/payment';

export default ({
  currency,
  balance,
  firstName,
  lastName,
  onSubmit,
  className,
  formRef,
  component: Component
}) => {
  const name = firstName + ((firstName && ' ') + lastName || '');
  const validRatesArr = validRates(balance);
  const initialValues = {
    iban: '',
    accountHolder: name,
    agree: false
  };
  return (
    <Component
      currency={currency}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validRates={validRatesArr}
      className={className}
      formRef={formRef}
      id="page.payments.component.sepa-direct-debit"
    />
  );
};
