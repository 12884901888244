import {
  hideCurrentLiveAgentButton,
  isLiveAgentChatOpen,
  RETURN_QURIOBOT,
  showLiveAgentChat
} from './liveAgentChat';
import { getQuriobotID } from '../../../utils/quriobot/quriobot_config';

let SCRIPT_CREATED = false;
let WIDGET_SHOW = false;
let WIDGET_INITIALIZED = false;

let LIVE_AGENT_MODE = false;

let lastRequestedParams = {
  isAuthenticated: false,
  displayWidget: false,
  language: null,
  firstName: null,
  lastName: null,
  email: null,
  caseId: null
};

let lastOpenParams = {
  isAuthenticated: false,
  displayWidget: false,
  language: null,
  firstName: null,
  lastName: null,
  email: null,
  caseId: null
};

export const showChatBot = (
  isAuthenticated,
  displayWidget,
  language,
  firstName,
  lastName,
  email,
  caseId,
  uploadTypesForQurio,
  uploadTypesForApi,
  uploadCallback,
  customerEmail,
  customerPhone,
  communicationTone
) => {
  lastRequestedParams = {
    isAuthenticated,
    displayWidget,
    language,
    firstName,
    lastName,
    email,
    caseId,
    uploadTypesForQurio,
    uploadTypesForApi,
    uploadCallback,
    customerEmail,
    customerPhone,
    communicationTone
  };

  mainLoop();
};

const mainLoop = () => {
  if (lastRequestedParams.displayWidget || LIVE_AGENT_MODE) {
    if (!isLiveAgentChatOpen()) {
      createScript();
      updateBotIfNeeded();
    }
  } else {
    tryToRemoveWidget();
  }
};

export const chatBotReturnFromLiveAgentChat = () => {
  LIVE_AGENT_MODE = false;
  mainLoop();
};

const tryToDestroy = () => {
  if (window.quriobot && window.quriobot.inited) {
    window.quriobot.destroy();
  }
};

// decision key says to remove (example logged out)
const tryToRemoveWidget = () => {
  // only can remove if it is attached
  if (!WIDGET_SHOW) {
    return;
  }
  // when talking to live agent block removing
  if (LIVE_AGENT_MODE) {
    return;
  }
  // remove only if it exists
  if (!!window.quriobot) {
    WIDGET_SHOW = false;
    WIDGET_INITIALIZED = false;
    tryToDestroy();
  }
};

const updateBotIfNeeded = () => {
  if (LIVE_AGENT_MODE) {
    if (WIDGET_SHOW) {
      WIDGET_SHOW = false;
      WIDGET_INITIALIZED = false;
      tryToDestroy();
    }

    return;
  }
  // was destroyed at some point
  if (!WIDGET_SHOW) {
    restartBot();
    WIDGET_SHOW = true;
  } else {
    if (!WIDGET_INITIALIZED) {
      return;
    }
    // check if is chatting and did not log-in/out
    if (
      window.quriobot.isOpen &&
      lastOpenParams.isAuthenticated === lastRequestedParams.isAuthenticated &&
      lastOpenParams.firstName === lastRequestedParams.firstName
    ) {
      return;
    }

    // bot "should not" be open now
    // check if language or authentication has changed since last check
    if (paramsChanged()) {
      WIDGET_INITIALIZED = false;
      tryToDestroy();
      restartBot();
    }
  }
};

const paramsChanged = () => {
  return (
    lastOpenParams.language !== lastRequestedParams.language ||
    lastOpenParams.isAuthenticated !== lastRequestedParams.isAuthenticated ||
    lastOpenParams.caseId !== lastRequestedParams.caseId ||
    lastOpenParams.firstName !== lastRequestedParams.firstName
  );
};

const restartBot = () => {
  window.qbOptions[0].use = getQuriobotID(lastRequestedParams.isAuthenticated);
  window.qbOptions[0].language = lastRequestedParams.language;
  window.qbOptions[0].visitor = createVisitor();
  window.qbOptions[0].context = createContextDataForBot();
  lastOpenParams = { ...lastRequestedParams };

  hideCurrentLiveAgentButton();
  window.quriobot.init();
};

const createScript = () => {
  if (SCRIPT_CREATED) {
    return;
  }
  SCRIPT_CREATED = true;

  if (!Array.isArray(window.qbOptions)) {
    window.qbOptions = [];
  }

  window.uploadCustomerFile = (filesText, type, method, value, description) => {
    const uploadType = lastRequestedParams.uploadTypesForApi.find(
      u => u.label === type
    ).value;
    const filePieces = filesText.split(',');
    const fileCount = filePieces.length / 3;
    const files = [];
    let callbackCount = 0;

    const callback = () => {
      callbackCount++;
      if (callbackCount < fileCount) {
        return;
      }
      lastRequestedParams.uploadCallback(
        files,
        uploadType,
        method,
        value,
        description
      );
    };

    for (let i = 0; i < fileCount; i++) {
      const fileName = filePieces[i * 3 + 1];
      const fileUrl = filePieces[i * 3 + 2];

      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], fileName, {
            type: blob.type,
            lastModified: blob.lastModified
          });
          files.push(file);
          callback();
        });
    }
  };

  window.switchToLiveChat = (firstName, lastName, caseId, email) => {
    LIVE_AGENT_MODE = true;
    if (WIDGET_SHOW) {
      WIDGET_SHOW = false;
      WIDGET_INITIALIZED = false;
      tryToDestroy();
    }
    showLiveAgentChat(
      RETURN_QURIOBOT,
      lastOpenParams.isAuthenticated,
      lastOpenParams.language,
      !!email ? email : lastOpenParams.email,
      !!firstName ? firstName : lastOpenParams.firstName,
      !!lastName ? lastName : lastOpenParams.lastName,
      !!caseId ? caseId : lastOpenParams.caseId,
      lastOpenParams.communicationTone
    );
  };

  lastOpenParams = { ...lastRequestedParams };

  window.qbOptions.push({
    baseUrl: 'https://botsrv2.com',
    use: getQuriobotID(lastRequestedParams.isAuthenticated),
    language: lastRequestedParams.language,
    visitor: createVisitor(),
    context: createContextDataForBot(),
    // 1) choose no help needed, bot will close
    // 2) choose no additional info needed
    // 3) exit from final conversation step
    onFinish: () => {
      setTimeout(() => {
        mainLoop();
      }, 1100);
    },
    // minimize chat from X
    onLeave: () => {
      mainLoop();
    },
    onInit: () => {
      WIDGET_INITIALIZED = true;
      WIDGET_SHOW = true;
      mainLoop();
    }
  });

  hideCurrentLiveAgentButton();
  WIDGET_SHOW = true;

  let script = document.createElement('script');
  script.src = 'https://static.botsrv2.com/website/js/widget2.b3eaea3b.min.js';
  script.integrity =
    'sha384-fqo7pJUTo0XK8ZCBcNdASmO0oWjb9LdK3zkE1ZEcnDIxRFEXODm3AvKr8KWnFd9w';
  script.crossOrigin = 'anonymous';
  script.defer = true;
  script.setAttribute('data-no-minify', '1');
  script.setAttribute('data-cfasync', 'false');

  const lastScript = document.scripts[document.scripts.length - 1];
  lastScript.parentElement.insertBefore(script, lastScript.nextSibling);
};

const createContextDataForBot = () => {
  let context = {};
  if (lastRequestedParams.caseId) {
    context = {
      caseId: lastRequestedParams.caseId,
      uploadTypes: lastRequestedParams.uploadTypesForQurio,
      customerEmail: lastRequestedParams.customerEmail,
      customerPhone: lastRequestedParams.customerPhone
    };
  }
  return context;
};

const createVisitor = () => {
  let visitor;
  if (
    lastRequestedParams.firstName ||
    lastRequestedParams.lastName ||
    lastRequestedParams.email
  ) {
    visitor = {
      firstName: lastRequestedParams.firstName,
      lastName: lastRequestedParams.lastName,
      email: lastRequestedParams.email
    };
  }
  return visitor;
};
