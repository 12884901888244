import { call, put, select, take } from 'redux-saga/effects';
import { goBack, push, replace } from 'react-router-redux';

import {
  FORGOTPASSWORD,
  GET_USER,
  LOGIN,
  LOGOUT,
  RESETPASSWORD
} from '../../constants/api';
import { callHttp } from '../../utils/api';
import { get, post } from '../../utils/httpUtil';

import * as routes from '../../constants/routes';

import {
  customersReset,
  customerTextsReset,
  decisionsReceived,
  decisionsReset,
  forgotPasswordOk,
  getCustomers,
  getDecisions,
  handleError,
  headersReset,
  liabilityReset,
  loginError,
  loginOk,
  logoutOk,
  paymentReset,
  profileInfoReset,
  questionsReset,
  resetPasswordOk,
  setAuthData,
  setRedirectOnPreviousPage,
  setToken,
  translationsReset,
  twoFAReset,
  uiReset,
  wordpressReset
} from '../actions';
import { translationsSelector } from '../selectors';
import { isInIframe } from '../../utils/browser';
import { oneTimeTokenLoginRequest } from '../../utils/token';
import { enqueueSnackbar } from '@troy/shared/src/store/actions';
import { initLanguagesAndAlsoCustomerIfAuthenticated } from './portal';
import { trackUIEvent } from '../../utils/ui';
import { DOMAIN_LANGUAGE } from '../../constants/browser';

export function* login({ payload }) {
  const { username, password } = payload;
  const translations = yield select(translationsSelector);

  try {
    if (username.trim().length === 0 || password.trim().length === 0) {
      yield put(
        enqueueSnackbar({
          message: 'LOGIN_EMPTY_ERROR',
          options: {
            variant: 'error'
          }
        })
      );
      yield put(loginError());
      return;
    }
    const { user } = yield call(post, LOGIN, { user: { username, password } });

    yield put(decisionsReset());
    yield put(customerTextsReset());

    yield put(
      loginOk({
        token: user.token,
        username: user.username,
        id: user.id,
        image: user.image,
        email: user.email
      })
    );
    yield put(getDecisions());
    yield take(decisionsReceived.getType());
    yield put(getCustomers());

    const redirectOnPreviousPage = yield select(
      state => state.ui.redirectOnPreviousPage
    );
    if (redirectOnPreviousPage) {
      yield put(goBack());
      yield put(setRedirectOnPreviousPage(false));
    } else {
      yield put(push(routes.HOME));
    }
  } catch (err) {
    yield put(loginError(err));
    trackUIEvent('error-failed_login-' + DOMAIN_LANGUAGE);
    if (err.status === 422) {
      yield put(
        handleError(
          new Error(
            translations['LOGIN_GENERIC_ERROR'] ||
              'Sorry, it seems there was an error while logging you in. Please try again!'
          )
        )
      );
    } else {
      yield put(handleError(err));
    }
  }
}

export function* getUser() {
  try {
    const { user } = yield callHttp(get, GET_USER);
    yield put(
      setAuthData({
        username: user.username,
        email: user.email
      })
    );
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* clearStore() {
  yield put(customersReset());
  yield put(decisionsReset());
  yield put(liabilityReset());
  yield put(paymentReset());
  yield put(translationsReset());
  yield put(questionsReset());
  yield put(wordpressReset());
  yield put(uiReset());
  // yield put(errorReset());
  yield put(headersReset());
  yield put(twoFAReset());
  yield put(profileInfoReset());

  if (!isInIframe()) {
    localStorage.clear();
  }

  yield put(logoutOk());
}

export function* logout({
  payload: { useToken = true, showErrorPage = false } = {}
}) {
  try {
    const token = yield select(store => store.auth.token); // due to this error on 21.01 with infinite refresh I had to implement this workaround
    if (token && useToken) {
      yield callHttp(post, LOGOUT, {});
    }
    if (showErrorPage) {
      yield put(push(routes.ERROR));
    } else {
      yield put(push(routes.HOME));
    }
    yield call(clearStore);
    yield call(initLanguagesAndAlsoCustomerIfAuthenticated);
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* clearURLHistory({ payload }) {
  yield put(replace(payload));
}

export function* forgotPassword({ payload }) {
  const { email } = payload;

  try {
    yield callHttp(post, FORGOTPASSWORD, { email });
    yield put(forgotPasswordOk());
    yield put(
      enqueueSnackbar({
        message: 'Forgot password call succeeded',
        options: {
          variant: 'success'
        }
      })
    );
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* resetPassword({ payload }) {
  try {
    const { email, password, token } = payload;
    yield callHttp(post, RESETPASSWORD, {
      email: email,
      password: password,
      token: token
    });
    yield put(resetPasswordOk());
    yield put(
      enqueueSnackbar({
        message: 'Reset password succeeded',
        options: {
          variant: 'success'
        }
      })
    );
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* getJWTOnOneTimeToken({ payload: oneTimeToken }) {
  try {
    const response = yield call(oneTimeTokenLoginRequest, oneTimeToken);
    const jwtToken = response.jwtToken;
    yield put(setToken(jwtToken));
  } catch (err) {
    yield put(handleError(err));
  }
}
