import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { motion } from 'framer-motion';

import { Blurred, PayNowButton } from '../../common';
import { fadeInAnimationItem } from '../../../constants/animations';
import PaymentMethodsList from './PaymentMethodsList';
import { useSelector } from 'react-redux';
import { hasNutunPaymentSelector } from '../../../store/selectors';
import PaymentMethodsListNutun from './PaymentMethodsListNutun';

export const HOME_PAY_NOW_LINK = 'page.home.component.pay-now.pay-now-link';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 136,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(
      1.5
    )} ${theme.spacing(3)}`,
    background: theme.palette.background.default,
    boxSizing: 'border-box'
  },
  rootMobile: {
    minHeight: 118,
    padding: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(
      1
    )} ${theme.spacing(2)}`
  },
  rootNutun: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  payNowMobile: {
    width: '100%',
    maxWidth: 256
  }
}));

const Payment = ({
  isMobileSize,
  balance,
  paymentMethods,
  paymentHide,
  blur
}) => {
  const classes = useStyles();
  const isNutunPayment = useSelector(hasNutunPaymentSelector);

  return (
    <div
      className={clsx(
        classes.root,
        isMobileSize && classes.rootMobile,
        isNutunPayment && classes.rootNutun
      )}
    >
      <Blurred blur={blur}>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeInAnimationItem}
          transition={{ delay: 0.4 }}
        >
          <PayNowButton
            id={HOME_PAY_NOW_LINK}
            className={isMobileSize ? classes.payNowMobile : null}
          />
        </motion.div>
      </Blurred>
      {!isNutunPayment && (
        <PaymentMethodsList
          isMobileSize={isMobileSize}
          balance={balance}
          paymentMethods={paymentMethods}
          paymentHide={paymentHide}
          blur={blur}
        />
      )}
      {isNutunPayment && (
        <PaymentMethodsListNutun isMobileSize={isMobileSize} blur={blur} />
      )}
    </div>
  );
};

Payment.propTypes = {
  isMobileSize: PropTypes.bool,
  paymentMethods: PropTypes.array,
  paymentHide: PropTypes.bool,
  balance: PropTypes.number,
  blur: PropTypes.bool
};

export default Payment;
