import React from 'react';
import ReactDOM from 'react-dom';
import 'moment/locale/de';
import 'moment/locale/tr';
import 'moment/locale/nl';
import 'moment/locale/sv';
import moment from 'moment-timezone';

import App from './App.troy';
import './index.troy.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-intl-tel-input/dist/main.css';

// most important information for development, provided by MC-Troy
// https://www.youtube.com/playlist?list=PLzPxiX2iZvTLjTAg1M4vHMkfmSAQpCVLI

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
