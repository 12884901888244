import { put } from 'redux-saga/effects';

import { post } from '../../utils/httpUtil';
import { callHttp } from '../../utils/api';
import { SECOND_FACTOR_LOGIN } from '../../constants/api';
import {
  customerTextsReset,
  getCustomers,
  getDecisions,
  handle2FALoginError,
  handleError,
  set2FAModalOpen,
  setToken
} from '../actions';

export function* submit2FALogin({ payload }) {
  try {
    const { isSuccessful, jwtToken } = yield callHttp(
      post,
      SECOND_FACTOR_LOGIN,
      {
        user: {
          secondFactorType: payload.type,
          secondFactor: payload.code
        }
      }
    );

    if (isSuccessful) {
      yield put(set2FAModalOpen(false));
      yield put(setToken(jwtToken));
      yield put(customerTextsReset());
      yield put(getDecisions());
      yield put(getCustomers());
    } else {
      yield put(handle2FALoginError());
    }
  } catch (err) {
    yield put(handleError(err));
    yield put(set2FAModalOpen(false));
    yield put(handle2FALoginError());
  }
}
