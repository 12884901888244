import { call, put, select, take } from 'redux-saga/effects';

import { getPortalUrlData, getSearchUrl } from '../../utils/httpUtil';
import { isInIframe } from '../../utils/browser';
import { isTokenValid } from '../../utils/jwt';

import {
  getPortalConfiguration as getPortalConfigurationAction,
  setPortalInitialized
} from '@troy/shared/src/store/actions';

import * as routes from '../../constants/routes';

import {
  availableLanguagesReceived,
  clearURLHistory,
  getAvailableLanguages,
  getCustomers,
  getDecisions,
  getJWTOnOneTimeToken,
  handleError,
  removeToken,
  sapAuthentication,
  selectBrowserLanguage,
  setAuthTokenLoading,
  setBusinessPartnerID,
  setCustomHeaders,
  setToken
} from '../actions';

import { isPortalInitialized, tokenSelector } from '../selectors';

export function* initPortal() {
  try {
    const isInitialized = yield select(isPortalInitialized);
    if (isInitialized) {
      return;
    }

    const {
      oneTimeToken,
      jwtFromUrl,
      businessPartnerID,
      hasJwtInUrl,
      hasOneTimeTokenInUrl,
      otherUrlParams
    } = getPortalUrlData();

    if (Object.keys(otherUrlParams).length) {
      yield put(setCustomHeaders(otherUrlParams));
    }

    yield put(
      getPortalConfigurationAction({
        portalKeyBoolean: 'customerAppEnabled',
        handleErrorAction: handleError
      })
    );

    const isIframe = isInIframe();

    const pathname = window.location.pathname;
    const isSAPIframe = pathname && pathname === routes.SAP_IFRAME;

    const storedToken = isIframe ? '' : localStorage.getItem('token');

    if (hasOneTimeTokenInUrl) {
      // one time token login and storage
      yield put(setAuthTokenLoading(true));
      yield put(getJWTOnOneTimeToken(oneTimeToken));
      const result = yield take([setToken.getType(), handleError.getType()]);
      if (result.type === handleError.getType()) {
        yield put(setPortalInitialized(true));
        yield put(setAuthTokenLoading(false));
        // skip requests if login fails
        return;
      }
    } else if (hasJwtInUrl) {
      if (isTokenValid(jwtFromUrl)) {
        // store token
        yield put(setToken(jwtFromUrl));
      } else {
        yield put(removeToken());
      }
    } else if (isSAPIframe && !!businessPartnerID) {
      // sap login, no information how it works or is used
      yield put(setAuthTokenLoading(true));
      yield put(setBusinessPartnerID(businessPartnerID));
      yield put(sapAuthentication(businessPartnerID));
      yield take([setToken.getType(), handleError.getType()]);
    } else if (!!storedToken && storedToken.length > 1) {
      if (isTokenValid(storedToken)) {
        // restore token from storage
        yield put(setToken(storedToken));
      } else {
        yield put(removeToken());
      }
    }

    if (hasJwtInUrl || hasOneTimeTokenInUrl) {
      yield put(
        clearURLHistory(
          getSearchUrl({
            url: pathname,
            query: otherUrlParams
          })
        )
      );
    }
    yield put(setPortalInitialized(true));
    yield put(setAuthTokenLoading(false));
    yield call(initLanguagesAndAlsoCustomerIfAuthenticated);
  } catch (err) {
    yield put(handleError(err));
    yield put(setPortalInitialized(true));
    yield put(setAuthTokenLoading(false));
  }
}

export function* initLanguagesAndAlsoCustomerIfAuthenticated() {
  const token = yield select(tokenSelector);
  const isAuthenticated = !!token;

  yield put(getDecisions());
  yield put(getAvailableLanguages());
  yield take(availableLanguagesReceived.getType());
  yield put(
    selectBrowserLanguage({
      isAuthenticated,
      withTranslations: !isAuthenticated
    })
  );
  if (isAuthenticated) {
    yield put(getCustomers());
  }
}
