import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import { ThemeButton } from '../../common';

import CookieGroup from './CookieGroup';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  backButtonIcon: {
    transform: 'rotate(180deg)'
  },
  groups: {
    overflow: 'auto',
    marginTop: theme.spacing(5),
    padding: `0 ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,

    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  buttons: {
    display: 'flex',
    paddingTop: theme.spacing(5)
  },
  buttonsMobile: {
    flexDirection: 'column',
    paddingTop: theme.spacing(3)
  },
  button: {
    width: `calc(50% - ${theme.spacing(1)})`,

    '&:first-of-type': {
      marginRight: theme.spacing(1)
    },
    '&:last-of-type': {
      marginLeft: theme.spacing(1)
    }
  },
  buttonMobile: {
    width: '100%',

    '&:first-of-type': {
      marginRight: 0
    },
    '&:last-of-type': {
      marginLeft: 0,
      marginTop: theme.spacing(2)
    }
  }
}));

const CookieAgreementDetailsScreen = ({
  isMobileWidth,
  onSaveClick,
  onCancelClick,
  onBackClick
}) => {
  const classes = useStyles();
  const [functional, setFunctional] = useState(true);
  const [analytical, setAnalytical] = useState(true);

  const handleSaveClick = () => {
    onSaveClick(functional, analytical);
  };

  return (
    <div className={clsx(classes.content)}>
      <ThemeButton
        fullWidth
        id="page.component.cookie-agreement.back-button"
        size="medium"
        color="textSecondary"
        onClick={onBackClick}
        textKey="COOKIE_BANNER_SETTINGS"
        endIcon="expandMore"
        endIconColor="textSecondary"
        iconClassName={classes.backButtonIcon}
      />
      <div className={classes.groups}>
        <CookieGroup
          alwaysActive
          isMobileWidth={isMobileWidth}
          id="essential"
          title="COOKIE_BANNER_ESSENTIAL"
          cookies={['COOKIE_CLOUDWATCH']}
        />
        <CookieGroup
          isEnabled={functional}
          toggleEnabled={setFunctional}
          isMobileWidth={isMobileWidth}
          id="functional"
          title="COOKIE_BANNER_FUNCTIONAL"
          cookies={[
            'COOKIE_QURIOBOT',
            'COOKIE_LIVEAGENT',
            'COOKIE_TYPEFORM',
            'COOKIE_KOREAI'
          ]}
        />
        <CookieGroup
          isEnabled={analytical}
          toggleEnabled={setAnalytical}
          isMobileWidth={isMobileWidth}
          id="analytical"
          title="COOKIE_BANNER_ANALYTICAL"
          cookies={['COOKIE_GOOGLEANALYTICS', 'COOKIE_GOOGLETAGMANAGER']}
        />
      </div>
      <div
        className={clsx(
          classes.buttons,
          isMobileWidth && classes.buttonsMobile
        )}
      >
        <ThemeButton
          className={clsx(
            classes.button,
            isMobileWidth && classes.buttonMobile
          )}
          id="page.component.cookie-agreement.save-button"
          variant="contained"
          textKey="COOKIE_BANNER_SAVE"
          onClick={handleSaveClick}
        />
        <ThemeButton
          className={clsx(
            classes.button,
            isMobileWidth && classes.buttonMobile
          )}
          id="page.component.cookie-agreement.decline-button"
          variant="outlined"
          color="primaryLight"
          textKey="COOKIE_BANNER_BUTTON_DECLINE"
          onClick={onCancelClick}
        />
      </div>
    </div>
  );
};

export default CookieAgreementDetailsScreen;
