import { Logger } from 'cloudwatch-front-logger';
import { isDomainNL } from '../constants/browser';
import { isDomainBE } from '../constants/browser';
import { envKeyString } from '../constants/env';
import { CORRELATION_ID } from '../constants/api';

export const createLogger = () => {
  const accessKeyId = 'AKIASQ2KSKIELD4GMB53';
  const secretAccessKey = '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';
  const region = 'eu-west-1';
  const logGroupName = '/Troy/Client/Log';

  const logger = new Logger(accessKeyId, secretAccessKey, region, logGroupName);
  if (process.env.JEST_WORKER_ID === undefined) {
    // bug in library, clear previous log stream name and sequence
    localStorage.removeItem('CloudWatchFrontLogger:logStreamName');
    localStorage.removeItem('CloudWatchFrontLogger:sequenceToken');

    logger.install({
      logStreamNameResolver() {
        const isNL = isDomainNL() || isDomainBE();
        return `${isNL ? 'nl-' : 'de-'}${envKeyString()}-cc-${CORRELATION_ID}`;
      }
    });
  }

  return logger;
};

export default createLogger();
