export const filterOptionsMap = {
  ALL: 'all',
  LETTER: 'post',
  SMS: 'sms',
  EMAIL: 'email',
  CHAT: 'chat',
  CUSTOMERCENTER: 'customercenter',
  INTERNAL_NOTE: 'internal-note',
  CLIENT: 'client',
  CLIENT_CHAT: 'chat'
};

export const filterTypeOptionsMap = {
  ALL: 'all',
  TROY: 'troy',
  CUSTOMER: 'customer'
};

export const filterOptions = [
  {
    label: 'FILTER_POST',
    name: filterOptionsMap['LETTER']
  },
  {
    label: 'FILTER_SMS',
    name: filterOptionsMap['SMS']
  },
  {
    label: 'FILTER_EMAIL',
    name: filterOptionsMap['EMAIL']
  },
  {
    label: 'FILTER_CHAT',
    name: filterOptionsMap['CHAT']
  },
  {
    label: 'FILTER_KUNDENCENTER',
    name: filterOptionsMap['CUSTOMERCENTER']
  }
];

export const clientFilterOption = {
  label: 'FILTER_CLIENT',
  name: filterOptionsMap['CLIENT']
};

export const filterTypeOptions = [
  {
    label: 'All',
    name: filterTypeOptionsMap['ALL']
  },
  {
    label: 'Troy',
    name: filterTypeOptionsMap['TROY']
  },
  {
    label: 'Customer',
    name: filterTypeOptionsMap['CUSTOMER']
  }
];

export const CUSTOMER_MESSAGE_TYPES_MAP = {
  FROM_CUSTOMER: 'from_customer',
  TO_CUSTOMER: 'to_customer',
  TO_CUSTOMER_FROM_LAWYER: 'to_customer_from_lawyer',
  TO_CUSTOMER_FROM_CLIENT: 'to_customer_from_client',
  INTERNAL: 'internal',
  CLIENT: 'client'
};

export const CUSTOMER_MESSAGE_TYPES_SAP = [
  { label: 'all', types: [] },
  {
    label: 'troy',
    types: [
      CUSTOMER_MESSAGE_TYPES_MAP['TO_CUSTOMER'],
      CUSTOMER_MESSAGE_TYPES_MAP['TO_CUSTOMER_FROM_LAWYER'],
      CUSTOMER_MESSAGE_TYPES_MAP['TO_CUSTOMER_FROM_CLIENT']
    ]
  },
  {
    label: 'customer',
    types: [CUSTOMER_MESSAGE_TYPES_MAP['FROM_CUSTOMER']]
  }
];
