import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { isDomainNL } from '../../constants/browser';
import { isDomainBE } from '../../constants/browser';
import { useAppWidthClass } from '../../utils/hooks';
import { Text } from '@troy/shared/src/components/common';

import manDogImg from '../../assets/img/feedback/mandog.png';
import payLaterImg from '../../assets/img/feedback/paylater_thanks.png';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: 'linear-gradient(256.82deg, #540FC9 8.34%, #AC2BED 42.92%)'
  },
  containerDesktop: {
    height: 213
  },
  containerTabletMobile: {
    height: 177
  },
  img: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: ' auto'
  },
  imgTablet: {
    left: -30
  },
  imgTablet2: {
    left: -110
  },
  imgMobile: {
    left: -35
  },
  message: {
    position: 'relative',
    textAlign: 'center',
    padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
    background: theme.palette.primary.contrastText,
    boxShadow: theme.customShadows.module,
    borderRadius: theme.shape.borderRadius * 6,
    boxSizing: 'border-box'
  },
  messageDesktop: {
    width: 433,
    marginRight: 72
  },
  messageTablet: {
    width: 345,
    marginRight: 24
  },
  messageDesktop2: {
    maxWidth: 433,
    marginRight: 112
  },
  messageTablet2: {
    maxWidth: 345,
    marginRight: 54
  }
}));

const ManDog = ({
  displayIfNL,
  manDogPayLater,
  textKey = manDogPayLater
    ? 'FEEDBACK_PAYLATER_PHOTO_TEXT'
    : 'FEEDBACK_INSTALLMENT_PHOTO_TEXT'
}) => {
  const classes = useStyles();
  const { isDesktopWidth, isTabletWidth, isMobileWidth } = useAppWidthClass();

  const visible = displayIfNL && (isDomainNL() || isDomainBE());

  return !visible ? null : (
    <Box
      className={clsx(
        classes.container,
        isDesktopWidth && classes.containerDesktop,
        !isDesktopWidth && classes.containerTabletMobile
      )}
    >
      <img
        className={clsx(
          classes.img,
          isDesktopWidth && classes.containerDesktop,
          !isDesktopWidth && classes.containerTabletMobile,
          isTabletWidth &&
            (manDogPayLater ? classes.imgTablet2 : classes.imgTablet),
          isMobileWidth && classes.imgMobile
        )}
        src={manDogPayLater ? payLaterImg : manDogImg}
        alt="illustration"
      />
      {!isMobileWidth && (
        <Box
          className={clsx(
            classes.message,
            isDesktopWidth &&
              (manDogPayLater
                ? classes.messageDesktop2
                : classes.messageDesktop),
            isTabletWidth &&
              (manDogPayLater ? classes.messageTablet2 : classes.messageTablet)
          )}
        >
          <Text
            translated
            color="secondary"
            component="h2"
            variant={isDesktopWidth ? 'h3' : 'h4'}
          >
            {textKey}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default ManDog;
