import { createReducer } from 'redux-act';

import * as actions from '../actions/wordpress';

const DEFAULT_STATE = {
  impressum: {},
  privacyStatement: {}
};

export default createReducer(
  {
    [actions.impressumPageDownloaded]: (state, data) => {
      return {
        ...state,
        impressum: { ...data, content: data.content.rendered }
      };
    },
    [actions.privacyStatementPageDownloaded]: (state, data) => {
      return {
        ...state,
        privacyStatement: { ...data, content: data.content.rendered }
      };
    },
    [actions.wordpressReset]: state => {
      return { ...DEFAULT_STATE };
    }
  },
  DEFAULT_STATE
);
