import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { Decision } from '@troy/shared/src/components/common';

import { HOME } from '../../../constants/routes';
import { useAppWidthClass } from '../../../utils/hooks';
import {
  HEADER_BAR_HEIGHT,
  HEADER_CONTACTS_HEIGHT,
  HEADER_NAVIGATION_HEIGHT,
  HEADER_PAY_BAR_HEIGHT
} from '../../../constants/ui';
import { hasPayButtonInHeader } from '../../../utils/ui';
import {
  hasFAQPageLinksSelector,
  isAuthenticatedAppLoadingSelector,
  translationsSelector
} from '../../../store/selectors';
import { HOME_PAY_NOW_LINK } from '../../home/Payment';
import { Link, PayNowButton, ThemeButton } from '../../common';

const BUTTON_MARGIN = 8;

const useStyles = makeStyles(theme => ({
  common: {
    textAlign: 'center',
    background: theme.palette.background.default,
    boxSizing: 'border-box',
    transition: theme.transitions.custom.header
  },
  homeBarWrapper: {
    display: 'none',
    boxShadow: theme.customShadows.menu
  },
  homeBarVisible: {
    display: 'flex',
    justifyContent: 'center',
    animation: '$homeBarIn 120ms forwards'
  },
  '@keyframes homeBarIn': {
    '0%': {
      height: 0
    },
    '100%': {
      height: HEADER_PAY_BAR_HEIGHT
    }
  },
  defaultBarHidden: {
    display: 'none'
  },
  defaultBar: {
    height: HEADER_PAY_BAR_HEIGHT
  },
  defaultBarSticky: {
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 1,
    width: '100vw',
    boxShadow: theme.customShadows.menu
  },
  button: {
    marginTop: BUTTON_MARGIN,

    '&:first-of-type:not(:last-of-type)': {
      marginRight: theme.spacing(1)
    },
    '&:last-of-type:not(:first-of-type)': {
      marginLeft: theme.spacing(1)
    }
  },
  mobileButton: {
    width: 256,

    '&:first-of-type:not(:last-of-type)': {
      width: 164
    },
    '&:last-of-type:not(:first-of-type)': {
      width: 164
    }
  }
}));

const PayNowBar = ({
  isInHeader,
  currentRoute,
  isDefaultPayBarSticky,
  setIsDefaultPayBarSticky,
  headerAnimationShift
}) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();
  const isAuthenticatedAppLoading = useSelector(
    isAuthenticatedAppLoadingSelector
  );
  const translations = useSelector(translationsSelector);

  const barRef = useRef();
  const [isHomeBarVisible, setIsHomeBarVisible] = useState(false);

  const isHome = currentRoute === HOME;
  const isHomeHeaderOrDefaultHeader = isHome === !!isInHeader;
  const isRouteWithPayBar = hasPayButtonInHeader(
    currentRoute,
    false,
    isHomeHeaderOrDefaultHeader
  );

  const onScrollForHomePageInHeader = useCallback(() => {
    const payNowLink = document.getElementById(HOME_PAY_NOW_LINK);

    if (payNowLink && payNowLink.offsetTop > 0) {
      if (
        window.scrollY >=
        payNowLink.offsetTop - HEADER_NAVIGATION_HEIGHT - BUTTON_MARGIN
      ) {
        if (!isHomeBarVisible) {
          setIsHomeBarVisible(true);
        }
      } else {
        if (isHomeBarVisible) {
          setIsHomeBarVisible(false);
        }
      }
    }
  }, [isHomeBarVisible, setIsHomeBarVisible]);

  const onScrollDefault = useCallback(() => {
    if (barRef && barRef.current) {
      if (
        window.scrollY >
        HEADER_BAR_HEIGHT + (isMobileWidth ? 0 : HEADER_CONTACTS_HEIGHT)
      ) {
        if (!isDefaultPayBarSticky) {
          setIsDefaultPayBarSticky(true);
        }
      } else {
        if (isDefaultPayBarSticky) {
          setIsDefaultPayBarSticky(false);
        }
      }
    }
  }, [isMobileWidth, barRef, isDefaultPayBarSticky, setIsDefaultPayBarSticky]);

  useEffect(() => {
    if (isRouteWithPayBar && isHome && isInHeader) {
      window.addEventListener('scroll', onScrollForHomePageInHeader);
    } else if (isRouteWithPayBar && !isHome && !isInHeader) {
      window.addEventListener('scroll', onScrollDefault);
    }
    return () => {
      if (isRouteWithPayBar && isHome && isInHeader) {
        window.removeEventListener('scroll', onScrollForHomePageInHeader);
      } else if (isRouteWithPayBar && !isHome && !isInHeader) {
        window.removeEventListener('scroll', onScrollDefault);
      }
    };
  }, [
    isMobileWidth,
    isRouteWithPayBar,
    isInHeader,
    isHome,
    isHomeBarVisible,
    isDefaultPayBarSticky,
    barRef
  ]);

  const top = Math.max(
    HEADER_BAR_HEIGHT +
      HEADER_NAVIGATION_HEIGHT +
      (isMobileWidth ? 0 : HEADER_CONTACTS_HEIGHT) -
      headerAnimationShift,
    HEADER_NAVIGATION_HEIGHT
  );

  return isRouteWithPayBar && isHomeHeaderOrDefaultHeader ? (
    <Box
      ref={barRef}
      className={clsx(
        classes.common,
        isHome && classes.homeBarWrapper,
        isHomeBarVisible && classes.homeBarVisible,
        !isHome && isAuthenticatedAppLoading && classes.defaultBarHidden,
        !isHome && !isAuthenticatedAppLoading && classes.defaultBar,
        isDefaultPayBarSticky && classes.defaultBarSticky
      )}
      style={{ top: `${top}px` }}
    >
      <Decision selector={hasFAQPageLinksSelector}>
        <ThemeButton
          className={clsx(
            classes.button,
            isMobileWidth && classes.mobileButton
          )}
          id="page.component.home-header.faq-link"
          variant="outlined"
          textKey="FAQ_BANNER_CTA"
          component={React.forwardRef((props, ref) => (
            <Link
              raw
              to={translations['FAQ_BANNER_URL'] || 'https://hilfe.troy.de'}
              {...props}
              ref={ref}
              target="_blank"
            />
          ))}
        />
      </Decision>
      <PayNowButton
        id="page.component.home-header.pay-now-link"
        className={clsx(classes.button, isMobileWidth && classes.mobileButton)}
      />
    </Box>
  ) : null;
};

export default PayNowBar;
