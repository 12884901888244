import clsx from 'clsx';
import { motion } from 'framer-motion';
import { makeStyles } from '@mui/styles';
import { fadeInAnimationItem } from '../../../constants/animations';
import { Blurred } from '../../common';

import visa from '../../../assets/img/payment/nutun/logo-visa-sm 1.png';
import masterCard from '../../../assets/img/payment/nutun/logo-mastercard-sm 1.png';
import standardBank from '../../../assets/img/payment/nutun/logo-standard-bank-sm 1.png';
import nedbank from '../../../assets/img/payment/nutun/logo-nedbank-sm 1.png';
import fnb from '../../../assets/img/payment/nutun/logo-fnb-sm 1.png';
import capitec from '../../../assets/img/payment/nutun/logo-capitec-sm 1.png';
import absa from '../../../assets/img/payment/nutun/logo-absa-sm 1.png';

const METHODS = [visa, masterCard, standardBank, nedbank, fnb, capitec, absa];

const useStyles = makeStyles(theme => ({
  methods: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    '& > div': {
      display: 'inline-flex'
    }
  },
  logo: {
    height: theme.spacing(4)
  },
  logoMargin: {
    marginLeft: theme.spacing(1)
  },
  logoMobile: {
    height: theme.spacing(3)
  }
}));

const PaymentMethodsList = ({ isMobileSize, blur }) => {
  const classes = useStyles();

  return (
    <div className={classes.methods}>
      {METHODS.map((src, index) => (
        <Blurred blur={blur} key={index}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInAnimationItem}
            transition={{ delay: 0.5 + index * 0.1 }}
          >
            <img
              className={clsx(
                index > 0 && classes.logoMargin,
                classes.logo,
                isMobileSize && classes.logoMobile
              )}
              src={src}
              alt="payment-method"
            />
          </motion.div>
        </Blurred>
      ))}
    </div>
  );
};

export default PaymentMethodsList;
