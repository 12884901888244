import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { useAppWidthClass } from '@troy/shared/src/utils/hooks';

import { hasStaticTextsSelector } from '../../../store/selectors';

import FooterLogo from '../FooterLogo';
import FooterNavigationLinks from '../FooterNavigationLinks';
import FooterSkeleton from '../FooterSkeleton';
import CustomFooterRow from './CustomFooterRow';

const useStyles = makeStyles((theme: Theme) => ({
  skeleton: {
    background: theme.palette.secondary.dark
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.text.primary,
    color: theme.palette.secondary.contrastText,
    ...theme.typography.body2
  },
  logoDesktop: {
    marginLeft: theme.spacing(1)
  },
  footerContainerDesktop: {
    width: 944,
    maxWidth: '100%',
    padding: `${theme.spacing(10)} 0`
  },
  footerContainerTabletMobile: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    margin: `0 ${theme.spacing(2)}`
  },
  columnsDesktop: {
    padding: `0 ${theme.spacing(1)}`,
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between'
  },
  columnsTablet: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  columnsMobile: {
    display: 'flex',
    flexDirection: 'column'
  },
  columnDesktop: {
    display: 'flex',
    flexDirection: 'column',
    width: 214
  },
  columnTablet: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc(50% - ${theme.spacing(1)})`,

    '&:nth-child(odd)': {
      marginRight: theme.spacing(1)
    },
    '&:nth-child(even)': {
      justifyContent: 'flex-end',
      marginLeft: theme.spacing(1)
    },
    '&:nth-child(1n + 3),&:nth-child(1n + 4)': {
      justifyContent: 'flex-end',
      marginTop: theme.spacing(5)
    }
  },
  columnMobile: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4)
  },
  link: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
    textDecoration: 'none',
    border: 'none',
    padding: 0,
    background: 'transparent',
    textAlign: 'left',
    cursor: 'pointer'
  }
}));

interface CustomFooterProps {
  isAuthenticated?: boolean;
  logout: () => void;
  footerData: string[][];
}

const CustomFooter: React.FC<CustomFooterProps> = ({
  isAuthenticated,
  logout,
  footerData
}): React.ReactElement => {
  const classes = useStyles();
  const hasStaticTexts = useSelector(hasStaticTextsSelector);
  const { isDesktopWidth, isTabletWidth, isMobileWidth } = useAppWidthClass();

  const columnCount = footerData.length;

  if (!hasStaticTexts) {
    return <FooterSkeleton className={classes.skeleton} />;
  }

  return (
    <footer className={classes.footer}>
      <div
        className={clsx(
          isDesktopWidth && classes.footerContainerDesktop,
          (isTabletWidth || isMobileWidth) &&
            classes.footerContainerTabletMobile
        )}
      >
        <FooterLogo className={isDesktopWidth ? classes.logoDesktop : ''} />
        <div
          className={clsx(
            isDesktopWidth && classes.columnsDesktop,
            isTabletWidth && classes.columnsTablet,
            isMobileWidth && classes.columnsMobile
          )}
        >
          {footerData.map((column, i) => {
            return (
              <div
                key={i}
                className={clsx(
                  isDesktopWidth && classes.columnDesktop,
                  isTabletWidth && classes.columnTablet,
                  isMobileWidth && classes.columnMobile
                )}
              >
                {column.map((row, j) => (
                  <CustomFooterRow key={j} row={row} linkClass={classes.link} />
                ))}
                {i === columnCount - 1 && columnCount >= 3 && (
                  <FooterNavigationLinks
                    linkClass={classes.link}
                    hasLogout={isAuthenticated}
                    logout={logout}
                  />
                )}
              </div>
            );
          })}
          {columnCount < 3 && (
            <div
              className={clsx(
                isDesktopWidth && classes.columnDesktop,
                isTabletWidth && classes.columnTablet,
                isMobileWidth && classes.columnMobile
              )}
            >
              <FooterNavigationLinks
                linkClass={classes.link}
                hasLogout={isAuthenticated}
                logout={logout}
              />
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default CustomFooter;
