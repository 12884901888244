import { createReducer } from 'redux-act';

import * as actions from '../actions/questions';

const DEFAULT_STATE = {
  toClient: null,
  toAgency: null,
  feedback: null,
  questions: []
};

const filterMapArr = (arr, filterField, filterValue) =>
  arr
    .filter(obj => obj[filterField] === filterValue)
    .map(({ entityID: id, text, group, type }) => ({ id, text, group, type }));

export default createReducer(
  {
    [actions.setQuestions]: (state, questions) => {
      return {
        toClient: filterMapArr(questions, 'group', 'NEXT_STEPS'),
        toAgency: filterMapArr(questions, 'group', 'AGENCY'),
        feedback: filterMapArr(questions, 'group', 'SATISFACTION'),
        questions
      };
    },
    [actions.questionsReset]: state => {
      return { ...DEFAULT_STATE };
    }
  },
  DEFAULT_STATE
);
