import { createAction } from 'redux-act';

export const getImpressum = createAction('Get impressum page');
export const impressumPageDownloaded = createAction('Imressum page downloaded');

export const getPrivacyStatement = createAction('Get privacy statement page');
export const privacyStatementPageDownloaded = createAction(
  'Privacy statement page downloaded'
);

export const wordpressReset = createAction('Reset wordpress state');
