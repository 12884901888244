import { envKeyString } from '../../constants/env';

const KEY_FORMAL = 'formal';
const KEY_INFORMAL = 'informal';

const typeformConfig = {
  [KEY_FORMAL]: {
    de_DE: {
      dev: 'ycdPGoSb',
      test: 'ycdPGoSb',
      demo: 'ycdPGoSb',
      prod: 'FDPBMtil'
    }
  },
  [KEY_INFORMAL]: {
    de_DE: {
      dev: 'dKMdisae',
      test: 'dKMdisae',
      demo: 'dKMdisae',
      prod: 'vReE64M5'
    },
    en_GB: {
      dev: 'ZiBgXVFS',
      test: 'ZiBgXVFS',
      demo: 'ZiBgXVFS',
      prod: 'YPUblyCv'
    },
    nl_NL: {
      dev: 'jaAOyYmS',
      test: 'KTT9xavm',
      demo: 'KTT9xavm',
      prod: 'KTT9xavm'
    },
    tr_TR: {
      dev: 'WU3xVh99',
      test: 'WU3xVh99',
      demo: 'WU3xVh99',
      prod: 'm2OmUBiY'
    },
    sv_SE: {
      dev: 'Arvqliwo',
      test: 'Arvqliwo',
      demo: 'Arvqliwo',
      prod: 'oH4lMHmg'
    },
    fr_FR: {
      dev: 'ZiBgXVFS',
      test: 'ZiBgXVFS',
      demo: 'ZiBgXVFS',
      prod: 'YPUblyCv'
    },
  }
};

export const getTypeformId = (language, isFormal) => {
  const hasFormal = isFormal && !!typeformConfig[KEY_FORMAL][language];
  const hasLang =
    !!typeformConfig[hasFormal ? KEY_FORMAL : KEY_INFORMAL][language];

  return typeformConfig[hasFormal ? KEY_FORMAL : KEY_INFORMAL][
    hasLang ? language : 'DE'
  ][envKeyString()];
};
