import jwt_decode from 'jwt-decode';

export const jwtDecode = token => jwt_decode(token);

export const isTokenValid = token => tokenExp(token) >= new Date().getTime();

export const tokenExp = token => {
  if (token) {
    const decoded = jwtDecode(token);
    return decoded && typeof decoded.exp !== 'undefined'
      ? decoded.exp * 1000
      : 0;
  } else {
    return 0;
  }
};
