import { post } from './httpUtil';
import { GET_TOKEN_ON_ONE_TIME, ONE_TIME_TOKEN } from '../constants/api';

import { handleError } from '../store/actions';

import store from '../store';

export const getOneTimeToken = async () => {
  try {
    const { token } = await post(ONE_TIME_TOKEN, {}, true);
    return token;
  } catch (err) {
    store.dispatch(handleError(err));
  }
};

export const oneTimeTokenLoginRequest = oneTimeToken =>
  post(GET_TOKEN_ON_ONE_TIME, {
    request: {
      token: oneTimeToken
    }
  });
