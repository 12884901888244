import React from 'react';
import PropTypes from 'prop-types';

import MaterialAvatar from '@mui/material/Avatar';

const Avatar = ({ alt, src, className }) => (
  <MaterialAvatar {...{ alt, src, className }} />
);

Avatar.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string
};

export default Avatar;
