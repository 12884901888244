import React from 'react';
import styles from './sap.module.scss';

import { TextField } from '../../common';

const Search = ({ styles, onSearchChange, searchValue, onSearchClear }) => (
  <div className={styles['sap-search']}>
    <div className={styles['sap-search__wrapper']}>
      <TextField
        placeholder="Search"
        className={styles['sap-search__input']}
        classNameInput={styles['sap-search__input_focus']}
        classNameNotched={styles['sap-search__input-fieldset']}
        endIcon={
          searchValue ? (
            <ClearIcon
              className={styles['sap-search__input-icon']}
              styles={styles}
            />
          ) : (
            <SearchIcon
              className={styles['sap-search__input-icon']}
              styles={styles}
            />
          )
        }
        onChange={onSearchChange}
        onEndIconClick={onSearchClear}
        value={searchValue}
      />
    </div>
  </div>
);

export const SearchIcon = ({ styles, className }) => (
  <div className={className}>
    <i className={styles['icon-search']} />
  </div>
);

export const ClearIcon = ({ styles, className }) => (
  <div className={className}>
    <i className={styles['icon-sys-cancel']} />
  </div>
);

const SAPSearch = props => <Search {...{ styles, ...props }} />;

export default SAPSearch;
