import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import { makeStyles } from '@mui/styles';

import { CustomIcon } from '@troy/shared/src/components/common';

import { useAppWidthClass } from '../../../utils/hooks';
import { STARS } from '../../../constants/questionTypes';

import { ThemeButton } from '../../common';

const useStyles = makeStyles(theme => ({
  question: {
    '&:nth-of-type(1n+2)': {
      marginTop: 24
    }
  },
  questionTitle: {
    ...theme.typography.body1Bold,
    color: theme.palette.text.primary
  },
  rating: {
    marginTop: 4
  },
  item: {
    display: 'inline-flex'
  },
  star: {
    margin: '0 4px'
  },
  iconActive: {
    transform: 'scale(1.25)'
  },
  submitContainer: {
    width: 224,
    display: 'flex',
    justifyContent: 'center'
  },
  submit: {
    marginTop: 32,
    minWidth: 224
  },
  progressContainer: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 112,
    width: '100%',
    height: 48,
    padding: '0 16px',
    background: theme.palette.primary.contrastText,
    boxShadow: theme.customShadows.module,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box'
  },
  sticky: {
    position: 'fixed',
    top: 80
  },
  progressBg: {
    width: '100%',
    maxWidth: 300,
    height: 8,
    background: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius * 1,
    overflow: 'hidden'
  },
  progress: {
    width: '100%',
    height: 8,
    background: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius * 1,
    transform: 'translate(-100%)',
    transition: theme.transitions.custom.fast
  },
  count: {
    marginLeft: 21,
    flexShrink: 0
  }
}));

const RatingForm = ({
  className,
  pageName,
  questions,
  buttonKey,
  onSubmit
}) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();
  const [values, setValues] = useState([]);
  const [focusIndex, setFocusIndex] = useState(0);
  const [progressBarSticky, setProgressBarSticky] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateValues = useCallback(() => {
    const filtered =
      questions && questions.length
        ? questions.filter(q => q.type === STARS)
        : [];

    const initialValues = filtered.map(({ group, id, text }) => ({
      group,
      id,
      text,
      value: (values.find(e => e.id === id) || { value: 0 }).value
    }));

    const newFocus = initialValues.findIndex(q => q.value === 0);
    setFocusIndex(newFocus);
    setValues(initialValues);
  }, [questions, values]);

  useEffect(() => {
    updateValues();
  }, [questions]);

  const onScroll = useCallback(() => {
    setProgressBarSticky(window.pageYOffset >= 32);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  const onButtonClick = () => {
    setLoading(true);
    onSubmit(values);
  };

  if (values.length === 0) {
    return null;
  }

  const answered = values.reduce((acc, v) => acc + (v.value > 0 ? 1 : 0), 0);
  const total = values.length;
  const progress = (1 - answered / total) * 100;

  return (
    <Box id="feedback-rating-form" className={className}>
      {isMobileWidth && (
        <Box
          className={clsx(
            classes.progressContainer,
            progressBarSticky && classes.sticky
          )}
        >
          <Box className={classes.progressBg}>
            <Box
              className={classes.progress}
              style={{ transform: `translate(-${progress}%)` }}
            />
          </Box>
          <Box className={classes.count}>
            <Typography
              variant="subtitle1"
              color={answered === total ? 'primary' : 'textSecondary'}
            >
              {answered}/{total}
            </Typography>
          </Box>
        </Box>
      )}
      {values.map(({ text, id, value }, idx) => {
        const handleChange = (e, value) => {
          let newValues = [...values];
          newValues.find(q => q.id === id).value = value == null ? 0 : value;
          const newFocus = newValues.findIndex(q => q.value === 0);
          setFocusIndex(newFocus);
          setValues(newValues);
        };

        return (
          <Box
            key={id}
            className={classes.question}
            data-testid="rating-form-question"
          >
            <Typography className={classes.questionTitle} variant="body1">
              {text}
            </Typography>
            <Rating
              classes={{
                root: classes.rating,
                label: classes.item,
                iconActive: classes.iconActive
              }}
              name={id}
              value={value}
              emptyIcon={
                <CustomIcon
                  className={classes.star}
                  icon={idx === focusIndex ? 'starFocus' : 'starEmpty'}
                  size="medium"
                />
              }
              icon={
                <CustomIcon
                  className={classes.star}
                  icon={idx === focusIndex ? 'starFocusFilled' : 'starFilled'}
                  size="medium"
                />
              }
              onChange={handleChange}
            />
          </Box>
        );
      })}
      <div className={classes.submitContainer}>
        <ThemeButton
          id={`page.${pageName}.rating-form.send-button`}
          className={classes.submit}
          variant="contained"
          size="large"
          disabled={focusIndex >= 0}
          textKey={buttonKey}
          startIcon="confirm"
          startIconColor="primaryContrast"
          onClick={onButtonClick}
          isLoading={loading}
        />
      </div>
    </Box>
  );
};

export default RatingForm;
