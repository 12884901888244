import React from 'react';
import { Redirect } from 'react-router-dom';

import { FEEDBACK, THANKS } from '../../constants/routes';
import { connectTo } from '../../utils';

const PaymentSuccess = ({ showFeedback }) =>
  showFeedback === null ? null : (
    <Redirect to={showFeedback ? FEEDBACK : THANKS} />
  );

export default connectTo(
  ({
    payment: {
      show: { feedback }
    }
  }) => ({
    showFeedback: feedback
  }),
  {},
  PaymentSuccess
);
