import * as errorTypes from '../../constants/errorStatuses';

const EN = 'en_GB';
const DE = 'de_DE';
const NL = 'nl_NL';

const supportedLanguages = [EN, DE, NL];

const MESSAGES = {
  [errorTypes.PAYMENT_CANCEL]: {
    // payment cancel, used for all payment methods
    [DE]: 'Die Durchführung der Zahlung wurde leider abgebrochen. Bitte versuchen Sie es erneut.',
    [NL]: 'De betaling is afgebroken. Wij verzoeken u het nogmaals te proberen.',
    [EN]: 'Please excuse me, but I was not able to process your payment. Please try again in a moment.'
  },
  // Payment failed, used for all payment methods
  [errorTypes.PAYMENT_EXPIRED]: {
    [DE]: 'Aufgrund eines technischen Fehlers wurde die Zahlung leider nicht erfolgreich durchgeführt. Bitte versuchen Sie es erneut oder melden Sie sich bei uns.',
    [NL]: 'De betaling is vanwege een technische fout niet gelukt. Wij verzoeken u het nogmaals te proberen of contact met ons op te nemen.',
    [EN]: 'Due to a technical problem we couldn’t process your payment. We’re really sorry about that – please try again. If this keeps happening, don’t hesitate to reach out to us.'
  },
  // Login timeout or a request needs token, or token  invalid
  [errorTypes.FORBIDDEN]: {
    [DE]: 'Um Ihre Daten zu schützen, loggen wir Sie automatisch aus, wenn Sie unsere Seite nicht nutzen. Bitte loggen Sie sich daher wieder ein.',
    [NL]: 'Om uw gegevens te beschermen, loggen wij u automatisch uit wanneer u onze website niet gebruikt. Wij verzoeken u opnieuw in te loggen.',
    [EN]: 'In order to protect your personal data, we log you out after a certain amount of time. Please log in again.'
  },
  // Timeout of the API (slow conneciton)
  [errorTypes.TIMEOUT]: {
    [DE]: 'Leider gibt es Verbindungsprobleme. Bitte suchen sie einen besseren Netzempfang und versuchen es erneut.',
    [NL]: 'Er zijn helaas verbindingsproblemen opgetreden. Wij verzoeken u een betere verbinding te zoeken en het opnieuw te proberen.',
    [EN]: 'We seem to have some connection issues. Please try again in a few moments.'
  },
  // API exception, or server exception
  [errorTypes.SERVER_ERROR]: {
    [DE]: 'Das Kundencenter steht zur Zeit nicht zur Verfügung. Versuchen Sie es bitte zu einem späteren Zeitpunkt noch einmal.',
    [NL]: 'Het klantenportaal is op dit moment niet beschikbaar. Wij vragen je om het op een later tijdstip nog eens te proberen.',
    [EN]: 'I had a problem loading your page. Please try again – or navigate to another page.'
  },
  // Error in the UI (React UI or in the sagas)
  [errorTypes.EXCEPTION]: {
    [DE]: 'Die Seite wurde leider nicht richtig geladen. Bitte laden Sie die Seite neu oder benutzen Sie den Zurück-Button.',
    [NL]: 'De pagina werd niet goed geladen. Wij vragen je de pagina opnieuw te laden of de terug knop te gebruiken.',
    [EN]: 'I had a problem loading your page. Please try again – or navigate to another page.'
  },
  // Page cannot be loaded (most likely stale bookmark, possibly error in deployment)
  // or API 404 error
  // or any other case when no status info
  [errorTypes.NOT_FOUND]: {
    [DE]: 'Die Seite wurde leider nicht richtig geladen. Bitte laden Sie die Seite neu oder benutzen Sie den Zurück-Button.',
    [NL]: 'De pagina kan niet worden geladen. Wij vragen je de pagina opnieuw te laden of de terug knop te gebruiken.',
    [EN]: 'I had a problem loading your page. Please try again – or navigate to another page.'
  }
};

const STATUS_TO_MESSAGE_KEY_MAP = {
  [errorTypes.PAYMENT_CANCEL]: errorTypes.PAYMENT_CANCEL,
  [errorTypes.PAYMENT_EXPIRED]: errorTypes.PAYMENT_EXPIRED,
  [errorTypes.PAYMENT_PENDING]: errorTypes.PAYMENT_EXPIRED,
  [errorTypes.PAYMENT_UNAUTHORIZED]: errorTypes.PAYMENT_EXPIRED,
  [errorTypes.PAYMENT_FAIL]: errorTypes.PAYMENT_EXPIRED,
  [errorTypes.FORBIDDEN]: errorTypes.FORBIDDEN,
  [errorTypes.TIMEOUT]: errorTypes.TIMEOUT,
  [errorTypes.ABORT_ERROR]: errorTypes.TIMEOUT,
  [errorTypes.SERVER_ERROR]: errorTypes.SERVER_ERROR,
  [errorTypes.EXCEPTION]: errorTypes.EXCEPTION,
  [errorTypes.NOT_FOUND]: errorTypes.NOT_FOUND
};

function toErrorKey(status, language) {
  let statusKey = STATUS_TO_MESSAGE_KEY_MAP[status];
  if (!statusKey) {
    statusKey = errorTypes.NOT_FOUND;
  }
  let lang = language;
  if (!supportedLanguages.includes(language)) {
    lang = EN;
  }

  return MESSAGES[statusKey][lang];
}

export default toErrorKey;
