import { createSelector } from 'reselect';
import {
  BROWSER_LANGUAGE,
  createFullLanguageCode,
  DOMAIN_LANGUAGE,
  languageFromFullCode
} from '../../constants/browser';

const stateSelector = state => state.translations;

export const translationsSelector = createSelector(
  stateSelector,
  translations => translations.translations
);

export const languagesSelector = createSelector(
  stateSelector,
  translations => translations.availableLanguages
);

export const languageSelector = createSelector(
  stateSelector,
  translations => translations.language
);

export const languageCodeSelector = createSelector(languageSelector, lang =>
  languageFromFullCode(lang)
);

export const hasStaticTextsSelector = createSelector(
  stateSelector,
  ({ hasStaticTexts }) => hasStaticTexts
);

export const hasCustomerTextsSelector = createSelector(
  stateSelector,
  ({ hasCustomerTexts }) => hasCustomerTexts
);

export const hasAllTextsSelector = createSelector(
  stateSelector,
  ({ hasStaticTexts, hasCustomerTexts }) => hasStaticTexts && hasCustomerTexts
);

export const defaultLanguageSelector = createSelector(
  languagesSelector,
  languages => {
    const fullCode = createFullLanguageCode(BROWSER_LANGUAGE);

    return (
      (languages &&
        !!languages.find(({ fullCode: language }) => fullCode === language) &&
        fullCode) ||
      createFullLanguageCode(DOMAIN_LANGUAGE)
    );
  }
);

export const flagSelectLanguagesSelector = createSelector(
  languagesSelector,
  languages =>
    languages
      ? languages.reduce(
          (acc, { fullCode, language }) => ({
            ...acc,
            [fullCode]: language
          }),
          {}
        )
      : []
);
