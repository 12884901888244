import {
  UIFilterOption,
  UINotification,
  UINotificationChannelFilter,
  UINotificationDirectionFilter,
  UINotificationsFiltered
} from '../../types/notifications';

export enum MessageDateTimeOrder {
  NewestFirst,
  OldestFirst
}

export const makeFiltersFromFilterOptions = (
  filterOptions: UIFilterOption[]
): Record<string, boolean> =>
  filterOptions.reduce((acc, { type }) => ({ ...acc, [type]: true }), {});

const itemFilter = (
  directionFilter: UINotificationDirectionFilter,
  directionFilterCategory: UINotificationDirectionFilter,
  filters: Record<UINotificationChannelFilter, boolean>,
  channel: UINotificationChannelFilter | null
): boolean => {
  // only filter if at least on channel is selected
  return (
    (directionFilter === 'all' ||
      directionFilterCategory === directionFilter) &&
    channel !== null &&
    filters[channel]
  );
};

const itemOrder =
  (isNewestFirst?: boolean) =>
  (a: UINotification, b: UINotification): number =>
    (isNewestFirst ? -1 : 1) *
    (new Date(a.datetime).getTime() - new Date(b.datetime).getTime());

export const filterNotifications = (
  notificationsList: UINotification[],
  filters: Record<UINotificationChannelFilter, boolean>,
  directionFilter: UINotificationDirectionFilter,
  isTwoLanes: boolean,
  isNewestFirst?: boolean
): UINotificationsFiltered => {
  const list = notificationsList || [];
  const result: UINotificationsFiltered = {
    rightLane: [],
    leftLane: []
  };
  if (!isTwoLanes) {
    result.rightLane = list
      .filter(({ channelFilterCategory, directionFilterCategory }) =>
        itemFilter(
          directionFilter,
          directionFilterCategory,
          filters,
          channelFilterCategory
        )
      )
      .sort(itemOrder(isNewestFirst));
  } else {
    result.leftLane = list
      .filter(
        ({
          doubleLaneColumn,
          channelFilterCategory,
          directionFilterCategory
        }) => {
          return (
            doubleLaneColumn === 'external' &&
            itemFilter(
              directionFilter,
              directionFilterCategory,
              filters,
              channelFilterCategory
            )
          );
        }
      )
      .sort(itemOrder(isNewestFirst));
    result.rightLane = list
      .filter(
        ({
          doubleLaneColumn,
          channelFilterCategory,
          directionFilterCategory
        }) => {
          return (
            doubleLaneColumn === 'internal' &&
            itemFilter(
              directionFilter,
              directionFilterCategory,
              filters,
              channelFilterCategory
            )
          );
        }
      )
      .sort(itemOrder(isNewestFirst));
  }
  return result;
};
