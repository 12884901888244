import gql from 'graphql-tag';

export const GET_CUSTOMERS = gql`
  {
    customers {
      language {
        language
        fullCode
      }

      firstname
      lastname
      companyName
      birthdate
      contacts {
        created
        purpose
        identifier
        type
      }
      address {
        datetime
        name
        address_line_2
        street_and_house_nr
        city
        zip
        country
      }
      liabilities {
        locale
        clientCustomerRefNo
        refNo
        secondFactorAttempts {
          secondFactor
          remainingAttempts
          isBlocked
        }
        pickup {
          pickup_date
          pickup_timerange
          pickup_address {
            name
            address_line_2
            street_and_house_nr
            city
            zip
            country
          }
          pickup_contact {
            identifier
          }
          pickup_state
        }
        ikaros_collection_case_number
        assigned_agent {
          name
          avatar_url
          signature_url
        }
        mandant {
          ikaros_mandant_id
          name
          secondFactors
        }
        category {
          name
          clientcategoryrefno
          logourl
          formal_salutation
          overlaycolor
        }
        optin {
          datetime
          type
          channel
          optionalIdentifier
        }
        liability_items {
          amount
          currency
          subject
          explanation
          type
          datetime
          documents {
            file_url
            logo_url
            text
            type
          }
        }
        liability_status {
          traffic_light
          due_date
          due_amount
          due_currency
          total_outstanding_balance
          summary
          date_first_dunning
          current_stage
          days_to_next_stage
        }
        notes {
          notes_list {
            text
            type
          }
          current_state
          last_contact_date
          last_contact_channel
        }
        serviceLog {
          event_id
          activeState
          datetime
          subject
          text
          channel
          direction
          agent {
            name
          }
          documents {
            type
            file_url
            logo_url
            text
          }
          dateTimeDemonstration
        }
        timeline {
          summary
          text
          type
          channel
          direction
          info_url
          logo_url
          datetime
          current_stage
        }
        satisfaction {
          surveyId
          rating
          comment
          type
          group
        }
        surveyUrl
      }
    }
  }
`;

export const GET_CUSTOMER_LIABILITY_PICKUP_DATA = gql`
  {
    customers {
      liabilities {
        pickup {
          pickup_date
          pickup_timerange
          pickup_address {
            name
            address_line_2
            street_and_house_nr
            city
            zip
            country
          }
          pickup_contact {
            identifier
          }
          pickup_state
        }
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  {
    customers {
      liabilities {
        serviceLog {
          event_id
          activeState
          datetime
          subject
          text
          channel
          direction
          documents {
            type
            file_url
            logo_url
            text
          }
          dateTimeDemonstration
        }
      }
    }
  }
`;

export const GET_TRANSLATIONS = (language, countryExperience) => gql`
  {
    staticText2(language: "${language}", countryExperience: "${countryExperience}") {
      notes_list {
        text
        type
      }
    }
  }
`;
