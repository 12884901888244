import { createSelector } from 'reselect';
import { customPortalPaymentUrlSelector } from './portal';
import { hasNutunPaymentSelector } from './decisions';
import { liabilityClientCustomerRefNoSelector } from './liability';

const stateSelector = state => state.payment;

export const totalBalanceSelector = createSelector(stateSelector, state =>
  state.paymentUserInfo ? state.paymentUserInfo.totalBalance : 0
);

export const customPaymentUrlSelector = createSelector(
  hasNutunPaymentSelector,
  liabilityClientCustomerRefNoSelector,
  totalBalanceSelector,
  customPortalPaymentUrlSelector,
  (
    isNutunPayment,
    clientCustomerRefNo,
    totalBalance,
    customPortalPaymentUrl
  ) => {
    const isCustomUrl =
      isNutunPayment && customPortalPaymentUrl && clientCustomerRefNo;
    return isCustomUrl
      ? customPortalPaymentUrl
          .replace('{CUSTOMER}', clientCustomerRefNo)
          .replace('{AMOUNT}', totalBalance)
      : null;
  }
);
