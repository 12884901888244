import React from 'react';
import clsx from 'clsx';
import MaterialModal from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  fullPaper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
    maxHeight: 'unset',
    margin: 0,
    background: 'transparent',
    borderRadius: 'unset',
    boxShadow: 'none'
  }
}));

const Modal = ({
  withFullSizePaper,
  paperClassName,
  backdropClassName,
  isOpen,
  onClose,
  onPaperClick,
  fullWidth = true,
  children
}) => {
  const classes = useStyles();
  const mouseDownTarget = React.useRef();
  const handleMouseDown = event => {
    mouseDownTarget.current = event.target;
  };
  const handlePaperClick = event => {
    // We don't want to close the dialog when clicking the dialog content.
    if (event.target !== event.currentTarget) {
      return;
    }
    // Make sure the event starts and ends on the same DOM element.
    if (event.target !== mouseDownTarget.current) {
      return;
    }
    mouseDownTarget.current = null;
    if (!!onPaperClick) {
      onPaperClick();
    }
  };

  return (
    <MaterialModal
      classes={{
        paper: clsx(paperClassName, withFullSizePaper && classes.fullPaper)
      }}
      BackdropProps={{ classes: { root: clsx(backdropClassName) } }}
      open={isOpen}
      onClose={onClose}
      fullWidth={fullWidth}
      PaperProps={{
        onMouseUp: handlePaperClick,
        onMouseDown: handleMouseDown
      }}
    >
      {children}
    </MaterialModal>
  );
};

export default Modal;
