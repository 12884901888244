import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { Grid } from '../../common';
import Timeline from '../Timeline';
import DemandTable from '../DemandTable';
import DemandTableDocuments from '../DemandTableDocuments';

import { useAppWidthClass } from '../../../utils/hooks';

const useStyles = makeStyles(() => ({
  relative: {
    position: 'relative',
    width: '100%',
    paddingLeft: 12,
    marginTop: 12
  },
  demandItemsSmall: {
    padding: '12px 0 12px 24px'
  },
  tableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tableContainerDesktop: {
    position: 'absolute',
    top: 0,
    bottom: 0
  },
  demandSectionDesktop: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: 12
  },
  documentsMain: {
    marginTop: 24
  },
  documentsDesktop: {
    margin: 12
  },
  timelineWrapperDesktop: {
    marginLeft: '33.333333%'
  },
  timelineDesktop: {
    margin: 12
  },
  timelineWrapperZeroMargin: {
    paddingTop: '0 !important',
    paddingRight: '0 !important'
  },
  'timelineWrapperZeroMargin_mobile-tablet': {
    paddingLeft: '0 !important'
  },
  demandTableWrapperZeroMargin: {
    paddingLeft: '0 !important'
  },
  'demandTableWrapperZeroMargin_mobile-tablet': {
    paddingBottom: '0 !important',
    paddingRight: '0 !important'
  },
  demandTableWrapperZeroMargin_desktop: {
    paddingTop: '0 !important'
  }
}));

const LiabilityInfo = ({
  balance,
  currency,
  locale,
  isPaidFully,
  timelineItems,
  timelineHide,
  timelineBlur,
  demandTableItems,
  demandTableHide,
  demandTableBlur,
  demandTableDocuments,
  disableOuterSpacing
}) => {
  const classes = useStyles();
  const { isTabletWidth, isMobileWidth, isDesktopWidth } = useAppWidthClass();

  return (
    <>
      {(isMobileWidth || isTabletWidth) && !timelineHide && (
        <Grid
          item
          xs={12}
          className={clsx(
            disableOuterSpacing && classes.timelineWrapperZeroMargin,
            disableOuterSpacing &&
              (isMobileWidth || isTabletWidth) &&
              classes['timelineWrapperZeroMargin_mobile-tablet']
          )}
          component="article"
        >
          <Timeline
            id="liability-info-timeline"
            isTabletSize={isTabletWidth}
            isMobileSize={isMobileWidth}
            blur={timelineBlur}
            items={timelineItems}
            isPaidFully={isPaidFully}
          />
        </Grid>
      )}
      <div
        className={clsx(
          classes.relative,
          (isMobileWidth || isTabletWidth) && classes.demandItemsSmall
        )}
      >
        {!demandTableHide && (
          <Grid
            item
            md={4}
            sm={12}
            className={clsx(
              classes.tableContainer,
              isDesktopWidth && classes.tableContainerDesktop,
              disableOuterSpacing && classes.demandTableWrapperZeroMargin,
              disableOuterSpacing &&
                (isMobileWidth || isTabletWidth) &&
                classes['demandTableWrapperZeroMargin_mobile-tablet'],
              disableOuterSpacing &&
                isDesktopWidth &&
                classes.demandTableWrapperZeroMargin_desktop
            )}
          >
            <Grid
              item
              className={clsx(isDesktopWidth && classes.demandSectionDesktop)}
              component="article"
            >
              <DemandTable
                id="liability-info-demand-table"
                isTabletSize={isTabletWidth}
                isMobileSize={isMobileWidth}
                blur={demandTableBlur}
                items={demandTableItems}
                balance={balance}
                currency={currency}
                locale={locale}
              />
            </Grid>
            <Grid item component="article">
              <DemandTableDocuments
                className={clsx(
                  !isDesktopWidth && classes.documentsMain,
                  isDesktopWidth && classes.documentsDesktop
                )}
                isTabletSize={isTabletWidth}
                isMobileSize={isMobileWidth}
                items={demandTableDocuments}
              />
            </Grid>
          </Grid>
        )}
        {isDesktopWidth && !timelineHide && (
          <Grid
            item
            md={8}
            className={clsx(
              isDesktopWidth && classes.timelineWrapperDesktop,
              disableOuterSpacing && classes.timelineWrapperZeroMargin
            )}
            component="article"
          >
            <Timeline
              className={clsx(isDesktopWidth && classes.timelineDesktop)}
              id="liability-info-timeline"
              blur={timelineBlur}
              items={timelineItems}
              isPaidFully={isPaidFully}
            />
          </Grid>
        )}
      </div>
    </>
  );
};

export default LiabilityInfo;
