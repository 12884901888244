import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { DOMAIN_LANGUAGE } from '../../../constants/browser';

import { CustomIcon, Text } from '@troy/shared/src/components/common';

import { PaymentFormInput } from '../../payments/index.troy';
import { PhoneInput } from '../../common';
import { onPhoneKeyPress } from '../../../utils/phone';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  header: {
    width: 120,
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0
  },
  headerLabel: {
    marginLeft: 8,
    lineHeight: '32px'
  }
}));

const ResponseChannelFormField = ({
  inputRowClassName,
  withPhoneInput,
  disabled,
  name,
  id,
  header,
  headerIcon,
  onClick,
  invalid,
  invalidMessage,
  onFocus,
  onBlur
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} onClick={onClick(name)}>
      <Box className={classes.header}>
        <CustomIcon
          icon={headerIcon}
          variant={disabled ? 'disabled' : 'textPrimary'}
          size="small"
        />
        <Text
          htmlFor={id}
          className={classes.headerLabel}
          component="label"
          variant="body1Bold"
          color={disabled ? 'textDisabled' : 'textPrimary'}
        >
          {header}
        </Text>
      </Box>
      <Box className={clsx(classes.content, inputRowClassName)}>
        {withPhoneInput ? (
          <PhoneInput
            id={id}
            disabledVisually={disabled}
            disabled={disabled}
            name={name}
            preferredCountries={[DOMAIN_LANGUAGE.toLowerCase()]}
            formatOnInit={false}
            error={invalid}
            errorMessage={invalidMessage}
            onKeyPress={onPhoneKeyPress}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        ) : (
          <PaymentFormInput
            id={id}
            disabledVisually={disabled}
            disabled={disabled}
            name={name}
            placeholder="user@example.com"
            error={invalid}
            errorMessage={invalidMessage}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}
      </Box>
    </Box>
  );
};

export default ResponseChannelFormField;
