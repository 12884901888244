import { getDomainLanguage } from '../utils/browser';
import moment from 'moment-timezone';

export const DE = 'de';
export const NL = 'nl';
export const BE = 'be';

const LOCALES = {
  DE,
  NL,
  BE
};

export const BROWSER_LANGUAGE = navigator.language || navigator.userLanguage;
export const DOMAIN_LANGUAGE =
  getDomainLanguage() ||
  (process.env.REACT_APP_ENV === 'staging' &&
    process.env.REACT_APP_LOCALE &&
    LOCALES[process.env.REACT_APP_LOCALE]) ||
  DE;

export const isDomainDE = () => DOMAIN_LANGUAGE === DE;

export const isDomainNL = () => DOMAIN_LANGUAGE === NL;

export const isDomainBE = () => DOMAIN_LANGUAGE === BE;

const getDomainTimeZone = () =>
  isDomainNL() || isDomainBE() ? 'Europe/Amsterdam' : 'Europe/Berlin';

const isBusinessHour = hour =>
  isDomainNL() || isDomainBE() ? hour >= 9 && hour < 17 : hour >= 8 && hour < 18;

export const isCurrentlyBusinessHours = () => {
  const time = moment().tz(getDomainTimeZone());
  const day = time.day();
  const hour = time.hour();

  return day >= 1 && day <= 5 && isBusinessHour(hour);
};

export const createFullLanguageCode = fullCode => {
  return fullCode.startsWith('en')
    ? 'en_GB'
    : fullCode.indexOf('-') !== -1
    ? fullCode.replace(/-/g, '_')
    : fullCode + '_' + fullCode.toUpperCase();
};

export const countryFromFullCode = fullCode =>
  fullCode.substring(fullCode.indexOf('_') + 1).toUpperCase();

export const languageFromFullCode = fullCode =>
  fullCode ? fullCode.substring(0, fullCode.indexOf('_')) : 'en';
