import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAppWidthClass } from '../../../utils/hooks';
import {
  caseIdSelector,
  hasStaticTextsSelector,
  portalIdentifierSelector,
  translationsSelector
} from '../../../store/selectors';

import { PORTAL_DE, PORTAL_NL } from '../../../utils/portal';

import FooterSkeleton from '../FooterSkeleton';
import FooterLogo from '../FooterLogo';

const useStyles = makeStyles(theme => ({
  footerWrapper: {
    background: theme.palette.text.primary
  },
  footer: {},
  footerDesktop: {
    minHeight: 250,
    paddingTop: 32,
    paddingBottom: 60
  },
  footerTablet: {
    paddingTop: 27,
    paddingBottom: 40
  },
  footerMobile: {
    paddingTop: 15,
    paddingBottom: 40
  },
  footerContent: {
    display: 'flex'
  },
  footerContentDesktop: {
    flexDirection: 'column'
  },
  footerContentTablet: {
    flexDirection: 'row'
  },
  footerContentMobile: {
    flexDirection: 'column'
  },
  columnsDesktop: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *:nth-of-type(1n+2)': {
      marginLeft: 24
    }
  },
  columnsDesktopWide: {
    '& > *:nth-of-type(1n+2)': {
      marginLeft: 'auto'
    }
  },
  columnsDesktopNVI: {
    '& > *:nth-of-type(1n+2)': {
      marginLeft: 0
    }
  },
  columnsDesktopUnauthorized: {
    '& > *:nth-of-type(1n+2)': {
      marginLeft: 120
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  columnSimple: {
    minWidth: 214
  },
  columnSimpleNL: {
    // minWidth: 135
    minWidth: 40
  },
  columnDesktopNL: {
    justifyContent: 'flex-end'
  },
  address: {
    ...theme.typography.body3,
    color: theme.palette.text.hint
  },
  addressDesktop: {
    minWidth: 135
  },
  addressTablet: {
    minWidth: 179
  },
  contacts: {},
  contactsTablet: {
    marginLeft: 16,
    minWidth: 276
  },
  contactLink: {
    ...theme.typography.body2,
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.secondary.contrastText,
    textDecoration: 'none',

    '&:nth-of-type(1n+2)': {
      marginTop: 8
    }
  },
  contactIcon: {
    marginRight: 16
  },
  links: {},
  logout: {},
  link: {
    ...theme.typography.body2,
    color: theme.palette.secondary.contrastText,
    textDecoration: 'none',
    background: 'transparent',
    border: 'none',
    padding: 0,
    display: 'flex',
    cursor: 'pointer',

    '&:nth-of-type(1n+2), &:last-of-type': {
      marginTop: 8
    }
  },
  bankInfo: {
    ...theme.typography.body2,
    color: theme.palette.secondary.contrastText
  },
  bankInfoItem: {
    '&:nth-of-type(1n+2)': {
      marginTop: 8
    }
  },
  bankInfoLabel: {
    minWidth: 65,
    display: 'inline-block'
  },
  bankInfoValue: {
    marginLeft: 14,
    minWidth: 214
  },
  logoTablet: {
    marginTop: 7,
    marginLeft: 44
  },
  columnsTablet: {
    marginLeft: 22,
    display: 'flex',
    flexDirection: 'column',

    '& > *:nth-of-type(1n+2)': {
      marginTop: 32
    }
  },
  addressContactsTablet: {
    display: 'flex',
    flexDirection: 'row'
  },
  columnsMobile: {
    display: 'flex',
    flexDirection: 'column',

    '& > *:nth-of-type(1n+2)': {
      marginTop: 24
    }
  },
  logoAddressMobile: {
    display: 'flex',
    flexDirection: 'row'
  },
  logoMobile: {
    marginTop: 4
  },
  addressMobile: {
    ...theme.typography.subtitle1,
    marginLeft: 22,
    color: theme.palette.text.hint
  },
  linkMobile: {
    color: theme.palette.text.hint
  },
  NVILogos: {},
  NVILogosDesktop: {
    '& > :not(:last-child)': {
      marginBottom: 8
    }
  },
  NVILogosTablet: {
    flexDirection: 'row',
    '& > :not(:last-child)': {
      marginRight: 16
    }
  },
  NVILogosMobile: {
    flexDirection: 'row',
    '& > :not(:last-child)': {
      marginRight: 32
    }
  }
}));

const Footer = ({ isAuthenticated, logout, columns }) => {
  const classes = useStyles();
  const { widthClass, isDesktopWidth, isTabletWidth, isMobileWidth } =
    useAppWidthClass();
  const translations = useSelector(translationsSelector);
  const hasStaticTexts = useSelector(hasStaticTextsSelector);
  const portalIdentifier = useSelector(portalIdentifierSelector);
  const caseId = useSelector(caseIdSelector);
  const IS_NL = portalIdentifier === PORTAL_NL;

  if (!hasStaticTexts) {
    return <FooterSkeleton />;
  }

  const cols = columns[portalIdentifier] || columns[PORTAL_DE];
  const Address = cols.address;
  const Contacts = cols.contacts;
  const Links = cols.links;
  const BankInfo = cols.bankInfo;
  const NVILogos = cols.nviLogos;
  const Logout = cols.logout;

  const hasBankInfo = !!BankInfo;
  const hasNVILogos = !!NVILogos;
  const hasLogout = !!Logout;

  return (
    <Box className={classes.footerWrapper}>
      <footer
        className={clsx(
          widthClass,
          classes.footer,
          isDesktopWidth && classes.footerDesktop,
          isTabletWidth && classes.footerTablet,
          isMobileWidth && classes.footerMobile
        )}
      >
        <Box
          className={clsx(
            classes.footerContent,
            isDesktopWidth && classes.footerContentDesktop,
            isTabletWidth && classes.footerContentTablet,
            isMobileWidth && classes.footerContentMobile
          )}
        >
          {isDesktopWidth && (
            <>
              <FooterLogo />
              <Box
                className={clsx(
                  classes.columnsDesktop,
                  !hasBankInfo && classes.columnsDesktopWide,
                  hasNVILogos && classes.columnsDesktopNVI,
                  hasLogout &&
                    !isAuthenticated &&
                    classes.columnsDesktopUnauthorized
                )}
              >
                <Address
                  className={clsx(
                    classes.address,
                    classes.addressDesktop,
                    IS_NL && classes.columnDesktopNL
                  )}
                />
                <Contacts
                  className={clsx(
                    classes.column,
                    classes.columnSimple,
                    IS_NL && classes.columnSimpleNL,
                    IS_NL && classes.columnDesktopNL,
                    classes.contacts
                  )}
                  linkClass={classes.contactLink}
                  iconClass={classes.contactIcon}
                  translations={translations}
                  caseId={caseId}
                />
                <Links
                  className={clsx(
                    classes.column,
                    classes.columnSimple,
                    IS_NL && classes.columnSimpleNL,
                    IS_NL && classes.columnDesktopNL,
                    classes.links
                  )}
                  linkClass={classes.link}
                  isAuthenticated={isAuthenticated}
                  logout={logout}
                  showLogout={!hasLogout}
                />
                {hasLogout && (
                  <Logout
                    className={clsx(
                      classes.column,
                      classes.columnSimple,
                      IS_NL && classes.columnSimpleNL,
                      IS_NL && classes.columnDesktopNL,
                      classes.logout
                    )}
                    linkClass={classes.link}
                    isAuthenticated={isAuthenticated}
                    logout={logout}
                  />
                )}
                {hasBankInfo && (
                  <BankInfo
                    className={clsx(
                      classes.column,
                      IS_NL && classes.columnDesktopNL,
                      classes.bankInfo
                    )}
                    itemClass={classes.bankInfoItem}
                    labelClass={classes.bankInfoLabel}
                    valueClass={classes.bankInfoValue}
                  />
                )}
                {hasNVILogos ? (
                  <NVILogos
                    className={clsx(
                      classes.column,
                      classes.columnSimple,
                      IS_NL && classes.columnSimpleNL,
                      IS_NL && classes.columnDesktopNL,
                      classes.NVILogos,
                      classes.NVILogosDesktop
                    )}
                  />
                ) : null}
              </Box>
            </>
          )}
          {isTabletWidth && (
            <>
              <FooterLogo noLogo className={classes.logoTablet} />
              <Box className={classes.columnsTablet}>
                <Box className={classes.addressContactsTablet}>
                  <Address
                    className={clsx(classes.address, classes.addressTablet)}
                  />
                  <Contacts
                    className={clsx(
                      classes.column,
                      classes.columnSimple,
                      IS_NL && classes.columnSimpleNL,
                      classes.contacts,
                      classes.contactsTablet
                    )}
                    linkClass={classes.contactLink}
                    iconClass={classes.contactIcon}
                    translations={translations}
                    caseId={caseId}
                  />
                </Box>
                {hasBankInfo && (
                  <BankInfo
                    className={clsx(classes.column, classes.bankInfo)}
                    itemClass={classes.bankInfoItem}
                    labelClass={classes.bankInfoLabel}
                    valueClass={classes.bankInfoValue}
                  />
                )}
                <Links
                  className={clsx(
                    classes.column,
                    classes.columnSimple,
                    IS_NL && classes.columnSimpleNL,
                    classes.links
                  )}
                  linkClass={classes.link}
                  isAuthenticated={isAuthenticated}
                  logout={logout}
                />
                {hasNVILogos ? (
                  <NVILogos
                    className={clsx(
                      classes.column,
                      classes.columnSimple,
                      IS_NL && classes.columnSimpleNL,
                      classes.NVILogos,
                      classes.NVILogosTablet
                    )}
                  />
                ) : null}
              </Box>
            </>
          )}
          {isMobileWidth && (
            <Box className={classes.columnsMobile}>
              <Box className={classes.logoAddressMobile}>
                <FooterLogo className={classes.logoMobile} />
                <Address className={classes.addressMobile} />
              </Box>
              {hasBankInfo && (
                <BankInfo
                  className={clsx(classes.column, classes.bankInfo)}
                  itemClass={classes.bankInfoItem}
                  labelClass={classes.bankInfoLabel}
                  valueClass={classes.bankInfoValue}
                />
              )}
              <Contacts
                className={clsx(
                  classes.column,
                  classes.columnSimple,
                  IS_NL && classes.columnSimpleNL,
                  classes.contacts
                )}
                linkClass={classes.contactLink}
                iconClass={classes.contactIcon}
                translations={translations}
                caseId={caseId}
              />
              <Links
                className={clsx(
                  classes.column,
                  classes.columnSimple,
                  IS_NL && classes.columnSimpleNL,
                  classes.links
                )}
                linkClass={clsx(classes.link, classes.linkMobile)}
                isAuthenticated={isAuthenticated}
                logout={logout}
              />
              {hasNVILogos ? (
                <NVILogos
                  className={clsx(
                    classes.column,
                    classes.columnSimple,
                    IS_NL && classes.columnSimpleNL,
                    classes.NVILogos,
                    classes.NVILogosMobile
                  )}
                />
              ) : null}
            </Box>
          )}
        </Box>
      </footer>
    </Box>
  );
};

export default Footer;
