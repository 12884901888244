import React from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import { phoneValidator } from '@troy/shared/src/utils/validation';
import { parsePhoneFromString } from '@troy/shared/src/utils/phone';
import { email as emailValidator } from '../../../utils/validation';
import {
  EMAIL_PLACEHOLDER,
  PHONE_PLACEHOLDER,
  validRates
} from '../../../constants/payment';

import { ThemeButton, Translation } from '../../common';
import {
  PaymentFormInput,
  PaymentFormRow,
  PaymentFormRowContent,
  PaymentFormRowLabel,
  PaymentFormSelect,
  PaymentFormText
} from '../index.troy';
import {useAppWidthClass} from "../../../utils/hooks";

const selectFieldInit = number => {
  const arr = [];
  for (let i = 0; i < number; i++) {
    arr.push({
      label: `${i + 1}`,
      value: i + 1,
      id: `page.payments.component.pay-later.day-select-${i + 1}-item`
    });
  }
  return arr;
};

const emailCheck = email => {
  return emailValidator(email);
};

const selectFieldOptions = selectFieldInit(31);

const useStyles = makeStyles(theme => ({
  form: {
    margin: '4px 0 40px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  submit: {
    margin: '40px auto 0 auto',
    minWidth: 354
  },
  submitCompact: {
    minWidth: 290
  },
  logo: {
    width: 28,
    height: 28
  }
}));

const PayLaterContent = ({
  onSubmit,
  className,
  balance,
  email,
  mobilePhone,
  phone,
  formRef
}) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  const validRatesArr = validRates(balance);
  const initialValues = {
    rate: validRatesArr[0],
    day: 1,
    email: email,
    mobilePhone: parsePhoneFromString(mobilePhone || phone).value
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={initialValues}
      validRates={validRatesArr}
      onSubmit={({ mobilePhone, email, ...values }) =>
        onSubmit({
          ...values,
          email: email || null,
          mobilePhone:
            (mobilePhone && parsePhoneFromString(mobilePhone).value) || null
        })
      }
    >
      {({ values, isValid }) => {
        const emailError = !!values.email && emailCheck(values.email);
        const phoneError = phoneValidator(values.mobilePhone);
        return (
          <Form className={clsx(classes.form, className)}>
            <Typography variant="h3" color="secondary">
              <Translation inline>PAYMENT_PAYLATER_HEADLINE</Translation>
            </Typography>
            <PaymentFormRow center>
              <PaymentFormRowLabel id="pay-later-form-day-label" wider>
                PAYMENT_PAYLATER_DATE_LEFT
              </PaymentFormRowLabel>
              <PaymentFormRowContent>
                <PaymentFormSelect
                  id="pay-later-form-day"
                  labelId="pay-later-form-day-label"
                  name="day"
                  options={selectFieldOptions}
                  detailText="PAYMENT_PAYLATER_DATE_RIGHT"
                />
              </PaymentFormRowContent>
            </PaymentFormRow>
            <PaymentFormRow smallMargin>
              <PaymentFormText>PAYMENT_PAYLATER_DATE_BENEATH</PaymentFormText>
            </PaymentFormRow>
            <PaymentFormRow>
              <PaymentFormRowLabel htmlFor="pay-later-form-email" wider>
                PAYMENT_EMAILADDRESS_LEFT
              </PaymentFormRowLabel>
              <PaymentFormRowContent>
                <PaymentFormInput
                  id="pay-later-form-email"
                  name="email"
                  placeholder={EMAIL_PLACEHOLDER}
                  validator={emailCheck}
                  error={emailError}
                  hint="PAYMENT_EMAILADDRESS_BENEATH"
                />
              </PaymentFormRowContent>
            </PaymentFormRow>
            <PaymentFormRow mediumMargin>
              <PaymentFormRowLabel htmlFor="pay-later-form-mobile-phone" wider>
                PAYMENT_MOBILENUMBER_LEFT
              </PaymentFormRowLabel>
              <PaymentFormRowContent>
                <PaymentFormInput
                  id="pay-later-form-mobile-phone"
                  name="mobilePhone"
                  placeholder={PHONE_PLACEHOLDER}
                  validator={phoneValidator}
                  error={phoneError}
                  hint="PAYMENT_MOBILENUMBER_BENEATH"
                />
              </PaymentFormRowContent>
            </PaymentFormRow>
            <ThemeButton
              id="page.payments.component.pay-later.button"
              className={clsx(
                  classes.submit,
                  isMobileWidth && classes.submitCompact
              )}
              variant="contained"
              type="submit"
              size="large"
              disabled={!isValid}
              textKey="PAYMENT_PAYLATER_BUTTON_TEXT"
              startIcon="confirm"
              startIconColor="primaryContrast"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default PayLaterContent;
