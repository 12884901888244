import { chatBotReturnFromLiveAgentChat } from './quriobot';
import {
  getLiveAgentId,
  liveAgentDomain,
  liveAgentSrcUrl
} from '../../../utils/quriobot/quriobot_config';
import { isCurrentlyBusinessHours } from '../../../constants/browser';

let MAIN_SCRIPT_ADDED = false;
let LIVE_AGENT_BUTTON_VISIBLE = false;

export const RETURN_QURIOBOT = 'quriobot';

let lastOpenParams = {
  returnTo: null,
  loggedInMode: false,
  language: null,
  isBusinessHoursChat: false
};

export const isLiveAgentChatOpen = () =>
  !!window.LiveAgent &&
  !!window.LiveAgent.instance &&
  window.LiveAgent.instance.hasOpenedWidget();

export const showLiveAgentChat = (
  returnTo,
  loggedInMode,
  language,
  email = '',
  firstName = '',
  lastName = '',
  caseId = '',
  communicationTone
) => {
  const lang = ['DE', 'EN', 'NL'].includes(language) ? language : 'EN';

  if (!MAIN_SCRIPT_ADDED) {
    MAIN_SCRIPT_ADDED = true;
    createMainScript(
      returnTo,
      loggedInMode,
      lang,
      email,
      firstName,
      lastName,
      caseId,
      communicationTone
    );
  } else {
    if (returnTo !== null || !LIVE_AGENT_BUTTON_VISIBLE) {
      hideCurrentLiveAgentButton(loggedInMode);
      showAgent(
        returnTo,
        loggedInMode,
        lang,
        email,
        firstName,
        lastName,
        caseId,
        communicationTone
      );
    }
  }
};

export const hideCurrentLiveAgentButton = () => {
  if (!LIVE_AGENT_BUTTON_VISIBLE) {
    return;
  }
  if (isLiveAgentChatOpen()) {
    return;
  }
  LIVE_AGENT_BUTTON_VISIBLE = false;

  window?.LiveAgent?.instance?.elements?.forEach(e => {
    e?.buttonDiv?.parentNode?.removeChild(e.buttonDiv);
  });
};

const showAgent = (
  returnTo,
  loggedInMode,
  language,
  email,
  firstName,
  lastName,
  caseId,
  communicationTone
) => {
  window.LiveAgent.clearAllUserDetails();

  window.LiveAgent.setUserDetails(email, firstName, lastName);
  window.LiveAgent.addContactField('caseid', caseId);
  window.LiveAgent.addTicketField('caseid', caseId);
  window.LiveAgent.addTicketField('tone', communicationTone);

  const isBusinessHours = isCurrentlyBusinessHours();
  const buttonId = getLiveAgentId(isBusinessHours);

  let existingElement = null;
  window?.LiveAgent?.instance?.elements?.forEach(e => {
    if (e.id === buttonId) {
      existingElement = e;
    }
  });

  if (!!existingElement) {
    existingElement.restartCallback();
  } else {
    let button = window.LiveAgent.createButton(
      getLiveAgentId(isBusinessHours),
      document.scripts[document.scripts.length - 1]
    );

    button.originalCloseFunction_ = button.onCloseFunction_;
    button.onCloseFunction_ = function () {
      if (!LIVE_AGENT_BUTTON_VISIBLE) {
        return;
      }
      button.originalCloseFunction_();

      let buttonDiv = null;
      window?.LiveAgent?.instance?.elements?.forEach(e => {
        if (e.id === buttonId) {
          buttonDiv = e.buttonDiv;
        }
      });

      if (returnTo !== null) {
        LIVE_AGENT_BUTTON_VISIBLE = false;

        if (!!buttonDiv) {
          buttonDiv.style.display = 'none';
        }

        setTimeout(() => {
          if (returnTo === RETURN_QURIOBOT) {
            chatBotReturnFromLiveAgentChat();
          }
        }, 100);
      } else {
        if (!!buttonDiv) {
          buttonDiv.style.display = 'block';
        }
      }
    };

    if (returnTo !== null) {
      setTimeout(() => {
        button.buttonDiv.click();
      }, 1000);
    }
  }

  lastOpenParams = {
    returnTo,
    loggedInMode,
    language,
    isBusinessHoursChat: isBusinessHours
  };

  LIVE_AGENT_BUTTON_VISIBLE = true;
};

const getCookie = name => {
  return document.cookie.split(';').some(c => {
    return c.trim().startsWith(name + '=');
  });
};

const deleteCookie = (name, path, domain) => {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
};

const createMainScript = (
  returnTo,
  loggedInMode,
  language,
  email,
  firstName,
  lastName,
  caseId,
  communicationTone
) => {
  // fixes contact-form values but still not the live chat customer details.
  deleteCookie('LaUserDetails', '/', liveAgentDomain);
  const host = window.location.hostname.split('.').reverse();
  const domain = (host[1] ? host[1] + '.' : '') + host[0];
  deleteCookie('LaUserDetails', '/', domain);

  let script = document.createElement('script');

  script.id = 'la_x2s6df8d';
  script.src = liveAgentSrcUrl;
  script.onload = script.onreadystatechange = function () {
    const rs = this.readyState;
    if (rs && rs !== 'complete' && rs !== 'loaded') {
      return;
    }
    showAgent(
      returnTo,
      loggedInMode,
      language,
      email,
      firstName,
      lastName,
      caseId,
      communicationTone
    );
  };

  const lastScript = document.scripts[document.scripts.length - 1];
  lastScript.parentElement.insertBefore(script, lastScript.nextSibling);
};
