import { IDEAL_INSTALLMENT_VALUE } from '../constants/payment';

export const filterPaymentMethods = (
  methods,
  { balance } = {},
  { minBalance = 25 } = {}
) => {
  if (balance < minBalance) {
    methods = methods.filter(({ value }) => value !== IDEAL_INSTALLMENT_VALUE);
  }
  return methods;
};
