import React from 'react';
import PropTypes from 'prop-types';

import ContactBox from '.';
import styles from './troy.module.scss';

const TroyContactBox = ({ link, blur }) => (
  <ContactBox {...{ link, blur, styles }} />
);

TroyContactBox.propTypes = {
  link: PropTypes.string
};

export default TroyContactBox;
