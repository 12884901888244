import React from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useAppWidthClass } from '../../../utils/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: 24,

    '&:first-child': {
      marginTop: 0
    }
  },
  center: {
    alignItems: 'center'
  },
  xsMargin: {
    marginTop: 8
  },
  smallMargin: {
    marginTop: 16
  },
  mediumMargin: {
    marginTop: 20
  },
  compact: {
    flexDirection: 'column'
  }
}));

const PaymentFormRow = ({
  className,
  children,
  center,
  xsMargin,
  smallMargin,
  mediumMargin,
  compact
}) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  return (
    <Box
      className={clsx(
        classes.root,
        center && classes.center,
        xsMargin && classes.xsMargin,
        smallMargin && classes.smallMargin,
        mediumMargin && classes.mediumMargin,
        (compact || isMobileWidth) && classes.compact,
        className
      )}
    >
      {children}
    </Box>
  );
};

export default PaymentFormRow;
