import { createReducer } from 'redux-act';

import * as actions from '../actions/liability';

import { getImage } from '../../utils/images';
import {
  getPortalCurrencyOverride,
  replaceWhiteLabelImages
} from '../../utils/portal';

const DEFAULT_STATE = {
  liabilityItems: null,
  liabilityStatus: null,
  locale: null,
  timeline: null,
  satisfaction: [],
  surveyUrl: null,
  logo: null,
  category: null,
  pickupDate: null,
  clientName: null,
  clientCustomerRefNo: null,
  refNo: null
};

export default createReducer(
  {
    [actions.setLiability]: (
      state,
      {
        liability,
        timelineLogo,
        timelineLogoFuture,
        timelineHandoverImage,
        currencyOverride
      }
    ) => {
      let isCurrent = false;
      let isFuture = false;
      const timeline = liability.timeline.map(
        ({ logo_url: logo, current_stage, ...rest }) => {
          if (current_stage) {
            isCurrent = true;
          } else if (isCurrent) {
            isFuture = true;
          }
          if (logo && logo[0] === '/') {
            if (isFuture && logo.includes('ra_ferkinghoff_logo.png')) {
              // https://troy-bleiben.atlassian.net/browse/MOON-767
              logo = logo.replace(
                'ra_ferkinghoff_logo.png',
                'ra_ferkinghoff_logo_future.png'
              );
            }
            logo = getImage(
              replaceWhiteLabelImages(
                logo,
                timelineLogo,
                timelineLogoFuture,
                timelineHandoverImage
              )
            );
          }
          const timelineItem = {
            logo: logo,
            current: current_stage,
            ...rest
          };
          if (!timelineItem.logo) {
            timelineItem.label = liability.mandant.name;
          }
          return timelineItem;
        }
      );

      return {
        locale: liability.locale,
        timeline: timeline,
        surveyUrl: liability.surveyUrl,
        satisfaction: liability.satisfaction,
        liabilityItems: liability.liability_items.map(item => ({
          ...item,
          currency: getPortalCurrencyOverride(
            item.currency || 'EUR',
            currencyOverride
          )
        })),
        liabilityStatus: liability.liability_status,
        logo:
          liability.category.logourl && getImage(liability.category.logourl),
        category: liability.category,
        pickup: liability.pickup,
        clientName: liability.mandant.name,
        clientCustomerRefNo: liability.clientCustomerRefNo,
        refNo: liability.refNo
      };
    },
    [actions.setLiabilityPickupData]: (state, liability) => {
      return {
        ...state,
        pickup: liability.pickup
      };
    },
    [actions.liabilityReset]: () => {
      return { ...DEFAULT_STATE };
    }
  },
  DEFAULT_STATE
);
