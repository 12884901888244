import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { PostBoxItem } from '@troy/shared/src/components/common';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '12px 0',
    margin: 0
  },
  item: {
    width: '75%',
    minWidth: 303
  }
}));

const NotificationList = ({
  notifications,
  customerToken,
  onFileAttachmentClick,
  showPostboxLiveAgentChatDebugSelector,
  scrollToNotificationStart
}) => {
  const classes = useStyles();

  return (
    <ul className={classes.wrapper}>
      {!!notifications &&
        !!notifications.rightLane &&
        !!notifications.rightLane.length &&
        notifications.rightLane.map((item, index) => (
          <PostBoxItem
            key={index}
            item={item}
            itemClass={classes.item}
            laneMode="single"
            isLast={index === notifications.rightLane.length - 1}
            customerToken={customerToken}
            onFileAttachmentClick={onFileAttachmentClick}
            showPostboxLiveAgentChatDebugSelector={
              showPostboxLiveAgentChatDebugSelector
            }
            scrollToNotificationStart={scrollToNotificationStart}
          />
        ))}
    </ul>
  );
};

export default NotificationList;
