import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import { Module } from '@troy/shared/src/components/common';

import { setShowCelebrationAnimation } from '../../../store/actions';
import { useAppWidthClass } from '../../../utils/hooks';
import { isDomainNL } from '../../../constants/browser';
import { isDomainBE } from '../../../constants/browser';

import { Translation } from '../../common';
import GreetingAvatar from '../../home/GreetingAvatar';
import ManDog from '../../../pages/Thanks/ManDog';
import RatingForm from '../RatingForm';

const buttonKey = 'FEEDBACK_BUTTON';
const headerKey = 'FEEDBACK_HEADER';
const contentKey = 'FEEDBACK_BOX';

const useStyles = makeStyles(() => ({
  module: {
    margin: '28px 0'
  },
  moduleTablet: {
    margin: '24px 0'
  },
  moduleMobile: {
    margin: '72px 16px 24px 16px'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 102px'
  },
  wrapperTablet: {
    padding: '24px 40px 40px 40px'
  },
  wrapperMobile: {
    padding: '24px 16px 40px 16px'
  },
  title: {
    marginBottom: 16
  },
  description: {
    maxWidth: 600,
    margin: '0 auto'
  },
  contentDesktop: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 72
  },
  contentTablet: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40
  },
  contentMobile: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24
  },
  greetingDesktop: {
    width: 220,
    marginRight: 60
  },
  greetingTablet: {
    marginLeft: 120
  },
  greetingMobile: {
    marginLeft: 32
  },
  formDesktop: {
    marginTop: 8
  },
  formTablet: {
    marginTop: 40,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  formMobile: {
    marginTop: 24,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const Feedback = ({ language, questions, getQuestions, sendAnswers }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isDesktopWidth, isTabletWidth, isMobileWidth } = useAppWidthClass();
  const [celebrationShow, setCelebrationShown] = useState(false);

  const updateQuestions = useCallback(() => {
    if (!celebrationShow) {
      setCelebrationShown(true);
      setTimeout(() => {
        dispatch(setShowCelebrationAnimation(true));
      }, 500);
    }
    getQuestions();
  }, [getQuestions, language]);

  useEffect(() => {
    updateQuestions();
  }, [language]);

  const onSubmit = values => {
    sendAnswers({
      answers: values.map(({ group, id, value }) => ({
        group,
        id,
        value
      }))
    });
  };

  const hideTitle = (isDomainNL() || isDomainBE()) && !isMobileWidth;

  return (
    <Module
      id="feedback-page-content"
      className={clsx(
        classes.module,
        isTabletWidth && classes.moduleTablet,
        isMobileWidth && classes.moduleMobile
      )}
    >
      <ManDog displayIfNL textKey={headerKey} />
      <div
        className={clsx(
          classes.wrapper,
          isTabletWidth && classes.wrapperTablet,
          isMobileWidth && classes.wrapperMobile
        )}
      >
        {!hideTitle && (
          <Typography
            className={classes.title}
            component="h1"
            variant="h3"
            color="secondary"
            align="center"
          >
            <Translation inline>{headerKey}</Translation>
          </Typography>
        )}
        <Typography
          className={classes.description}
          variant="body1"
          align={isMobileWidth ? 'left' : 'center'}
        >
          <Translation inline>{contentKey}</Translation>
        </Typography>
        <div
          className={clsx(
            isDesktopWidth && classes.contentDesktop,
            isTabletWidth && classes.contentTablet,
            isMobileWidth && classes.contentMobile
          )}
        >
          <GreetingAvatar
            className={clsx(
              isDesktopWidth && classes.greetingDesktop,
              isTabletWidth && classes.greetingTablet,
              isMobileWidth && classes.greetingMobile
            )}
            isTabletSize={isTabletWidth || isMobileWidth}
          />
          <RatingForm
            className={clsx(
              isDesktopWidth && classes.formDesktop,
              isTabletWidth && classes.formTablet,
              isMobileWidth && classes.formMobile
            )}
            pageName="feedback"
            questions={questions}
            buttonKey={buttonKey}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </Module>
  );
};

export default Feedback;
