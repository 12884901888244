import { post } from './httpUtil';
import { SEND_UI_EVENT } from '../constants/api';
import {
  CALCULATOR_AWAIT,
  ERROR,
  FEEDBACK,
  HOME,
  PAYMENTS,
  THANKS
} from '../constants/routes';

export const trackUIEvent = element => {
  post(
    SEND_UI_EVENT(element, encodeURIComponent(window.location.href)),
    {},
    true
  );
};

export const onClickWrapper =
  (identity, handler) =>
  (event, ...args) => {
    const id = identity === null ? event.nativeEvent.target.id : identity;
    trackUIEvent(id);
    if (handler) {
      handler(event, ...args);
    }
  };

export const registerOnClickWrapperHandler = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.onClickWrapper = onClickWrapper;
};

export const hasPayButtonInHeader = (currentRoute, isPaidFully, ignoreHome) => {
  return (
    !isPaidFully &&
    (ignoreHome || currentRoute !== HOME) &&
    currentRoute !== PAYMENTS &&
    currentRoute !== FEEDBACK &&
    currentRoute !== THANKS &&
    currentRoute !== CALCULATOR_AWAIT &&
    currentRoute !== ERROR
  );
};
