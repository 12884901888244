import React from 'react';
import { useSelector } from 'react-redux';

import { portalOnBehalfOfClientKeySelector } from '../../../store/selectors';
import { Translation } from '../../common';

export default ({
  showTitle,
  html,
  logo,
  clientName,
  logoClassName,
  contentClassName
}) => {
  const textKey = useSelector(portalOnBehalfOfClientKeySelector);

  return html ? (
    <div
      className={contentClassName}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  ) : logo ? (
    <>
      {showTitle && (
        <Translation keyIfNoValue className={contentClassName}>
          {textKey}
        </Translation>
      )}
      <img className={logoClassName} src={logo} alt={clientName} />
    </>
  ) : null;
};
