import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { Blurred } from '../../common';
import Welcome from '../Welcome';
import ClientLogo from '../ClientLogo';
import GreetingAvatar from '../GreetingAvatar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px 42px 32px 32px',
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 6,
    boxSizing: 'border-box',
    boxShadow: theme.customShadows.module
  },
  rootMedium: {
    padding: '24px'
  },
  rootSmall: {
    padding: '24px 16px'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  contentCompact: {
    flexDirection: 'column'
  },
  greeting: {
    width: 212,
    marginRight: 40,
    marginLeft: 0
  },
  greetingCompact: {
    flexShrink: 1
  },
  greetingTablet: {
    marginTop: 24,
    marginBottom: 40
  },
  welcome: {
    marginLeft: 0,
    marginRight: 32
  },
  welcomeTextDesktop: {
    width: 'auto',
    maxWidth: 394
  },
  welcomeMobile: {
    marginTop: 24
  },
  logoWrapper: {
    marginLeft: 32,
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerCompact: {
    width: '100%'
  },
  logoWrapperCompact: {
    flexShrink: 0,
    paddingLeft: 4,
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  logoWrapperTablet: {
    justifyContent: 'flex-start'
  },
  clientName: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    textAlign: 'center'
  },
  clientNameTablet: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
    textAlign: 'right'
  },
  clientNameMobile: {
    fontSize: '0.5625rem',
    lineHeight: 1.333333,
    color: theme.palette.text.primary,
    textAlign: 'right'
  },
  clientLogo: {
    marginTop: 16,
    maxWidth: 144,
    maxHeight: 144
  },
  clientLogoMedium: {
    marginTop: 8,
    maxWidth: 150,
    maxHeight: 120
  },
  clientLogoSmall: {
    marginTop: theme.spacing(1),
    maxWidth: 115,
    maxHeight: 64
  }
}));

const TroyGreetings = ({
  id,
  isPaidFully,
  isMobileSize,
  isTabletSize,
  clientLogo,
  clientName,
  blur
}) => {
  const classes = useStyles();
  const isCompact = isTabletSize || isMobileSize;
  const logoMissing = !clientLogo;

  return (
    <Box
      id={id}
      className={clsx(
        classes.root,
        isTabletSize && classes.rootMedium,
        isMobileSize && classes.rootSmall
      )}
    >
      <Blurred blur={blur}>
        <Box
          className={clsx(classes.content, isCompact && classes.contentCompact)}
        >
          <Box
            className={clsx(classes.header, isCompact && classes.headerCompact)}
          >
            <GreetingAvatar
              logoMissing={logoMissing}
              isTabletSize={isTabletSize}
              isMobileSize={isMobileSize}
              className={clsx(
                !isCompact && classes.greeting,
                isCompact && classes.greetingCompact,
                isTabletSize && classes.greetingTablet
              )}
            />
            {!logoMissing && isCompact && (
              <Box
                className={clsx(
                  classes.logoWrapperCompact,
                  isTabletSize && classes.logoWrapperTablet
                )}
              >
                <ClientLogo
                  showTitle
                  logo={clientLogo}
                  clientName={clientName}
                  contentClassName={
                    isTabletSize
                      ? classes.clientNameTablet
                      : classes.clientNameMobile
                  }
                  logoClassName={
                    isMobileSize
                      ? classes.clientLogoSmall
                      : classes.clientLogoMedium
                  }
                />
              </Box>
            )}
          </Box>
          <Welcome
            isPaidFully={isPaidFully}
            isTabletSize={isCompact}
            className={clsx(
              !isCompact && classes.welcome,
              isMobileSize && classes.welcomeMobile
            )}
            textClassName={
              !isCompact && !logoMissing && classes.welcomeTextDesktop
            }
          />
          {!isCompact && !logoMissing && (
            <Box className={classes.logoWrapper}>
              <ClientLogo
                showTitle
                logo={clientLogo}
                clientName={clientName}
                contentClassName={classes.clientName}
                logoClassName={classes.clientLogo}
              />
            </Box>
          )}
        </Box>
      </Blurred>
    </Box>
  );
};

TroyGreetings.propTypes = {
  isMobileSize: PropTypes.bool,
  isTabletSize: PropTypes.bool,
  welcomeText: PropTypes.string
};

export default TroyGreetings;
