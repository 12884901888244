import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { CustomIcon } from '@troy/shared/src/components/common';
import { Link, ThemeButton } from '../../common';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.secondary.contrastText
  },
  link: {
    marginLeft: 16,
    '&:first-of-type': {
      marginLeft: 0
    }
  }
}));

const ContactsBarContent = ({ className, items }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.wrapper, className)}>
      {items.map(({ label, icon, link, id }) => (
        <ThemeButton
          key={id}
          id={id}
          className={classes.link}
          size="medium"
          color="secondary"
          fontStyle="subtitle1"
          startElement={
            <CustomIcon icon={icon} variant="secondary" size="small" />
          }
          component={React.forwardRef((props, ref) => (
            <Link raw to={link} target="_blank" {...props} ref={ref} />
          ))}
          textKey={label}
        />
      ))}
    </Box>
  );
};

export default ContactsBarContent;
