import { createSelector } from 'reselect';

const stateSelector = state => state.fileUpload;

export const isFileUploadLoadingSelector = createSelector(
  stateSelector,
  state => state.isLoading
);

export const isFileUploadModalOpenedSelector = createSelector(
  stateSelector,
  state => state.isModalOpened
);

export const isFileUploadSuccessfulSelector = createSelector(
  stateSelector,
  state => state.isUploadSuccessful
);
