import React from 'react';
import clsx from 'clsx';
import { IconButton, ListItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { DOC, DOCX, JPEG, PDF, PNG } from '../../../constants/file-upload';

import { CustomIcon, Text } from '@troy/shared/src/components/common';
import { onClickWrapper } from '../../../utils/ui';
import { svgFill } from '@troy/shared/src/utils/styles';

const TYPES_ICONS = {
  [PDF]: 'pdfIcon',
  [DOC]: 'wordIcon',
  [DOCX]: 'wordIcon',
  [JPEG]: 'imageIcon',
  [PNG]: 'imageIcon'
};

const useStyles = makeStyles(theme => ({
  item: {
    padding: theme.spacing(1),
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.text.disabled}`,
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius * 2,
    transition: theme.transitions.custom.fast
  },
  itemHover: {
    '&:hover': {
      borderColor: theme.palette.text.hint,
      boxShadow: theme.customShadows.active,

      '& > span': {
        color: theme.palette.text.secondary
      }
    }
  },
  name: {
    marginLeft: 8,
    color: theme.palette.text.hint,
    wordBreak: 'break-all'
  },
  remove: {
    marginLeft: 'auto',
    padding: 4,

    '&:hover svg': {
      ...svgFill(theme.transitions.custom.fast, theme.palette.text.secondary)
    }
  }
}));

const FilePreview = ({
  className,
  file,
  onRemove,
  removeButtonId,
  disabled
}) => {
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.item, !!onRemove && classes.itemHover, className)}
    >
      <CustomIcon
        size="small"
        icon={TYPES_ICONS[file.type]}
        variant="primary"
      />
      <Text
        className={clsx(classes.name)}
        variant="subtitle2"
        component="span"
        color="textHint"
      >
        {file.name}
      </Text>
      {!!onRemove && (
        <IconButton
          className={classes.remove}
          aria-label="remove"
          id={removeButtonId}
          onClick={onClickWrapper(removeButtonId, onRemove)}
          size="medium"
          disabled={disabled}
        >
          <CustomIcon size="xs" icon="dismiss" variant="hint" />
        </IconButton>
      )}
    </ListItem>
  );
};

export default FilePreview;
