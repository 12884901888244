import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import { Module } from '@troy/shared/src/components/common';

import { connectTo } from '../../utils';
import { useAppWidthClass } from '../../utils/hooks';
import toErrorKey from './utils';
import { NAVBAR_HOME_KEY } from '../../store/reducers/ui';
import { languageSelector } from '../../store/selectors';
import { PAY_NOW_TEXT_KEY } from '../../constants/payment';
import { HOME, PAYMENTS } from '../../constants/routes';
import * as errorTypes from '../../constants/errorStatuses';
import { EXCEPTION } from '../../constants/errorStatuses';

import { Link, ThemeButton, Translation } from '../../components/common';

import { ReactComponent as Leela } from '../../assets/img/error/leela.svg';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: '16px',
    display: 'flex'
  },
  wrapperDesktop: {
    padding: '40px 124px 40px 124px',
    flexDirection: 'row',
    alignItems: 'center'
  },
  wrapperTablet: {
    padding: '40px 35.5px',
    flexDirection: 'row'
  },
  wrapperMobile: {
    padding: '16px 24px 40px 24px',
    flexDirection: 'column',
    alignItems: 'center'
  },
  leela: {
    flexShrink: 0,

    '& path[fill="#8523FC"]': {
      fill: theme.customColors.botColor
    },
    '& path[fill="#410E81"]': {
      fill: theme.palette.secondary.dark
    },
    '& path[fill="#5C18B1"]': {
      fill: theme.palette.secondary.main
    },
    '& path[fill="#164743"]': {
      fill: theme.palette.primary.dark
    }
  },
  leelaDesktop: {
    width: 214,
    height: 275
  },
  leelaTablet: {
    marginLeft: 10,
    width: 112,
    height: 158
  },
  leelaMobile: {
    margin: '10px 0',
    width: 96,
    height: 134
  },
  contentDesktop: {
    marginLeft: 54
  },
  contentTablet: {
    marginLeft: 42
  },
  contentMobile: {
    textAlign: 'center'
  },
  description: {
    marginTop: 24
  },
  button: {
    marginTop: 24,
    minWidth: 184
  }
}));

const ErrorPage = ({ status }) => {
  const classes = useStyles();
  const { isMobileWidth, isTabletWidth, isDesktopWidth } = useAppWidthClass();
  const language = useSelector(languageSelector);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const errorReason = searchParams.get('errorReason');

  let statusKey = status;
  if (errorReason === EXCEPTION) {
    statusKey = EXCEPTION;
  }

  const key = toErrorKey(statusKey, language);

  const hasPaymentLink = [
    errorTypes.PAYMENT_CANCEL,
    errorTypes.PAYMENT_EXPIRED,
    errorTypes.PAYMENT_FAIL,
    errorTypes.PAYMENT_PENDING,
    errorTypes.PAYMENT_UNAUTHORIZED
  ].includes(status);

  return (
    <Module
      id="error-page-content"
      className={clsx(
        classes.wrapper,
        isDesktopWidth && classes.wrapperDesktop,
        isTabletWidth && classes.wrapperTablet,
        isMobileWidth && classes.wrapperMobile
      )}
    >
      <Leela
        alt="leela"
        className={clsx(
          classes.leela,
          isDesktopWidth && classes.leelaDesktop,
          isTabletWidth && classes.leelaTablet,
          isMobileWidth && classes.leelaMobile
        )}
      />
      <Box
        className={clsx(
          isDesktopWidth && classes.contentDesktop,
          isTabletWidth && classes.contentTablet,
          isMobileWidth && classes.contentMobile
        )}
      >
        <Typography component="h1" variant="h2" color="secondary">
          <Translation inline>ERROR_PAGE_HEADER</Translation>
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.description}
        >
          <Translation keyIfNoValue inline skipLoadingCheck>
            {key}
          </Translation>
        </Typography>
        <ThemeButton
          className={classes.button}
          id={
            hasPaymentLink
              ? 'page.error.component.pay-now.pay-now-link'
              : 'page.error.component.error.home-link'
          }
          variant="contained"
          component={React.forwardRef((props, ref) => (
            <Link
              raw
              to={hasPaymentLink ? PAYMENTS : HOME}
              {...props}
              ref={ref}
            />
          ))}
          textKey={hasPaymentLink ? PAY_NOW_TEXT_KEY : NAVBAR_HOME_KEY}
        />
      </Box>
    </Module>
  );
};

export default connectTo(
  ({ error: { status } }) => ({ status }),
  {},
  ErrorPage
);
