import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { Text } from '@troy/shared/src/components/common';

import { Translation } from '../../common';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  descriptionDesktop: {
    marginTop: 16,
    width: 490,

    '& > *': {
      textAlign: 'justify'
    }
  }
}));

export const WelcomeTitle = React.forwardRef(
  ({ className, isTabletWidth, isMobileWidth }, ref) => (
    <Text
      ref={ref}
      className={className}
      variant={isMobileWidth ? 'h3' : isTabletWidth ? 'h2' : 'h1'}
      component="h1"
      color={isMobileWidth ? 'textPrimary' : 'primaryContrast'}
      align={isMobileWidth || isTabletWidth ? 'center' : undefined}
    >
      <Translation inline>LOGINPAGE_HEADER</Translation>
    </Text>
  )
);

export const WelcomeDescription = ({ className, isTabletWidth, noWrap }) => (
  <Text
    className={className}
    variant={isTabletWidth ? 'body1Bold' : 'h3'}
    component="h2"
    color="primaryContrast"
    align={isTabletWidth ? 'center' : undefined}
  >
    <Translation inline noWrap={noWrap}>
      LOGINPAGE_DESCRIPTION
    </Translation>
  </Text>
);

const Welcome = ({ id, className }) => {
  const classes = useStyles();

  return (
    <Box id={id} className={clsx(classes.root, className)}>
      <WelcomeTitle />
      <WelcomeDescription className={classes.descriptionDesktop} noWrap />
    </Box>
  );
};

Welcome.propTypes = {
  className: PropTypes.string
};

export default Welcome;
