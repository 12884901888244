import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import { connectTo } from '../../utils';
import { useAppWidthClass } from '../../utils/hooks';
import { isAuthenticatedAppLoadingSelector } from '../../store/selectors';
import { Module } from '@troy/shared/src/components/common';

import {
  Link,
  Signature,
  SkeletonModule,
  Translation
} from '../../components/common';
import GreetingAvatar from '../../components/home/GreetingAvatar';

import megaphone from '../../assets/img/thanks/megaphone_illustration.png';
import ManDog from './ManDog';

const useStyles = makeStyles(theme => ({
  module: {
    margin: '28px 16px'
  },
  moduleTablet: {
    margin: '24px 16px'
  },
  moduleMobile: {
    margin: '24px 16px'
  },
  wrapper: {
    padding: '40px 80px 40px 60px'
  },
  wrapperTablet: {
    padding: '24px 40px 40px 40px'
  },
  wrapperMobile: {
    padding: '24px 16px 40px 16px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  containerTablet: {
    flexDirection: 'column'
  },
  containerMobile: {
    flexDirection: 'column'
  },
  greetingDesktop: {
    width: 220,
    marginRight: 36
  },
  greetingTablet: {
    marginLeft: 140
  },
  greetingMobile: {
    marginLeft: 54
  },
  content: {
    textAlign: 'left'
  },
  contentTablet: {
    marginTop: 40
  },
  contentMobile: {
    marginTop: 24
  },
  messageDesktop: {
    marginTop: 8
  },
  messageTablet: {
    marginTop: 16
  },
  messageMobile: {
    marginTop: 16
  },
  signature: {
    marginTop: 16
  },
  linkContainer: {
    marginTop: 56,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  linkContainerTablet: {
    marginTop: 40
  },
  linkContainerMobile: {
    marginTop: 32
  },
  link: {
    ...theme.typography.body1,
    marginLeft: 16,
    maxWidth: 336,
    color: theme.palette.secondary.main
  }
}));

const ThanksPage = ({
  addManDogNL,
  manDogPayLater,
  showGoogleLink,
  isAuthenticatedAppLoading,
  noGoogleLinkMode,
  addSignature,
  headerText,
  bodyText
}) => {
  const classes = useStyles();
  const { isDesktopWidth, isTabletWidth, isMobileWidth } = useAppWidthClass();

  if (isAuthenticatedAppLoading) {
    return (
      <div style={{ margin: 16 }}>
        <SkeletonModule type="widgets" />
        <SkeletonModule />
      </div>
    );
  }

  return (
    <Module
      id="thanks-page-content"
      className={clsx(
        classes.module,
        isTabletWidth && classes.moduleTablet,
        isMobileWidth && classes.moduleMobile
      )}
    >
      <ManDog displayIfNL={addManDogNL} manDogPayLater={manDogPayLater} />
      <Box
        className={clsx(
          classes.wrapper,
          isTabletWidth && classes.wrapperTablet,
          isMobileWidth && classes.wrapperMobile
        )}
      >
        <Box
          className={clsx(
            isDesktopWidth && classes.container,
            isTabletWidth && classes.containerTablet,
            isMobileWidth && classes.containerMobile
          )}
        >
          <GreetingAvatar
            className={clsx(
              isDesktopWidth && classes.greetingDesktop,
              isTabletWidth && classes.greetingTablet,
              isMobileWidth && classes.greetingMobile
            )}
            isTabletSize={isTabletWidth || isMobileWidth}
          />
          <Box
            className={clsx(
              isDesktopWidth && classes.content,
              isTabletWidth && classes.contentTablet,
              isMobileWidth && classes.contentMobile
            )}
          >
            <Typography
              component="h1"
              variant="h3"
              color="secondary"
              align={isMobileWidth ? 'center' : 'left'}
            >
              <Translation inline keyIfNoValue>
                {!!headerText ? headerText : 'MAIN_SALUTATION'}
              </Translation>
            </Typography>
            <Typography
              id="thanks-page-message"
              className={clsx(
                isDesktopWidth && classes.messageDesktop,
                isTabletWidth && classes.messageTablet,
                isMobileWidth && classes.messageMobile
              )}
              component="p"
              variant="body1"
              color="textPrimary"
            >
              <Translation withSignature inline>
                {!!bodyText ? bodyText : 'CHECKOUT_THANK_YOU_TOP'}
              </Translation>
            </Typography>
            {!!addSignature && <Signature className={classes.signature} />}
          </Box>
        </Box>
        {!noGoogleLinkMode && showGoogleLink && (
          <Box
            className={clsx(
              classes.linkContainer,
              isTabletWidth && classes.linkContainerTablet,
              isMobileWidth && classes.linkContainerMobile
            )}
          >
            <img src={megaphone} alt="megaphone" />
            <Link
              raw
              id="page.thanks.component.controls.redirect-to-google-link"
              className={classes.link}
              to="https://g.page/troy-gmbh/review?rc"
              target="_blank"
            >
              <Translation keyIfNoValue inline>
                CHECKOUT_GOOGLE_REVIEW
              </Translation>
            </Link>
          </Box>
        )}
      </Box>
    </Module>
  );
};

export default connectTo(
  state => {
    const {
      ui: {
        thanks: {
          googleLink: { show: showGoogleLink }
        }
      }
    } = state;
    return {
      showGoogleLink,
      isAuthenticatedAppLoading: isAuthenticatedAppLoadingSelector(state)
    };
  },
  {},
  ThanksPage
);
