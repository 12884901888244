import React, { useState } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import makeStyles from '@mui/styles/makeStyles';

import {
  Switch,
  Text,
  useTranslationsContext
} from '@troy/shared/src/components/common';

import { ThemeButton } from '../../common';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  right: {
    marginLeft: 'auto'
  },
  collapseButton: {
    paddingLeft: 0
  },
  arrow: {
    transition: theme.transitions.custom.fast
  },
  arrowOpen: {
    transform: 'rotate(90deg)'
  },
  content: {
    background: theme.customColors.actionsInfo,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderRadius: Number(theme.shape.borderRadius) * 2
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',

    '&:not(:first-of-type)': {
      marginTop: theme.spacing(2)
    }
  },
  title: {
    width: 112,
    flexShrink: 0
  },
  description: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'pre-line',
    wordBreak: 'break-word'
  },
  descriptionMobile: {
    marginLeft: theme.spacing(1)
  },
  line: {
    width: '100%',
    height: '1px',
    background: theme.palette.text.secondary
  }
}));

const CookieGroup = ({
  alwaysActive,
  isEnabled,
  toggleEnabled,
  isMobileWidth,
  id,
  title,
  cookies
}) => {
  const classes = useStyles();
  const { translate } = useTranslationsContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleCollapseClick = () => setIsOpen(!isOpen);

  const hasTexts = translate(cookies[0]) !== '';

  return (
    <div className={clsx(classes.container)}>
      <div className={classes.header}>
        <ThemeButton
          id={`page.component.cookie-agreement.group.${id}`}
          className={classes.collapseButton}
          size="large"
          color="textPrimary"
          onClick={handleCollapseClick}
          textKey={title}
          startIcon="chevronRight"
          startIconColor="textPrimary"
          iconClassName={clsx(classes.arrow, isOpen && classes.arrowOpen)}
        />
        {!alwaysActive && (
          <Switch
            className={classes.right}
            isEnabled={isEnabled}
            toggleEnabled={toggleEnabled}
            title={title}
          />
        )}
        {alwaysActive && (
          <Text
            translated
            className={classes.right}
            component="span"
            variant="body1Bold"
            color="primary"
          >
            COOKIE_BANNER_ALWAYSACTIVE
          </Text>
        )}
      </div>
      {hasTexts && (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
              style={{ overflow: 'hidden' }}
              role="region"
              aria-live="polite"
            >
              {cookies.map(cookie => {
                const rows = translate(cookie).split(';');

                return (
                  <div key={rows[0]} className={classes.content}>
                    {rows.map(row => {
                      const cols = row.split('=');
                      return (
                        <div key={cols[0]} className={classes.row}>
                          <Text
                            component="div"
                            variant="body2Bold"
                            color="textPrimary"
                            className={classes.title}
                          >
                            {cols[0]}
                          </Text>
                          <Text
                            component="div"
                            variant="body2"
                            className={clsx(
                              classes.description,
                              isMobileWidth && classes.descriptionMobile
                            )}
                            dangerouslySetInnerHTML={{ __html: cols[1] }}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>
      )}
      <div className={classes.line} />
    </div>
  );
};

export default CookieGroup;
