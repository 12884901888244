import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Radio,
  RadioGroup as MaterialRadioGroup
} from '@mui/material';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';

import { onClickWrapper } from '../../../utils/ui';
import { FormField } from '@troy/shared/src/components/common';

const RadioGroup = class RadioGroup extends React.Component {
  static renderRadioGroup = ({
    ariaControlsId,
    onChange,
    value,
    options,
    className,
    radioClassName,
    radioLabelClassName,
    radioIconClassName,
    radioIconCheckedClassName,
    animationProps,
    animationIndexFunction,
    field,
    labelInteractive
  }) => {
    let Element = React.Fragment;
    let props = {};

    if (animationProps) {
      Element = motion.div;
      props = animationProps;
    }

    return (
      <MaterialRadioGroup
        aria-controls={ariaControlsId}
        className={className}
        {...field}
        value={field ? field.value : value}
        onChange={onClickWrapper(null, field?.onChange || onChange)}
      >
        <AnimateSharedLayout>
          {options.map(
            (
              { label, id, value: optionValue, content, testLabelRole },
              idx
            ) => (
              <Element
                key={idx}
                {...props}
                {...(animationIndexFunction ? animationIndexFunction(idx) : {})}
              >
                <FormControlLabel
                  className={radioClassName}
                  classes={{ label: radioLabelClassName }}
                  label={label}
                  value={optionValue}
                  onClick={e => labelInteractive && e.preventDefault()}
                  control={
                    <Radio
                      classes={{
                        root: radioIconClassName,
                        checked: radioIconCheckedClassName
                      }}
                      color="secondary"
                      id={id}
                      onClick={onClickWrapper(
                        null,
                        field?.onChange || onChange
                      )}
                    />
                  }
                  data-label-role={testLabelRole}
                />
                <AnimatePresence>
                  {value === optionValue && (
                    <motion.div
                      initial={{ height: 0 }}
                      animate={{ height: 'auto' }}
                      exit={{ height: 0 }}
                      transition={{ duration: 0.2, ease: 'easeInOut' }}
                      style={{ overflow: 'hidden' }}
                      id={ariaControlsId}
                      role="region"
                      aria-live="polite"
                    >
                      {content}
                    </motion.div>
                  )}
                </AnimatePresence>
              </Element>
            )
          )}
        </AnimateSharedLayout>
      </MaterialRadioGroup>
    );
  };

  render() {
    const {
      name,
      onChange,
      value,
      options,
      className,
      radioClassName,
      radioLabelClassName,
      radioIconClassName,
      radioIconCheckedClassName,
      animationProps,
      animationIndexFunction,
      labelInteractive
    } = this.props;

    const customProps = {
      onChange,
      value,
      options,
      className,
      radioClassName,
      radioLabelClassName,
      radioIconClassName,
      radioIconCheckedClassName,
      animationProps,
      animationIndexFunction,
      labelInteractive
    };
    return name ? (
      <FormField
        component={RadioGroup.renderRadioGroup}
        name={name}
        onChange={onChange}
        {...customProps}
      />
    ) : (
      RadioGroup.renderRadioGroup(customProps)
    );
  }
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  options: PropTypes.array,
  className: PropTypes.string,
  radioClassName: PropTypes.string,
  radioLabelClassName: PropTypes.string,
  radioIconClassName: PropTypes.string,
  radioIconCheckedClassName: PropTypes.string
};

export default RadioGroup;
