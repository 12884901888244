export const PORTAL_DE = 'troy_de_DE';
export const PORTAL_NL = 'troy_nl_NL';

export const replaceWhiteLabelImages = (
  imgFilePath,
  timelineLogo,
  timelineLogoFuture,
  timelineHandoverImage
) => {
  if (imgFilePath === '/img/troy_login.png' && !!timelineLogo) {
    return timelineLogo;
  } else if (imgFilePath === '/img/troy_future.png' && !!timelineLogoFuture) {
    return timelineLogoFuture;
  } else if (
    imgFilePath === '/icon/documents_icon.png' &&
    !!timelineHandoverImage
  ) {
    return timelineHandoverImage;
  }
  return imgFilePath;
};

export const getPortalCurrencyOverride = (defaultCurrency, currencyOverride) =>
  currencyOverride ? currencyOverride : defaultCurrency;

export const isValidCustomFooterData = data => {
  return (
    [2, 3, 4].includes(data.length) && data.every(column => column.length > 0)
  );
};
