import { createReducer } from 'redux-act';

import * as actions from '../actions/headers';

const DEFAULT_STATE = {};

export default createReducer(
  {
    [actions.setCustomHeaders]: (state, customHeadersObj) => {
      const tenant = process.env.REACT_APP_TENANT.toLowerCase();
      return {
        ...state,
        [tenant.charAt(0).toUpperCase() + tenant.slice(1) + 'CustomParameters']:
          Object.entries(customHeadersObj)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')
      };
    },
    [actions.headersReset]: () => ({ ...DEFAULT_STATE })
  },
  DEFAULT_STATE
);
