import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import * as routes from '../../../constants/routes';
import { fadeInAnimationItem } from '../../../constants/animations';
import { DOMAIN_LANGUAGE, NL } from '../../../constants/browser';
import { translationsSelector } from '../../../store/selectors';
import { Blurred, Link } from '../../common';
import {
  INSTALLMENT_VALUE,
  OFFLINE_SEPA_VALUE,
  SEPA_DIRECT_DEBIT_VALUE,
  SEPA_IMAGE
} from '../../../constants/payment';
import { filterPaymentMethods } from '../../../utils/payment';

const useStyles = makeStyles(theme => ({
  methods: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  methodsMobile: {
    marginTop: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-evenly'
  },
  methodsMobileNL: {
    width: '70%'
  },
  link: {
    display: 'inline-flex',
    margin: 0,
    padding: theme.spacing(1.5),

    '&:focus, &:focus-visible': {
      outline: 'none',

      '& > img': {
        margin: '-1px -1px -2px -1px',
        borderWidth: '1px 1px 2px 1px',
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.main,
        boxShadow: theme.customShadows.focus,
        borderRadius: theme.shape.borderRadius * 0.5
      }
    }
  },
  linkNL: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`
  },
  linkMobile: {
    padding: `${theme.spacing(0.25)} 0 ${theme.spacing(1.5)} 0`
  },
  logo: {
    height: 24
  },
  logoNL: {
    height: 40
  },
  logoMobile: {
    height: 16
  },
  logoMobileNL: {
    height: 28
  }
}));

const parseMethods = (paymentMethods, paymentHide, balance) => {
  if (paymentHide) {
    return paymentMethods.slice(0, 1);
  } else {
    const firstSepaValueFromOrder = [
      OFFLINE_SEPA_VALUE,
      INSTALLMENT_VALUE,
      SEPA_DIRECT_DEBIT_VALUE
    ].reduce((acc, sepaValue) => {
      if (!acc.found) {
        const method = paymentMethods.find(({ value }) => value === sepaValue);
        if (method) {
          acc = method;
          acc.found = true;
        }
      }
      return acc;
    }, {});
    let methods = paymentMethods.reduce((acc, method) => {
      if (method.image !== SEPA_IMAGE) {
        acc.push(method);
      } else if (method.value === firstSepaValueFromOrder.value) {
        const { found, ...sepaMethod } = firstSepaValueFromOrder;
        acc.push(sepaMethod);
      }
      return acc;
    }, []);
    return filterPaymentMethods(methods, { balance });
  }
};

const PaymentMethodsList = ({
  isMobileSize,
  balance,
  paymentMethods,
  paymentHide,
  blur
}) => {
  const classes = useStyles();

  const isNL = DOMAIN_LANGUAGE === NL;
  let methods = parseMethods(paymentMethods, paymentHide, balance);
  const translations = useSelector(translationsSelector);

  return (
    <div
      className={clsx(
        classes.methods,
        isMobileSize && classes.methodsMobile,
        isNL && isMobileSize && classes.methodsMobileNL
      )}
    >
      {methods.length > 0 &&
        methods.map(({ label, image, value, id }, index) => (
          <Blurred blur={blur} key={id}>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInAnimationItem}
              transition={{ delay: 0.5 + index * 0.1 }}
            >
              <Link
                className={clsx(
                  classes.link,
                  isNL && classes.linkNL,
                  isMobileSize && classes.linkMobile
                )}
                id={`page.home.component.payment-list.${id}`}
                to={`${routes.PAYMENTS}?method=${value}`}
              >
                <img
                  className={clsx(
                    isNL ? classes.logoNL : classes.logo,
                    isMobileSize && classes.logoMobile,
                    isMobileSize && isNL && classes.logoMobileNL
                  )}
                  src={image}
                  alt={translations[label]}
                />
              </Link>
            </motion.div>
          </Blurred>
        ))}
    </div>
  );
};

export default PaymentMethodsList;
