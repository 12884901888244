import { put, select } from 'redux-saga/effects';

import { notificationsReceived } from '@troy/shared/src/store/actions';
import { callHttp } from '../../utils/api';
import {
  customersReceived,
  handleError, set2FAInfo,
  setLanguage,
  setLiability,
  setLiabilityPickupData,
  setPaymentUserInfo,
  setProfileInfo,
  setSecondFactorInfo,
  setTranslations,
  submitReturnGoodsError,
  submitReturnGoodsOk
} from '../actions';

import {
  GET_CUSTOMER_LIABILITY_PICKUP_DATA,
  GET_CUSTOMERS
} from '../../constants/graphql';
import client from '../../config/graphql';
import { post } from '../../utils/httpUtil';
import { RETURN_GOODS } from '../../constants/api';
import {
  isTokenInternalSelector,
  portalCurrencyLocalOverrideSelector,
  portalTimelineHandoverImageSelector,
  portalTimelineLogoFutureSelector,
  portalTimelineLogoSelector
} from '../selectors';

export function* getCustomers() {
  try {
    const response = yield callHttp(client.query, { query: GET_CUSTOMERS });
    const data = response.data;
    if (data.customers) {
      const customers = data.customers;
      yield put(customersReceived(customers));
      yield put(setLanguage(customers[0].language));
      yield put(
        setTranslations({
          translations: customers[0].liabilities[0].notes.notes_list
        })
      );
      const timelineLogo = yield select(portalTimelineLogoSelector);
      const timelineLogoFuture = yield select(portalTimelineLogoFutureSelector);
      const timelineHandoverImage = yield select(
        portalTimelineHandoverImageSelector
      );
      const currencyLocalOverride = yield select(
        portalCurrencyLocalOverrideSelector
      );

      yield put(
        setLiability({
          liability: customers[0].liabilities[0],
          timelineLogo,
          timelineLogoFuture,
          timelineHandoverImage,
          currencyOverride: currencyLocalOverride
        })
      );
      yield put(
        set2FAInfo({ liability: customers[0].liabilities[0] })
      );
      yield put(
        setPaymentUserInfo({
          liability: customers[0].liabilities[0],
          currencyOverride: currencyLocalOverride
        })
      );
      yield put(setProfileInfo(customers[0]));
      const isTokenInternal = yield select(isTokenInternalSelector);
      yield put(
        notificationsReceived({
          items: customers[0].liabilities[0].serviceLog,
          isTokenInternal
        })
      );
    } else {
      throw new Error(response.errors.message);
    }
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* submitReturnGoods({
  payload: {
    pickupDate,
    time,
    customerName,
    street,
    postCode,
    city,
    addressCountry,
    phoneNumber
  }
}) {
  const body = {
    ReturnGoodsRequest: {
      pickupDate,
      pickupTime: time,
      address: {
        name: customerName,
        street: street,
        zipAndTown: `${postCode} ${city}`,
        country: addressCountry
      },
      phoneNumber: phoneNumber
    }
  };

  try {
    yield callHttp(post, RETURN_GOODS, body);

    const response = yield callHttp(client.query, {
      query: GET_CUSTOMER_LIABILITY_PICKUP_DATA
    });
    const data = response.data;
    if (data.customers) {
      const customers = data.customers;
      yield put(setLiabilityPickupData(customers[0].liabilities[0]));
      yield put(submitReturnGoodsOk());
    } else {
      yield put(submitReturnGoodsError());
      yield put(handleError(response.errors.message));
    }
  } catch (error) {
    yield put(submitReturnGoodsError());
    yield put(handleError(error));
  }
}
