import sepa from '../assets/img/payment/sepa.png';

import { DOMAIN_LANGUAGE, NL } from './browser';

export const SEPA_IMAGE = sepa;

export const getBalanceWithCurrency = (balance, currency, locale) =>
  Number(balance).toLocaleString(locale || 'de-DE', {
    currency: currency || 'EUR',
    style: 'currency'
  });

export const IDEAL_MOLLIE_VALUE = 'IDEAL_MOLLIE';
export const IDEAL_INSTALLMENT_VALUE = 'IDEAL_INSTALLMENT';
export const PAY_LATER_VALUE = 'PAY_LATER';
export const CREDIT_CARD_VALUE = 'CREDIT_CARD';
export const CREDIT_CARD_MOLLIE_VALUE = 'CREDIT_CARD_MOLLIE';
export const PAYPAL_VALUE = 'PAYPAL';
export const PAYPAL_MOLLIE_VALUE = 'PAYPAL_MOLLIE';
export const OFFLINE_SEPA_VALUE = 'OFFLINE_SEPA';
export const SOFORT_VALUE = 'SOFORT';
export const SOFORT_MOLLIE_VALUE = 'SOFORT_MOLLIE';
export const SEPA_DIRECT_DEBIT_VALUE = 'SEPA_DIRECT_DEBIT';
export const INSTALLMENT_VALUE = 'INSTALLMENT';
export const BAN_CONTACT_VALUE = 'BAN_CONTACT';
export const BAN_CONTACT_MOLLIE_VALUE = 'BAN_CONTACT_MOLLIE';

export const allRates = [25, 30, 40, 50, 75, 100, 150, 250, 500, 1000];
export const installmentRates = [
  ...allRates,
  2500,
  3000,
  4000,
  5000,
  7500,
  10000
];

export const validRates = (balance, rates = allRates, minPercentage = 10) =>
  rates.filter(item => {
    return balance >= item && item >= (balance / 100) * minPercentage;
  });

export const IDEAL_INSTALLMENT_FORM_NAME = 'ideal-installment';
export const PAY_LATER_FORM_NAME = 'pay-later';
export const INSTALLMENT_FORM_NAME = 'calculator';
export const SEPA_DIRECT_DEBIT_FORM_NAME = 'sepa';

export const PAY_NOW_TEXT_KEY = 'PAY_NOW';

export const EMAIL_PLACEHOLDER = DOMAIN_LANGUAGE === NL ? 'user@troy.nl' : '';
export const PHONE_PLACEHOLDER = DOMAIN_LANGUAGE === NL ? '0612345678' : '';
