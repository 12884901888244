import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  portalDirectorDescriptionKeySelector,
  portalDirectorImageSelector,
  portalDirectorNameSelector
} from '../../../store/selectors';

import { Avatar, Translation } from '../../common';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexShrink: 0
  },
  rootSmall: {
    flexDirection: 'row'
  },
  rootNoLogo: {
    margin: 'auto'
  },
  rootNoLogoTablet: {
    marginBottom: 24
  },
  avatar: {
    width: 128,
    height: 128
  },
  avatarSmall: {
    width: 80,
    height: 80
  },
  detailsTablet: {
    maxWidth: 166
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 8
  },
  name: {
    ...theme.typography.body1Bold,
    marginTop: theme.spacing(1),
    color: theme.palette.text.primary,
    textAlign: 'center'
  },
  nameSmall: {
    ...theme.typography.body2Bold,
    marginTop: 0,
    marginBottom: theme.spacing(0.5),
    textAlign: 'left'
  },
  description: {
    display: 'inline-block',
    textAlign: 'center'
  },
  descriptionSmall: {
    maxWidth: 86,
    fontSize: '0.5625rem',
    lineHeight: 1.333333,
    textAlign: 'left'
  }
}));

const GreetingAvatar = ({
  logoMissing,
  isTabletSize,
  isMobileSize,
  className
}) => {
  const classes = useStyles();
  const isCompact = isTabletSize || isMobileSize;
  const directorName = useSelector(portalDirectorNameSelector);
  const directorImage = useSelector(portalDirectorImageSelector);
  const descriptionKey = useSelector(portalDirectorDescriptionKeySelector);

  return (
    <div
      className={clsx(
        classes.root,
        isCompact && classes.rootSmall,
        logoMissing && classes.rootNoLogo,
        isTabletSize && logoMissing && classes.rootNoLogoTablet,
        className
      )}
    >
      <Avatar
        alt=""
        src={directorImage}
        className={clsx(classes.avatar, isCompact && classes.avatarSmall)}
      />
      <Box
        className={clsx(
          isTabletSize && classes.detailsTablet,
          isCompact && classes.details
        )}
      >
        <Typography
          variant="body1"
          component="p"
          className={clsx(classes.name, isCompact && classes.nameSmall)}
        >
          {directorName}
        </Typography>
        <Typography
          className={clsx(
            !isTabletSize && classes.description,
            !logoMissing && isMobileSize && classes.descriptionSmall
          )}
          variant={isCompact ? 'subtitle1' : 'body2'}
          component="p"
          color="textPrimary"
        >
          <Translation inline keyIfNoValue>
            {descriptionKey}
          </Translation>
        </Typography>
      </Box>
    </div>
  );
};

GreetingAvatar.propTypes = {
  logoMissing: PropTypes.bool,
  isTabletSize: PropTypes.bool,
  isMobileSize: PropTypes.bool,
  className: PropTypes.string
};

export default GreetingAvatar;
