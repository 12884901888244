import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { useAppWidthClass } from '../../utils/hooks';
import { Module, Text } from '@troy/shared/src/components/common';

import { Translation } from '../../components/common';

import illustration from '../../assets/img/installment_feedback/installment_thanks.png';
import { Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: 'auto 16px'
  },
  container: {
    margin: '16px 0',
    padding: '64px 88px',
    display: 'flex',
    flexDirection: 'row'
  },
  containerTablet: {
    padding: 40,
    flexDirection: 'column'
  },
  containerMobile: {
    padding: '32px 16px',
    flexDirection: 'column'
  },
  imgSmall: {
    height: 63,
    margin: 'auto'
  },
  imgLarge: {
    height: 91,
    margin: 'auto'
  },
  textDesktop: {
    marginLeft: 32
  },
  textDevice: {
    marginTop: 24
  }
}));

const CalculatorAwait = () => {
  const classes = useStyles();
  const { isDesktopWidth, isTabletWidth, isMobileWidth } = useAppWidthClass();

  return (
    <Box className={classes.wrapper}>
      <Module
        className={clsx(
          classes.container,
          isTabletWidth && classes.containerTablet,
          isMobileWidth && classes.containerMobile
        )}
      >
        <img
          className={isMobileWidth ? classes.imgSmall : classes.imgLarge}
          src={illustration}
          alt="illustration"
        />
        <Text
          className={clsx(
            isDesktopWidth && classes.textDesktop,
            (isTabletWidth || isMobileWidth) && classes.textDevice
          )}
          variant={isMobileWidth ? 'body1Bold' : 'h4'}
          color="secondary"
          align="center"
        >
          <Translation inline>CALCULATOR_AWAIT_CONFIRMATION</Translation>
        </Text>
      </Module>
    </Box>
  );
};

export default CalculatorAwait;
