import React from 'react';

import { connectTo } from '../../utils';
import {
  beginIdealInstallmentSession,
  beginMollieSession,
  beginPaymentSession,
  getUser,
  offlineSepaSubmit,
  payLater,
  payMollieSofort,
  payOffline,
  paySepaDirectDebit,
  sendCalculatorInfo,
  setPaymentMethod,
  payMollieBanContact
} from '../../store/actions';

import InstallmentContent from '../../components/common/InstallmentContent';
import {
  IDEALInstallment,
  PayLater,
  PaymentMethodLabel,
  PaymentsContent,
  Sepa as SepaContent,
  SepaDirectDebit
} from '../../components/payments/index.troy';

import {
  CREDIT_CARD_MOLLIE_VALUE,
  getBalanceWithCurrency,
  IDEAL_INSTALLMENT_VALUE,
  IDEAL_MOLLIE_VALUE,
  INSTALLMENT_VALUE,
  OFFLINE_SEPA_VALUE,
  PAY_LATER_VALUE,
  PAYPAL_MOLLIE_VALUE,
  SEPA_DIRECT_DEBIT_VALUE,
  SOFORT_MOLLIE_VALUE,
  BAN_CONTACT_MOLLIE_VALUE
} from '../../constants/payment';
import * as routes from '../../constants/routes';

import { filterPaymentMethods } from '../../utils/payment';
import { getSearchUrl, getUrlParams } from '../../utils/httpUtil';
import {
  hasNutunPaymentSelector,
  isAuthenticatedAppLoadingSelector
} from '../../store/selectors';
import { SkeletonModule } from '../../components/common';
import { HEADER_NAVIGATION_HEIGHT } from '../../constants/ui';
import { useAppWidthClass } from '../../utils/hooks';

const imagedPaymentMethods = paymentMethods =>
  paymentMethods.map(({ label, image, value, displayName, id }) => ({
    label: <PaymentMethodLabel label={label} image={image} />,
    value,
    content: null,
    displayName,
    id: 'page.payments.component.payment-radiogroup.' + id,
    testLabelRole: 'paymentMethod'
  }));

const transformPaymentMethods = (
  paymentMethods,
  {
    username,
    currency,
    locale,
    balance,
    firstName,
    lastName,
    email,
    mobilePhone,
    phone,
    sendCalculatorInfo,
    beginIdealInstallmentSession,
    paySepaDirectDebit,
    payLater,
    formRef
  }
) => {
  paymentMethods = imagedPaymentMethods(paymentMethods);
  paymentMethods = filterPaymentMethods(paymentMethods, { balance });

  const offlineSepaInd = paymentMethods.indexOf(
    paymentMethods.find(({ value }) => value === OFFLINE_SEPA_VALUE)
  );
  if (offlineSepaInd >= 0) {
    paymentMethods[offlineSepaInd].content = (
      <SepaContent username={username} />
    );
  }

  const installmentInd = paymentMethods.indexOf(
    paymentMethods.find(({ value }) => value === INSTALLMENT_VALUE)
  );
  if (installmentInd >= 0) {
    paymentMethods[installmentInd].content = (
      <InstallmentContent
        currency={currency}
        locale={locale}
        balance={balance}
        firstName={firstName}
        lastName={lastName}
        email={email}
        mobilePhone={mobilePhone}
        phone={phone}
        onSubmit={values => {
          sendCalculatorInfo({ currency, ...values });
        }}
        formRef={formRef}
        pageName="payments"
      />
    );
  }

  const directDebitInd = paymentMethods.indexOf(
    paymentMethods.find(({ value }) => value === SEPA_DIRECT_DEBIT_VALUE)
  );
  if (directDebitInd >= 0) {
    paymentMethods[directDebitInd].content = (
      <SepaDirectDebit
        {...{
          firstName,
          lastName,
          onSubmit: values => {
            paySepaDirectDebit(values);
          },
          formRef
        }}
      />
    );
  }

  const idealInstallmentInd = paymentMethods.indexOf(
    paymentMethods.find(({ value }) => value === IDEAL_INSTALLMENT_VALUE)
  );
  if (idealInstallmentInd >= 0) {
    paymentMethods[idealInstallmentInd].content = (
      <IDEALInstallment
        {...{
          currency,
          locale,
          balance,
          email,
          mobilePhone,
          phone,
          onSubmit: values => {
            beginIdealInstallmentSession({
              currency,
              ...values
            });
          },
          formRef
        }}
      />
    );
  }

  const payLaterInd = paymentMethods.indexOf(
    paymentMethods.find(({ value }) => value === PAY_LATER_VALUE)
  );
  if (payLaterInd >= 0) {
    paymentMethods[payLaterInd].content = (
      <PayLater
        {...{
          balance,
          email,
          mobilePhone,
          phone,
          onSubmit: values => {
            payLater(values);
          },
          formRef
        }}
      />
    );
  }

  return paymentMethods;
};

export class PaymentsConnected extends React.Component {
  constructor(props) {
    super(props);
    const { method, ...params } = getUrlParams();
    this.state = {
      methodName: '',
      methodValue: method || null,
      otherParams: params,
      paymentMethods: [],
      formRef: React.createRef()
    };
  }

  componentDidMount() {
    const {
      getUser,
      username,
      history,
      location,
      hasNutunPayment,
      isMobileWidth
    } = this.props;

    const { methodValue } = this.state;

    if (hasNutunPayment) {
      history.replace(routes.HOME);
      return;
    }

    history.replace(
      getSearchUrl({ url: location.pathname, query: this.state.otherParams })
    );

    if (isMobileWidth && methodValue) {
      const elem = document.querySelector(`input[value="${methodValue}"]`);
      if (elem) {
        setTimeout(() => {
          const elemTop = elem.getBoundingClientRect().top;
          window.scrollTo({
            top: window.scrollY + elemTop - HEADER_NAVIGATION_HEIGHT - 40,
            behaviour: 'smooth'
          });
        }, 50);
      }
    }

    if (!username) {
      getUser();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      history,
      username,
      paymentMethods,
      hide,
      currency,
      locale,
      balance,
      firstName,
      lastName,
      email,
      mobilePhone,
      phone,
      sendCalculatorInfo,
      beginMollieSession,
      beginIdealInstallmentSession,
      paySepaDirectDebit,
      payLater,
      hasNutunPayment
    } = nextProps;

    if (hasNutunPayment) {
      history.replace(routes.HOME);
      return null;
    }

    if (paymentMethods && paymentMethods.length) {
      const transformed = transformPaymentMethods(paymentMethods, {
        username,
        currency,
        locale,
        balance,
        firstName,
        lastName,
        email,
        mobilePhone,
        phone,
        sendCalculatorInfo,
        beginMollieSession,
        beginIdealInstallmentSession,
        paySepaDirectDebit,
        payLater,
        formRef: prevState.formRef
      });
      const newState = {
        paymentMethods: hide ? transformed.slice(0, 1) : transformed
      };

      if (hide) {
        newState.methodName = transformed[0].displayName;
        newState.methodValue = transformed[0].value;
      } else if (prevState.methodValue) {
        const selectedMethod = transformed.find(
          ({ value }) => value === prevState.methodValue
        );
        if (selectedMethod) {
          newState.methodName = selectedMethod.displayName;
        }
      }

      return newState;
    }

    return null;
  }

  onCardChange = ({ target: { value } }) => {
    const displayName = this.state.paymentMethods.find(
      method => method.value === value
    ).displayName;
    this.setState({
      methodName: displayName,
      methodValue: value
    });
  };

  onPayNow = () => {
    const {
      balance,
      currency,
      offlineSepaSubmit,
      beginPaymentSession,
      beginMollieSession
    } = this.props;
    const { methodValue } = this.state;
    if (balance > 0) {
      switch (methodValue) {
        case OFFLINE_SEPA_VALUE: {
          offlineSepaSubmit();
          break;
        }
        case INSTALLMENT_VALUE:
        case SEPA_DIRECT_DEBIT_VALUE:
        case IDEAL_INSTALLMENT_VALUE:
        case PAY_LATER_VALUE: {
          prevState.formRef.current.handleSubmit();
          break;
        }
        case IDEAL_MOLLIE_VALUE:
        case SOFORT_MOLLIE_VALUE:
        case BAN_CONTACT_MOLLIE_VALUE:
        case CREDIT_CARD_MOLLIE_VALUE:
        case PAYPAL_MOLLIE_VALUE: {
          beginMollieSession({
            amount: balance,
            currency,
            type: methodValue
          });
          break;
        }
        default: {
          beginPaymentSession({
            type: methodValue,
            amount: balance,
            currency: currency
          });
          break;
        }
      }
    }
  };

  render() {
    let { methodName, methodValue, paymentMethods, formRef } = this.state;
    const {
      balance,
      currency,
      locale,
      paymentButtonDecision,
      isAuthenticatedAppLoading,
      isPaymentLoading
    } = this.props;

    const payNowDisabled =
      (balance <= 1 && paymentButtonDecision) ||
      methodValue === INSTALLMENT_VALUE ||
      methodValue === SEPA_DIRECT_DEBIT_VALUE ||
      methodValue === IDEAL_INSTALLMENT_VALUE ||
      (methodValue === PAY_LATER_VALUE &&
        !(formRef.current && formRef.current.isValid));

    if (isAuthenticatedAppLoading) {
      return (
        <div>
          <SkeletonModule topMargin type="widgets" />
          <SkeletonModule bottomMargin />
        </div>
      );
    }

    return (
      <PaymentsContent
        amount={getBalanceWithCurrency(balance, currency, locale)}
        methods={paymentMethods}
        methodName={methodName}
        methodValue={methodValue}
        onMethodChange={this.onCardChange}
        onPayNow={this.onPayNow}
        payNowDisabled={payNowDisabled}
        isPaymentLoading={isPaymentLoading}
      />
    );
  }
}

const ConnectPayments = connectTo(
  state => {
    const {
      auth: { username },
      ui: {
        payment: { hide, offlineTransferHide, methods: paymentMethods }
      },
      payment: {
        paymentUserInfo: { totalBalance, dueCurrency },
        isLoading: isPaymentLoading
      },
      liability: { locale },
      profile: { firstname, lastname, email, mobilePhone, phone },
      decisions: {
        'troy.feature.payment.button.disable': paymentButtonDecision
      }
    } = state;

    return {
      username,
      paymentMethods,
      hide,
      offlineTransferHide,
      balance: totalBalance,
      currency: dueCurrency,
      locale,
      firstName: firstname,
      lastName: lastname,
      email,
      mobilePhone,
      phone,
      paymentButtonDecision,
      isAuthenticatedAppLoading: isAuthenticatedAppLoadingSelector(state),
      isPaymentLoading,
      hasNutunPayment: hasNutunPaymentSelector(state)
    };
  },
  {
    beginPaymentSession,
    beginMollieSession,
    beginIdealInstallmentSession,
    payOffline,
    getUser,
    sendCalculatorInfo,
    paySepaDirectDebit,
    payLater,
    setPaymentMethod,
    payMollieSofort,
    offlineSepaSubmit,
    payMollieBanContact
  },
  PaymentsConnected
);

export default props => {
  const { isMobileWidth } = useAppWidthClass();

  return <ConnectPayments {...props} isMobileWidth={isMobileWidth} />;
};
