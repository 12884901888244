import React from 'react';
import PropTypes from 'prop-types';

const DocumentPage = ({ html, styles }) => (
  <div className={styles['document__wrapper']}>
    <div
      className={styles['document__content-wrapper']}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  </div>
);

DocumentPage.propTypes = {
  html: PropTypes.string,
  styles: PropTypes.object
};

export default DocumentPage;
