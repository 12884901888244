import { useSelector } from 'react-redux';
import { OutputSelector } from 'reselect';
import { SelectorArray } from 'reselect/es/types';

export interface DecisionProps {
  reverse?: boolean;
  selector:
    | (() => boolean)
    | OutputSelector<SelectorArray, unknown, (res: never) => unknown>;
  children: JSX.Element;
}

const Decision = ({
  reverse,
  selector,
  children
}: DecisionProps): JSX.Element | null => {
  const selectorValue = useSelector(selector);

  if (Boolean(selectorValue) === !reverse) {
    return children;
  }
  return null;
};

export default Decision;
