import React from 'react';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { addDays, addMonths, getDay } from 'date-fns';
import { translationsSelector } from '../../../store/selectors';
import { useAppWidthClass } from '../../../utils/hooks';
import { TIME_AFTERNOON, TIME_MORNING } from '../../../constants/return-goods';

import {
  RadioGroup,
  ThemeButton,
  Translation
} from '../../../components/common';
import {
  FormDateSelect,
  Text,
  Tooltip
} from '@troy/shared/src/components/common';

import rentedGoodsBox from '../../../assets/img/rented-goods/rented_goods_box.svg';

import { ReturnGoodsContactDetails } from '../index';
import { notEmptyValidator as ne } from '../../../utils/validation';

const useStyles = makeStyles(theme => ({
  titleRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    width: 40,
    height: 40
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto'
  },
  description: {
    marginTop: theme.spacing(4),
    textAlign: 'center'
  },
  dateLabel: {
    marginTop: theme.spacing(2)
  },
  section: {
    marginTop: theme.spacing(2)
  },
  tooltipMobile: {
    marginTop: theme.spacing(1)
  },
  tooltipMargin: {
    marginTop: theme.spacing(8)
  },
  dateWrapper: {
    display: 'inline-block'
  },
  dateMobile: {
    width: '100%',

    '& > div': {
      width: '100%',

      '& > div': {
        width: '100%'
      }
    }
  },
  dateSelect: {
    width: 205
  },
  radio: {
    marginLeft: 0,
    marginRight: 0
  },
  radioIcon: {
    padding: theme.spacing(0.5),

    '& svg:first-of-type': {
      transform: 'scale(0.8)'
    }
  },
  radioLabel: {
    marginLeft: theme.spacing(2)
  },
  line: {
    display: 'inline-block',
    margin: `${theme.spacing(1)} 0`,
    width: '100%',
    height: 1,
    background: theme.palette.text.disabled
  },
  confirmationText: {
    paddingTop: theme.spacing(2),
    textAlign: 'center'
  },
  textLeft: {
    textAlign: 'left'
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',

    '& > button:first-of-type': {
      marginRight: theme.spacing(1)
    },
    '& > button:last-of-type': {
      marginLeft: theme.spacing(1)
    }
  },
  buttonMobile: {
    minWidth: 'unset',
    flexGrow: 1
  }
}));

const getInitialValue = () => {
  let date = addDays(new Date(), 14);

  const day = getDay(date);

  // check and move over weekend
  if (day === 0) {
    date = addDays(date, 1);
  } else if (day === 6) {
    date = addDays(date, 2);
  }
  return date;
};

const isDayValid = date => {
  if (date == null) {
    return;
  }
  const day = getDay(date);
  return day !== 0 && day !== 6;
};

const ReturnGoodsEditViewContent = ({
  contentClass,
  onConfirm,
  onDeclineButton,
  customerName,
  street,
  postCode,
  city,
  addressCountry,
  phone,
  isSubmitLoading
}) => {
  const classes = useStyles();
  const t = useSelector(translationsSelector);
  const { isDesktopWidth, isMobileWidth } = useAppWidthClass();

  return (
    <>
      <Box className={classes.titleRow}>
        <img className={classes.icon} src={rentedGoodsBox} alt="box" />
        <Text
          translated
          className={classes.title}
          component="h1"
          variant={isDesktopWidth ? 'h3' : 'h4'}
          color="secondary"
        >
          BSH_RETURNGOODS_WORKFLOW_HEADLINE
        </Text>
      </Box>
      <Box className={clsx(classes.content, contentClass)}>
        <Text
          translated
          className={clsx(
            classes.description,
            !isDesktopWidth && classes.textLeft
          )}
          component="p"
          variant="body1"
          color="textPrimary"
        >
          BSH_RETURNGOODS_WORKFLOW_COPY
        </Text>
        <Formik
          initialValues={{
            pickupDate: getInitialValue(),
            time: TIME_MORNING
          }}
          onSubmit={({ pickupDate, time }) => onConfirm(pickupDate, time)}
        >
          {({ values, touched }) => {
            const dayError = !isDayValid(values.pickupDate);
            const userDataError =
              !!ne(customerName) ||
              !!ne(street) ||
              !!ne(postCode) ||
              !!ne(city) ||
              !!ne(addressCountry);
            const phoneError = !phone.isValid;
            const showPickMessage = !touched['pickupDate'] || dayError;

            const disabled = dayError || userDataError || phoneError;

            return (
              <Form>
                <Text
                  translated
                  className={classes.dateLabel}
                  component="h3"
                  variant="h4"
                  color="textPrimary"
                >
                  BSH_RETURNGOODS_WORKFLOW_SUBHEAD1
                </Text>
                <Box className={classes.section}>
                  <Tooltip
                    open
                    arrow
                    secondary={showPickMessage}
                    title={
                      showPickMessage
                        ? t['BSH_RETURNGOODS_WORKFLOW_TOOLTIP1'] || 'select'
                        : t['BSH_RETURNGOODS_WORKFLOW_TOOLTIP2'] || 'done'
                    }
                    placement={isMobileWidth ? 'bottom' : 'right'}
                    tooltipClass={
                      isMobileWidth ? classes.tooltipMobile : undefined
                    }
                  >
                    <Box
                      className={clsx(
                        classes.dateWrapper,
                        isMobileWidth && classes.dateMobile
                      )}
                    >
                      <FormDateSelect
                        className={classes.dateSelect}
                        id="return-goods-date"
                        name="pickupDate"
                        views={['day']}
                        minDate={addDays(new Date(), 3)}
                        maxDate={addMonths(new Date(), 3)}
                        shouldDisableDateCheck={day => !isDayValid(day)}
                        hintMode={touched['pickupDate']}
                        error={dayError}
                      />
                    </Box>
                  </Tooltip>
                </Box>
                <Box
                  className={clsx(
                    classes.section,
                    isMobileWidth && classes.tooltipMargin
                  )}
                >
                  <RadioGroup
                    name="time"
                    radioClassName={classes.radio}
                    radioIconClassName={classes.radioIcon}
                    radioLabelClassName={classes.radioLabel}
                    options={[
                      {
                        label: (
                          <Box>
                            <Translation keyIfNoValue inline>
                              BSH_RETURNGOODS_WORKFLOW_OPTION1
                            </Translation>
                          </Box>
                        ),
                        value: TIME_MORNING,
                        id: 'page.return-goods.edit-form.time-morning',
                        testLabelRole: 'return-time-item'
                      },
                      {
                        label: (
                          <Box>
                            <Translation keyIfNoValue inline>
                              BSH_RETURNGOODS_WORKFLOW_OPTION2
                            </Translation>
                          </Box>
                        ),
                        value: TIME_AFTERNOON,
                        id: 'page.return-goods.edit-form.time-other',
                        testLabelRole: 'return-time-item'
                      }
                    ]}
                  />
                </Box>
                <Box className={classes.section}>
                  <Box className={classes.line} />
                </Box>
                <ReturnGoodsContactDetails
                  title="BSH_RETURNGOODS_WORKFLOW_SUBHEAD2"
                  customerName={customerName}
                  street={street}
                  postCode={postCode}
                  city={city}
                  addressCountry={addressCountry}
                  phone={phone.value}
                />
                <Box className={classes.section}>
                  <Text
                    translated
                    className={clsx(
                      classes.confirmationText,
                      isMobileWidth && classes.textLeft
                    )}
                    component="p"
                    variant="body1"
                    color="textPrimary"
                  >
                    BSH_RETURNGOODS_WORKFLOW_CONFIRMATION
                  </Text>
                  <Box className={classes.buttons}>
                    <ThemeButton
                      className={isMobileWidth && classes.buttonMobile}
                      id="page.return-goods.edit-form.cancel-button"
                      color="secondary"
                      variant="outlined"
                      size="large"
                      endIcon="dismiss"
                      endIconColor="secondary"
                      onClick={onDeclineButton}
                      textKey={'BSH_RETURNGOODS_WORKFLOW_BUTTON_NO'}
                    />
                    <ThemeButton
                      hasAnimation
                      isLoading={isSubmitLoading}
                      className={isMobileWidth && classes.buttonMobile}
                      id="page.return-goods.edit-form.confirm-button"
                      disabled={disabled}
                      type="submit"
                      variant="contained"
                      size="large"
                      endIcon="confirm"
                      endIconColor="primaryContrast"
                      textKey={'BSH_RETURNGOODS_WORKFLOW_BUTTON_YES'}
                    />
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default ReturnGoodsEditViewContent;
