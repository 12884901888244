import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import { CustomIcon } from '@troy/shared/src/components/common';

import Hyphenate from '../../../utils/hyphenate';
import { getBalanceWithCurrency } from '../../../constants/payment';
import { Translation } from '../../common';

const CLAIM_TABLE_POSITION_KEY = 'CLAIM_TABLE_POSITION';
const CLAIM_TABLE_AMOUNT_KEY = 'CLAIM_TABLE_AMOUNT';

const EXPANDABLE_MAX_COLLAPSED_HEIGHT = 340;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  mock: {
    height: 150
  },
  table: {
    transition: 'max-height 250ms ease-in-out',
    overflowY: 'auto'
  },
  expandLine: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: -1,
    height: 2,
    background: theme.palette.text.disabled
  },
  expand: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 50%)',
    padding: 10,
    background: theme.palette.primary.main,

    '&:hover': {
      background: theme.palette.primary.light
    }
  },
  expandOpen: {
    transform: 'translate(-50%, 50%) rotate(180deg)'
  },
  cell: {
    padding: 12,
    color: theme.palette.text.primary
  },
  headerCell: {
    ...theme.typography.body2Bold,
    background: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    borderBottom: `2px solid ${theme.palette.text.disabled}`
  },
  row: {
    background: theme.palette.background.default,
    cursor: 'pointer',

    '&:hover': {
      background: theme.palette.action.disabled
    }
  },
  rowSelected: {
    background: theme.palette.primary.light
  },
  bodyCell: {
    ...theme.typography.subtitle1,
    borderBottom: 0
  },
  numberCell: {
    ...theme.typography.numerical3
  },
  border: {
    borderBottom: `1px solid ${theme.palette.text.disabled}`
  }
}));

const useExpandableTable = (
  tableRef,
  tableWrapperRef,
  isExpandable,
  isExpanded,
  setIsExpanded
) => {
  const [isExpandableTableOverflowing, setIsExpandableTableOverflowing] =
    useState(false);
  const [height, setHeight] = useState(0);

  const onResize = useCallback(() => {
    const height = tableRef.current.offsetHeight;
    const isOverflow = height > EXPANDABLE_MAX_COLLAPSED_HEIGHT;

    setIsExpandableTableOverflowing(isOverflow);
    setHeight(height);
    if (!isOverflow) {
      setIsExpanded(false);
    }
  }, [tableRef]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => window.removeEventListener('resize', onResize);
  }, [tableRef, tableWrapperRef]);

  useEffect(() => {
    if (!isExpandable && isExpanded) {
      setIsExpanded(false);
    }
  }, [isExpandable, isExpanded, setIsExpanded]);

  const onExpandClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [setIsExpanded, isExpanded]);

  const expandableStyle = {
    maxHeight: `${isExpanded ? height : EXPANDABLE_MAX_COLLAPSED_HEIGHT}px`,
    overflowY: 'scroll'
  };

  return {
    isExpandableTableOverflowing,
    onExpandClick,
    expandableStyle
  };
};

const DemandTableContent = ({
  className,
  tableBodyClassName,
  isMock,
  isExpandable,
  isExpanded,
  setIsExpanded,
  items,
  locale,
  selectedTableRow,
  setSelectedTableRow
}) => {
  const classes = useStyles();
  const tableRef = useRef();
  const tableWrapperRef = useRef();

  const { isExpandableTableOverflowing, onExpandClick, expandableStyle } =
    useExpandableTable(
      tableRef,
      tableWrapperRef,
      isExpandable,
      isExpanded,
      setIsExpanded
    );

  const onSelectRow = index => () => {
    if (setSelectedTableRow) {
      if (selectedTableRow === index) {
        setSelectedTableRow(null);
      } else {
        setSelectedTableRow(index);
      }
    }
  };

  let style = {};

  if (isExpandable) {
    style = expandableStyle;
  }

  let removeTableRowBorderCount = 0;

  return (
    <Box
      className={clsx(
        classes.root,
        !isExpandable && classes.overflowHidden,
        className
      )}
    >
      <Box
        ref={tableWrapperRef}
        className={clsx(
          classes.table,
          isMock && classes.mock,
          tableBodyClassName
        )}
        style={style}
      >
        <Table
          stickyHeader
          ref={tableRef}
          aria-label="demand table"
          id="demand-table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.headerCell, classes.cell)}>
                <Translation inline>{CLAIM_TABLE_POSITION_KEY}</Translation>
              </TableCell>
              <TableCell
                className={clsx(classes.headerCell, classes.cell)}
                align="right"
              >
                <Translation inline>{CLAIM_TABLE_AMOUNT_KEY}</Translation>
              </TableCell>
            </TableRow>
          </TableHead>
          <ClickAwayListener onClickAway={onSelectRow(null)}>
            <TableBody>
              {items.map(({ amount, currency, subject }, index) => {
                if (amount === 0) {
                  removeTableRowBorderCount = 2;
                }
                let removeBorder = false;
                if (removeTableRowBorderCount > 0) {
                  removeBorder = true;
                  removeTableRowBorderCount--;
                }
                return (
                  <TableRow
                    className={clsx(
                      classes.row,
                      index === selectedTableRow && classes.rowSelected
                    )}
                    onClick={onSelectRow(index)}
                    key={index}
                  >
                    <TableCell
                      className={clsx(
                        classes.cell,
                        classes.bodyCell,
                        !removeBorder && classes.border
                      )}
                    >
                      <Hyphenate>
                        <div>{subject}</div>
                      </Hyphenate>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.cell,
                        classes.bodyCell,
                        classes.numberCell,
                        !removeBorder && classes.border
                      )}
                      align="right"
                    >
                      {amount === 0
                        ? ''
                        : getBalanceWithCurrency(amount, currency, locale)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </ClickAwayListener>
        </Table>
      </Box>
      {isExpandable && isExpandableTableOverflowing && (
        <>
          <div className={classes.expandLine} />
          <IconButton
            aria-label="expand"
            className={clsx(classes.expand, isExpanded && classes.expandOpen)}
            onClick={onExpandClick}
            size="large"
          >
            <CustomIcon
              icon="expandMore"
              size="xss"
              variant="primaryContrast"
            />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default DemandTableContent;
