import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import { RETURN_GOODS } from '../../../constants/routes';

import { ThemeButton, Translation } from '../../common';

import boxImg from '../../../assets/img/rented-goods/rented_goods_box.svg';

const useStyles = makeStyles(theme => ({
  content: {
    width: 463,
    display: 'flex',
    flexDirection: 'column'
  },
  contentCompact: {
    width: '100%'
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  boxImg: {
    width: 40,
    height: 40
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  description: {
    ...theme.typography.body1,
    marginTop: theme.spacing(3),
    color: theme.palette.text.primary
  },
  button: {
    minWidth: 288,
    margin: 'auto 0 auto auto'
  },
  buttonCompact: {
    minWidth: '100%',
    margin: '24px auto 0 auto'
  }
}));

const ReturnGoodsInfoAppointment = ({ isCompact }) => {
  const classes = useStyles();
  const history = useHistory();

  const onButtonClick = () => {
    history.push(RETURN_GOODS);
  };

  return (
    <>
      <Box
        className={clsx(classes.content, isCompact && classes.contentCompact)}
      >
        <Box className={classes.titleRow}>
          <img src={boxImg} alt="Box" className={classes.boxImg} />
          <Typography
            className={classes.title}
            component="h3"
            variant={isCompact ? 'h4' : 'h3'}
            color="secondary"
          >
            <Translation inline keyIfNoValue>
              BSH_RETURNGOODS_WORKFLOW_HEADLINE
            </Translation>
          </Typography>
        </Box>
        <Translation className={classes.description} keyIfNoValue>
          BSH_RETURNGOODS_WORKFLOW_INTRO_COPY
        </Translation>
      </Box>
      <ThemeButton
        id="page.home.component.return-goods.appointment-button"
        className={clsx(classes.button, isCompact && classes.buttonCompact)}
        color="secondary"
        variant="outlined"
        size="large"
        endIcon="chevronRight"
        endIconColor="secondary"
        onClick={onButtonClick}
        textKey="BSH_RETURNGOODS_WORKFLOW_BUTTON"
      />
    </>
  );
};

export default ReturnGoodsInfoAppointment;
