import { takeEvery } from 'redux-saga/effects';

import * as authActions from '../actions/auth';
import * as authSagas from './auth';

import * as customersActions from '../actions/customers';
import * as customersSagas from './customers';

import * as decisionsActions from '../actions/decisions';
import * as decisionsSagas from './decisions';

import * as paymentActions from '../actions/payment';
import * as paymentSagas from './payment';

import * as errorActions from '../actions/error';
import * as errorSagas from './error';

import * as questionsActions from '../actions/questions';
import * as questionsSagas from './questions';

import * as wordpressActions from '../actions/wordpress';
import * as wordpressSagas from './wordpress';

import * as postBoxActions from '@troy/shared/src/store/actions/post-box';
import * as postBoxSagas from './post-box';

import * as calculatorActions from '../actions/calculator';
import * as calculatorSagas from './calculator';

import * as fileUploadActions from '../actions/file-upload';
import * as fileUploadSagas from './file-upload';

import * as SAPActions from '../actions/sap';
import * as SAPSagas from './sap';

import * as translationsActions from '../actions/translations';
import * as translationsSagas from './translations';

import * as portalActions from '@troy/shared/src/store/actions/portal';
import * as portalSagas from './portal';
import * as sharedPortalSagas from '@troy/shared/src/store/sagas/portal';

import * as twoFAActions from '../actions/2fa';
import * as twoFASagas from './2fa';

export default function* saga() {
  const relations = [
    [authActions, authSagas],
    [customersActions, customersSagas],
    [decisionsActions, decisionsSagas],
    [paymentActions, paymentSagas],
    [errorActions, errorSagas],
    [questionsActions, questionsSagas],
    [wordpressActions, wordpressSagas],
    [postBoxActions, postBoxSagas],
    [calculatorActions, calculatorSagas],
    [fileUploadActions, fileUploadSagas],
    [SAPActions, SAPSagas],
    [translationsActions, translationsSagas],
    [portalActions, portalSagas],
    [portalActions, sharedPortalSagas],
    [twoFAActions, twoFASagas]
  ];

  for (const [actions, sagas] of relations) {
    for (const [actionName, action] of Object.entries(actions)) {
      const saga = sagas[actionName];

      if (saga) yield takeEvery(action.getType(), saga); // for multiple same async requests running at the same time use takeEvery (e.g. nodes for TreeView loading)
    }
  }
}
