import React from 'react';
import { makeStyles } from '@mui/styles';

import {
  UIFilterOptionClient,
  UIFilterOptionInternal,
  UIFilterOptions
} from '@troy/shared/src/utils/post-box/utils';
import PostBoxFilter from '@troy/shared/src/components/Communication/PostBoxFilter';
import BodySeparator from '@troy/shared/src/components/Communication/BodySeparator';
import {
  filterNotifications,
  makeFiltersFromFilterOptions,
  MessageDateTimeOrder
} from '@troy/shared/src/components/Communication/utils';

import { onAttachmentClick } from '../../../utils/httpUtil';
import { showPostboxLiveAgentChatDebugSelector } from '../../../store/selectors';

import { Blurred, BlurWithLogin, SkeletonModule } from '../../common';

import NotificationList from '../NotificationList';
import UploadFile from '../UploadFile';
import UploadFileModal from '../UploadFileModal';
import mockNotifications from './mockNotifications';

export const usePostboxStyles = makeStyles(theme => ({
  body: {
    padding: `0 ${theme.spacing(2)}`
  },
  separator: {
    marginTop: theme.spacing(3)
  },
  filter: {
    marginTop: theme.spacing(3)
  },
  loginBlur: {
    top: 0,
    transform: 'translate(-50%, 0)'
  }
}));

export default class PostBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      directionFilter: 'all',
      filterOptions: [],
      filters: {},
      messageDateTimeOrder: MessageDateTimeOrder.NewestFirst
    };

    const { internal, showInternalNotesForCustomer } = props;
    const componentFilterOptions = [...UIFilterOptions];
    if (showInternalNotesForCustomer && internal) {
      componentFilterOptions.push(UIFilterOptionInternal);
      componentFilterOptions.push(UIFilterOptionClient);
    }
    this.state.filterOptions = componentFilterOptions;
    this.state.filters = makeFiltersFromFilterOptions(componentFilterOptions);
  }

  componentDidMount() {
    this.onFilterClick();
  }

  componentDidUpdate(prevProps) {
    const { showInternalNotesForCustomer: prevShowInternalNotesForCustomer } =
      prevProps;
    const {
      autoScroll,
      isAuthenticatedAppLoading,
      showInternalNotesForCustomer,
      internal
    } = this.props;

    if (
      !prevShowInternalNotesForCustomer &&
      showInternalNotesForCustomer &&
      internal
    ) {
      const componentFilterOptions = [...UIFilterOptions];
      componentFilterOptions.push(UIFilterOptionInternal);
      componentFilterOptions.push(UIFilterOptionClient);
      this.state.filterOptions = componentFilterOptions;
      this.state.filters = makeFiltersFromFilterOptions(componentFilterOptions);
    }

    if (!isAuthenticatedAppLoading && autoScroll) {
      this.scrollToBottom();
    }

    if (prevProps.notifications !== this.props.notifications) {
      this.onFilterClick();
    }
  }

  onFilterOptionChange = name => event => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: event.target.checked
        }
      },
      this.onFilterClick
    );
  };

  onDirectionFilterChange = type => {
    this.setState(
      {
        directionFilter: type
      },
      this.onFilterClick
    );
  };

  onFilterClick = () => {
    const { notifications } = this.props;
    const { filters, directionFilter, messageDateTimeOrder } = this.state;

    this.setState({
      notifications: filterNotifications(
        notifications,
        filters,
        directionFilter,
        false,
        messageDateTimeOrder === MessageDateTimeOrder.NewestFirst
      )
    });
  };

  onChangeOrder = order => {
    this.setState({ messageDateTimeOrder: order }, this.onFilterClick);
  };

  onUploadFileClick = () => {
    this.props.setFileModalOpened(true);
  };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    const {
      notifications,
      filters,
      filterOptions,
      directionFilter,
      messageDateTimeOrder
    } = this.state;

    const {
      classes,
      directionFilterCounts,
      fileUploadHidden,
      fileUploadBlur,
      notificationsListBlur,
      isAuthenticatedAppLoading,
      isLiabilityPaid
    } = this.props;

    if (isAuthenticatedAppLoading) {
      return (
        <div className={classes.body}>
          <SkeletonModule topMargin type="widgets" />
          <SkeletonModule bottomMargin />
        </div>
      );
    }

    const scrollToNotificationStart = notificationRef => {
      window.scroll({
        top:
          notificationRef.current.offsetTop - 90 - (isLiabilityPaid ? 0 : 56),
        behavior: 'smooth'
      });
    };

    return (
      <div>
        <div className={classes.body}>
          {!fileUploadHidden && (
            <Blurred blur={fileUploadBlur}>
              <UploadFile
                className={classes.separator}
                onButtonClick={this.onUploadFileClick}
              />
            </Blurred>
          )}
          <PostBoxFilter
            className={classes.filter}
            directionFilterCounts={directionFilterCounts}
            onDirectionFilterChange={this.onDirectionFilterChange}
            directionFilter={directionFilter}
            filterOptions={filterOptions}
            filterValues={filters}
            onOptionChange={this.onFilterOptionChange}
          />
          <BodySeparator
            messageDateTimeOrder={messageDateTimeOrder}
            setMessageDateTimeOrder={this.onChangeOrder}
          />
          <BlurWithLogin
            blur={notificationsListBlur}
            descriptionKey="POSTBOX_CENSORED"
            className={classes.loginBlur}
            buttonId="postbox.blur.logout-button"
            mockData={
              <NotificationList
                notifications={mockNotifications}
                customerToken=""
                onFileAttachmentClick={() => {}}
                showPostboxLiveAgentChatDebugSelector={() => false}
                scrollToNotificationStart={() => {}}
              />
            }
          >
            <NotificationList
              notifications={notifications}
              customerToken=""
              onFileAttachmentClick={onAttachmentClick}
              showPostboxLiveAgentChatDebugSelector={
                showPostboxLiveAgentChatDebugSelector
              }
              scrollToNotificationStart={scrollToNotificationStart}
            />
          </BlurWithLogin>
          <UploadFileModal />
        </div>
        <div
          style={{ float: 'left', clear: 'both' }}
          ref={el => {
            this.messagesEnd = el;
          }}
        ></div>
      </div>
    );
  }
}
