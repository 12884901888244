import { createReducer } from 'redux-act';
import * as a from '../actions/auth';

import { isInIframe } from '../../utils/browser';
import { jwtDecode } from '../../utils/jwt';

const checkIsInternal = token => {
  if (token) {
    const decoded = jwtDecode(token);
    return decoded && !!decoded.imp && !!decoded.imp.startsWith('cm:');
  }
  return false;
};

const getTokenUserName = token => {
  if (token) {
    const decoded = jwtDecode(token);
    return decoded && decoded.sub ? decoded.sub : '';
  }
  return '';
};

const DEFAULT_STATE = {
  loading: false,
  tokenLoading: false,
  token: '',
  internal: false,
  username: ''
};

export default createReducer(
  {
    [a.login]: state => {
      return { ...state, loading: true };
    },
    [a.loginError]: state => {
      return { ...state, loading: false };
    },
    [a.loginOk]: (state, { token, ...restProps }) => {
      if (!isInIframe()) {
        localStorage.setItem('token', token);
      }
      return {
        token,
        internal: checkIsInternal(token),
        username: getTokenUserName(token),
        loading: false,
        ...restProps
      };
    },
    [a.setAuthData]: (state, { email, username }) => {
      return { ...state, email, username };
    },
    [a.logoutOk]: () => {
      if (!isInIframe()) {
        localStorage.removeItem('token');
      }
      return DEFAULT_STATE;
    },
    [a.removeToken]: () => {
      if (!isInIframe()) {
        localStorage.removeItem('token');
      }
      return DEFAULT_STATE;
    },
    [a.setAuthTokenLoading]: (state, isLoading) => {
      return { ...state, tokenLoading: isLoading };
    },
    [a.setToken]: (state, token) => {
      if (!isInIframe()) {
        localStorage.setItem('token', token);
      }
      return {
        ...state,
        token,
        username: getTokenUserName(token),
        internal: checkIsInternal(token)
      };
    },
    [a.resetPasswordOk]: state => ({
      ...state,
      isSuccessResetPassword: true
    })
  },
  DEFAULT_STATE
);
