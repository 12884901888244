import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { Text } from '@troy/shared/src/components/common';

import { TWO_FA_TYPE_CLIENT_UNIQUE_ID } from '../../../constants/2fa';
import { useAppWidthClass } from '../../../utils/hooks';
import { ThemeButton } from '../../common';
import { ReactComponent as Leela } from '../../../assets/img/2fa-code/troy-security.svg';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(4),
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(3)
    }
  },
  wrapperMobile: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`
  },
  text: {
    textAlign: 'center'
  },
  img: {
    width: 160,
    height: 208,
    transform: 'rotate(-7.5deg)',

    '& path[fill="#8523FC"]': {
      fill: theme.customColors.botColor
    },
    '& path[fill="#410E81"]': {
      fill: theme.palette.secondary.dark
    },
    '& path[fill="#5C18B1"]': {
      fill: theme.palette.secondary.main
    },
    '& path[fill="#164743"]': {
      fill: theme.palette.primary.dark
    },
    '& path[fill="#1AC7B8"]': {
      fill: theme.palette.primary.main
    }
  },
  imgMobile: {
    width: 102,
    height: 134
  },
  imgWrapper: {
    position: 'absolute',
    transform: 'translate(-156px, -46px)',
    marginBottom: '0 !important'
  },
  imgWrapperMobile: {
    textAlign: 'center'
  }
}));

const TwoFAHelper = ({ type, onButtonClick }) => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  return (
    <div
      className={clsx(classes.wrapper, isMobileWidth && classes.wrapperMobile)}
    >
      <div
        className={
          isMobileWidth ? classes.imgWrapperMobile : classes.imgWrapper
        }
      >
        <Leela
          alt="leela-secure"
          className={clsx(classes.img, isMobileWidth && classes.imgMobile)}
        />
      </div>
      <Text
        translated
        className={classes.text}
        component="p"
        variant="body1"
        color="textPrimary"
      >
        {`${
          type === TWO_FA_TYPE_CLIENT_UNIQUE_ID ? 'SSN' : 'ZIP'
        }_LOGIN_HELP_COPY`}
      </Text>
      <ThemeButton
        fullWidth
        onClick={onButtonClick}
        id="page.home.component.2fa-code-helper.button"
        size="medium"
        color="primary"
        variant="contained"
        textKey="ZIP_LOGIN_GOTIT_BUTTON_LABEL"
        startIcon="confirm"
        startIconColor="primaryContrast"
      />
    </div>
  );
};

export default TwoFAHelper;
