import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import { useAppWidthClass } from '../../utils/hooks';
import { MessageDateTimeOrder } from './utils';
import { FormSelect, Text, Translation } from '../common';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '28px 0'
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  mobileHeaderContent: {
    flexDirection: 'column'
  },
  mobileLabel: {
    marginBottom: '20px'
  },
  header: {
    ...theme.typography.body1Bold,
    color: theme.palette.text.hint
  },
  selectLabel: {
    ...theme.typography.body1Bold,
    color: theme.palette.text.secondary,
    marginRight: 16
  },
  inputControl: {
    display: 'flex',
    alignItems: 'center'
  },
  line: {
    marginTop: 16,
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.grey[500]
  },
  selectRoot: {
    backgroundColor: theme.palette.background.default
  },
  selectItem: {
    ...theme.typography.body1,
    padding: '6px 16px'
  }
}));

interface BodySeparatorProps {
  messageDateTimeOrder: MessageDateTimeOrder;
  setMessageDateTimeOrder: (order: MessageDateTimeOrder) => void;
}

const BodySeparator: React.FC<BodySeparatorProps> = ({
  messageDateTimeOrder,
  setMessageDateTimeOrder
}): React.ReactElement => {
  const classes = useStyles();
  const { isMobileWidth } = useAppWidthClass();

  return (
    <Box className={classes.root}>
      <Box
        className={clsx(
          classes.headerContent,
          isMobileWidth && classes.mobileHeaderContent
        )}
      >
        <Text
          component="div"
          translated
          className={clsx(classes.header, isMobileWidth && classes.mobileLabel)}
        >
          UNIC_POSTBOX_HISTORY
        </Text>
        <Box className={classes.inputControl}>
          <Translation keyIfNoValue className={classes.selectLabel}>
            UNIC_FILTER_SORTBY
          </Translation>
          <FormSelect
            id="postbox.filter.sort-by"
            className={classes.selectRoot}
            classNameMenuItem={classes.selectItem}
            value={messageDateTimeOrder}
            onChange={(value: MessageDateTimeOrder): void =>
              setMessageDateTimeOrder(value)
            }
            options={[
              {
                label: 'UNIC_FILTER_SORTBY_NEWEST',
                value: MessageDateTimeOrder.NewestFirst
              },
              {
                label: 'UNIC_FILTER_SORTBY_OLDEST',
                value: MessageDateTimeOrder.OldestFirst
              }
            ]}
          />
        </Box>
      </Box>
      <Box className={classes.line} />
    </Box>
  );
};

export default BodySeparator;
