import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { isFormalCommunicationSelector } from '../../../store/selectors/ui';
import { useAppWidthClass } from '../../../utils/hooks';
import { getTypeformId } from '../../../utils/typeform/typeform_config';
import { Module } from '@troy/shared/src/components/common';

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '28px 16px'
  },
  wrapperTablet: {
    margin: '24px 16px'
  },
  wrapperMobile: {
    margin: '24px 16px'
  },
  formDesktop: {
    width: '100%',
    height: 446
  },
  formTablet: {
    width: '100%',
    height: 376
  },
  formMobile: {
    width: '100%',
    height: 620
  },
  formElement: {
    width: '100%',
    height: '100%',

    '& > div': {
      width: '100%',
      height: '100%',

      '& > iframe': {
        width: '100%',
        height: '100%',
        border: 'none'
      }
    }
  }
}));

let SCRIPT_LOADING = false;

const FORM_ELEMENT_ID = 'typeform_container';

const TypeformFeedback = ({ language }) => {
  const classes = useStyles();
  const { isDesktopWidth, isTabletWidth, isMobileWidth } = useAppWidthClass();
  const isFormalCommunication = useSelector(isFormalCommunicationSelector);

  const showForm = useCallback(() => {
    const typeformId = getTypeformId(language, isFormalCommunication);

    window.tf.createWidget(typeformId, {
      container: document.getElementById(FORM_ELEMENT_ID),
      hideHeaders: true
    });
  }, [language, isFormalCommunication]);

  useEffect(() => {
    if (!language || SCRIPT_LOADING) {
      return;
    }
    const hasScript = !!document.getElementById('typeform');
    if (!hasScript) {
      SCRIPT_LOADING = true;

      const script = document.createElement('script');
      script.id = 'typeform';
      script.src = 'https://embed.typeform.com/next/embed.js';
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);

      script.onload = script.onreadystatechange = function () {
        const rs = this.readyState;
        if (rs && rs !== 'complete' && rs !== 'loaded') {
          return;
        }

        showForm();
        SCRIPT_LOADING = false;
      };
    } else {
      showForm();
    }
  }, [language]);

  return (
    <Module
      className={clsx(
        classes.wrapper,
        isTabletWidth && classes.wrapperTablet,
        isMobileWidth && classes.wrapperMobile
      )}
    >
      <Box
        className={clsx(
          isDesktopWidth && classes.formDesktop,
          isTabletWidth && classes.formTablet,
          isMobileWidth && classes.formMobile
        )}
      >
        {!!language && (
          <Box className={classes.formElement} id={FORM_ELEMENT_ID} />
        )}
      </Box>
    </Module>
  );
};

export default TypeformFeedback;
