import React from 'react';
import PropTypes from 'prop-types';

import MaterialField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FormField } from '@troy/shared/src/components/common';

const TextField = React.forwardRef(
  (
    {
      field,
      className,
      classNameInput,
      classNameFocused,
      classNameRoot,
      classNameNotched,
      variant,
      type,
      startIcon,
      endIcon,
      onEndIconClick,
      readonly,
      rawInput,
      inputMode,
      pattern,
      id,
      labelId,
      form,
      meta,
      format,
      ...props
    },
    ref
  ) => {
    const onBlur = event => {
      field && field.onBlur && field.onBlur(event);
      props.onBlur && props.onBlur(event);
    };

    const onFocus = event => {
      field && field.onFocus && field.onFocus(event);
      props.onFocus && props.onFocus(event);
    };

    const onChange = event => {
      const match = pattern
        ? new RegExp('^' + pattern + '$').test(event.target.value)
        : true;
      if (match) {
        field && field.onChange && field.onChange(event);
        props.onChange && props.onChange(event);
      }
    };

    return rawInput ? (
      <input
        id={id}
        aria-labelledby={labelId}
        {...{ className, readOnly: readonly, ref }}
        {...props}
        {...field}
        {...{ onBlur, onFocus }}
      />
    ) : (
      <MaterialField
        {...props}
        className={className}
        InputProps={{
          classes: {
            input: classNameInput,
            focused: classNameFocused,
            root: classNameRoot,
            notchedOutline: classNameNotched
          },
          startAdornment: startIcon && (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),

          endAdornment: endIcon && (
            <InputAdornment position="end" onClick={onEndIconClick}>
              {endIcon}
            </InputAdornment>
          ),
          readOnly: readonly,
          inputMode,
          pattern
        }}
        inputProps={{
          id,
          'aria-labelledby': labelId
        }}
        variant={variant || 'outlined'}
        type={type || 'text'}
        ref={ref}
        {...field}
        {...{ onBlur, onFocus, onChange }}
      />
    );
  }
);

const FormTextField = React.forwardRef(
  ({ onChange, onFormat, name, validate, ...props }, ref) => {
    return name ? (
      <FormField
        component={TextField}
        name={name}
        validate={validate}
        onChange={onChange}
        format={onFormat}
        {...{ ...props, ref }}
      />
    ) : (
      <TextField {...props} {...{ ref, onChange }} />
    );
  }
);

TextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFormat: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  validate: PropTypes.func,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  classNameFocused: PropTypes.string,
  classNameRoot: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element
};

export default FormTextField;
