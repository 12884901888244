export const PAYMENT_CANCEL = 'payment/cancel';
export const PAYMENT_FAIL = 'payment/failed';
export const PAYMENT_PENDING = 'payment/pending';
export const PAYMENT_EXPIRED = 'payment/expired';
export const PAYMENT_UNAUTHORIZED = 'payment/unauthorized';
export const FORBIDDEN = 401;
export const NOT_FOUND = 404;
export const SERVER_ERROR = 500;
export const TIMEOUT = 'timeout';
export const EXCEPTION = 'exception';
export const ABORT_ERROR = 'AbortError';
