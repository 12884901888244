import { createReducer } from 'redux-act';
import moment from 'moment';

import * as actions from '../actions/translations';

import { mapToObj } from '../../utils';
import { isInIframe } from '../../utils/browser';
import { languageFromFullCode } from '../../constants/browser';

const STATIC_TEXT_KEY = 'static-text';

const mapTypeToText = arr => (arr ? mapToObj(arr, 'type', 'text') : {});

const staticTranslations = isInIframe()
  ? {}
  : {
      ...mapTypeToText(JSON.parse(localStorage.getItem(STATIC_TEXT_KEY)))
    };

const DEFAULT_STATE = {
  availableLanguages: null,
  language: null,
  hasStaticTexts: Object.keys(staticTranslations).length > 1,
  hasCustomerTexts: false,
  translations: staticTranslations
};

export default createReducer(
  {
    [actions.setTranslations]: (state, { translations, isStaticText }) => {
      if (isStaticText && !isInIframe()) {
        localStorage.setItem(STATIC_TEXT_KEY, JSON.stringify(translations));
      }

      return {
        ...state,
        hasStaticTexts: state.hasStaticTexts || isStaticText,
        hasCustomerTexts: state.hasCustomerTexts || !isStaticText,
        translations: {
          ...state.translations,
          ...mapTypeToText(translations)
        }
      };
    },
    [actions.translationsReset]: () => {
      return DEFAULT_STATE;
    },
    [actions.availableLanguagesReceived]: (state, availableLanguages) => ({
      ...state,
      availableLanguages
    }),
    [actions.setLanguageFinal]: (state, language) => {
      moment.locale(languageFromFullCode(language));
      return {
        ...state,
        language
      };
    },
    [actions.customerTextsReset]: state => ({
      ...state,
      hasCustomerTexts: false
    })
  },
  DEFAULT_STATE
);
